import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken,
  CancelTokenSource,
} from "axios";

// ==== Stores ====
import AuthenticationStore from "stores/AuthenticationStore";

// ==== Types ====
import { IPrivateLabelSetting, EHttpMethods } from "types/globalTypes";
import {
  IIndividualProfile,
  IFinancialProfile,
  IGovernmentId,
  IDemographics,
  ICAISForm,
  IIndustryAffiliation,
  IEmployment,
  IFirmAffiliation,
  IAddress,
  ITrustedContact,
  ITradingExperience,
  IPublicCompanyAffiliation,
  IOnboardingProfile,
  IDocumentType,
} from "views/SignUp/Onboarding/types/onboardingTypes";

// ==== Utilities ====
import { generateBaseUrl, handleErrorTracking } from "utilities/apiUtilities";

class ProfileApi {
  newTokenSource = (): CancelTokenSource => {
    return axios.CancelToken.source();
  };

  getProfileById = async (
    cancelToken: CancelToken,
    profileId: string,
  ): Promise<IOnboardingProfile> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.GET,
      url: `?Id=${profileId}`,
    });

    return response ? response.data : undefined;
  };

  getHasProfile = async (cancelToken: CancelToken): Promise<boolean> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.GET,
      url: `/HasProfile`,
    });

    return response ? response.data : undefined;
  };

  postProfile = async (
    cancelToken: CancelToken,
    GoogleClientId?: string,
  ): Promise<{
    id: string;
  }> => {
    const response = await this._call(
      {
        cancelToken,
        data: { GoogleClientId },
        method: EHttpMethods.POST,
        url: `/Profile`,
      },
      true,
    );

    return response ? response.data : undefined;
  };

  putIndividualProfile = async (
    cancelToken: CancelToken,
    profileId: string,
    individualData: IIndividualProfile,
  ): Promise<IIndividualProfile> => {
    const response = await this._call({
      cancelToken,
      data: individualData,
      method: EHttpMethods.PUT,
      url: `/Individual/${profileId}`,
    });

    return response ? response.data : undefined;
  };

  putAddress = async (
    cancelToken: CancelToken,
    addressData: IAddress,
  ): Promise<IAddress> => {
    const response = await this._call({
      cancelToken,
      data: addressData,
      method: EHttpMethods.PUT,
      url: `/Address`,
    });

    return response ? response.data : undefined;
  };

  deleteAddress = async (
    cancelToken: CancelToken,
    addressId: string,
  ): Promise<boolean> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.DELETE,
      url: `/Address/${addressId}`,
    });

    return response ? response.data : undefined;
  };

  putProfileSettings = async (
    cancelToken: CancelToken,
    profileId: string,
    profileLabelCode: string,
  ): Promise<{
    id: string;
    privateLabelCode: string;
  }> => {
    const response = await this._call({
      cancelToken,
      data: {
        privateLabelCode: profileLabelCode,
      },
      method: EHttpMethods.PUT,
      url: `/ProfileSettings/${profileId}`,
    });

    return response ? response.data : undefined;
  };

  getPrivateLabelSetting = async (
    cancelToken: CancelToken,
    profileLabelCode: string,
  ): Promise<IPrivateLabelSetting> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.GET,
      url: `/PrivateLabelSetting/?PrivateLabelCode=${profileLabelCode}`,
    });

    return response ? response.data : undefined;
  };

  putTrustedContact = async (
    cancelToken: CancelToken,
    trustedContactData?: ITrustedContact,
  ): Promise<ITrustedContact> => {
    const response = await this._call({
      cancelToken,
      data: trustedContactData,
      method: EHttpMethods.PUT,
      url: `/TrustedContact`,
    });

    return response ? response.data : undefined;
  };

  deleteTrustedContact = async (
    cancelToken: CancelToken,
    trustedContactId: string,
  ): Promise<boolean> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.DELETE,
      url: `/TrustedContact/${trustedContactId}`,
    });

    return response ? response.data : undefined;
  };

  putEmploymentByProfileId = async (
    cancelToken: CancelToken,
    profileId: string,
    employmentData: IEmployment,
  ): Promise<IEmployment> => {
    const response = await this._call({
      cancelToken,
      data: employmentData,
      method: EHttpMethods.PUT,
      url: `/Employment/${profileId}`,
    });

    return response ? response.data : undefined;
  };

  putDemographic = async (
    cancelToken: CancelToken,
    profileId: string,
    demographicsData: IDemographics,
  ): Promise<IDemographics> => {
    const response = await this._call({
      cancelToken,
      data: demographicsData,
      method: EHttpMethods.PUT,
      url: `/Demographic/${profileId}`,
    });

    return response ? response.data : undefined;
  };

  putCAISCustomerType = async (
    cancelToken: CancelToken,
    profileId: string,
    caisData: ICAISForm,
  ): Promise<ICAISForm> => {
    const response = await this._call({
      cancelToken,
      data: caisData,
      method: EHttpMethods.PUT,
      url: `/CAISCustomerType/${profileId}`,
    });

    return response ? response.data : undefined;
  };

  putIndustryAffiliation = async (
    cancelToken: CancelToken,
    profileId: string,
    demographicsData: IIndustryAffiliation,
  ): Promise<IIndustryAffiliation> => {
    const response = await this._call({
      cancelToken,
      data: demographicsData,
      method: EHttpMethods.PUT,
      url: `/IndustryAffiliation/${profileId}`,
    });

    return response ? response.data : undefined;
  };

  putPublicCompanyAffiliation = async (
    cancelToken: CancelToken,
    profileId: string,
    affiliationData: IPublicCompanyAffiliation,
  ): Promise<IPublicCompanyAffiliation> => {
    const response = await this._call({
      cancelToken,
      data: affiliationData,
      method: EHttpMethods.PUT,
      url: `/PublicCompanyAffiliation/${profileId}`,
    });

    return response ? response.data : undefined;
  };

  putFirmAffiliation = async (
    cancelToken: CancelToken,
    profileId: string,
    affiliationData: IFirmAffiliation,
  ): Promise<IFirmAffiliation> => {
    const response = await this._call({
      cancelToken,
      data: affiliationData,
      method: EHttpMethods.PUT,
      url: `/FirmAffiliation/${profileId}`,
    });

    return response ? response.data : undefined;
  };

  putGovernmentId = async (
    cancelToken: CancelToken,
    documentData: IGovernmentId,
    documentId: string,
  ): Promise<IGovernmentId> => {
    const response = await this._call({
      cancelToken,
      data: documentData,
      method: EHttpMethods.PUT,
      url: `/GovernmentId?documentId=${documentId}`,
    });

    return response ? response.data : undefined;
  };

  putFinancialProfile = async (
    cancelToken: CancelToken,
    profileId: string,
    incomeData: IFinancialProfile,
  ): Promise<IFinancialProfile> => {
    const response = await this._call({
      cancelToken,
      data: incomeData,
      method: EHttpMethods.PUT,
      url: `/FinancialProfile/${profileId}`,
    });

    return response ? response.data : undefined;
  };

  putTradingExperience = async (
    cancelToken: CancelToken,
    profileId: string,
    yearsOfExperienceData: ITradingExperience,
  ): Promise<ITradingExperience> => {
    const response = await this._call({
      cancelToken,
      data: yearsOfExperienceData,
      method: EHttpMethods.PUT,
      url: `/TradingExperience/${profileId}`,
    });

    return response ? response.data : undefined;
  };

  deleteDocument = async (
    cancelToken: CancelToken,
    documentId: string,
  ): Promise<boolean> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.DELETE,
      url: `/Document/${documentId}`,
    });

    return response ? response.data : undefined;
  };

  getMasterDocument = async (
    cancelToken: CancelToken,
    documentName: string,
  ): Promise<IDocumentType> => {
    const response = await this._call({
      cancelToken,
      url: `/MasterDocument?DocumentName=${documentName}`,
    });

    return response ? response.data : undefined;
  };

  getMasterDocumentDownload = async (
    cancelToken: CancelToken,
    documentName: string,
  ): Promise<string> => {
    const response = await this._call({
      cancelToken,
      responseType: "arraybuffer",
      url: `/MasterDocumentDownload?DocumentName=${documentName}`,
    });

    return response ? response.data : undefined;
  };

  private _call = async (
    config: AxiosRequestConfig,
    v2?: boolean,
  ): Promise<AxiosResponse> => {
    try {
      let _axios = axios.create({
        baseURL: `${generateBaseUrl()}torchapi/${v2 ? "v2" : "v1"}/Profile`,
        headers: {
          Authorization: `Bearer ${AuthenticationStore.accessToken}`,
          "Cache-Control": "no-cache,no-store,max-age=-1",
          Pragma: "no-cache",
        },
        responseType: "json",
        timeout: 60000,
      });

      const response = await _axios(config);
      return response;
    } catch (e) {
      handleErrorTracking(e, "ProfileApi", "_call");
    }
  };
}

export default new ProfileApi();
