import * as React from "react";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { Input, Select, Col, Row, Tooltip } from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import ConfigurationStore from "stores/ConfigurationStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import FormsStore from "stores/FormsStore";

// ==== Components ====
import AddressForm from "views/SignUp/Onboarding/components/AddressForm";
import FormHeader from "components/FormHeader";
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";
import YesNoQuestionFormElement from "components/YesNoQuestionFormElement";

// ==== Types ====
import {
  EOnboardingForms,
  EOnboardingScreens,
  ITrustedContactForm,
} from "views/SignUp/Onboarding/types/onboardingTypes";

// ==== Utilities ====
import {
  getMaxPhoneNumberLength,
  getMinPhoneNumberLength,
} from "utilities/formValidators";
import {
  addOptionalTag,
  concatenateSentences,
} from "utilities/genericUtilities";

// ==== Data ====
import COLORS from "assets/styles/config/colors.json";

const TrustedContactForm: React.FunctionComponent<FormComponentProps> =
  observer(({ form }) => {
    const { getFieldDecorator, validateFields } = form;
    const { t } = useTranslation();

    const handleSubmit = async (
      event: React.FormEvent<HTMLFormElement>,
      nextScreenKey: string,
    ): Promise<void> => {
      event.preventDefault();

      validateFields(async (err: string[]): Promise<void> => {
        if (!err) {
          await OnboardingStore.validateTrustedContactInfo(nextScreenKey);
        }
      });
    };

    const getInitialValue = (key: string) =>
      OnboardingStore.getFormItemData(EOnboardingForms.TRUSTED_CONTACT, key);

    const hasTrustedContact = getInitialValue("HasTrustedContact");
    const hasTrustedContactAddress = getInitialValue(
      "HasTrustedContactAddress",
    );

    React.useEffect(() => {
      if (
        hasTrustedContact &&
        getInitialValue("ContactCountryCode") === undefined
      ) {
        OnboardingStore.setFormItemData(EOnboardingForms.TRUSTED_CONTACT, {
          ContactCountryCode: "1",
        });
      }
    }, [hasTrustedContact]);

    const isNextButtonDisabled = (): {
      disabledMessage: string;
      isDisabled: boolean;
    } => {
      let disabledMessage: string = "";
      let isDisabled: boolean = false;

      // User has not filled out the form yet so we should disable button
      if (hasTrustedContact === undefined) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t("views.signUp.forms.TrustedContactForm.wouldYou"),
        );
      }

      if (hasTrustedContact) {
        if (
          !getInitialValue("ContactFirstName") ||
          !getInitialValue("ContactLastName") ||
          !getInitialValue("ContactRelationship") ||
          !getInitialValue("ContactPhone")
        ) {
          isDisabled = true;
          disabledMessage = concatenateSentences(
            disabledMessage,
            t("views.signUp.forms.TrustedContactForm.MustAddTrustedInfo"),
          );
        }

        // User filled out trusted contact info but did not answer whether they want to
        // provide the address for the trusted contact
        if (hasTrustedContactAddress === undefined) {
          isDisabled = true;
          disabledMessage = concatenateSentences(
            disabledMessage,
            t(
              "views.signUp.forms.TrustedContactForm.MustAddTrustedAddressSelect",
            ),
          );
        }
      }

      if (hasTrustedContactAddress) {
        if (
          getInitialValue("ContactStreetAddress1")?.length > 30 ||
          getInitialValue("ContactStreetAddress2")?.length > 30
        ) {
          isDisabled = true;
          disabledMessage = concatenateSentences(
            disabledMessage,
            t("views.signUp.forms.AddressCollectionForm.errorMessages.tooLong"),
          );
        }

        if (
          !getInitialValue("ContactStreetAddress1") ||
          !getInitialValue("ContactCity") ||
          !getInitialValue("ContactStateProvince") ||
          !getInitialValue("ContactZipPostal")
        ) {
          isDisabled = true;
          disabledMessage = concatenateSentences(
            disabledMessage,
            t("views.signUp.forms.TrustedContactForm.MustAddTrustedAddress"),
          );
        }
      }

      return {
        disabledMessage,
        isDisabled,
      };
    };

    return (
      <div className="trustedContactForm slideInWrapper">
        <FormHeader
          headerText={t("views.signUp.forms.TrustedContactForm.header")}
        />
        <Form layout="vertical" hideRequiredMark>
          <Row justify="space-between">
            <YesNoQuestionFormElement
              getFieldDecorator={getFieldDecorator}
              fieldDecoratorValue={"HasTrustedContact"}
              initialValue={hasTrustedContact}
              label={t("views.signUp.forms.TrustedContactForm.wouldYou")}
              tooltip={
                <Tooltip
                  placement="rightBottom"
                  title={t("views.signUp.forms.TrustedContactForm.moreInfo")}
                >
                  <QuestionCircleTwoTone
                    twoToneColor={COLORS.branding.secondary}
                  />
                </Tooltip>
              }
            />
          </Row>
          {/* Render only after the option has been selected */}
          {OnboardingStore.getFormItemData(
            EOnboardingForms.TRUSTED_CONTACT,
            "HasTrustedContact",
          ) && (
            <React.Fragment>
              <Form.Item>
                {getFieldDecorator("ContactFirstName", {
                  initialValue: getInitialValue("ContactFirstName"),
                  rules: [
                    {
                      message: t("forms.validation.isRequired", {
                        fieldName: t("forms.fields.firstName"),
                      }),
                      required: true,
                    },
                  ],
                  validateTrigger: "onBlur",
                })(
                  <Input
                    placeholder={t("forms.fields.firstName")}
                    allowClear
                  />,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("ContactLastName", {
                  initialValue: getInitialValue("ContactLastName"),
                  rules: [
                    {
                      message: t("forms.validation.isRequired", {
                        fieldName: t("forms.fields.lastName"),
                      }),
                      required: true,
                    },
                  ],
                  validateTrigger: "onBlur",
                })(
                  <Input placeholder={t("forms.fields.lastName")} allowClear />,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("ContactRelationship", {
                  initialValue: getInitialValue("ContactRelationship"),
                  rules: [
                    {
                      message: t("forms.validation.isRequired", {
                        fieldName: t("forms.fields.relationship"),
                      }),
                      required: true,
                    },
                  ],
                  validateTrigger: "onBlur",
                })(
                  <Input
                    placeholder={t("forms.fields.relationship")}
                    allowClear
                  />,
                )}
              </Form.Item>
              <Form.Item required={true}>
                <Row gutter={12}>
                  <Col span={12}>
                    <Form.Item>
                      {getFieldDecorator("ContactCountryCode", {
                        initialValue: getInitialValue("ContactCountryCode"),
                        rules: [
                          {
                            message: t("forms.validation.isRequired", {
                              fieldName: t("forms.fields.countryCode"),
                            }),
                            required: true,
                          },
                        ],
                      })(
                        <Select
                          filterOption={(inputValue, option) =>
                            option.props.children
                              .toString()
                              .toLowerCase()
                              .includes(inputValue.toLowerCase())
                          }
                        >
                          {ConfigurationStore?.phoneCountryCodes?.map(
                            ({ countryCode, countryName }) => (
                              <Select.Option
                                key={countryName}
                                value={countryCode}
                              >
                                +{countryCode.trim()} ({countryName})
                              </Select.Option>
                            ),
                          )}
                        </Select>,
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item>
                      {getFieldDecorator("ContactPhone", {
                        initialValue: getInitialValue("ContactPhone"),
                        rules: [
                          {
                            message: t("forms.validation.isRequired", {
                              fieldName: t("forms.fields.phoneNumber"),
                            }),
                            required: true,
                          },
                          {
                            message: t("forms.validation.min", {
                              number: getMinPhoneNumberLength(
                                getInitialValue("ContactCountryCode"),
                              ),
                            }),
                            min: getMinPhoneNumberLength(
                              getInitialValue("ContactCountryCode"),
                            ),
                          },
                          {
                            max: getMaxPhoneNumberLength(
                              getInitialValue("ContactCountryCode"),
                            ),
                            message: t("forms.validation.max", {
                              number: getMaxPhoneNumberLength(
                                getInitialValue("ContactCountryCode"),
                              ),
                            }),
                          },
                          {
                            message: t("forms.validation.digits"),
                            pattern: new RegExp("^\\d+$"),
                          },
                        ],
                        validateTrigger: "onBlur",
                      })(<Input inputMode="tel" />)}
                    </Form.Item>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("ContactEmail", {
                  initialValue: getInitialValue("ContactEmail"),
                  validateTrigger: "onBlur",
                })(
                  <Input
                    placeholder={addOptionalTag(
                      t,
                      "views.signUp.forms.TrustedContactForm.contactEmail",
                    )}
                    allowClear
                  />,
                )}
              </Form.Item>
              <div style={{ paddingTop: 25 }}>
                <YesNoQuestionFormElement
                  getFieldDecorator={getFieldDecorator}
                  fieldDecoratorValue={"HasTrustedContactAddress"}
                  initialValue={hasTrustedContactAddress}
                  label={t("views.signUp.forms.TrustedContactForm.addAddress")}
                />
                {OnboardingStore.getFormItemData(
                  EOnboardingForms.TRUSTED_CONTACT,
                  "HasTrustedContactAddress",
                ) && (
                  <AddressForm
                    form={form}
                    decoratorPrefix="Contact"
                    formKey={EOnboardingForms.TRUSTED_CONTACT}
                    isRequired={true}
                  />
                )}
              </div>
            </React.Fragment>
          )}
          <FormButtonFooter
            isGoForwardDisabled={isNextButtonDisabled().isDisabled}
            goForwardDisabledMessage={isNextButtonDisabled().disabledMessage}
            goForwardHandler={(e) =>
              handleSubmit(e, EOnboardingScreens.STONEX_AFFILIATE)
            }
            goBackHandler={() =>
              FormsStore.setCurrentScreen(
                EOnboardingScreens.EMPLOYMENT_INFORMATION,
              )
            }
          />
        </Form>
      </div>
    );
  });

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: ITrustedContactForm) => {
    OnboardingStore.updateFormData(
      changedFields,
      EOnboardingForms.TRUSTED_CONTACT,
    );
  },
})(TrustedContactForm);
