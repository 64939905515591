// ==== Enums ====
export const TOKEN_USER_STATES = {
  // Token found, user has authenticated
  AUTHORIZED: "Authorized",
  // Got me, it got blow'd up.
  ERROR: "Error",
  // User found but must login through auth
  REQUIRES_LOGIN: "RequiresLogin",
  // Account found but requires a new token
  TOKEN_NOT_FOUND: "TokenNotFound",
  // No user found, need auth token AND user submit
  USER_NOT_FOUND: "UserNotFound",
};

export const EAuthenticationScreens = {
  ACCOUNT_SELECTION: "accountSelection",
  EMAIL_VALIDATION: "emailValidation",
  LOGIN: "login",
  NEW_USER_CREATION: "newUserCreation",
  VERIFICATION_CODE: "verificationCode",
};

// ==== Interfaces ====
export interface IDevToken {
  dateOfBirth: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneCountryCode: string;
  phoneNumber: string;
  token: string;
  userState: string;
}

// ==== Forms ====
export interface ILoginFormData {
  ConfirmEmail: string;
  Email: string;
}

// Mixed cases make me sad =(
// Will be fixed in the great lowercase refactoring of 2022
export interface INewUserCreationFormData {
  ConfirmCountryCode: string;
  ConfirmPhone: string;
  CountryCode: string;
  Phone: string;
  companyName: string;
  confirmEmail: string;
  confirmPassword: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
}

export interface IVerificationCodeFormData {
  password?: string;
  verificationCode: string;
}

// ==== API ====
export interface ISignUpApiRequest {
  companyName?: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  phoneNumber: {
    countryCode: string;
    phone: string;
  };
}

export interface IVerificationCodeApiRequest {
  activationCode: string;
  email: string;
  password: string;
}
export interface IVerificationCodeApiResponse {
  jwt: string;
  success: boolean;
}
