import * as React from "react";
import { Row, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// ==== Components ====
import FormHeader from "components/FormHeader";

// ==== Stores ====
import AuthenticationStore from "stores/AuthenticationStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import BeneficiariesStore from "views/SignUp/Onboarding/store/BeneficiariesStore";
import FormsStore from "stores/FormsStore";

// ==== Utilities ====
import { assignNewUrl } from "utilities/googleTagsUtilities";

const BasicRow: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleOnClick = () => {
    const { urlRedirectUrl, privateLabelSetting } = AuthenticationStore;

    // If there is a redirect URL
    if (urlRedirectUrl) {
      assignNewUrl(urlRedirectUrl);
      // Fallback to the PLS return link
    } else if (privateLabelSetting?.returnLink) {
      assignNewUrl(privateLabelSetting?.returnLink);
      // All else fails, take them home
    } else {
      OnboardingStore.handleFormReset();
      BeneficiariesStore.handleFormReset();
      FormsStore.setCurrentScreen(undefined);
      history.push("/l");
    }
  };

  return (
    <div style={{ marginBottom: 20 }}>
      <FormHeader
        headerText={t("views.signUp.forms.SuccessScreen.headerBasic")}
        subheaderText={t("views.signUp.forms.SuccessScreen.subheaderBasic")}
      />

      <Row align="middle" justify="end" style={{ marginTop: 25 }}>
        <Button
          disabled={!AuthenticationStore.config.clientUri}
          onClick={handleOnClick}
          type="link"
        >
          {t("forms.buttons.returnHome")}
        </Button>
      </Row>
    </div>
  );
};

export default BasicRow;
