import * as React from "react";
import { useTranslation } from "react-i18next";

const PrivacyPolicyFooter: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="meta">
      <i>
        {`${t("views.signUp.forms.IndividualForm.footer1")} ${t(
          "views.signUp.forms.IndividualForm.footer2a",
        )}`}
        <a
          className="link"
          target="_blank"
          rel="noopener noreferrer"
          href=" https://stonex.com/globalassets/landingpage-content/disclosures/comp_privacy-policy-sx1-20211103x.pdf"
        >
          {` ${t("views.signUp.forms.IndividualForm.footer2b")} `}
        </a>
        {t("views.signUp.forms.IndividualForm.footer2c")}
      </i>
    </div>
  );
};

export default PrivacyPolicyFooter;
