// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import FormsStore from "stores/FormsStore";
import AuthenticationStore from "stores/AuthenticationStore";

// ==== Apis ====
import UserApi from "apis/UserApi";

// ==== Utilities ====
import { handleErrorTracking } from "utilities/apiUtilities";

export const getUserProfile = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);
    const response = await UserApi.getProfile(
      AuthenticationStore._tokenSource.token,
    );

    if (response?.user) {
      OnboardingStore.setProfileId(response.user.userId);
    } else {
      FormsStore.setErrorHandler("getUserProfile");
    }
  } catch (e) {
    handleErrorTracking(e, "UserApiHandler", "getUserProfile");
  } finally {
    FormsStore.setLoading(false);
  }
};
