import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken,
  CancelTokenSource,
} from "axios";

// ==== Types ====
import { EHttpMethods } from "types/globalTypes";
import type { IConfig, IFormData } from "types/globalTypes";
import type { IDevToken } from "types/authenticationTypes";

// ==== Utilities ====
import { handleErrorTracking } from "utilities/apiUtilities";

class ConfigurationApi {
  getConfig = async (
    cancelToken: CancelToken,
  ): Promise<AxiosResponse<IConfig>> => {
    return await this._call({
      cancelToken,
      method: EHttpMethods.GET,
      url: "/l/config",
    });
  };

  getDevToken = async (
    cancelToken: CancelToken,
  ): Promise<AxiosResponse<IDevToken>> => {
    return await this._call({
      cancelToken,
      method: EHttpMethods.GET,
      url: "/l/token",
    });
  };

  postContact = async (
    data: IFormData,
    cancelToken: CancelToken,
  ): Promise<AxiosResponse> => {
    return await this._call({
      cancelToken,
      data,
      method: EHttpMethods.POST,
      url: "/l/v1/contact",
    });
  };

  getCountryCodeData = async (cancelToken: CancelToken): Promise<any> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.GET,
      url: "/l/v1/CountryCode",
    });

    return response.data;
  };

  getPhoneCountryCodeData = async (cancelToken: CancelToken): Promise<any> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.GET,
      url: "/l/v1/CountryCode/Phone",
    });

    return response.data;
  };

  newTokenSource = (): CancelTokenSource => {
    return axios.CancelToken.source();
  };

  private _call = async (
    config: AxiosRequestConfig,
  ): Promise<AxiosResponse> => {
    try {
      let _axios = axios.create({
        responseType: "json",
        timeout: 60000,
      });
      const response = await _axios(config);
      return response;
    } catch (e) {
      handleErrorTracking(e, "ConfigurationApi", "_call");
    }
  };
}

export default new ConfigurationApi();
