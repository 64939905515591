import * as React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Types ====
import { EOnboardingForms } from "views/SignUp/Onboarding/types/onboardingTypes";

// ==== Components ====
import SummaryFieldBlock from "components/SummaryFieldBlock";
import SummaryHeader from "views/SignUp/Onboarding/forms/SummaryPage/SummaryHeader";

interface IProps {
  isEditButtonDisabled?: boolean;
}

const SummaryPersonalInformationForm: React.FunctionComponent<IProps> =
  observer(({ isEditButtonDisabled }) => {
    const { t } = useTranslation();

    const getFormData = (key: string) =>
      OnboardingStore.getFormItemData(EOnboardingForms.PERSONAL_INFO, key);

    return (
      <React.Fragment>
        <SummaryHeader
          header={t("views.signUp.components.summery.taxInformation")}
          editButtonKey={
            !isEditButtonDisabled && EOnboardingForms.PERSONAL_INFO
          }
        />
        {getFormData("CountryOfCitizenship") && (
          <SummaryFieldBlock
            question={`${t(
              "views.signUp.forms.PersonalInfoForm.countryOfCitizenship",
            )}:`}
            answer={getFormData("CountryOfCitizenship")}
          />
        )}
        {getFormData("USTaxId") && (
          <SummaryFieldBlock
            question={`${t("views.signUp.forms.PersonalInfoForm.TaxId")}:`}
            answer={getFormData("USTaxId")}
          />
        )}
        {getFormData("ForeignTaxId") && (
          <SummaryFieldBlock
            question={`${t(
              "views.signUp.forms.PersonalInfoForm.ForeignTaxId",
            )}:`}
            answer={getFormData("ForeignTaxId")}
          />
        )}
      </React.Fragment>
    );
  });

export default SummaryPersonalInformationForm;
