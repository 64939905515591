import * as React from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  isLink?: boolean;
}

declare global {
  interface Window {
    embedded_svc?: any;
  }
}

function SalesForceChat({ isLink }: IProps) {
  const { t } = useTranslation();

  const openSalesforceChatWindow = () => {
    let element: HTMLElement = document.getElementsByClassName(
      "helpButtonEnabled",
    )[0] as HTMLElement;
    element.click();
  };

  const renderButton = (isLink: boolean) => {
    if (isLink) {
      // link
      return (
        <a onClick={() => openSalesforceChatWindow()} id="chat-button">
          {t("contact.chatSupportLink")}
        </a>
      );
    }

    // Button
    return (
      <div>
        <a
          className="ant-btn salesForceButton"
          onClick={() => openSalesforceChatWindow()}
          id="chat-button"
        >
          {t("contact.chatSupportButton")}
        </a>
      </div>
    );
  };

  return (
    <div
      className="centeredContent salesForceChat"
      style={{ paddingBottom: 10 }}
    >
      {renderButton(isLink)}
    </div>
  );
}

export default SalesForceChat;
