import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Input } from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import FormsStore from "stores/FormsStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Components ====
import FormHeader from "components/FormHeader";
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";
import YesNoQuestionFormElement from "components/YesNoQuestionFormElement";

// ==== Types ====
import {
  EOnboardingForms,
  EOnboardingScreens,
  IStockExchangeAssociationForm,
} from "views/SignUp/Onboarding/types/onboardingTypes";
import {
  ACCOUNT_OPENING_TYPES,
  RELATIONSHIP_TYPES,
} from "views/SignUp/Onboarding/types/onboardingEnums";

// ==== Utilities ====
import { concatenateSentences } from "utilities/genericUtilities";

const StockExchangeAssociationForm: React.FunctionComponent<FormComponentProps> =
  observer(({ form }) => {
    const { getFieldDecorator, validateFields } = form;
    const { t } = useTranslation();

    const handleSubmit = async (
      event: React.FormEvent<HTMLFormElement>,
      nextScreenKey: string,
    ): Promise<void> => {
      event.preventDefault();

      validateFields(async (err: string[]): Promise<void> => {
        if (!err) {
          await OnboardingStore.validateExchangeAssociationInfo(nextScreenKey);
        }
      });
    };

    const getInitialValue = (key: string) =>
      OnboardingStore.getFormItemData(
        EOnboardingForms.STOCK_EXCHANGE_ASSOCIATION,
        key,
      );

    const getNextButtonKey = (): string => {
      if (
        OnboardingStore.getAccountType() ===
          ACCOUNT_OPENING_TYPES.TRADITIONAL_IRA.value ||
        OnboardingStore.getAccountType() ===
          ACCOUNT_OPENING_TYPES.ROTH_IRA.value
      ) {
        return EOnboardingScreens.BENEFICIARIES;
      }

      return EOnboardingScreens.SUMMARY;
    };

    const isNextButtonDisabled = (): {
      disabledMessage: string;
      isDisabled: boolean;
    } => {
      let isDisabled = false;
      let disabledMessage = "";

      if (typeof getInitialValue("IsFINRA") === "undefined") {
        isDisabled = true;
        disabledMessage = t(
          "views.signUp.forms.StockExchangeAssociationForm.validationMessages.FINRAStatus",
        );
      }

      // If the user has a FINRA status, they must fill out the name
      if (getInitialValue("IsFINRA") && !getInitialValue("BusinessName")) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t(
            "views.signUp.forms.StockExchangeAssociationForm.validationMessages.relatedBusinessName",
          ),
        );
      }

      if (typeof getInitialValue("Rule114") === "undefined") {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t(
            "views.signUp.forms.StockExchangeAssociationForm.validationMessages.rule114",
          ),
        );
      }

      // If the user has a Rule114 status, they must fill out the CompanyName and CUSIP
      if (getInitialValue("Rule114")) {
        if (!getInitialValue("CompanyName") || !getInitialValue("CUSIP")) {
          isDisabled = true;
          disabledMessage = concatenateSentences(
            disabledMessage,
            t(
              "views.signUp.forms.StockExchangeAssociationForm.validationMessages.CUSIP",
            ),
          );
        }
      }

      return {
        disabledMessage,
        isDisabled,
      };
    };

    const isSnexAffiliated = (): boolean => {
      let status = false;

      const isSnexRelated = OnboardingStore.getFormItemData(
        EOnboardingForms.STONEX_AFFILIATE,
        "AreYouRelated",
      );
      const relation = OnboardingStore.getFormItemData(
        EOnboardingForms.STONEX_AFFILIATE,
        "AssociationRelationshipType",
      );

      /* If user answered yes to being StoneX related, and their relationship to the related is
     self, child, or spouse then the affiliated status should be true */
      if (
        isSnexRelated &&
        (relation === RELATIONSHIP_TYPES.CHILD.value ||
          relation === RELATIONSHIP_TYPES.SELF.value ||
          relation === RELATIONSHIP_TYPES.SPOUSE.value)
      ) {
        status = true;
      }
      return status;
    };

    return (
      <div className="slideInWrapper stockExchangeAssociationForm">
        <FormHeader
          headerText={t(
            "views.signUp.forms.StockExchangeAssociationForm.header",
          )}
        />
        <Form layout="vertical" hideRequiredMark>
          <YesNoQuestionFormElement
            getFieldDecorator={getFieldDecorator}
            isNoDisabled={isSnexAffiliated()}
            fieldDecoratorValue={"IsFINRA"}
            initialValue={getInitialValue("IsFINRA")}
            label={t(
              "views.signUp.forms.StockExchangeAssociationForm.areYouAssociated",
            )}
            radioTooltipMessage={t(
              "views.signUp.forms.StockExchangeAssociationForm.tooltipMessage",
            )}
          />
          {getInitialValue("IsFINRA") && (
            <React.Fragment>
              <Form.Item className="indentedContent">
                {getFieldDecorator("BusinessName", {
                  initialValue: getInitialValue("BusinessName"),
                  rules: [
                    {
                      message: t("forms.validation.isRequired", {
                        fieldName: t("forms.fields.field"),
                      }),
                      required: true,
                    },
                  ],
                  validateTrigger: "onBlur",
                })(
                  <Input
                    placeholder={t(
                      "views.signUp.forms.StockExchangeAssociationForm.businessName",
                    )}
                    allowClear
                  />,
                )}
              </Form.Item>
            </React.Fragment>
          )}
          <div className="subheader" style={{ marginBottom: 8 }}>
            {t("views.signUp.forms.StockExchangeAssociationForm.rule114.line1")}
            <a
              className="link"
              href="https://www.sec.gov/reportspubs/investor-publications/investorpubsrule144htm.html"
              target="_blank"
              rel="noreferrer"
            >
              {t(
                "views.signUp.forms.StockExchangeAssociationForm.rule114.line2",
              )}
            </a>
          </div>
          <YesNoQuestionFormElement
            getFieldDecorator={getFieldDecorator}
            fieldDecoratorValue={"Rule114"}
            initialValue={getInitialValue("Rule114")}
          />
          {getInitialValue("Rule114") && (
            <React.Fragment>
              <Form.Item className="indentedContent">
                {getFieldDecorator("CompanyName", {
                  initialValue: getInitialValue("CompanyName"),
                  rules: [
                    {
                      message: t("forms.validation.isRequired", {
                        fieldName: t("forms.fields.field"),
                      }),
                      required: true,
                    },
                  ],
                  validateTrigger: "onBlur",
                })(
                  <Input
                    placeholder={t(
                      "views.signUp.forms.StockExchangeAssociationForm.companyName",
                    )}
                    allowClear
                  />,
                )}
              </Form.Item>
              <Form.Item className="indentedContent">
                {getFieldDecorator("CUSIP", {
                  initialValue: getInitialValue("CUSIP"),
                  rules: [
                    {
                      message: t("forms.validation.isRequired", {
                        fieldName: t("forms.fields.field"),
                      }),
                      required: true,
                    },
                  ],
                  validateTrigger: "onBlur",
                })(
                  <Input
                    placeholder={t(
                      "views.signUp.forms.StockExchangeAssociationForm.whatIsCUSP",
                    )}
                    allowClear
                  />,
                )}
              </Form.Item>
            </React.Fragment>
          )}
          <FormButtonFooter
            goForwardHandler={(e) => handleSubmit(e, getNextButtonKey())}
            isGoForwardDisabled={isNextButtonDisabled().isDisabled}
            goForwardDisabledMessage={isNextButtonDisabled().disabledMessage}
            goBackHandler={() =>
              FormsStore.setCurrentScreen(EOnboardingScreens.CAIS)
            }
          />
        </Form>
      </div>
    );
  });

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: IStockExchangeAssociationForm) => {
    OnboardingStore.updateFormData(
      changedFields,
      EOnboardingForms.STOCK_EXCHANGE_ASSOCIATION,
    );
  },
})(StockExchangeAssociationForm);
