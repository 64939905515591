import * as React from "react";
import { observer } from "mobx-react";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";

// ==== Types ====
import {
  ACCOUNT_OPENING_TYPES,
  ACCOUNT_OPEN_STATUSES,
} from "views/SignUp/Onboarding/types/onboardingEnums";
import { ERoutePaths } from "types/globalTypes";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import AuthenticationStore from "stores/AuthenticationStore";

// ==== Components ====
import FormHeader from "components/FormHeader";
import AlertBanner from "views/SignUp/Onboarding/forms/SuccessContainer/components/AlertBanner";
import ErrorMessageAlert from "views/SignUp/Onboarding/components/ErrorMessageAlert";
import MarginRow from "views/SignUp/Onboarding/forms/SuccessContainer/components/MarginRow";
import OptionRow from "views/SignUp/Onboarding/forms/SuccessContainer/components/OptionRow";
import BasicRow from "views/SignUp/Onboarding/forms/SuccessContainer/components/BasicRow";
import HomeFooterButton from "components/HomeFooterButton";

// ==== Utilities ====
import { assignNewUrl } from "utilities/googleTagsUtilities";

export const renderFeaturesByAccountType = (accountType: number) => {
  const handleOnClick = () => {
    assignNewUrl(AuthenticationStore.config.oneStonexUri);
  };

  switch (accountType) {
    case ACCOUNT_OPENING_TYPES.INDIVIDUAL.value:
    case ACCOUNT_OPENING_TYPES.INDIVIDUAL_FOREIGN.value:
      return (
        <React.Fragment>
          <MarginRow />
          <OptionRow />
          <HomeFooterButton handleOnClick={handleOnClick} />
        </React.Fragment>
      );
    case ACCOUNT_OPENING_TYPES.TRADITIONAL_IRA.value:
    case ACCOUNT_OPENING_TYPES.ROTH_IRA.value:
      return (
        <React.Fragment>
          <OptionRow />
          <HomeFooterButton handleOnClick={handleOnClick} />
        </React.Fragment>
      );
    default:
      return <BasicRow />;
  }
};

const SuccessContainer: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();

  const renderErrorState = () => (
    <React.Fragment>
      <FormHeader
        headerText={t("views.signUp.forms.SuccessScreen.errorHeader")}
      />
      <p>{t("views.signUp.forms.SuccessScreen.errorBody")}</p>
      <ErrorMessageAlert resetPath={ERoutePaths.SELF_ENROLL} />
    </React.Fragment>
  );

  const renderContent = () => {
    // First check if done loading
    const accountStatus = OnboardingStore.getAccountOpening()?.status;

    if (
      accountStatus === ACCOUNT_OPEN_STATUSES.SUBMITTED.value ||
      accountStatus === ACCOUNT_OPEN_STATUSES.OPENED.value
    ) {
      return renderFeaturesByAccountType(OnboardingStore.getAccountType());
    }
    // She's dead Jim
    if (OnboardingStore.errorMaxRetryFailed) {
      return renderErrorState();
    }

    return <div style={{ height: 200 }} />;
  };

  return (
    <div className="slideInWrapper">
      <AlertBanner
        accountStatus={OnboardingStore.getAccountOpening()?.status}
      />
      <Spin spinning={OnboardingStore.submitLoading}>{renderContent()}</Spin>
    </div>
  );
});

export default SuccessContainer;
