import * as React from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { observer } from "mobx-react";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";

// ==== Components ====
import FormHeader from "components/FormHeader";
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import FormsStore from "stores/FormsStore";

// ==== Api Handlers ====
import { getEligibleAccountOpeningType } from "apiHandlers/AccountOpeningApiHandler";

// ==== Types ====
import { EOnboardingScreens } from "views/SignUp/Onboarding/types/onboardingTypes";
import {
  ACCOUNT_OPENING_TYPES,
  IAccount,
} from "views/SignUp/Onboarding/types/onboardingEnums";

// ==== Utilities ====
import { isEnumFalsey } from "utilities/genericUtilities";

const isAccountTypeEnabled = (accountTypeValue: number) => {
  switch (accountTypeValue) {
    case ACCOUNT_OPENING_TYPES.INDIVIDUAL.value:
    case ACCOUNT_OPENING_TYPES.INDIVIDUAL_FOREIGN.value:
      return true;
    case ACCOUNT_OPENING_TYPES.TRADITIONAL_IRA.value:
    case ACCOUNT_OPENING_TYPES.ROTH_IRA.value:
      return false;
    default:
      return false;
    // code block
  }
};

const AccountCreationForm: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const [isAccountEligibleError, setIsAccountEligibleError] =
    React.useState(false);

  React.useEffect(() => {
    const { eligibleAccountTypes, accountOpening } = OnboardingStore;

    const initializeAccountCreation = async (): Promise<void> => {
      await getEligibleAccountOpeningType();
    };

    // If we don't know the user's eligible account types when they get to this
    // form, preload them
    if (!eligibleAccountTypes) {
      initializeAccountCreation();
    }

    // Check to see if we have an open existing accountOpening
    if (isEnumFalsey(accountOpening?.openType)) {
      // If the current open account is NOT eligible, throw alert
      if (!eligibleAccountTypes.includes(accountOpening.openType)) {
        setIsAccountEligibleError(true);
      }
    }
  }, []);

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    nextScreenKey: string,
  ): Promise<void> => {
    await OnboardingStore.validateCreationSelection(
      nextScreenKey,
      isAccountEligibleError,
    );
  };

  const isNextButtonDisabled = (): boolean => {
    const { getAccountOpeningType, getFormItemData } = OnboardingStore;

    if (isAccountEligibleError) {
      // If there is an eligibility error, make sure the user selects something else
      if (
        getAccountOpeningType() ===
        getFormItemData(EOnboardingScreens.ACCOUNT_CREATION, "accountType")
      ) {
        return true;
      }
    }

    return !isEnumFalsey(OnboardingStore.getAccountType());
  };

  const renderAccountButton = (accountType: IAccount) => {
    // Check eligibility
    if (!OnboardingStore.eligibleAccountTypes?.includes(accountType.value)) {
      return;
    }

    const accountButtonOnClickHandler = () => {
      OnboardingStore.setFormItemData(EOnboardingScreens.ACCOUNT_CREATION, {
        accountType: accountType.value,
      });
    };

    const getAccountButtonClasses = () => {
      let classes = "";

      if (!isAccountTypeEnabled(accountType.value)) {
        classes += " isDisabled";
      }

      if (!isAccountEligibleError) {
        if (isEnumFalsey(OnboardingStore.getAccountOpeningType())) {
          classes += " isDisabled";
        }
      }

      if (OnboardingStore.getAccountType() === accountType.value) {
        classes += " isSelected";
      }

      return classes;
    };

    return (
      <div
        onClick={accountButtonOnClickHandler}
        className={`button flexColumn centeredContent ${getAccountButtonClasses()} 
        `}
      >
        <div>{t(accountType.translationKey)}</div>
      </div>
    );
  };

  const renderContentHeader = (accountType: number) => {
    switch (accountType) {
      case ACCOUNT_OPENING_TYPES.INDIVIDUAL.value:
        return t("views.signUp.forms.AccountCreationForm.accountTypeHeader", {
          accountType: t(
            "views.signUp.forms.AccountCreationForm.accountTypes.individual",
          ),
        });
      case ACCOUNT_OPENING_TYPES.INDIVIDUAL_FOREIGN.value:
        return t("views.signUp.forms.AccountCreationForm.accountTypeHeader", {
          accountType: t(
            "views.signUp.forms.AccountCreationForm.accountTypes.individualForeign",
          ),
        });
      case ACCOUNT_OPENING_TYPES.TRADITIONAL_IRA.value:
        return t("views.signUp.forms.AccountCreationForm.accountTypeHeader", {
          accountType: t(
            "views.signUp.forms.AccountCreationForm.accountTypes.traditionalIra",
          ),
        });
      case ACCOUNT_OPENING_TYPES.ROTH_IRA.value:
        return t("views.signUp.forms.AccountCreationForm.accountTypeHeader", {
          accountType: t(
            "views.signUp.forms.AccountCreationForm.accountTypes.rothIra",
          ),
        });
      default:
        return t("views.signUp.forms.AccountCreationForm.accountDefaultHeader");
    }
  };

  const renderContentBody = (accountType: number) => {
    switch (accountType) {
      case ACCOUNT_OPENING_TYPES.INDIVIDUAL.value:
        return (
          <p>
            {t(
              "views.signUp.forms.AccountCreationForm.accountDescriptions.individual",
            )}
          </p>
        );
      case ACCOUNT_OPENING_TYPES.INDIVIDUAL_FOREIGN.value:
        return (
          <p>
            {t(
              "views.signUp.forms.AccountCreationForm.accountDescriptions.individualForeign",
            )}
          </p>
        );
      case ACCOUNT_OPENING_TYPES.TRADITIONAL_IRA.value:
        return (
          <p>
            {t(
              "views.signUp.forms.AccountCreationForm.accountDescriptions.traditionalIra",
            )}
          </p>
        );
      case ACCOUNT_OPENING_TYPES.ROTH_IRA.value:
        return (
          <p>
            {t(
              "views.signUp.forms.AccountCreationForm.accountDescriptions.rothIra",
            )}
          </p>
        );
      default:
        return (
          <React.Fragment>
            <p>
              {t("views.signUp.forms.AccountCreationForm.accountDefaultBody1")}
            </p>
            <p>
              {t("views.signUp.forms.AccountCreationForm.accountDefaultBody2")}
            </p>
          </React.Fragment>
        );
    }
  };

  const renderContent = (accountType: number) => (
    <React.Fragment>
      <div className="contentHeader">{renderContentHeader(accountType)}</div>
      <div>{renderContentBody(accountType)}</div>
    </React.Fragment>
  );

  const getGoBackHandler = () => {
    // Disable ability to Go Back if user is creating an additional account
    if (OnboardingStore.isCreatingMultipleAccounts) {
      return;
    }

    return () => {
      FormsStore.setCurrentScreen(EOnboardingScreens.PERSONAL_INFO);
    };
  };

  const goForwardHandler = (e: React.FormEvent<HTMLFormElement>) => {
    let nextScreenKey;

    if (OnboardingStore.isCreatingMultipleAccounts) {
      const accountType = OnboardingStore.getAccountType();

      if (
        accountType === ACCOUNT_OPENING_TYPES.ROTH_IRA.value ||
        accountType === ACCOUNT_OPENING_TYPES.TRADITIONAL_IRA.value
      ) {
        nextScreenKey = EOnboardingScreens.BENEFICIARIES;
      } else {
        nextScreenKey = EOnboardingScreens.SUMMARY;
      }
    } else {
      nextScreenKey = EOnboardingScreens.ADDRESS_COLLECTION;
    }

    handleSubmit(e, nextScreenKey);
  };

  return (
    <div className="slideInWrapper accountSelection">
      {isAccountEligibleError && (
        <Alert
          message={t(
            "views.signUp.forms.AccountCreationForm.plsChangeAccountTypeErr",
          )}
          closable
          showIcon
          style={{ margin: "0 0 2em" }}
          type="info"
        />
      )}
      <FormHeader
        headerText={t("views.signUp.forms.AccountCreationForm.header")}
        subheaderText={t("views.signUp.forms.AccountCreationForm.subheader")}
      />
      <div className="wrapper">
        <div className="buttonRowWrapper tempButtonWrapperClass">
          {renderAccountButton(ACCOUNT_OPENING_TYPES.INDIVIDUAL)}
          {renderAccountButton(ACCOUNT_OPENING_TYPES.INDIVIDUAL_FOREIGN)}
          {renderAccountButton(ACCOUNT_OPENING_TYPES.TRADITIONAL_IRA)}
          {renderAccountButton(ACCOUNT_OPENING_TYPES.ROTH_IRA)}
        </div>
        <div className="contentRowWrapper">
          {renderContent(OnboardingStore.getAccountType())}
        </div>
      </div>
      {isEnumFalsey(OnboardingStore.getAccountType()) && (
        <div className="reminderText" style={{ paddingTop: 10 }}>
          <QuestionCircleOutlined
            className="infoBlue"
            style={{ paddingRight: 10 }}
          />
          {t("views.signUp.forms.AccountCreationForm.disabledButton")}
        </div>
      )}
      <FormButtonFooter
        goForwardHandler={goForwardHandler}
        goBackHandler={getGoBackHandler()}
        goForwardButtonText={
          isEnumFalsey(OnboardingStore.getAccountType())
            ? t("forms.buttons.next")
            : t("views.signUp.forms.AccountCreationForm.selectAnAccountType")
        }
        isGoForwardDisabled={isNextButtonDisabled()}
      />
      <div className="smallMeta" style={{ paddingTop: 20 }}>
        <p>
          <strong>
            {t("views.signUp.forms.AccountCreationForm.legalese.header1")}
          </strong>
        </p>
        <p>{t("views.signUp.forms.AccountCreationForm.legalese.paragraph1")}</p>
        <p>
          <strong>
            {t("views.signUp.forms.AccountCreationForm.legalese.header2")}
          </strong>
        </p>
        <p>{t("views.signUp.forms.AccountCreationForm.legalese.paragraph2")}</p>
        <p>
          <strong>
            {t("views.signUp.forms.AccountCreationForm.legalese.header3")}
          </strong>
        </p>
        <p>
          {t("views.signUp.forms.AccountCreationForm.legalese.paragraph3a")}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://brokercheck.finra.org/"
          >
            {t("views.signUp.forms.AccountCreationForm.legalese.paragraph3b")}
          </a>
        </p>
        <p>{t("views.signUp.forms.AccountCreationForm.legalese.paragraph4")}</p>
        <p>{t("views.signUp.forms.AccountCreationForm.legalese.paragraph5")}</p>
        <p>{t("views.signUp.forms.AccountCreationForm.legalese.paragraph6")}</p>
        <p>{t("views.signUp.forms.AccountCreationForm.legalese.paragraph7")}</p>
      </div>
    </div>
  );
});

export default AccountCreationForm;
