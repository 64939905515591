import * as React from "react";
import { observer } from "mobx-react";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";

// ==== Components ====
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";
import { EOnboardingScreens } from "views/SignUp/Onboarding/types/onboardingTypes";
import FormHeader from "components/FormHeader";
import { isEnumFalsey } from "utilities/genericUtilities";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import FormsStore from "stores/FormsStore";

// ==== Summery Blocks ====
import IndividualForm from "views/SignUp/Onboarding/forms/SummaryPage/FormSections/SummaryIndividualForm";
import SummaryTaxForm from "views/SignUp/Onboarding/forms/SummaryPage/FormSections/SummaryPersonalInformationForm";
import SummaryAccountCreation from "views/SignUp/Onboarding/forms/SummaryPage/FormSections/SummaryAccountSelection";
import SummaryAddressCollectionForm from "views/SignUp/Onboarding/forms/SummaryPage/FormSections/SummaryAddressCollectionForm";
import SummaryUploadIdForm from "views/SignUp/Onboarding/forms/SummaryPage/FormSections/SummaryUploadIdForm";
import SummaryTrustedContactForm from "views/SignUp/Onboarding/forms/SummaryPage/FormSections/SummaryTrustedContactForm";
import SummaryEmploymentInfoForm from "views/SignUp/Onboarding/forms/SummaryPage/FormSections/SummaryEmploymentInfoForm";
import SummaryStockExchangeForm from "views/SignUp/Onboarding/forms/SummaryPage/FormSections/SummaryStockExchangeForm";
import SummaryCaisForm from "views/SignUp/Onboarding/forms/SummaryPage/FormSections/SummaryCaisForm";
import SummaryStonexAffiliateForm from "views/SignUp/Onboarding/forms/SummaryPage/FormSections/SummaryStonexAffiliateForm";
import SummaryBeneficiariesForm from "views/SignUp/Onboarding/forms/SummaryPage/FormSections/SummaryBeneficiariesForm";

// ==== Types ====
import { ACCOUNT_OPENING_TYPES } from "views/SignUp/Onboarding/types/onboardingEnums";

const SummaryContainer: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();

  const getBackButtonKey = (): string => {
    if (
      OnboardingStore.getAccountType() ===
        ACCOUNT_OPENING_TYPES.TRADITIONAL_IRA.value ||
      OnboardingStore.getAccountType() === ACCOUNT_OPENING_TYPES.ROTH_IRA.value
    ) {
      return EOnboardingScreens.BENEFICIARIES;
    }

    return EOnboardingScreens.STOCK_EXCHANGE_ASSOCIATION;
  };

  const isNextButtonDisabled = () =>
    !isEnumFalsey(OnboardingStore.getAccountType());

  const newAccountGoForwardHandler = () => {
    FormsStore.setGuardedCurrentScreen(EOnboardingScreens.DOCUMENTS);
  };

  const newAccountGoBackHandler = () => {
    FormsStore.setCurrentScreen(getBackButtonKey());
  };

  const multipleAccountGoBackHandler = () => {
    FormsStore.setCurrentScreen(EOnboardingScreens.BENEFICIARIES);
  };

  const renderNewAccountSummery = () => (
    <React.Fragment>
      <FormHeader
        headerText={t("views.signUp.components.summery.header")}
        subheaderText={t("views.signUp.components.summery.subheader")}
      />
      <IndividualForm />
      <SummaryTaxForm />
      <SummaryAccountCreation />
      <SummaryAddressCollectionForm />
      <SummaryUploadIdForm />
      <SummaryTrustedContactForm />
      <SummaryEmploymentInfoForm />
      <SummaryStonexAffiliateForm />
      <SummaryCaisForm />
      <SummaryStockExchangeForm />
      <SummaryBeneficiariesForm />
      <FormButtonFooter
        goForwardHandler={newAccountGoForwardHandler}
        goBackHandler={newAccountGoBackHandler}
        isGoForwardDisabled={isNextButtonDisabled()}
        goForwardDisabledMessage={t(
          "views.signUp.components.summery.newAccount.disabledMessage",
        )}
      />
    </React.Fragment>
  );

  const renderSecondAccountSummery = () => {
    const getGoBackHandler = () => {
      const accountType = OnboardingStore.getAccountType();
      return accountType === ACCOUNT_OPENING_TYPES.TRADITIONAL_IRA.value ||
        accountType === ACCOUNT_OPENING_TYPES.ROTH_IRA.value
        ? multipleAccountGoBackHandler
        : undefined;
    };

    return (
      <React.Fragment>
        <Alert
          message={t("views.signUp.components.summery.newAccount.alertMessage")}
          closable
          showIcon
          style={{ margin: "0 0 2em" }}
          type="success"
        />
        <FormHeader
          headerText={t("views.signUp.components.summery.newAccount.header")}
          subheaderText={t(
            "views.signUp.components.summery.newAccount.subheader",
          )}
        />
        <IndividualForm isEditButtonDisabled />
        <SummaryTaxForm isEditButtonDisabled />
        <SummaryAddressCollectionForm isEditButtonDisabled />
        <SummaryUploadIdForm isEditButtonDisabled />
        <SummaryTrustedContactForm isEditButtonDisabled />
        <SummaryEmploymentInfoForm isEditButtonDisabled />
        <SummaryStonexAffiliateForm isEditButtonDisabled />
        <SummaryCaisForm isEditButtonDisabled />
        <SummaryStockExchangeForm isEditButtonDisabled />
        <SummaryBeneficiariesForm isEditButtonDisabled />
        <FormButtonFooter
          goBackHandler={getGoBackHandler()}
          goForwardHandler={newAccountGoForwardHandler}
          isGoForwardDisabled={isNextButtonDisabled()}
          goForwardDisabledMessage={t(
            "views.signUp.components.summery.newAccount.disabledMessage",
          )}
        />
      </React.Fragment>
    );
  };

  return (
    <div className="slideInWrapper">
      {OnboardingStore.isCreatingMultipleAccounts
        ? renderSecondAccountSummery()
        : renderNewAccountSummery()}
    </div>
  );
});

export default SummaryContainer;
