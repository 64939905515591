import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken,
  CancelTokenSource,
} from "axios";

// ==== Stores ====
import AuthenticationStore from "stores/AuthenticationStore";

// ==== Types ====
import { EHttpMethods } from "types/globalTypes";
import {
  IAccountOpening,
  IAccountFeature,
  ITermsAgreements,
  IBeneficiaries,
} from "views/SignUp/Onboarding/types/onboardingTypes";

// ==== Utilities ====
import { handleErrorTracking, generateBaseUrl } from "utilities/apiUtilities";

class AccountOpeningApi {
  newTokenSource = (): CancelTokenSource => {
    return axios.CancelToken.source();
  };

  postAccountOpening = async (
    cancelToken: CancelToken,
    profileId: string,
    accountType: number,
  ): Promise<IAccountOpening> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.POST,
      url: `?ProfileId=${profileId}&Type=${accountType}`,
    });

    return response ? response.data : undefined;
  };

  postAccountOpeningUpdate = async (
    cancelToken: CancelToken,
    accountOpening: IAccountOpening,
  ): Promise<IAccountOpening> => {
    const response = await this._call({
      cancelToken,
      data: accountOpening,
      method: EHttpMethods.POST,
      url: `/update`,
    });

    return response ? response.data : undefined;
  };

  getHasAccess = async (
    cancelToken: CancelToken,
    email: string,
  ): Promise<boolean> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.GET,
      url: `/HasAccess?Email=${email}`,
    });

    return response ? response.data : undefined;
  };

  getEligibleAccountOpeningType = async (
    cancelToken: CancelToken,
    profileId: string,
  ): Promise<number[]> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.GET,
      url: `/Eligible/AccountOpeningType?profileId=${profileId}`,
    });

    return response ? response.data : undefined;
  };

  putBeneficiary = async (
    cancelToken: CancelToken,
    beneficiaryData: IBeneficiaries,
  ): Promise<IBeneficiaries> => {
    const response = await this._call({
      cancelToken,
      data: beneficiaryData,
      method: EHttpMethods.PUT,
      url: `/Beneficiary`,
    });

    return response ? response.data : undefined;
  };

  deleteBeneficiary = async (
    cancelToken: CancelToken,
    beneficiaryId: string,
  ): Promise<boolean> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.DELETE,
      url: `/Beneficiary/${beneficiaryId}`,
    });

    return response ? response.data : undefined;
  };

  putTermsAgreements = async (
    cancelToken: CancelToken,
    data: ITermsAgreements,
  ): Promise<ITermsAgreements> => {
    const response = await this._call({
      cancelToken,
      data: data,
      method: EHttpMethods.PUT,
      url: `/TermsAgreements`,
    });

    return response ? response.data : undefined;
  };

  putAccountFeature = async (
    cancelToken: CancelToken,
    accountOpeningId: string,
    data: IAccountFeature,
  ): Promise<IAccountFeature> => {
    const response = await this._call({
      cancelToken,
      data: data,
      method: EHttpMethods.PUT,
      url: `/accountFeature/${accountOpeningId}`,
    });

    return response ? response.data : undefined;
  };

  postAccountOpeningSubmit = async (
    cancelToken: CancelToken,
    accountOpeningId: string,
  ): Promise<boolean> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.POST,
      url: `/${accountOpeningId}/submit`,
    });

    return response ? response.data : undefined;
  };

  postOptionsTradingLevel = async (
    cancelToken: CancelToken,
    accountOpeningId: string,
    level: number,
  ): Promise<boolean> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.POST,
      url: `OptionsTradingLevel/${accountOpeningId}?level=${level}`,
    });

    return response ? response.data : undefined;
  };

  postInvestmentObjective = async (
    cancelToken: CancelToken,
    accountOpeningId: string,
    investmentObjective: number,
  ): Promise<boolean> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.POST,
      url: `InvestmentObjective/${accountOpeningId}?objective=${investmentObjective}`,
    });

    return response ? response.data : undefined;
  };

  private _call = async (
    config: AxiosRequestConfig,
  ): Promise<AxiosResponse> => {
    try {
      let _axios = axios.create({
        baseURL: `${generateBaseUrl()}torchapi/v1/AccountOpening`,
        headers: {
          Authorization: `Bearer ${AuthenticationStore.accessToken}`,
          "Cache-Control": "no-cache,no-store,max-age=-1",
          Pragma: "no-cache",
        },
        responseType: "json",
        timeout: 60000,
      });
      const response = await _axios(config);
      return response;
    } catch (e) {
      handleErrorTracking(e, "AccountOpeningApi", "_call");
    }
  };
}

export default new AccountOpeningApi();
