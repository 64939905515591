import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Button, Input, Select, Tooltip } from "antd";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { FormComponentProps } from "@ant-design/compatible/lib/form";

import BeneficiariesStore from "views/SignUp/Onboarding/store/BeneficiariesStore";
import DateOfBirth from "views/SignUp/components/DateOfBirth";
import { isEnumFalsey } from "utilities/genericUtilities";
import {
  EOnboardingForms,
  IBeneficiaryAccount,
} from "views/SignUp/Onboarding/types/onboardingTypes";
import {
  RELATIONSHIP_TYPES,
  BENEFICIARY_ACCOUNT_TYPES,
  MARITAL_STATUS_TYPES,
} from "views/SignUp/Onboarding/types/onboardingEnums";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

const { Option } = Select;

const AddBeneficiaryForm: React.FunctionComponent<FormComponentProps> =
  observer(({ form }) => {
    const { getFieldDecorator, validateFields, setFieldsValue } = form;
    const { t } = useTranslation();

    const handleSubmit = async (
      event: React.FormEvent<HTMLFormElement>,
    ): Promise<void> => {
      event.preventDefault();

      validateFields(async (err: string[]): Promise<void> => {
        if (!err) {
          await BeneficiariesStore.createBeneficiaryAccount();
        }
      });
    };

    const isPrimaryAccount =
      BeneficiariesStore.addBeneficiaryData.accountType ===
      BENEFICIARY_ACCOUNT_TYPES.PRIMARY.value;

    const isBeneficiaryTheSpouse =
      BeneficiariesStore.addBeneficiaryData.beneficiaryRelationshipType ===
      RELATIONSHIP_TYPES.SPOUSE.value;

    const maritalStatus = OnboardingStore.getFormItemData(
      EOnboardingForms.BENEFICIARIES,
      "maritalStatus",
    );
    const isMarried = maritalStatus === MARITAL_STATUS_TYPES.MARRIED.value;
    const shouldAllocateEverything = isMarried && isPrimaryAccount;
    const shouldDisablePercentageAllocation =
      shouldAllocateEverything && !isBeneficiaryTheSpouse;
    const shouldSetValueForPercentageAllocation =
      isBeneficiaryTheSpouse && shouldAllocateEverything;

    React.useEffect(() => {
      // Whenever the user updates the account type dropdown, we need to clear the value stored
      // in percentageAllocated
      if (shouldSetValueForPercentageAllocation) {
        setFieldsValue({
          [`percentageAllocated`]: 100,
        });
      } else {
        setFieldsValue({
          [`percentageAllocated`]:
            BeneficiariesStore?.addBeneficiaryData?.percentageAllocated,
        });
      }
      /* eslint-disable react-hooks/exhaustive-deps */
    }, [
      BeneficiariesStore?.addBeneficiaryData?.percentageAllocated,
      shouldSetValueForPercentageAllocation,
    ]);

    const renderSelectOptions = (allocatedPercentage: number) => {
      const options = [];

      if (shouldAllocateEverything) {
        // 100% will display as the first and only selectable option
        for (let i = 100; i >= 0; i--) {
          options.push(
            <Option key={i} value={i} disabled={i !== 100}>
              {`${i}%`}
            </Option>,
          );
        }
      } else {
        for (let i = 1; i <= 100 - allocatedPercentage; i++) {
          options.push(
            <Option key={i} value={i}>
              {`${i}%`}
            </Option>,
          );
        }
      }

      return options;
    };

    const renderPercentageAllocationSelection = () => {
      return (
        <Form.Item>
          {getFieldDecorator("percentageAllocated", {
            rules: [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: t("forms.fields.field"),
                }),
                required: true,
              },
            ],
          })(
            <Select
              style={{ marginBottom: 0, width: 100 }}
              filterOption={(inputValue, option) =>
                option.props.children
                  .toString()
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
              placeholder={shouldAllocateEverything ? "100%" : "0%"}
              disabled={shouldDisablePercentageAllocation}
            >
              {renderSelectOptions(getAllocatedPercentage())}
            </Select>,
          )}
        </Form.Item>
      );
    };

    const getAllocatedPercentage = () => {
      return BeneficiariesStore.getAllocatedPercentage(
        isPrimaryAccount
          ? BENEFICIARY_ACCOUNT_TYPES.PRIMARY.value
          : BENEFICIARY_ACCOUNT_TYPES.CONTINGENT.value,
      );
    };

    const renderSelect = () => {
      if (getAllocatedPercentage() === 100) {
        return (
          <div style={{ paddingBottom: 20 }}>
            {t("views.signUp.forms.BeneficiariesForm.noPercentageLeft")}
          </div>
        );
      }

      if (shouldDisablePercentageAllocation) {
        return (
          <div style={{ paddingBottom: 20 }}>
            {t("views.signUp.forms.BeneficiariesForm.maritalStatusInfoMessage")}
          </div>
        );
      }

      return (
        <div className="allocationSelectWrapper">
          <div className="reminderText">
            {t("views.signUp.forms.BeneficiariesForm.percentAllocated")}:
          </div>
          {shouldAllocateEverything ? (
            <Tooltip
              title={t(
                "views.signUp.forms.BeneficiariesForm.maritalStatusInfoMessage",
              )}
              placement="left"
            >
              {renderPercentageAllocationSelection()}
            </Tooltip>
          ) : (
            renderPercentageAllocationSelection()
          )}
        </div>
      );
    };

    return (
      <div className="beneficiariesForm">
        <div className="reminderText" style={{ marginBottom: 24 }}>
          {t("views.signUp.forms.BeneficiariesForm.beneficiaryFormHeader")}
        </div>
        <Form layout="vertical" onSubmit={handleSubmit}>
          <Form.Item>
            {getFieldDecorator("firstName", {
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.firstName"),
                  }),
                  required: true,
                },
              ],
              validateTrigger: "onBlur",
            })(<Input placeholder={t("forms.fields.firstName")} allowClear />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("lastName", {
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.lastName"),
                  }),
                  required: true,
                },
              ],
              validateTrigger: "onBlur",
            })(<Input placeholder={t("forms.fields.lastName")} allowClear />)}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("beneficiaryRelationshipType", {
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.field"),
                  }),
                  required: true,
                },
              ],
            })(
              <Select placeholder={t("forms.fields.relationship")}>
                <Option value={RELATIONSHIP_TYPES.CHILD.value}>
                  {t("forms.relationshipTypes.child")}
                </Option>
                <Option value={RELATIONSHIP_TYPES.OTHER.value}>
                  {t("forms.relationshipTypes.other")}
                </Option>
                <Option value={RELATIONSHIP_TYPES.SPOUSE.value}>
                  {t("forms.relationshipTypes.spouse")}
                </Option>
              </Select>,
            )}
          </Form.Item>
          <Form.Item
            label={t("forms.fields.dateOfBirth")}
            style={{ marginBottom: 0 }}
          >
            <DateOfBirth
              fieldNameDay="dobDay"
              fieldNameMonth="dobMonth"
              fieldNameYear="dobYear"
              form={form}
            />
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("accountType", {
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.field"),
                  }),
                  required: true,
                },
              ],
            })(
              <Select
                placeholder={t(
                  "views.signUp.forms.BeneficiariesForm.beneficiaryType",
                )}
              >
                <Option
                  key={BENEFICIARY_ACCOUNT_TYPES.PRIMARY.key}
                  value={BENEFICIARY_ACCOUNT_TYPES.PRIMARY.value}
                >
                  {t("views.signUp.forms.BeneficiariesForm.primary")}
                </Option>
                <Option
                  key={BENEFICIARY_ACCOUNT_TYPES.CONTINGENT.key}
                  value={BENEFICIARY_ACCOUNT_TYPES.CONTINGENT.value}
                >
                  {t("views.signUp.forms.BeneficiariesForm.contingent")}
                </Option>
              </Select>,
            )}
          </Form.Item>
          {isEnumFalsey(BeneficiariesStore.addBeneficiaryData.accountType) && (
            <React.Fragment>
              {renderSelect()}
              <div>
                <Button
                  htmlType="submit"
                  type="primary"
                  block
                  className={`${
                    getAllocatedPercentage() !== 100 ? "submitButton" : ""
                  }`}
                  disabled={
                    getAllocatedPercentage() === 100 ||
                    shouldDisablePercentageAllocation
                  }
                >
                  {t("views.signUp.forms.BeneficiariesForm.newBeneficiary")}
                </Button>
                <div className="percentAllocatedSummary">
                  <span>
                    {t(
                      "views.signUp.forms.BeneficiariesForm.remainingPercentage",
                    )}
                  </span>
                  <span className="percentRemaining">{` ${
                    100 - getAllocatedPercentage()
                  }%`}</span>
                </div>
              </div>
            </React.Fragment>
          )}
        </Form>
      </div>
    );
  });

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: IBeneficiaryAccount) => {
    BeneficiariesStore.updateAddBeneficiaryFormData(changedFields);
  },
})(AddBeneficiaryForm);
