import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// ==== Components ====
import FormHeader from "components/FormHeader";
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";

// ==== Stores ====
import AuthenticationStore from "stores/AuthenticationStore";
import FormsStore from "stores/FormsStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Types ====
import { EMarginScreens } from "views/Margin/types/marginTypes";
import { ERoutePaths } from "types/globalTypes";

const MarginRow: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleGoForward = () => {
    FormsStore.setCurrentScreen(EMarginScreens.WELCOME);
    history.push(`${ERoutePaths.MARGIN}/${EMarginScreens.WELCOME}`);
  };

  // If the user already has an options agreement, don't render anything
  if (OnboardingStore.getAccountFeatures().margin) {
    return null;
  }

  return (
    <div style={{ marginBottom: 40 }}>
      <FormHeader
        headerText={t("views.signUp.forms.SuccessScreen.headerMargin")}
      />
      <div style={{ paddingBottom: 20 }}>
        {OnboardingStore.isAccountApproved()
          ? t("views.signUp.forms.SuccessScreen.marginBody")
          : t("views.signUp.forms.SuccessScreen.marginPendingBody")}
      </div>
      <div style={{ display: "flex", justifyContent: "end", marginTop: 25 }}>
        <FormButtonFooter
          isGoForwardDisabled={!AuthenticationStore.config.clientUri}
          goForwardHandler={handleGoForward}
          goForwardButtonText={t(
            "views.signUp.forms.SuccessScreen.marginButton",
          )}
        />
      </div>
      <p className="meta" style={{ paddingTop: 20 }}>
        {t("views.signUp.forms.SuccessScreen.marginLegal")}
      </p>
    </div>
  );
};

export default MarginRow;
