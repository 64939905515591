import * as React from "react";
import { observer } from "mobx-react";
import { EditOutlined } from "@ant-design/icons";
import { Row, Button, Col } from "antd";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import FormsStore from "stores/FormsStore";

interface IProps {
  editButtonKey?: string;
  header: string;
}

const SummaryHeader: React.FunctionComponent<IProps> = observer(
  ({ header, editButtonKey }) => {
    const { t } = useTranslation();

    return (
      <React.Fragment>
        <Row align="middle" justify="space-between" style={{ marginTop: 25 }}>
          <Col span={12} style={{ fontSize: 20 }}>
            <strong>{header}</strong>
          </Col>
          <Col style={{ textAlign: "right" }} span={12}>
            {editButtonKey && (
              <Button
                type="primary"
                icon={<EditOutlined />}
                size="small"
                onClick={() => FormsStore.setCurrentScreen(editButtonKey)}
              >
                {t("forms.buttons.edit")}
              </Button>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  },
);

export default SummaryHeader;
