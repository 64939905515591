import * as React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

import FormHeader from "components/FormHeader";

const CheckEmail: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();

  return (
    <div className="slideInWrapper">
      <FormHeader
        headerText={t("views.signUp.forms.CheckEmail.header")}
        subheaderText={t("views.signUp.forms.CheckEmail.subheader")}
      />
    </div>
  );
});

export default CheckEmail;
