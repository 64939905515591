import * as React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Types ====
import { EOnboardingForms } from "views/SignUp/Onboarding/types/onboardingTypes";
import { MARITAL_STATUS_TYPES } from "views/SignUp/Onboarding/types/onboardingEnums";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Components ====
import SummaryFieldBlock from "components/SummaryFieldBlock";
import SummaryHeader from "views/SignUp/Onboarding/forms/SummaryPage/SummaryHeader";
import { getTranslationKeyFromBool } from "utilities/genericUtilities";
import BeneficiaryTable from "views/SignUp/Onboarding/forms/BeneficiariesForm/BeneficiaryTable";

interface IProps {
  isEditButtonDisabled?: boolean;
}

const SummaryBeneficiariesForm: React.FunctionComponent<IProps> = observer(
  ({ isEditButtonDisabled }) => {
    const { t } = useTranslation();

    const getFormData = (key: string) =>
      OnboardingStore.getFormItemData(EOnboardingForms.BENEFICIARIES, key);

    const getTranslationKeyFromMaritalStatusValue = (
      maritalStatusValue: number,
    ) => {
      return (
        Object.values(MARITAL_STATUS_TYPES).filter(({ value }) => {
          return maritalStatusValue === value;
        })[0]?.translationKey || ""
      );
    };

    const renderSummaryData = () => {
      if (!getFormData("HasBeneficiaries")) {
        return null;
      }

      return (
        <React.Fragment>
          <SummaryHeader
            header={t("views.signUp.components.summery.beneficiaries")}
            editButtonKey={
              !isEditButtonDisabled && EOnboardingForms.BENEFICIARIES
            }
          />
          <SummaryFieldBlock
            question={t(
              "views.signUp.forms.BeneficiariesForm.setUpBeneficiaries",
            )}
            answer={t(
              getTranslationKeyFromBool(getFormData("HasBeneficiaries")),
            )}
          />
          {OnboardingStore.isUserInMaritalStatusState() && (
            <SummaryFieldBlock
              question={t("views.signUp.forms.BeneficiariesForm.maritalStatus")}
              answer={t(
                getTranslationKeyFromMaritalStatusValue(
                  getFormData("maritalStatus"),
                ),
              )}
            />
          )}
          <div style={{ margin: "0.5rem 0" }} className="beneficiariesForm">
            <BeneficiaryTable isAddDeleteDisabled />
          </div>
        </React.Fragment>
      );
    };

    return <React.Fragment>{renderSummaryData()}</React.Fragment>;
  },
);

export default SummaryBeneficiariesForm;
