import * as React from "react";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";

// ==== Types ====
import { ACCOUNT_OPEN_STATUSES } from "views/SignUp/Onboarding/types/onboardingEnums";

interface IProps {
  accountStatus: number;
}

const AlertBanner: React.FunctionComponent<IProps> = ({ accountStatus }) => {
  const { t } = useTranslation();

  const getAlertBannerType = (
    accountStatus: number,
  ): "error" | "info" | "success" | "warning" => {
    switch (accountStatus) {
      case ACCOUNT_OPEN_STATUSES.CREATED.value:
      case ACCOUNT_OPEN_STATUSES.PENDING.value:
      case ACCOUNT_OPEN_STATUSES.SUBMIT.value:
      case ACCOUNT_OPEN_STATUSES.SUBMITTING.value:
      case ACCOUNT_OPEN_STATUSES.QUEUED.value:
        return "info";
      case ACCOUNT_OPEN_STATUSES.SUBMITTED.value:
      case ACCOUNT_OPEN_STATUSES.OPENED.value:
        return "success";
      default:
        return "error";
    }
  };

  const getAlertBannerMessage = (accountStatus: number): string => {
    switch (accountStatus) {
      case ACCOUNT_OPEN_STATUSES.CREATED.value:
      case ACCOUNT_OPEN_STATUSES.PENDING.value:
      case ACCOUNT_OPEN_STATUSES.SUBMIT.value:
        return t("views.signUp.forms.SuccessScreen.alertMessages.info");
      case ACCOUNT_OPEN_STATUSES.QUEUED.value:
        return t("views.signUp.forms.SuccessScreen.alertMessages.queued");
      case ACCOUNT_OPEN_STATUSES.SUBMITTING.value:
        return t("views.signUp.forms.SuccessScreen.alertMessages.submitting");
      case ACCOUNT_OPEN_STATUSES.SUBMITTED.value:
      case ACCOUNT_OPEN_STATUSES.OPENED.value:
        return t("views.signUp.forms.SuccessScreen.alertMessages.success");
      default:
        return t("views.signUp.forms.SuccessScreen.alertMessages.error", {
          status: `${accountStatus}`,
        });
    }
  };

  // 9438: Remove success alerts
  if (
    accountStatus === ACCOUNT_OPEN_STATUSES.SUBMITTED.value ||
    accountStatus === ACCOUNT_OPEN_STATUSES.OPENED.value
  )
    return <></>;

  return (
    <Alert
      message={getAlertBannerMessage(accountStatus)}
      closable
      showIcon
      style={{ margin: "0 0 2em" }}
      type={getAlertBannerType(accountStatus)}
    />
  );
};

export default AlertBanner;
