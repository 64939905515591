import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Input, Alert, Tooltip, Select } from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import FormsStore from "stores/FormsStore";
import ConfigurationStore from "stores/ConfigurationStore";
import AuthenticationStore from "stores/AuthenticationStore";

// ==== Components ====
import FormHeader from "components/FormHeader";
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";

// ==== Types ====
import {
  EOnboardingForms,
  EOnboardingScreens,
  IPersonalInfoForm,
} from "views/SignUp/Onboarding/types/onboardingTypes";
import { FEATURE_FLAGS } from "types/globalTypes";

// ==== Utilities ====
import { concatenateSentences, cleanString } from "utilities/genericUtilities";

const { Option } = Select;

const PersonalInformation: React.FunctionComponent<FormComponentProps> =
  observer(({ form }) => {
    const { getFieldDecorator, validateFields } = form;
    const { t } = useTranslation();

    const handleSubmit = async (
      event: React.MouseEvent<HTMLButtonElement, Event>,
      nextScreenKey: string,
    ): Promise<void> => {
      event.preventDefault();

      validateFields(async (err: string[]): Promise<void> => {
        if (!err) {
          await OnboardingStore.validateTaxInfo(nextScreenKey);
        }
      });
    };

    const getInitialValue = (key: string) =>
      OnboardingStore.getFormItemData(EOnboardingForms.PERSONAL_INFO, key);

    const formatTaxId = (taxId: string = ""): string => {
      let formattedId = "";

      if (taxId.length < 4) {
        formattedId = taxId;
      }

      if (taxId.length > 3 && taxId.length < 6) {
        formattedId = `${taxId.substr(0, 3)}-${taxId.substr(3)}`;
      }

      if (taxId.length > 5) {
        formattedId = `${taxId.substr(0, 3)}-${taxId.substr(
          3,
          2,
        )}-${taxId.substr(5)}`;
      }

      return formattedId;
    };

    const isNextButtonDisabled = (): {
      disabledMessage: string;
      isDisabled: boolean;
    } => {
      let isDisabled = false;
      let disabledMessage = "";

      const countryOfCitizenship = getInitialValue("CountryOfCitizenship");
      const taxId = getInitialValue("USTaxId");
      const foreignTaxId = getInitialValue("ForeignTaxId");

      if (!countryOfCitizenship) {
        isDisabled = true;
        disabledMessage = t(
          "views.signUp.forms.PersonalInfoForm.errorMessages.needCountry",
        );
      } else {
        if (countryOfCitizenship === "US") {
          if (!taxId) {
            isDisabled = true;
            disabledMessage = concatenateSentences(
              disabledMessage,
              t(
                "views.signUp.forms.PersonalInfoForm.errorMessages.needUSTaxId",
              ),
            );
          } else if (taxId?.length !== 9) {
            isDisabled = true;
            disabledMessage = concatenateSentences(
              disabledMessage,
              t(
                "views.signUp.forms.PersonalInfoForm.errorMessages.taxIdLength",
              ),
            );
          }
        } else {
          if (countryOfCitizenship !== "US") {
            // If foreign account creation feature flag is enabled
            if (
              !AuthenticationStore.getFeatureFlag(FEATURE_FLAGS.FOREIGN_ACCOUNT)
            ) {
              isDisabled = true;
              disabledMessage = concatenateSentences(
                disabledMessage,
                t(
                  "views.signUp.forms.PersonalInfoForm.errorMessages.nonUSUser",
                ),
              );
            } else {
              if (!foreignTaxId) {
                isDisabled = true;
                disabledMessage = concatenateSentences(
                  disabledMessage,
                  t(
                    "views.signUp.forms.PersonalInfoForm.errorMessages.needForeignTaxId",
                  ),
                );
              }
            }
          }
        }
      }

      return {
        disabledMessage,
        isDisabled,
      };
    };

    const renderAlerts = () => {
      const countryOfCitizenship = getInitialValue("CountryOfCitizenship");

      if (
        !AuthenticationStore.getFeatureFlag(FEATURE_FLAGS.FOREIGN_ACCOUNT) &&
        countryOfCitizenship &&
        countryOfCitizenship !== "US"
      ) {
        return (
          <Alert
            message={t(
              "views.signUp.forms.PersonalInfoForm.errorMessages.nonUSUser",
            )}
            closable
            showIcon
            style={{ margin: "0 0 2em" }}
            type="error"
          />
        );
      }

      if (OnboardingStore.hasNewAccountNotification) {
        return (
          <Alert
            message={t("views.signUp.forms.PersonalInfoForm.alertMessage")}
            closable
            showIcon
            style={{ margin: "0 0 2em" }}
            type="success"
          />
        );
      }

      if (OnboardingStore.hasPartialAccountData) {
        return (
          <Alert
            message={t(
              "views.signUp.forms.PersonalInfoForm.partialAccountData",
            )}
            closable
            showIcon
            style={{ margin: "0 0 2em" }}
            type="info"
          />
        );
      }
    };

    const renderUSForm = () => {
      const formattedTaxId = formatTaxId(getInitialValue("USTaxId"));

      return (
        <Tooltip
          visible={!!formattedTaxId}
          title={formattedTaxId}
          placement="topLeft"
        >
          <Form.Item>
            {getFieldDecorator("USTaxId", {
              initialValue: getInitialValue("USTaxId"),
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("views.signUp.forms.PersonalInfoForm.TaxId"),
                  }),
                  required: true,
                },
                {
                  len: 9,
                  message: t("forms.validation.exact", {
                    number: 9,
                  }),
                },
                {
                  message: t("forms.validation.digits"),
                  pattern: new RegExp("^\\d+$"),
                },
              ],
              validateTrigger: "onBlur",
            })(
              <Input
                placeholder={t("views.signUp.forms.PersonalInfoForm.TaxId")}
                allowClear
              />,
            )}
          </Form.Item>
        </Tooltip>
      );
    };

    // Input for Tax Identification Number/Passport number.
    const renderForeignForm = () => (
      <Form.Item>
        {getFieldDecorator("ForeignTaxId", {
          initialValue: getInitialValue("ForeignTaxId"),
          rules: [
            {
              message: t("forms.validation.isRequired", {
                fieldName: t(
                  "views.signUp.forms.PersonalInfoForm.ForeignTaxId",
                ),
              }),
              required: true,
            },
          ],
          validateTrigger: "onBlur",
        })(
          <Input
            placeholder={t("views.signUp.forms.PersonalInfoForm.ForeignTaxId")}
            allowClear
          />,
        )}
      </Form.Item>
    );

    return (
      <div className="slideInWrapper">
        {renderAlerts()}
        <FormHeader
          headerText={t(
            "views.signUp.forms.PersonalInfoForm.personalInformation",
          )}
        />
        <Form layout="vertical" hideRequiredMark>
          <Form.Item>
            {getFieldDecorator(`CountryOfCitizenship`, {
              initialValue: getInitialValue("CountryOfCitizenship"),
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.country"),
                  }),
                  required: true,
                },
              ],
            })(
              <Select
                placeholder={t(
                  "views.signUp.forms.PersonalInfoForm.countryOfCitizenship",
                )}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  cleanString(option.props.children).indexOf(
                    cleanString(input),
                  ) >= 0
                }
              >
                {ConfigurationStore?.countryCodes?.map(
                  (country: { code: string; name: string }) => (
                    <Option value={country.code} key={country.code}>
                      {country.name}
                    </Option>
                  ),
                )}
              </Select>,
            )}
          </Form.Item>
          {typeof getInitialValue("CountryOfCitizenship") !== "undefined" && (
            <React.Fragment>
              {getInitialValue("CountryOfCitizenship") === "US"
                ? renderUSForm()
                : renderForeignForm()}
            </React.Fragment>
          )}
          <FormButtonFooter
            goForwardHandler={(e) =>
              handleSubmit(e, EOnboardingScreens.ACCOUNT_CREATION)
            }
            isGoForwardDisabled={isNextButtonDisabled().isDisabled}
            goForwardDisabledMessage={isNextButtonDisabled().disabledMessage}
            goBackHandler={() =>
              FormsStore.setCurrentScreen(EOnboardingScreens.INDIVIDUAL)
            }
          />
        </Form>
      </div>
    );
  });

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: IPersonalInfoForm) => {
    OnboardingStore.updateFormData(
      changedFields,
      EOnboardingForms.PERSONAL_INFO,
    );
  },
})(PersonalInformation);
