import * as React from "react";
import { Button, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

interface IProps {
  goBackButtonText?: string;
  goBackHandler?: (event: any) => void;
  goForwardButtonText?: string;
  goForwardDisabledMessage?: string;
  goForwardHandler: (event: any) => void;
  // The Go forward button is also disabled if there is a form error
  isGoForwardDisabled?: boolean;
}

const FormButtonFooter: React.FunctionComponent<IProps> = ({
  goForwardHandler,
  goBackHandler,
  goForwardDisabledMessage,
  isGoForwardDisabled,
  goBackButtonText,
  goForwardButtonText,
}) => {
  const { t } = useTranslation();

  return (
    <div className="formButtonFooterWrapper">
      <div className="successButton">
        <Tooltip
          placement="top"
          title={isGoForwardDisabled && goForwardDisabledMessage}
        >
          <Button
            tabIndex={0}
            disabled={isGoForwardDisabled}
            onClick={(e) => goForwardHandler(e)}
            htmlType="submit"
            type="primary"
            block
          >
            {goForwardButtonText
              ? goForwardButtonText
              : t("forms.buttons.next")}
          </Button>
        </Tooltip>
      </div>
      <div className="goBackButtonRow">
        {goBackHandler && (
          <Button onClick={(e) => goBackHandler(e)} type="link" tabIndex={0}>
            {goBackButtonText ? goBackButtonText : t("forms.buttons.goBack")}
          </Button>
        )}
      </div>
    </div>
  );
};

export default FormButtonFooter;
