// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import BeneficiariesStore from "views/SignUp/Onboarding/store/BeneficiariesStore";
import OptionsStore from "views/Options/OptionsStore";

// ==== Types ====
import {
  EOnboardingForms,
  IOnboardingProfile,
  IAccountOpening,
  IEmployment,
  IIndividualProfile,
  IIndustryAffiliation,
  IPublicCompanyAffiliation,
  IProfileDemographics,
  ITrustedContact,
  IFirmAffiliation,
  IDocument,
  IAddress,
  ICAISForm,
} from "views/SignUp/Onboarding/types/onboardingTypes";
import {
  ACCOUNT_OPENING_TYPES,
  ADDRESS_TYPES,
} from "views/SignUp/Onboarding/types/onboardingEnums";
import { EOptionsForms } from "views/Options/types/optionsTypes";
import { INVESTMENT_EXPERIENCE } from "views/Options/types/optionsEnums";
import AuthenticationStore from "stores/AuthenticationStore";

export const filterAccountOpening = (
  accountOpening: IAccountOpening[],
): void => {
  let preloadAccountOpening;

  // If there is only one account, load that one
  if (accountOpening.length === 1) {
    preloadAccountOpening = accountOpening[0];

    // if there is a url account number, attempt to load that
  } else if (AuthenticationStore.urlAccountNumber) {
    const filteredAccounts = accountOpening.filter(
      ({ accountNumber }) =>
        accountNumber === AuthenticationStore.urlAccountNumber,
    );

    if (filteredAccounts.length === 1) {
      preloadAccountOpening = filteredAccounts[0];
    }
  }

  if (preloadAccountOpening) {
    preloadAccountOpeningData(preloadAccountOpening);
  }
};

export const preloadAccountOpeningData = (
  accountOpening: IAccountOpening,
): void => {
  OnboardingStore.setAccountOpening(accountOpening);

  // If we are preloading a existing in progress account, preload data
  preloadAccountType(accountOpening);
  preloadMarginOptionData(accountOpening);
  preloadBeneficiariesData(accountOpening);
};

export const preloadProfileData = (
  rawProfileData: IOnboardingProfile,
): void => {
  const {
    accountOpenings,
    affiliations,
    individual,
    addresses,
    documents,
    trustedContacts,
    employment,
    demographic,
    caisCustomerType,
  } = rawProfileData;

  // partially completed accounts only have zero and one account openings
  filterAccountOpening(accountOpenings);

  preloadIndividualData(individual);
  preloadTaxData(individual);
  preloadAddressData(addresses);
  preloadDocumentData(documents);
  preloadTrustedContactsData(trustedContacts);
  preloadEmploymentData(employment);
  preloadDemographicData(demographic);
  preloadAffiliations(affiliations);
  preloadCais(caisCustomerType);
};

// ==== Preload Data Functions ====
const preloadIndividualData = (individual: IIndividualProfile): void => {
  if (individual) {
    OnboardingStore.formData[EOnboardingForms.INDIVIDUAL] = {
      ...OnboardingStore.formData[EOnboardingForms.INDIVIDUAL],
      ConfirmCountryCode: individual.countryCode,
      ConfirmEmail: individual.email,
      ConfirmPhone: individual.phoneNumber,
      CountryCode: individual.countryCode,
      Email: individual.email,
      FirstName: individual.firstName,
      LastName: individual.lastName,
      MiddleName: individual.middleName,
      Phone: individual.phoneNumber,
      Suffix: individual.suffix,
    };

    // Edge case, if birthDate doesn't exist when loading the user's token
    if (individual.birthDate) {
      let birthday = new Date(individual.birthDate);

      // There is an edge case where the API returns the default boolean values,
      // first check to make sure the user has completed this form
      OnboardingStore.hasPartialAccountData = true;

      // Month is zero index'd, need to add one to it
      const month = birthday.getMonth() + 1;
      OnboardingStore.formData[EOnboardingForms.INDIVIDUAL] = {
        ...OnboardingStore.formData[EOnboardingForms.INDIVIDUAL],
        DobDay: birthday.getDate(),
        DobMonth: month,
        DobYear: birthday.getFullYear(),
      };
    }
  }
};

const preloadTaxData = (individual: IIndividualProfile): void => {
  if (individual) {
    // There is an edge case where the API returns the default boolean values,
    // first check to make sure the user has completed this form
    if (individual.taxId || individual.foreignTaxId) {
      OnboardingStore.formData[EOnboardingForms.INDIVIDUAL] = {
        ...OnboardingStore.formData[EOnboardingForms.INDIVIDUAL],
        MiddleName: individual.middleName,
        Suffix: individual.suffix,
      };

      OnboardingStore.formData[EOnboardingForms.PERSONAL_INFO] = {
        ...OnboardingStore.formData[EOnboardingForms.PERSONAL_INFO],
        CountryOfCitizenship: individual.citizenshipCountry,
        ForeignTaxId: individual.foreignTaxId,
        USTaxId: individual.taxId,
      };
    }
  }
};

const preloadAddressData = (addresses: IAddress[]): void => {
  if (addresses) {
    const userAddresses = addresses.filter(
      (address: { type: number }) =>
        address.type === ADDRESS_TYPES.INDIVIDUAL.value,
    );
    const employerAddresses = addresses.filter(
      (address: { type: number }) =>
        address.type === ADDRESS_TYPES.EMPLOYMENT.value,
    );

    if (userAddresses && userAddresses.length > 0) {
      // We only currently support one address so lets hope it's the first one
      const userAddress = userAddresses[0];

      OnboardingStore.formData[EOnboardingForms.ADDRESS_COLLECTION] = {
        ...OnboardingStore.formData[EOnboardingForms.ADDRESS_COLLECTION],
        City: userAddress.city,
        Country: userAddress.country,
        StateProvince: userAddress.stateProvince,
        StreetAddress1: userAddress.street1,
        StreetAddress2: userAddress.street2,
        ZipPostal: userAddress.zipPostal,
      };
    }

    if (employerAddresses && employerAddresses.length > 0) {
      const employerAddress = employerAddresses[0];

      OnboardingStore.formData[EOnboardingForms.EMPLOYMENT_INFORMATION] = {
        ...OnboardingStore.formData[EOnboardingForms.EMPLOYMENT_INFORMATION],
        EmployerCity: employerAddress.city,
        EmployerCountry: employerAddress.country,
        EmployerStateProvince: employerAddress.stateProvince,
        EmployerStreetAddress1: employerAddress.street1,
        EmployerStreetAddress2: employerAddress.street2,
        EmployerZipPostal: employerAddress.zipPostal,
      };
    }
  }
};

const preloadDocumentData = (documents: IDocument[]): void => {
  if (documents && documents.length > 0) {
    // We only currently support one document so lets hope it's the first one
    const userDocument = documents[0];

    OnboardingStore.formData[EOnboardingForms.UPLOAD_ID] = {
      ...OnboardingStore.formData[EOnboardingForms.UPLOAD_ID],
      DocumentId: userDocument.id,
      File: userDocument.file,
      FileName: userDocument.fileName,
      isFileUploaded: true,
    };

    // preload GovDoc info
    if (userDocument.governmentId) {
      const rawExpirationDate = userDocument.governmentId.expirationDate;
      const rawIssuedDate = userDocument.governmentId.issueDate;

      // Because expiration date/ issue date are optional, it's possible
      // that we only have one of the dates that we need to preload
      if (rawExpirationDate) {
        //Date comes from the API in the format YYYY-MM-DDThh:ss:mm
        const splitExpirationDate = rawExpirationDate.split("T");
        const expirationDate = splitExpirationDate[0].split("-");

        OnboardingStore.formData[EOnboardingForms.UPLOAD_ID] = {
          ...OnboardingStore.formData[EOnboardingForms.UPLOAD_ID],
          ExpirationDay: expirationDate[2],
          ExpirationMonth: expirationDate[1],
          ExpirationYear: expirationDate[0],
        };
      }

      if (rawIssuedDate) {
        const splitIssuedDate = rawIssuedDate.split("T");
        const issuedDate = splitIssuedDate[0].split("-");

        OnboardingStore.formData[EOnboardingForms.UPLOAD_ID] = {
          ...OnboardingStore.formData[EOnboardingForms.UPLOAD_ID],
          CountryOfIssuance: userDocument.governmentId.country,
          IdNumber: userDocument.governmentId.idNumber,
          IdType: userDocument.governmentId.idType,
          IssuedDay: issuedDate[2],
          IssuedMonth: issuedDate[1],
          IssuedYear: issuedDate[0],
        };
      }

      // Preload all required data
      OnboardingStore.formData[EOnboardingForms.UPLOAD_ID] = {
        ...OnboardingStore.formData[EOnboardingForms.UPLOAD_ID],
        CountryOfIssuance: userDocument.governmentId.country,
        Description: userDocument.governmentId.description,
        IdNumber: userDocument.governmentId.idNumber,
        IdType: userDocument.governmentId.idType,
      };
    }
  }
};

const preloadBeneficiariesData = (accountOpening: IAccountOpening): void => {
  if (accountOpening) {
    const beneficiaries = accountOpening.beneficiaries;

    if (beneficiaries && beneficiaries.length > 0) {
      BeneficiariesStore.setAccountType(
        ACCOUNT_OPENING_TYPES.INDIVIDUAL.value,
        accountOpening.id,
      );

      OnboardingStore.setFormItemData(EOnboardingForms.BENEFICIARIES, {
        HasBeneficiaries: true,
      });

      BeneficiariesStore.preloadBeneficiaries(beneficiaries);
    } else {
      OnboardingStore.setFormItemData(EOnboardingForms.BENEFICIARIES, {
        HasBeneficiaries: false,
      });
    }
  }
};

const preloadTrustedContactsData = (
  trustedContacts: ITrustedContact[],
): void => {
  if (trustedContacts && trustedContacts.length > 0) {
    // We only currently support one trusted contact so lets hope it's the first one
    const trustedContact = trustedContacts[0];

    OnboardingStore.formData[EOnboardingForms.TRUSTED_CONTACT] = {
      ...OnboardingStore.formData[EOnboardingForms.TRUSTED_CONTACT],
      ContactCity: trustedContact.city,
      ContactCountry: trustedContact.country,
      ContactCountryCode: trustedContact.countryCode,
      ContactEmail: trustedContact.email,
      ContactFirstName: trustedContact.firstName,
      ContactLastName: trustedContact.lastName,
      ContactPhone: trustedContact.phoneNumber,
      ContactRelationship: trustedContact.relation,
      ContactStateProvince: trustedContact.state,
      ContactStreetAddress1: trustedContact.street1,
      ContactStreetAddress2: trustedContact.street2,
      ContactZipPostal: trustedContact.zipCode,
      HasTrustedContact: !!trustedContact.firstName,
      HasTrustedContactAddress: !!trustedContact.street1,
    };
  } else {
    // If the trustedContacts array is empty the user has not gotten to this section yet
    OnboardingStore.formData[EOnboardingForms.TRUSTED_CONTACT] = {
      ...OnboardingStore.formData[EOnboardingForms.TRUSTED_CONTACT],
      HasTrustedContact: undefined,
    };
  }
};

const preloadEmploymentData = (employment: IEmployment): void => {
  if (employment) {
    OnboardingStore.formData[EOnboardingForms.EMPLOYMENT_INFORMATION] = {
      ...OnboardingStore.formData[EOnboardingForms.EMPLOYMENT_INFORMATION],
      Employer: employment.employer,
      EmploymentStatus: employment.employmentStatus,
      Industry: employment.industry,
      IndustryDesc: employment.industryDesc,
      JobFunction: employment.jobFunction,
      JobFunctionDesc: employment.jobFunctionDesc,
      JobTitle: employment.jobTitle,
      Occupation: employment.occupation,
      OccupationDesc: employment.occupationDesc,
    };
  }
};

const preloadCais = (cais: ICAISForm): void => {
  if (cais) {
    OnboardingStore.formData[EOnboardingForms.CAIS] = {
      ...OnboardingStore.formData[EOnboardingForms.CAIS],
      accredited: cais.accredited,
      advisor: cais.advisor,
      cp: cais.cp,
      employee: cais.employee,
      foreign: cais.foreign,
      id: cais.id,
      notApplicable: cais.notApplicable,
      otherBroker: cais.otherBroker,
    };
  }
};

const preloadDemographicData = (demographic: IProfileDemographics): void => {
  if (demographic) {
    OnboardingStore.formData[EOnboardingForms.EMPLOYMENT_INFORMATION] = {
      ...OnboardingStore.formData[EOnboardingForms.EMPLOYMENT_INFORMATION],
      LiquidNetWorth: demographic.liquidNetWorth,
      SourceOfIncome: demographic.incomePrimarySource,
    };

    OptionsStore.setFormItemData(EOptionsForms.TRADING_EXPERIENCE, {
      alternativeInvestments:
        demographic.yearsAltInvestment || INVESTMENT_EXPERIENCE.NONE.value,
      annuities: demographic.yearsAnnuities || INVESTMENT_EXPERIENCE.NONE.value,
      mutualFunds:
        demographic.yearsMutualFund || INVESTMENT_EXPERIENCE.NONE.value,
      options: demographic.yearsOptions || INVESTMENT_EXPERIENCE.NONE.value,
      stockAndBonds:
        demographic.yearsStockBonds || INVESTMENT_EXPERIENCE.NONE.value,
    });

    OptionsStore.setFormItemData(EOptionsForms.FINANCIAL_PROFILE, {
      annualIncome: demographic.income,
      netWorth: demographic.networth,
    });
  }
};

const preloadAccountType = (accountOpening: IAccountOpening): void => {
  if (accountOpening?.id) {
    OnboardingStore.setFormItemData(EOnboardingForms.ACCOUNT_CREATION, {
      accountType: accountOpening.openType,
    });
  }
};

const preloadMarginOptionData = (accountOpening: IAccountOpening): void => {
  if (accountOpening?.investmentObjective) {
    OptionsStore.setFormItemData(EOptionsForms.OPTION_INVESTMENT_OBJECTIVES, {
      investmentObjectives: accountOpening.investmentObjective,
    });
  }

  if (accountOpening?.optionsLevel) {
    OptionsStore.setFormItemData(EOptionsForms.OPTION_INVESTMENT_OBJECTIVES, {
      level: accountOpening.optionsLevel,
    });
  }

  if (accountOpening?.accountFeature?.options) {
    OptionsStore.setIsODDRead(true);
    OptionsStore.setFormItemData(EOptionsForms.DOCUMENT_REVIEW, {
      doesUserAgree: true,
    });
  }
};

const preloadAffiliations = (affiliations: {
  firm: IFirmAffiliation;
  id: string;
  industry: IIndustryAffiliation;
  publicCompany: IPublicCompanyAffiliation;
}): void => {
  if (affiliations) {
    const { firm, industry, publicCompany } = affiliations;

    if (firm) {
      OnboardingStore.formData[EOnboardingForms.STONEX_AFFILIATE] = {
        ...OnboardingStore.formData[EOnboardingForms.STONEX_AFFILIATE],
        AffiliatedEntity: firm.affiliatedEntity,
        AffiliateFirstName: firm.firstName,
        AffiliateLastName: firm.lastName,
        AreYouRelated: firm.associated,
        AssociationRelationshipType: firm.relationship,
        WhatPosition: firm.position,
      };
    }
    if (industry) {
      OnboardingStore.formData[EOnboardingForms.STOCK_EXCHANGE_ASSOCIATION] = {
        ...OnboardingStore.formData[
          EOnboardingForms.STOCK_EXCHANGE_ASSOCIATION
        ],
        BusinessName: industry.companyName,
        IsFINRA: industry.associated,
      };
    }
    if (publicCompany) {
      OnboardingStore.formData[EOnboardingForms.STOCK_EXCHANGE_ASSOCIATION] = {
        ...OnboardingStore.formData[
          EOnboardingForms.STOCK_EXCHANGE_ASSOCIATION
        ],
        CompanyName: publicCompany.name,
        CUSIP: publicCompany.symbol,
        Rule114: publicCompany.associated,
      };
    }
  }
};
