import * as React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Types ====
import { EOnboardingForms } from "views/SignUp/Onboarding/types/onboardingTypes";
import {
  OCCUPATIONS,
  EMPLOYMENT_SOURCE_OF_INCOME,
  EMPLOYMENT_STATUSES,
  INDUSTRIES,
  JOB_FUNCTIONS,
  MONEY_OPTIONS,
} from "views/SignUp/Onboarding/types/onboardingEnums";

// ==== Utilities ====
import { getTranslationKeyWithValue } from "utilities/typeUtilities";
import { isEnumFalsey } from "utilities/genericUtilities";

// ==== Components ====
import SummaryFieldBlock from "components/SummaryFieldBlock";
import SummaryHeader from "views/SignUp/Onboarding/forms/SummaryPage/SummaryHeader";

interface IProps {
  isEditButtonDisabled?: boolean;
}

const SummaryEmploymentInfoForm: React.FunctionComponent<IProps> = observer(
  ({ isEditButtonDisabled }) => {
    const { t } = useTranslation();

    const getFormData = (key: string) =>
      OnboardingStore.getFormItemData(
        EOnboardingForms.EMPLOYMENT_INFORMATION,
        key,
      );

    return (
      <React.Fragment>
        <SummaryHeader
          header={t("views.signUp.components.summery.employmentInformation")}
          editButtonKey={
            !isEditButtonDisabled && EOnboardingForms.EMPLOYMENT_INFORMATION
          }
        />
        {isEnumFalsey(getFormData("EmploymentStatus")) && (
          <SummaryFieldBlock
            question={`${t("forms.fields.employmentStatus")}:`}
            answer={t(
              getTranslationKeyWithValue(
                EMPLOYMENT_STATUSES,
                getFormData("EmploymentStatus"),
              ),
            )}
          />
        )}
        {isEnumFalsey(getFormData("SourceOfIncome")) && (
          <SummaryFieldBlock
            question={`${t("forms.fields.sourceOfIncome")}:`}
            answer={t(
              getTranslationKeyWithValue(
                EMPLOYMENT_SOURCE_OF_INCOME,
                getFormData("SourceOfIncome"),
              ),
            )}
          />
        )}
        {isEnumFalsey(getFormData("LiquidNetWorth")) && (
          <SummaryFieldBlock
            question={`${t(
              "views.Options.FinancialProfileForm.liquidNetWorth",
            )}:`}
            answer={t(
              getTranslationKeyWithValue(
                MONEY_OPTIONS,
                getFormData("LiquidNetWorth"),
              ),
            )}
          />
        )}

        {getFormData("Employer") && (
          <SummaryFieldBlock
            question={`${t("forms.fields.employer")}:`}
            answer={getFormData("Employer")}
          />
        )}
        {isEnumFalsey(getFormData("Industry")) && (
          <SummaryFieldBlock
            question={`${t("forms.fields.industry")}:`}
            answer={`${t(
              getTranslationKeyWithValue(INDUSTRIES, getFormData("Industry")),
            )}${
              getFormData("Industry") === INDUSTRIES.OTHER.value
                ? `: ${getFormData("IndustryDesc")}`
                : ""
            }`}
          />
        )}
        {isEnumFalsey(getFormData("Occupation")) && (
          <SummaryFieldBlock
            question={`${t("forms.fields.occupation")}:`}
            answer={`${t(
              getTranslationKeyWithValue(
                OCCUPATIONS,
                getFormData("Occupation"),
              ),
            )}${
              getFormData("Occupation") === OCCUPATIONS.OTHER.value
                ? `: ${getFormData("OccupationDesc")}`
                : ""
            }`}
          />
        )}
        {isEnumFalsey(getFormData("JobFunction")) && (
          <SummaryFieldBlock
            question={`${t("forms.fields.jobFunction")}:`}
            answer={`${t(
              getTranslationKeyWithValue(
                JOB_FUNCTIONS,
                getFormData("JobFunction"),
              ),
            )}${
              getFormData("JobFunction") === JOB_FUNCTIONS.OTHER.value
                ? `: ${getFormData("JobFunctionDesc")}`
                : ""
            }`}
          />
        )}
        {getFormData("JobTitle") && (
          <SummaryFieldBlock
            question={`${t("forms.fields.jobTitle")}:`}
            answer={getFormData("JobTitle")}
          />
        )}
        <React.Fragment>
          {getFormData("EmployerStreetAddress1") && (
            <div style={{ fontSize: 20 }}>
              <strong>
                {t("views.signUp.components.summery.employerAddress")}
              </strong>
            </div>
          )}
          {getFormData("EmployerStreetAddress1") && (
            <SummaryFieldBlock
              question={`${t("forms.fields.address1")}:`}
              answer={getFormData("EmployerStreetAddress1")}
            />
          )}
          {getFormData("EmployerStreetAddress2") && (
            <SummaryFieldBlock
              question={`${t("forms.fields.address2")}:`}
              answer={getFormData("EmployerStreetAddress2")}
            />
          )}
          {getFormData("EmployerCity") && (
            <SummaryFieldBlock
              question={`${t("forms.fields.city")}:`}
              answer={getFormData("EmployerCity")}
            />
          )}
          {getFormData("EmployerState") && (
            <SummaryFieldBlock
              question={`${t("forms.fields.state")}:`}
              answer={getFormData("EmployerStateProvince")}
            />
          )}
          {getFormData("EmployerZipPostal") && (
            <SummaryFieldBlock
              question={`${
                OnboardingStore.isUserUSCitizen()
                  ? t("forms.fields.zipCode")
                  : t("forms.fields.postalCode")
              }:`}
              answer={getFormData("EmployerZipPostal")}
            />
          )}
          {getFormData("EmployerCountry") && (
            <SummaryFieldBlock
              question={`${t("forms.fields.country")}:`}
              answer={getFormData("EmployerCountry")}
            />
          )}
        </React.Fragment>
      </React.Fragment>
    );
  },
);

export default SummaryEmploymentInfoForm;
