import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken,
  CancelTokenSource,
} from "axios";

// ==== Types ====
import { EHttpMethods } from "types/globalTypes";

// ==== Utilities ====
import { handleErrorTracking } from "utilities/apiUtilities";
import type { IFormData, IRequestPreview } from "types/globalTypes";

class RegisterApi {
  getPreview = async (
    id: string,
    cancelToken: CancelToken,
  ): Promise<AxiosResponse<IRequestPreview>> => {
    return await this._call({
      cancelToken,
      method: EHttpMethods.GET,
      url: `/l/migration/wealthaccess/${id}/preview`,
    });
  };

  putMetaData = async (
    id: string,
    data: IFormData,
    cancelToken: CancelToken,
  ): Promise<AxiosResponse> => {
    return await this._call({
      cancelToken,
      data,
      method: EHttpMethods.PUT,
      url: `/l/migration/wealthaccess/${id}/metadata`,
    });
  };

  postQueue = async (
    id: string,
    cancelToken: CancelToken,
  ): Promise<AxiosResponse> => {
    return await this._call({
      cancelToken,
      data: {},
      method: EHttpMethods.POST,
      url: `/l/migration/wealthaccess/${id}/queue`,
    });
  };

  newTokenSource = (): CancelTokenSource => {
    return axios.CancelToken.source();
  };

  private _call = async (
    config: AxiosRequestConfig,
  ): Promise<AxiosResponse> => {
    try {
      let _axios = axios.create({
        responseType: "json",
        timeout: 60000,
      });
      const response = await _axios(config);
      return response;
    } catch (e) {
      handleErrorTracking(e, "RegisterApi", "_call");
    }
  };
}

export default new RegisterApi();
