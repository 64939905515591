import * as React from "react";
import { TFunction } from "i18next";
import { Select } from "antd";

// ==== Types ====
import { getIndexByTranslation, IEnumType } from "utilities/typeUtilities";
import { IMoneyOptionsType } from "views/Options/types/optionsTypes";
import {
  OCCUPATIONS,
  MONEY_OPTIONS,
} from "views/SignUp/Onboarding/types/onboardingEnums";

const { Option } = Select;

export const renderSelectOptions = (
  options: IEnumType | IMoneyOptionsType,
  t: TFunction,
): JSX.Element[] => {
  const selectOptions: JSX.Element[] = [];
  let otherOption: JSX.Element;

  const translations = Object.keys(options).map((k) => {
    return t(options[k].translationKey);
  });

  // IMoneyOptionsType options don't need to be sorted
  if (translations.includes(t(MONEY_OPTIONS.LESS_THAN_50K.translationKey))) {
    translations.forEach((translation) => {
      // Retrieve entire enum
      const { key, value } = getIndexByTranslation(options, translation, t);

      // Create JSX Element for respective option
      selectOptions.push(
        <Option key={key} value={value}>
          {translation}
        </Option>,
      );
    });
  } else {
    // Generic IEnumType options should be sorted
    translations.sort().forEach((translation) => {
      const { key, value } = getIndexByTranslation(options, translation, t);

      // Populate OTHER option
      if (key === OCCUPATIONS.OTHER.key) {
        otherOption = (
          <Option key={key} value={value}>
            {translation}
          </Option>
        );
      } else {
        selectOptions.push(
          <Option key={key} value={value}>
            {translation}
          </Option>,
        );
      }
    });

    // OTHER should always be the last option
    selectOptions.push(otherOption);
  }

  return selectOptions;
};
