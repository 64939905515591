import { makeAutoObservable, get, toJS } from "mobx";

// ==== Stores ====
import AuthenticationStore from "stores/AuthenticationStore";
import FormsStore from "stores/FormsStore";

// ==== Types ====
import {
  EOnboardingModelTypes,
  EOnboardingForms,
  EOnboardingScreens,
  IAddressCollectionForm,
  IBeneficiariesForm,
  IDocumentsForm,
  IEmploymentInformationForm,
  IIndividualForm,
  IStockExchangeAssociationForm,
  IStonexAffiliateForm,
  ICAISForm,
  IPersonalInfoForm,
  IDocumentType,
  IOnboardingProfile,
  ITrustedContactForm,
  IAccountSelectionForm,
  IUploadIdForm,
  ITermsAgreements,
  IOnboardingFormData,
  IAccountOpening,
  IAccountFeature,
} from "views/SignUp/Onboarding/types/onboardingTypes";
import { IDevToken, TOKEN_USER_STATES } from "types/authenticationTypes";
import { ACCOUNT_OPEN_STATUSES } from "views/SignUp/Onboarding/types/onboardingEnums";

// ==== Api Handlers ====
import {
  postAccountType,
  putTermsAgreements,
  putAccountFeature,
  postSubmitAccount,
  postAccountOpeningUpdate,
  getEligibleAccountOpeningType,
} from "apiHandlers/AccountOpeningApiHandler";
import { uploadFile } from "apiHandlers/DocumentApiHandler";
import { addNewUserClient } from "apiHandlers/SelfEnrollApiHandler";
import {
  getProfileById,
  putIndividualData,
  putAddressFormData,
  putTrustedContactFormData,
  putEmploymentFormData,
  putDemographicFormData,
  putIndustryAffiliationFormData,
  putCAISFormData,
  putPublicCompanyAffiliation,
  putGovernmentId,
  putUpdateFinancialProfile,
  putFirmAffiliation,
} from "apiHandlers/ProfileApiHandler";

// ==== Data ====
import STATES from "assets/data/states.json";
import { DOCUMENTS_LIST } from "views/SignUp/Onboarding/forms/DocumentsForm";

// ==== Utilities ====
import { cleanString } from "utilities/genericUtilities";

class OnboardingStore {
  // Magic bool used to control the submit spinner
  submitLoading: boolean = false;
  // Magic bool used to tell the success screen that this is a second account
  submittedAdditionalAccount: boolean = false;

  // ==== Notifications ====
  // Used by postAccountOpeningSubmit if the max retry limit has hit
  errorMaxRetryFailed: boolean = false;
  // Controls the notification telling users to check their email
  hasNewAccountNotification: boolean = false;
  // Controls the notification if the user already has pre-existing data
  hasPartialAccountData: boolean = false;
  // Displays a banner to a user if they are creating their 2nd (or 3rd+) account
  isCreatingMultipleAccounts: boolean = false;
  // Used on the documentsForm when a file is downloading
  // Note: Value is the documentName currently being downloading
  isFileDownloading: string = undefined;

  // ==== API Response ====
  profileId: string = undefined;
  // The massive blob of data that stores everything
  profile: IOnboardingProfile = undefined;
  // A user can have more than one accountOpening stored on the profile
  // This is the accountOpening we are working with
  accountOpening: IAccountOpening = undefined;
  // The eligible account types that the is allowed to open
  eligibleAccountTypes: number[];

  // ==== User Input ====
  // Used in UploadId form
  uploadedFile: any = undefined;
  // The stored data for our MasterDocument pdf data
  pdfData: IDocumentType[] = [];
  // Behold, the typing for our FORM DATA!
  formData: {
    [EOnboardingForms.ACCOUNT_CREATION]: Partial<IAccountSelectionForm>;
    [EOnboardingForms.ADDRESS_COLLECTION]: Partial<IAddressCollectionForm>;
    [EOnboardingForms.BENEFICIARIES]: Partial<IBeneficiariesForm>;
    [EOnboardingForms.CAIS]: Partial<ICAISForm>;
    [EOnboardingForms.DOCUMENTS]: Partial<IDocumentsForm>;
    [EOnboardingForms.EMPLOYMENT_INFORMATION]: Partial<IEmploymentInformationForm>;
    [EOnboardingForms.INDIVIDUAL]: Partial<IIndividualForm>;
    [EOnboardingForms.PERSONAL_INFO]: Partial<IPersonalInfoForm>;
    [EOnboardingForms.STOCK_EXCHANGE_ASSOCIATION]: Partial<IStockExchangeAssociationForm>;
    [EOnboardingForms.STONEX_AFFILIATE]: Partial<IStonexAffiliateForm>;
    [EOnboardingForms.TRUSTED_CONTACT]: Partial<ITrustedContactForm>;
    [EOnboardingForms.UPLOAD_ID]: Partial<IUploadIdForm>;
  } = {
    // Default form values
    [EOnboardingForms.ADDRESS_COLLECTION]: {},
    [EOnboardingForms.ACCOUNT_CREATION]: {},
    [EOnboardingForms.BENEFICIARIES]: {},
    [EOnboardingForms.DOCUMENTS]: {},
    [EOnboardingForms.EMPLOYMENT_INFORMATION]: {},
    [EOnboardingForms.INDIVIDUAL]: {
      ConfirmCountryCode: "1",
      CountryCode: "1",
    },
    [EOnboardingForms.STOCK_EXCHANGE_ASSOCIATION]: {},
    [EOnboardingForms.STONEX_AFFILIATE]: {},
    [EOnboardingForms.CAIS]: {},
    [EOnboardingForms.PERSONAL_INFO]: {},
    [EOnboardingForms.TRUSTED_CONTACT]: {},
    [EOnboardingForms.UPLOAD_ID]: {},
  };

  constructor() {
    makeAutoObservable(this);
  }

  // ==== Utility functions ====
  handleFormReset = () => {
    FormsStore.resetErrorHandler();

    this.submitLoading = false;
    this.submittedAdditionalAccount = false;
    this.errorMaxRetryFailed = false;
    this.hasNewAccountNotification = false;
    this.hasPartialAccountData = false;
    this.isCreatingMultipleAccounts = false;

    this.profileId = undefined;

    this.profile = undefined;
    this.accountOpening = undefined;

    this.pdfData = [];
    this.uploadedFile = undefined;

    this.formData = {
      [EOnboardingForms.ADDRESS_COLLECTION]: {},
      [EOnboardingForms.ACCOUNT_CREATION]: {},
      [EOnboardingForms.BENEFICIARIES]: {},
      [EOnboardingForms.DOCUMENTS]: {},
      [EOnboardingForms.EMPLOYMENT_INFORMATION]: {},
      [EOnboardingForms.INDIVIDUAL]: {
        ConfirmCountryCode: "1",
        CountryCode: "1",
      },
      [EOnboardingForms.STOCK_EXCHANGE_ASSOCIATION]: {},
      [EOnboardingForms.STONEX_AFFILIATE]: {},
      [EOnboardingForms.CAIS]: {},
      [EOnboardingForms.PERSONAL_INFO]: {},
      [EOnboardingForms.TRUSTED_CONTACT]: {},
      [EOnboardingForms.UPLOAD_ID]: {},
    };
  };

  // ==== Getter and Setters ====
  setProfile = (profile: IOnboardingProfile) => {
    this.profile = profile;
  };

  setAccountOpening = (accountOpening: IAccountOpening) => {
    this.accountOpening = accountOpening;
  };

  setErrorMaxRetryFailed = (errorMaxRetryFailed: boolean) => {
    this.errorMaxRetryFailed = errorMaxRetryFailed;
  };

  setSubmitLoading = (submitLoading: boolean) => {
    this.submitLoading = submitLoading;
  };

  setEligibleAccountTypes = (eligibleAccountTypes: number[]) => {
    this.eligibleAccountTypes = eligibleAccountTypes;
  };

  setHasNewAccountNotification = (hasNewAccountNotification: boolean) => {
    this.hasNewAccountNotification = hasNewAccountNotification;
  };

  setUploadedFile = (uploadedFile: string) => {
    this.uploadedFile = uploadedFile;
  };

  setPdfData = (pdfData: IDocumentType) => {
    this.pdfData = this.pdfData.concat([pdfData]);
  };

  setProfileId = (profileId: string) => {
    this.profileId = profileId;
  };

  // Gets the data for a specific key in a form
  // e.g. (NEW_ACCOUNT_INFO, firstName) -> 'Bob'
  getFormItemData = (formKey: EOnboardingForms, key: string) => {
    return get(this.formData[formKey], key);
  };

  // New value must be in the form of "{key: value}"
  // e.g. {country: 'US'}
  setFormItemData = (
    formKey: EOnboardingForms,
    newValue: Partial<IOnboardingFormData>,
  ) => {
    this.formData[formKey] = {
      ...this.formData[formKey],
      ...newValue,
    };
  };

  // Gets the data for a specific form as a mobx data object
  //e.g. (NEW_ACCOUNT_INFO) -> { firstName: "bob", lastName: "smith"... }
  getFormData = (formKey: EOnboardingForms) => {
    return this.formData[formKey];
  };

  // Takes a modelType and optional address type, returns ID or undefined
  // e.g. (ADDRESSES, ADDRESS_TYPES.EMPLOYMENT.value) => 12345-123532-1231 || undefined
  getModelId = (model: EOnboardingModelTypes, addressType?: number): string => {
    switch (model) {
      // Currently we only support ONE address per type
      case EOnboardingModelTypes.ADDRESSES: {
        const addresses = toJS(this.profile[model]);

        const filteredAddressed = addresses.filter(
          (address: { type: number }) => address.type === addressType,
        );

        return filteredAddressed[0] ? filteredAddressed[0]?.id : undefined;
      }
      // Currently we only support a single trusted contact
      case EOnboardingModelTypes.DOCUMENTS:
      case EOnboardingModelTypes.TRUSTED_CONTACTS: {
        const trustedContacts = toJS(this.profile[model]);

        return trustedContacts[0] ? trustedContacts[0]?.id : undefined;
      }
      case EOnboardingModelTypes.PUBLIC_COMPANY:
      case EOnboardingModelTypes.FIRM:
      case EOnboardingModelTypes.INDUSTRY: {
        const affiliationData = toJS(
          this.profile[EOnboardingModelTypes.AFFILIATIONS],
        );

        return affiliationData ? affiliationData[model]?.id : undefined;
      }
      case EOnboardingModelTypes.ACCOUNT_FEATURE: {
        return this.accountOpening?.accountFeature?.id;
      }
      default:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore - Not all sub-objects in profile have IDs, this generically will grab the ones that do
        return this.profile[model]?.id;
    }
  };

  getTermsAgreementsId = (documentType: string): string => {
    if (this.accountOpening) {
      const filteredTermsAgreements =
        this.accountOpening.termsAgreements.filter(
          (agreements: ITermsAgreements) =>
            agreements.documentName === documentType,
        );

      // If we have more than one yolo and just pop the first one
      if (filteredTermsAgreements[0]) {
        return filteredTermsAgreements[0].id;
      }
    }
    return undefined;
  };

  getAccountType = (): number => {
    return this.getFormItemData(
      EOnboardingForms.ACCOUNT_CREATION,
      "accountType",
    );
  };

  getPdfData = (documentName: string): IDocumentType => {
    return this.pdfData.find((pdfDoc) => pdfDoc.documentName === documentName);
  };

  // ==== Account Opening Functions ====
  getAccountOpening = (): IAccountOpening => {
    return this.accountOpening;
  };

  getAccountOpeningAccountNumber = (): string => {
    return this.getAccountOpening()?.accountNumber;
  };

  getAccountOpeningId = (): string => {
    return this.getAccountOpening()?.id;
  };

  getAccountOpeningType = (): number => {
    return this.getAccountOpening()?.openType;
  };

  getAccountFeatures = (): IAccountFeature => {
    return this.getAccountOpening()?.accountFeature;
  };

  isAccountApproved = (): boolean => {
    const accountStatus = this.getAccountOpening()?.status;

    if (accountStatus === ACCOUNT_OPEN_STATUSES.OPENED.value) {
      return true;
    }

    return false;
  };

  isUserUSCitizen = (): boolean => {
    return (
      this.getFormItemData(
        EOnboardingForms.PERSONAL_INFO,
        "CountryOfCitizenship",
      ) === "US"
    );
  };
  setTokenData = (token: IDevToken) => {
    if (token) {
      const {
        email,
        firstName,
        lastName,
        phoneNumber,
        phoneCountryCode,
        dateOfBirth,
      } = token;

      // Fixes an edge case where StoneX One's apis returned a dirty phone number
      // clears all non-numbers from the phone number
      const cleanPhoneNumber = phoneNumber.replace(/\D/g, "");

      AuthenticationStore.updateNewUserCreationFormData({
        confirmEmail: email,
        email: email,
      });
      AuthenticationStore.updateLoginFormData({
        ConfirmEmail: email,
        Email: email,
      });

      // Adding our good friend cleanString here to maybe hopefully unlikely
      // fix a bug where phone numbers are being preloaded incorrectly
      this.setFormItemData(EOnboardingScreens.INDIVIDUAL, {
        ConfirmCountryCode: cleanString(phoneCountryCode),
        ConfirmEmail: email,
        ConfirmPhone: cleanString(cleanPhoneNumber),
        CountryCode: cleanString(phoneCountryCode),
        Email: email,
        FirstName: firstName,
        LastName: lastName,
        Phone: cleanString(cleanPhoneNumber),
      });

      // 0001-01-01T00:00:00 = the default value if a DoB is not added to an account
      if (dateOfBirth && dateOfBirth !== "0001-01-01T00:00:00") {
        const dateTimeArray = dateOfBirth.split(" ");
        const dobArray = dateTimeArray[0].split("/");

        // StoneX One reported NaN issues with the DOB fields, we
        // were unable to replicate but I think it has something to do with
        // bad data being injected. Added an extra check here
        if (dobArray.length === 3) {
          this.setFormItemData(EOnboardingScreens.INDIVIDUAL, {
            DobDay: Number(dobArray[1]),
            DobMonth: Number(dobArray[0]),
            DobYear: Number(dobArray[2]),
          });
        }
      }
    }
  };

  // ==== Form Functions ====
  isUserInMaritalStatusState = (): boolean => {
    const stateOfRecord = this.getFormItemData(
      EOnboardingForms.ADDRESS_COLLECTION,
      "StateProvince",
    );
    return STATES.some(
      (state) => stateOfRecord === state.code && state.isMaritalStatusRequired,
    );
  };

  // ==== Form Validate Functions ====
  validateNewAccountInfo = async (updateScreenKey: string): Promise<void> => {
    if (
      AuthenticationStore.tokenUserStats === TOKEN_USER_STATES.USER_NOT_FOUND
    ) {
      addNewUserClient();
      FormsStore.setGuardedCurrentScreen(EOnboardingScreens.CHECK_EMAIL);
    } else {
      await getProfileById();

      FormsStore.setGuardedCurrentScreen(updateScreenKey);
    }
  };

  validateAddressInfo = async (updateScreenKey: string): Promise<void> => {
    await putAddressFormData();

    FormsStore.setGuardedCurrentScreen(updateScreenKey);
  };

  validateAffiliateInfo = async (updateScreenKey: string): Promise<void> => {
    await putFirmAffiliation();

    FormsStore.setGuardedCurrentScreen(updateScreenKey);
  };

  validateBeneficiariesInfo = (updateScreenKey: string) => {
    FormsStore.setGuardedCurrentScreen(updateScreenKey);
  };

  validateEmploymentInfo = async (updateScreenKey: string): Promise<void> => {
    await putEmploymentFormData();
    await putDemographicFormData();

    // lol
    await putUpdateFinancialProfile();

    FormsStore.setGuardedCurrentScreen(updateScreenKey);
  };

  validateCaisInfo = async (updateScreenKey: string): Promise<void> => {
    await putCAISFormData();

    FormsStore.setGuardedCurrentScreen(updateScreenKey);
  };

  validateExchangeAssociationInfo = async (
    updateScreenKey: string,
  ): Promise<void> => {
    await putIndustryAffiliationFormData();
    await putPublicCompanyAffiliation();

    FormsStore.setGuardedCurrentScreen(updateScreenKey);
  };

  validateTrustedContactInfo = async (
    updateScreenKey: string,
  ): Promise<void> => {
    await putTrustedContactFormData();

    FormsStore.setGuardedCurrentScreen(updateScreenKey);
  };

  validateTaxInfo = async (updateScreenKey: string): Promise<void> => {
    await putIndividualData();

    // Preload Valid Account Types
    await getEligibleAccountOpeningType();

    FormsStore.setGuardedCurrentScreen(updateScreenKey);
  };

  validateUploadIdInfo = async (updateScreenKey: string): Promise<void> => {
    // If we don't have a file uploaded, upload it
    if (!this.getFormItemData(EOnboardingForms.UPLOAD_ID, "isFileUploaded")) {
      await uploadFile();
    }
    // After uploading the file, we need to PUT the document info
    await putGovernmentId();

    FormsStore.setGuardedCurrentScreen(updateScreenKey);
  };

  validateCreationSelection = async (
    updateScreenKey: string,
    isAccountEligibleError: boolean,
  ): Promise<void> => {
    const newAccountType = this.getAccountType();
    const oldAccountType = this.getAccountOpeningType();

    if (newAccountType !== oldAccountType) {
      if (isAccountEligibleError) {
        // Edit existing account
        this.accountOpening.openType = newAccountType;
        await postAccountOpeningUpdate();
      } else {
        // Create new account
        await postAccountType(newAccountType);
      }
    }

    FormsStore.setGuardedCurrentScreen(updateScreenKey);
  };

  validateDocumentsInfo = async (updateScreenKey: string): Promise<void> => {
    for (const document of DOCUMENTS_LIST) {
      await putTermsAgreements(document.documentName);
    }

    const isNoboChecked = this.getFormItemData(
      EOnboardingForms.DOCUMENTS,
      "isNoboChecked",
    );

    await putAccountFeature(this.getAccountOpeningId(), {
      noboOptOut: !!isNoboChecked,
    });

    // Check to make sure that the account has NOT been submitted already
    const accountOpening = this.getAccountOpening();

    FormsStore.setGuardedCurrentScreen(updateScreenKey);

    if (accountOpening?.status === ACCOUNT_OPEN_STATUSES.CREATED.value) {
      // Hold onto your butts
      await postSubmitAccount(this.getAccountOpeningId());
    }
  };

  // ==== Form Update Functions ====
  updateFormData = (
    changedFields: Partial<IOnboardingFormData>,
    formKey: EOnboardingForms,
  ) => {
    this.formData[formKey] = {
      ...this.formData[formKey],
      ...changedFields,
    };

    // If the user updates the form, reset the error state
    FormsStore.resetErrorHandler();

    // These functions clears form data if one of their parent options has changed
    // e.g. User updates their country after entering their USTaxId
    // We would then need to clear their USTaxId because they are no longer a US citizen
    switch (formKey) {
      case EOnboardingForms.PERSONAL_INFO:
        this.handlePersonalInfoFormDataReset(changedFields);
        break;
      case EOnboardingForms.TRUSTED_CONTACT:
        this.handleTrustedContactDataReset(changedFields);
        break;
      case EOnboardingForms.EMPLOYMENT_INFORMATION:
        this.handleEmploymentDataReset(changedFields);
        break;
      case EOnboardingForms.STOCK_EXCHANGE_ASSOCIATION:
        this.handleStockExchangeAssociationDataReset(changedFields);
        break;
      case EOnboardingForms.STONEX_AFFILIATE:
        this.handleStonexAffiliateDataReset(changedFields);
        break;
      case EOnboardingForms.BENEFICIARIES:
        this.handleBeneficiaryFormReset(changedFields);
        break;
    }
  };

  // ==== Data OnClick Handlers ====
  handleAccountOpeningUpdate = (profile: any) => {
    const accountOpenings = profile.accountOpenings;

    if (accountOpenings && this.accountOpening) {
      const filteredAccountOpening = accountOpenings.filter(
        (account: IAccountOpening) => account.id === this.accountOpening.id,
      );

      if (filteredAccountOpening && filteredAccountOpening.length === 1) {
        this.accountOpening = filteredAccountOpening[0];
      } else {
        FormsStore.setErrorHandler("handleAccountOpeningUpdate");
      }
    }
  };

  // ==== Form Data Reset Functions ====
  handlePersonalInfoFormDataReset = (
    changedFields: Partial<IOnboardingFormData>,
  ) => {
    if (
      Object.prototype.hasOwnProperty.call(
        changedFields,
        "CountryOfCitizenship",
      )
    ) {
      this.formData[EOnboardingForms.PERSONAL_INFO] = {
        ...this.formData[EOnboardingForms.PERSONAL_INFO],
        ForeignTaxId: undefined,
        USTaxId: undefined,
      };
    }
  };

  handleTrustedContactDataReset = (
    changedFields: Partial<IOnboardingFormData>,
  ) => {
    if (
      Object.prototype.hasOwnProperty.call(changedFields, "HasTrustedContact")
    ) {
      this.formData[EOnboardingForms.TRUSTED_CONTACT] = {
        ...this.formData[EOnboardingForms.TRUSTED_CONTACT],
        ContactCity: undefined,
        ContactCountry: undefined,
        ContactCountryCode: undefined,
        ContactEmail: undefined,
        ContactFirstName: undefined,
        ContactLastName: undefined,
        ContactPhone: undefined,
        ContactRelationship: undefined,
        ContactStateProvince: undefined,
        ContactStreetAddress1: undefined,
        ContactStreetAddress2: undefined,
        ContactZipPostal: undefined,
        HasTrustedContactAddress: undefined,
      };
    } else if (
      Object.prototype.hasOwnProperty.call(
        changedFields,
        "HasTrustedContactAddress",
      )
    ) {
      this.formData[EOnboardingForms.TRUSTED_CONTACT] = {
        ...this.formData[EOnboardingForms.TRUSTED_CONTACT],
        ContactCity: undefined,
        ContactCountry: undefined,
        ContactStateProvince: undefined,
        ContactStreetAddress1: undefined,
        ContactStreetAddress2: undefined,
        ContactZipPostal: undefined,
      };
    }
  };

  handleEmploymentDataReset = (changedFields: Partial<IOnboardingFormData>) => {
    if (
      Object.prototype.hasOwnProperty.call(changedFields, "EmploymentStatus")
    ) {
      this.formData[EOnboardingForms.EMPLOYMENT_INFORMATION] = {
        ...this.formData[EOnboardingForms.EMPLOYMENT_INFORMATION],
        Employer: undefined,
        EmployerCity: undefined,
        EmployerCountry: undefined,
        EmployerStateProvince: undefined,
        EmployerStreetAddress1: undefined,
        EmployerStreetAddress2: undefined,
        EmployerZipPostal: undefined,
        Industry: undefined,
        IndustryDesc: undefined,
        JobFunction: undefined,
        JobFunctionDesc: undefined,
        JobTitle: undefined,
        Occupation: undefined,
        OccupationDesc: undefined,
      };
    }
  };

  handleStockExchangeAssociationDataReset = (
    changedFields: Partial<IOnboardingFormData>,
  ) => {
    if (Object.prototype.hasOwnProperty.call(changedFields, "IsFINRA")) {
      this.formData[EOnboardingForms.STOCK_EXCHANGE_ASSOCIATION] = {
        ...this.formData[EOnboardingForms.STOCK_EXCHANGE_ASSOCIATION],
        BusinessName: undefined,
      };
    } else if (Object.prototype.hasOwnProperty.call(changedFields, "Rule114")) {
      this.formData[EOnboardingForms.STOCK_EXCHANGE_ASSOCIATION] = {
        ...this.formData[EOnboardingForms.STOCK_EXCHANGE_ASSOCIATION],
        CompanyName: undefined,
        CUSIP: undefined,
      };
    }
  };

  handleStonexAffiliateDataReset = (
    changedFields: Partial<IOnboardingFormData>,
  ) => {
    if (Object.prototype.hasOwnProperty.call(changedFields, "AreYouRelated")) {
      this.formData[EOnboardingForms.STONEX_AFFILIATE] = {
        ...this.formData[EOnboardingForms.STONEX_AFFILIATE],
        AffiliatedEntity: undefined,
        AffiliateFirstName: undefined,
        AffiliateLastName: undefined,
        AssociationRelationshipType: undefined,
        WhatPosition: undefined,
      };
    }
  };

  // Since this function is used outside of this store as well, we do not always have
  // to check to see which fields are changed.  If, however, we are calling this function inside
  // this store, we need to know which fields have changed to prevent the Beneficiary Form from
  // being reset too much
  handleBeneficiaryFormReset = (
    changedFields?: Partial<IOnboardingFormData>,
  ) => {
    if (
      changedFields === undefined ||
      Object.prototype.hasOwnProperty.call(changedFields, "StateProvince")
    ) {
      this.formData[EOnboardingForms.BENEFICIARIES] = {
        ...this.formData[EOnboardingForms.BENEFICIARIES],
        maritalStatus: undefined,
      };
    }
  };
}

export default new OnboardingStore();
