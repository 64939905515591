import { makeAutoObservable } from "mobx";

import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import FormsStore from "stores/FormsStore";
import {
  putTermsAgreements,
  putAccountFeature,
} from "apiHandlers/AccountOpeningApiHandler";
import { DOCUMENTS } from "views/SignUp/Onboarding/types/onboardingEnums";

class MarginStore {
  constructor() {
    makeAutoObservable(this);
  }

  // ==== Form OnSubmit Functions ====
  validateMarginAgreementFormUpdate = async (nextScreenKey: string) => {
    const accountOpening = OnboardingStore.getAccountOpening();
    if (accountOpening) {
      await putTermsAgreements(DOCUMENTS.DIRECT_MARGIN_AGREEMENT.documentName);
      // Only making the call if they don't already have margin enabled
      if (!accountOpening?.accountFeature?.margin) {
        await putAccountFeature(OnboardingStore.getAccountOpeningId(), {
          margin: true,
        });
      }
    }

    FormsStore.setGuardedCurrentScreen(nextScreenKey);
  };

  // ==== Form Update Functions ====
  handleFormReset = () => {
    FormsStore.resetErrorHandler();
  };
}

export default new MarginStore();
