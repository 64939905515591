import axios, {
  AxiosRequestConfig,
  CancelToken,
  CancelTokenSource,
} from "axios";

// ==== Types ====
import { EHttpMethods } from "types/globalTypes";
import { IDevToken } from "types/authenticationTypes";

// ==== Utilities ====
import { handleErrorTracking } from "utilities/apiUtilities";

// ==== Stores ====
import AuthenticationStore from "stores/AuthenticationStore";

class TokenApi {
  newTokenSource = (): CancelTokenSource => {
    return axios.CancelToken.source();
  };

  getToken = async (
    cancelToken: CancelToken,
    email?: string,
  ): Promise<IDevToken> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.GET,
      params: { email },
      url: `/Token`,
    });

    return response;
  };

  private _call = async (config: AxiosRequestConfig): Promise<any> => {
    // The baseURL for the token call is authRedirect because the BE devs said so!
    const _axios = axios.create({
      baseURL: `${AuthenticationStore?.config?.authRedirect}l/api`,
      responseType: "text",
      timeout: 60000,
    });

    try {
      const response = await _axios(config);
      return response?.data;

      // NOTE: Errors on catch blocks in TS are only ever defined as any or unknown
      // https://devblogs.microsoft.com/typescript/announcing-typescript-4-0-beta/#unknown-on-catch
    } catch (e: any) {
      // Triggered on a 401 when a user does not have a token
      if (e.response?.data) {
        return e.response.data;
      } else {
        handleErrorTracking(e, "TokenApi", "_call");
      }
    }
  };
}

export default new TokenApi();
