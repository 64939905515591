import * as React from "react";
import { QuestionCircleTwoTone } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { Col, Tooltip, Modal, Radio, Row, Select } from "antd";
import { observer } from "mobx-react";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import FormsStore from "stores/FormsStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import BeneficiariesStore from "views/SignUp/Onboarding/store/BeneficiariesStore";

// ==== Components ====
import FormHeader from "components/FormHeader";
import BeneficiaryTable from "./BeneficiaryTable";
import AddBeneficiaryForm from "./AddBeneficiaryForm";
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";

// ==== Types ====
import {
  EOnboardingForms,
  EOnboardingScreens,
  IBeneficiariesForm,
} from "views/SignUp/Onboarding/types/onboardingTypes";
import {
  BENEFICIARY_ACCOUNT_TYPES,
  MARITAL_STATUS_TYPES,
} from "views/SignUp/Onboarding/types/onboardingEnums";

// ==== Data ====
import COLORS from "assets/styles/config/colors.json";

const { Option } = Select;

const BeneficiariesContainer: React.FunctionComponent<FormComponentProps> =
  observer(({ form }) => {
    const { getFieldDecorator } = form;
    const { t } = useTranslation();

    React.useEffect(() => {
      if (
        OnboardingStore.getAccountOpeningType() !==
          BeneficiariesStore.accountType ||
        OnboardingStore.getAccountOpeningId() !==
          BeneficiariesStore.accountOpeningId
      ) {
        BeneficiariesStore.setAccountType(
          OnboardingStore.getAccountOpeningType(),
          OnboardingStore.getAccountOpeningId(),
        );
      }
    });

    const getInitialValue = (id: string) =>
      OnboardingStore.getFormItemData(EOnboardingForms.BENEFICIARIES, id);

    const isNextButtonDisabled = () => {
      if (getInitialValue("HasBeneficiaries") === undefined) {
        return true;
      } else if (getInitialValue("HasBeneficiaries")) {
        if (
          OnboardingStore.isUserInMaritalStatusState() &&
          getInitialValue("maritalStatus") === undefined
        ) {
          return true;
        }
        return !BeneficiariesStore.isBeneficiaryPercentageValid();
      } else {
        return false;
      }
    };

    const isBeneficiariesAccountsEmpty = () => {
      // If we have ANY percentage, we have an account
      const primaryPercentage = BeneficiariesStore.getAllocatedPercentage(
        BENEFICIARY_ACCOUNT_TYPES.PRIMARY.value,
      );

      const contingentPercentage = BeneficiariesStore.getAllocatedPercentage(
        BENEFICIARY_ACCOUNT_TYPES.CONTINGENT.value,
      );

      return contingentPercentage === 0 && primaryPercentage === 0
        ? true
        : false;
    };

    const renderPercentageHeader = (header: string, percentType: number) => {
      const percentage = BeneficiariesStore.getAllocatedPercentage(percentType);
      let isPercentageValid = false;

      if (percentType === BENEFICIARY_ACCOUNT_TYPES.CONTINGENT.value) {
        const primaryPercentage = BeneficiariesStore.getAllocatedPercentage(
          BENEFICIARY_ACCOUNT_TYPES.PRIMARY.value,
        );
        if (
          primaryPercentage === 100 &&
          (percentage === 0 || percentage === 100)
        ) {
          isPercentageValid = true;
        }
      } else {
        if (percentage === 100) {
          isPercentageValid = true;
        }
      }

      return (
        <div className="headerWrapper">
          <div className="percentageHeader">{header}</div>
          <div
            className={`percentage ${
              isPercentageValid ? "successGreen" : "errorRed"
            } `}
          >
            {`${percentage}%`}
          </div>
        </div>
      );
    };

    const renderBeneficiariesForm = () => {
      return (
        <Row align="middle" justify="space-between">
          <Col>
            {renderPercentageHeader(
              t("views.signUp.forms.BeneficiariesForm.primaryTotal"),
              BENEFICIARY_ACCOUNT_TYPES.PRIMARY.value,
            )}
          </Col>
          <Col>
            {renderPercentageHeader(
              t("views.signUp.forms.BeneficiariesForm.contingentTotal"),
              BENEFICIARY_ACCOUNT_TYPES.CONTINGENT.value,
            )}
          </Col>
        </Row>
      );
    };

    const renderBeneficiaryContainer = () => (
      <React.Fragment>
        <div className="subheader" style={{ marginBottom: 24 }}>
          {t("views.signUp.forms.BeneficiariesForm.pleaseAdd")}
        </div>
        {renderBeneficiariesForm()}
        <BeneficiaryTable />
        <Modal
          title={t("views.signUp.forms.BeneficiariesForm.newBeneficiary")}
          centered
          destroyOnClose
          onCancel={() => {
            BeneficiariesStore.toggleModalState();
            BeneficiariesStore.clearFormData();
          }}
          visible={BeneficiariesStore.isModalOpen}
          footer={null}
        >
          <AddBeneficiaryForm />
        </Modal>
      </React.Fragment>
    );

    const goForwardHandler = () => {
      !isNextButtonDisabled() &&
        OnboardingStore.validateBeneficiariesInfo(EOnboardingScreens.SUMMARY);
    };

    const goBackHandler = () => {
      FormsStore.setCurrentScreen(
        EOnboardingScreens.STOCK_EXCHANGE_ASSOCIATION,
      );
    };

    return (
      <div className="slideInWrapper beneficiariesForm">
        <FormHeader
          headerText={t(
            "views.signUp.forms.BeneficiariesForm.beneficiaryHeader",
          )}
        />
        <Form layout="vertical" hideRequiredMark>
          <Form.Item
            className="yesNoFormElement"
            label={t("views.signUp.forms.BeneficiariesForm.setUpBeneficiaries")}
          >
            {getFieldDecorator("HasBeneficiaries", {
              initialValue: getInitialValue("HasBeneficiaries"),
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.field"),
                  }),
                  required: true,
                },
              ],
              validateTrigger: "onBlur",
            })(
              <Radio.Group className="buttonGroupWrapper">
                <Radio.Button value={true}>
                  {t("forms.options.yes")}
                </Radio.Button>
                <Tooltip
                  title={
                    !isBeneficiariesAccountsEmpty() &&
                    t("views.signUp.forms.BeneficiariesForm.pleaseRemove")
                  }
                >
                  <Radio.Button
                    disabled={!isBeneficiariesAccountsEmpty()}
                    value={false}
                  >
                    {t("forms.options.no")}
                  </Radio.Button>
                </Tooltip>
              </Radio.Group>,
            )}
          </Form.Item>
          {getInitialValue("HasBeneficiaries") &&
            OnboardingStore.isUserInMaritalStatusState() && (
              <Form.Item
                style={{ paddingBottom: 25 }}
                className="yesNoFormElement"
                label={
                  <div className="flexJustifyCenteredContent">
                    <div>
                      {t("views.signUp.forms.BeneficiariesForm.maritalStatus")}
                    </div>
                    <Tooltip
                      title={t(
                        "views.signUp.forms.BeneficiariesForm.maritalStatusExplanation",
                      )}
                    >
                      <QuestionCircleTwoTone
                        twoToneColor={COLORS.branding.secondary}
                        className="cursorPointer"
                      />
                    </Tooltip>
                  </div>
                }
              >
                {getFieldDecorator("maritalStatus", {
                  initialValue: getInitialValue("maritalStatus"),
                  rules: [
                    {
                      message: t("forms.validation.isRequired", {
                        fieldName: t("forms.fields.maritalStatus"),
                      }),
                      required: true,
                    },
                  ],
                })(
                  <Select placeholder={t("forms.fields.maritalStatus")}>
                    {Object.values(MARITAL_STATUS_TYPES).map(
                      ({ key, translationKey, value }) => {
                        return (
                          <Option key={key} value={value}>
                            {t(translationKey)}
                          </Option>
                        );
                      },
                    )}
                  </Select>,
                )}
              </Form.Item>
            )}
        </Form>
        {typeof getInitialValue("HasBeneficiaries") !== "undefined" &&
          (OnboardingStore.isUserInMaritalStatusState()
            ? getInitialValue("maritalStatus") !== undefined
            : true) && (
            <React.Fragment>
              {getInitialValue("HasBeneficiaries") &&
                renderBeneficiaryContainer()}
            </React.Fragment>
          )}
        <FormButtonFooter
          goForwardHandler={goForwardHandler}
          goBackHandler={
            !OnboardingStore.isCreatingMultipleAccounts
              ? goBackHandler
              : undefined
          }
          isGoForwardDisabled={isNextButtonDisabled()}
          goForwardDisabledMessage={t(
            "views.signUp.forms.BeneficiariesForm.nextButtonDisabledMessage",
          )}
        />
      </div>
    );
  });

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: IBeneficiariesForm) => {
    OnboardingStore.updateFormData(
      changedFields,
      EOnboardingForms.BENEFICIARIES,
    );
  },
})(BeneficiariesContainer);
