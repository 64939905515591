import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken,
  CancelTokenSource,
} from "axios";

// ==== Types ====
import { EHttpMethods, IPhoneNumber } from "types/globalTypes";
import {
  ICreateNewUserAccount,
  ICreateUserAccount,
  IValidateAccountInfo,
  IValidateUser,
  IUserExistsResponse,
} from "views/SignUp/SelfEnroll/types/selfEnrollTypes";
import {
  ISignUpApiRequest,
  IVerificationCodeApiRequest,
  IVerificationCodeApiResponse,
} from "types/authenticationTypes";

// ==== Stores ====
import AuthenticationStore from "stores/AuthenticationStore";
import FormsStore from "stores/FormsStore";

// ==== Utilities ====
import { generateBaseUrl, handleErrorTracking } from "utilities/apiUtilities";

const FILE_NAME = "SignUpApi";

class SignUpApi {
  newTokenSource = (): CancelTokenSource => {
    return axios.CancelToken.source();
  };

  getValidateAccount = async (
    accountNumber: string,
    cancelToken: CancelToken,
  ): Promise<{
    acctNo: string;
    acctType: string;
    status?: string;
  }> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.GET,
      url: `/ValidateAccount?AcctNo=${accountNumber}`,
    });

    return response ? response.data : undefined;
  };

  postAddNewUserAccount = async (
    data: ICreateNewUserAccount,
    cancelToken: CancelToken,
  ): Promise<{
    message: string;
    status: string;
  }> => {
    const response = await this._call({
      cancelToken,
      data,
      method: EHttpMethods.POST,
      url: "/AddNewUserAccount",
    });

    return response ? response.data : undefined;
  };

  postSignUp = async (
    data: ISignUpApiRequest,
    cancelToken: CancelToken,
  ): Promise<boolean> => {
    const response = await this._call({
      cancelToken,
      data,
      method: EHttpMethods.POST,
      url: "/SignUp",
    });

    return response ? response.data : undefined;
  };

  postExistingUserSignUp = async (
    data: ISignUpApiRequest,
    cancelToken: CancelToken,
  ): Promise<boolean> => {
    const response = await this._call({
      cancelToken,
      data,
      method: EHttpMethods.POST,
      url: "/ExistingUserSignUp",
    });

    return response ? response.data : undefined;
  };

  postVerification = async (
    data: IVerificationCodeApiRequest,
    cancelToken: CancelToken,
  ): Promise<IVerificationCodeApiResponse> => {
    const response = await this._call({
      cancelToken,
      data,
      method: EHttpMethods.POST,
      url: "/verification",
    });

    return response ? response.data : undefined;
  };

  getUserExists = async (
    email: string,
    cancelToken: CancelToken,
  ): Promise<IUserExistsResponse> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.GET,
      url: `/userExists?email=${email}`,
    });

    return response ? response.data : undefined;
  };

  getExistingEmail = async (
    profileId: string,
    cancelToken: CancelToken,
  ): Promise<string> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.GET,
      url: `/existingEmail?profileId=${profileId}`,
    });

    return response ? response.data : undefined;
  };

  postAuthenticate = async (): Promise<void> => {
    let headers = new Headers();
    headers.append("Content-Type", "application/x-www-form-urlencoded");

    // Need a redirect URL via authRedirect form the /config endpoint
    const redirectBaseUrl = AuthenticationStore.config.authRedirect;

    // Lord help us
    await fetch(`${redirectBaseUrl}l/authenticate`, {
      body: new URLSearchParams({
        Jwt: AuthenticationStore.jwtToken,
        RedirectURI: "signup/onboarding",
      }),
      headers: headers,
      method: EHttpMethods.POST,
      redirect: "follow",
    })
      .then((response) => {
        if (response.redirected) {
          window.location.href = response.url;
        }
      })
      .catch((e) => {
        FormsStore.setErrorHandler("authenticate");
        handleErrorTracking(e, FILE_NAME, "fetch");
      });
  };

  postAddUserAccount = async (
    data: ICreateUserAccount,
    cancelToken: CancelToken,
  ): Promise<{
    message: string;
    status: string;
  }> => {
    const response = await this._call({
      cancelToken,
      data,
      method: EHttpMethods.POST,
      url: "/AddUserAccount",
    });

    return response ? response.data : undefined;
  };

  getValidateAccountInfo = async (
    params: IValidateAccountInfo,
    cancelToken: CancelToken,
  ): Promise<{
    message: string;
    status: string;
  }> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.GET,
      params,
      url: "/ValidateAccountInfo",
    });

    return response ? response.data : undefined;
  };

  getValidateUser = async (
    params: IValidateUser,
    cancelToken: CancelToken,
  ): Promise<{
    message: string;
    status: string;
  }> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.GET,
      params,
      url: "/ValidateUser",
    });

    return response ? response.data : undefined;
  };

  postAddNewUserClient = async (
    cancelToken: CancelToken,
    data: {
      dateOfBirth: string;
      email: string;
      firstName: string;
      lastName: string;
      phoneNumber: IPhoneNumber;
      privatelabelcode: string;
      taxId?: string;
      taxType?: string;
    },
  ): Promise<{
    message: string;
    status: string;
  }> => {
    const response = await this._authorizedCall({
      cancelToken,
      data,
      method: EHttpMethods.POST,
      url: `/AddNewUserClient`,
    });

    return response ? response.data : undefined;
  };

  getVerificationEmailResend = async (
    cancelToken: CancelToken,
    email: string,
  ): Promise<boolean> => {
    const response = await this._authorizedCall({
      cancelToken,
      method: EHttpMethods.GET,
      params: { email },
      url: `/VerificationReSend`,
    });

    return response ? response.data : undefined;
  };

  private _call = async (
    config: AxiosRequestConfig,
  ): Promise<AxiosResponse> => {
    try {
      let _axios = axios.create({
        baseURL: `${generateBaseUrl()}l/api/SelfEnroll`,
        responseType: "json",
        timeout: 60000,
      });

      const response = await _axios(config);
      return response;
    } catch (e) {
      handleErrorTracking(e, FILE_NAME, "_call");
    }
  };

  private _authorizedCall = async (
    config: AxiosRequestConfig,
  ): Promise<AxiosResponse> => {
    try {
      let _axios = axios.create({
        baseURL: `${generateBaseUrl()}l/api/SelfEnroll`,
        headers: {
          Authorization: `Bearer ${AuthenticationStore.accessToken}`,
          "Cache-Control": "no-cache,no-store,max-age=-1",
          Pragma: "no-cache",
        },
        responseType: "json",
        timeout: 60000,
      });

      const response = await _axios(config);
      return response;
    } catch (e) {
      handleErrorTracking(e, "SelfEnrollApi", "_call");
    }
  };
}

export default new SignUpApi();
