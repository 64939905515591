import * as React from "react";
import { ColumnProps } from "antd/lib/table";
import { CloseCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Table, Row, Col, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

import BeneficiariesStore from "views/SignUp/Onboarding/store/BeneficiariesStore";
import { IBeneficiaryAccount } from "views/SignUp/Onboarding/types/onboardingTypes";
import { BENEFICIARY_ACCOUNT_TYPES } from "views/SignUp/Onboarding/types/onboardingEnums";

interface IBeneficiaryTableProps {
  isAddDeleteDisabled?: boolean;
}

const BeneficiaryTable: React.FunctionComponent<IBeneficiaryTableProps> = ({
  isAddDeleteDisabled,
}) => {
  const { t } = useTranslation();

  const alphabetizeAccountUsers = (users: IBeneficiaryAccount[]) => {
    return users.sort((a: IBeneficiaryAccount, b: IBeneficiaryAccount) => {
      let nameA = a.lastName.toUpperCase(); // ignore upper and lowercase
      let nameB = b.lastName.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1; //If nameA comes first
      }
      if (nameA > nameB) {
        return 1; // If nameB comes first
      }
      return 0; // Equal
    });
  };

  const columns: ColumnProps<any>[] = [
    {
      align: "left",
      dataIndex: "lastName",
      key: "lastName",
      render: (text: string, record: IBeneficiaryAccount) => (
        <React.Fragment>
          {`${record.firstName} ${record.lastName}`}
        </React.Fragment>
      ),
      title: t("forms.fields.name"),
      width: "33%",
    },
    {
      align: "center",
      key: "accountType",
      render: (text: string, record: IBeneficiaryAccount) => (
        <React.Fragment>
          {record.accountType === BENEFICIARY_ACCOUNT_TYPES.PRIMARY.value ? (
            <div className="primaryText">
              {t("views.signUp.forms.BeneficiariesForm.primary")}
            </div>
          ) : (
            <div className="contingentText">
              {t("views.signUp.forms.BeneficiariesForm.contingent")}
            </div>
          )}
        </React.Fragment>
      ),
      title: t("views.signUp.forms.BeneficiariesForm.type"),
      width: "33%",
    },
    {
      align: "center",
      key: "percentageAllocated",
      render: (text: string, record: IBeneficiaryAccount) => (
        <div>{`${record.percentageAllocated}%`}</div>
      ),
      title: "Percentage Allocated",
      width: "33%",
    },
    {
      align: "right",
      key: "action",
      render: (text: string, record: IBeneficiaryAccount) => {
        const handleRemoveAccount = () =>
          BeneficiariesStore.removeAccount(record.accountType, record.id);
        return (
          !isAddDeleteDisabled && (
            <Tooltip
              title={t("views.signUp.forms.BeneficiariesForm.deleteUser")}
            >
              <div className="deleteButton" onClick={handleRemoveAccount}>
                <CloseCircleOutlined />
              </div>
            </Tooltip>
          )
        );
      },
      width: "1%",
    },
  ];

  //Cleaning up or data before rendering it to the users
  const alphabetizedPrimaryUsers = alphabetizeAccountUsers(
    BeneficiariesStore.getBeneficiaries(
      BENEFICIARY_ACCOUNT_TYPES.PRIMARY.value,
    ),
  );
  const alphabetizedContingentUsers = alphabetizeAccountUsers(
    BeneficiariesStore.getBeneficiaries(
      BENEFICIARY_ACCOUNT_TYPES.CONTINGENT.value,
    ),
  );

  const tableData = alphabetizedPrimaryUsers.concat(
    alphabetizedContingentUsers,
  );

  const getRowKey = (record: IBeneficiaryAccount) => record.id;
  return (
    <div className="headerTable">
      <Row justify="space-between">
        <Col>
          <div className="subheader">
            <strong>
              {t(
                "views.signUp.forms.BeneficiariesForm.beneficiaryTableAddedHeader",
              )}
            </strong>
          </div>
        </Col>
        <Col>
          {!isAddDeleteDisabled && (
            <Row
              justify="space-between"
              className="addAccountButton"
              onClick={BeneficiariesStore.toggleModalState}
            >
              <Col>
                <div className="sidePadding">
                  {t("views.signUp.forms.BeneficiariesForm.newBeneficiary")}
                </div>
              </Col>
              <Col>
                <div className="sidePadding">
                  <PlusCircleOutlined />
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
      <Table
        columns={columns}
        rowKey={getRowKey}
        dataSource={tableData}
        pagination={false}
      />
    </div>
  );
};

export default BeneficiaryTable;
