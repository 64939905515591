import * as React from "react";
import { Spin } from "antd";
import { observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";

// ==== Stores ====
import FormsStore from "stores/FormsStore";
import AuthenticationStore from "stores/AuthenticationStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Components ====
import SidebarWrapper from "components/contentWrappers/SidebarWrapper";
import SplashScreenWrapper from "components/contentWrappers/SplashScreenWrapper";

// ==== Authentication Forms ====
import EmailValidateForm from "components/authenticationForms/EmailValidationForm";
import LoginForm from "components/authenticationForms/LoginForm";
import NewUserCreationForm from "components/authenticationForms/NewUserCreationForm";
import VerificationCodeForm from "components/authenticationForms/VerificationCodeForm";

// ==== Types ====
import { EOnboardingScreens } from "views/SignUp/Onboarding/types/onboardingTypes";
import { EAuthenticationScreens } from "types/authenticationTypes";
import { EMarginScreens } from "views/Margin/types/marginTypes";
import { EOptionScreens } from "views/Options/types/optionsTypes";
import { ERoutePaths } from "types/globalTypes";
import { ACCOUNT_OPEN_STATUSES } from "views/SignUp/Onboarding/types/onboardingEnums";

// ==== Forms ====
import PersonalInformationForm from "views/SignUp/Onboarding/forms/PersonalInformationForm";
import IndividualForm from "views/SignUp/Onboarding/forms/IndividualForm";
import AccountCreationForm from "views/SignUp/Onboarding/forms/AccountCreationForm";
import AddressCollectionForm from "views/SignUp/Onboarding/forms/AddressCollectionForm";
import UploadIdForm from "views/SignUp/Onboarding/forms/UploadIdForm";
import TrustedContactForm from "views/SignUp/Onboarding/forms/TrustedContactForm";
import EmploymentInformationForm from "views/SignUp/Onboarding/forms/EmploymentInformationForm";
import StockExchangeAssociationForm from "views/SignUp/Onboarding/forms/StockExchangeAssociationForm";
import SummaryContainer from "views/SignUp/Onboarding/forms/SummaryPage/SummaryContainer";
import DocumentsForm from "views/SignUp/Onboarding/forms/DocumentsForm";
import SuccessContainer from "views/SignUp/Onboarding/forms/SuccessContainer";
import CheckEmail from "views/SignUp/Onboarding/forms/CheckEmail";
import BeneficiariesContainer from "views/SignUp/Onboarding/forms/BeneficiariesForm";
import AccountSelectionForm from "views/SignUp/Onboarding/forms/AccountSelectionForm";
import StonexAffiliateForm from "views/SignUp/Onboarding/forms/StonexAffiliateForm";
import CAISForm from "views/SignUp/Onboarding/forms/CAISForm";

// ==== Utilities ====
import {
  setQueryStringsIntoState,
  injectDevToken,
} from "utilities/apiUtilities";
import {
  isApplicationRunningLocally,
  scrollUserToTopOfWindow,
} from "utilities/genericUtilities";
import { isFormKeyOnboarding } from "utilities/onboardingUtilities";

const OnboardingContainer: React.FunctionComponent = observer(() => {
  const location = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    const { search } = location;

    const getCurrentScreen = () => {
      const accountOpening = OnboardingStore.accountOpening;

      // No current screen, default to email validation
      if (!FormsStore.currentScreen) {
        return EAuthenticationScreens.EMAIL_VALIDATION;
      }

      // If there is no formKey in the url, add one
      if (!window.location.href.includes(FormsStore.currentScreen)) {
        return FormsStore.currentScreen;
      }

      // Current screen not in onboarding flow
      if (!isFormKeyOnboarding(FormsStore.currentScreen)) {
        // Is account submitted?
        if (
          accountOpening?.status === ACCOUNT_OPEN_STATUSES.SUBMITTED.value ||
          accountOpening?.status === ACCOUNT_OPEN_STATUSES.OPENED.value
        ) {
          return EAuthenticationScreens.ACCOUNT_SELECTION;
        } else {
          return EOnboardingScreens.INDIVIDUAL;
        }
      }

      // Stopping users from going back after submitting
      if (FormsStore.currentScreen === EOnboardingScreens.INDIVIDUAL) {
        if (
          accountOpening?.status === ACCOUNT_OPEN_STATUSES.SUBMITTED.value ||
          accountOpening?.status === ACCOUNT_OPEN_STATUSES.OPENED.value
        ) {
          return EAuthenticationScreens.ACCOUNT_SELECTION;
        }
      }
    };

    // Dev Toke Injection
    if (isApplicationRunningLocally()) {
      injectDevToken();
    }

    // check for bad state
    if (getCurrentScreen()) {
      FormsStore.setCurrentScreen(getCurrentScreen());
    }

    // Fetch token if there isn't data loaded
    if (!OnboardingStore.profile) {
      AuthenticationStore.handleInitialTokenFetch(
        EOnboardingScreens.INDIVIDUAL,
      );
    }

    setQueryStringsIntoState(search);
  }, [location]);

  const renderAuthFlow = () => {
    scrollUserToTopOfWindow();

    switch (FormsStore.currentScreen) {
      case EAuthenticationScreens.LOGIN:
        // Auth Flow
        return <LoginForm />;
      // 1st step: Ask for email/ validate it
      case EAuthenticationScreens.EMAIL_VALIDATION:
        return <EmailValidateForm />;
      // 2nd: Get user account info
      case EAuthenticationScreens.NEW_USER_CREATION:
        return <NewUserCreationForm />;
      // 3rd: User enterers email code
      case EAuthenticationScreens.VERIFICATION_CODE:
        return <VerificationCodeForm />;
      default:
        return undefined;
    }
  };

  const renderForms = () => {
    scrollUserToTopOfWindow();

    switch (FormsStore.currentScreen) {
      case EAuthenticationScreens.ACCOUNT_SELECTION:
        return <AccountSelectionForm />;
      // 2nd screen
      case EOnboardingScreens.INDIVIDUAL:
        return <IndividualForm />;
      // 3rd screen - Side effect of New Account Info creating an account
      case EOnboardingScreens.CHECK_EMAIL:
        return <CheckEmail />;
      // 3a - Rendered after creating an account via INDIVIDUAL
      case EOnboardingScreens.PERSONAL_INFO:
        return <PersonalInformationForm />;
      // 4th screen
      case EOnboardingScreens.ACCOUNT_CREATION:
        return <AccountCreationForm />;
      // 5th screen
      case EOnboardingScreens.ADDRESS_COLLECTION:
        return <AddressCollectionForm />;
      // 6th screen - Only for NON US citizens
      case EOnboardingScreens.UPLOAD_ID:
        return <UploadIdForm />;
      // 7th screen
      case EOnboardingScreens.EMPLOYMENT_INFORMATION:
        return <EmploymentInformationForm />;
      // 8th screen
      case EOnboardingScreens.TRUSTED_CONTACT:
        return <TrustedContactForm />;
      // 9th screen
      case EOnboardingScreens.STONEX_AFFILIATE:
        return <StonexAffiliateForm />;
      // 10th screen
      case EOnboardingScreens.CAIS:
        return <CAISForm />;
      // 11th screen
      case EOnboardingScreens.STOCK_EXCHANGE_ASSOCIATION:
        return <StockExchangeAssociationForm />;
      // 12th Screen - Only for IRA
      case EOnboardingScreens.BENEFICIARIES:
        return <BeneficiariesContainer />;
      // 13th screen
      case EOnboardingScreens.SUMMARY:
        return <SummaryContainer />;
      // 14th step
      case EOnboardingScreens.DOCUMENTS:
        return <DocumentsForm />;
      // 15th step - Can be redirected to the Options/ Margin container form here
      case EOnboardingScreens.SUCCESS:
        return <SuccessContainer />;

      // This is an anti-pattern...
      // Because we can't use history.push in our stores we need some way to warp the user
      // to a new route if they just exited the login flow - see getReturnNavigationScreen()
      //
      // This will cause problem down the road, need a better way to handle user navigation
      case EOnboardingScreens.WARP_MARGIN:
        history.push(`${ERoutePaths.MARGIN}/${EMarginScreens.WELCOME}`);
        FormsStore.setCurrentScreen(EMarginScreens.WELCOME);
        return <div />;
      case EOnboardingScreens.WARP_OPTIONS:
        history.push(`${ERoutePaths.OPTIONS}/${EOptionScreens.LANDING}`);
        FormsStore.setCurrentScreen(EOptionScreens.LANDING);
        return <div />;
      default:
        return <div />;
    }
  };

  return (
    <React.Fragment>
      {renderAuthFlow() ? (
        <SplashScreenWrapper isLogoStonexOne>
          <Spin
            spinning={FormsStore.isInitialAuthLoading || FormsStore.isLoading}
          >
            {renderAuthFlow()}
          </Spin>
        </SplashScreenWrapper>
      ) : (
        <SidebarWrapper>
          <Spin
            spinning={FormsStore.isInitialAuthLoading || FormsStore.isLoading}
          >
            {renderForms()}
          </Spin>
        </SidebarWrapper>
      )}
    </React.Fragment>
  );
});

export default OnboardingContainer;
