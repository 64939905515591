import * as React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Components ====
import SummaryFieldBlock from "components/SummaryFieldBlock";
import SummaryHeader from "views/SignUp/Onboarding/forms/SummaryPage/SummaryHeader";

// ==== Types ====
import { EOnboardingForms } from "views/SignUp/Onboarding/types/onboardingTypes";
import { GOVERNMENT_ID_TYPES } from "views/SignUp/Onboarding/types/onboardingEnums";

// ==== Utilities ====
import { getTranslationKeyWithValue } from "utilities/typeUtilities";

interface IProps {
  isEditButtonDisabled?: boolean;
}

const SummaryUploadIdForm: React.FunctionComponent<IProps> = observer(
  ({ isEditButtonDisabled }) => {
    const { t } = useTranslation();

    const getFormData = (key: string) =>
      OnboardingStore.getFormItemData(EOnboardingForms.UPLOAD_ID, key) || "";

    const renderSummaryData = () => {
      // This is only rendered for Non-US citizens
      if (OnboardingStore.isUserUSCitizen()) {
        return null;
      }

      const formattedIssued = `${getFormData("IssuedYear")}/${getFormData(
        "IssuedMonth",
      )}/${getFormData("IssuedDay")} `;

      const formattedExpiration = `${getFormData(
        "ExpirationYear",
      )}/${getFormData("ExpirationMonth")}/${getFormData("ExpirationDay")} `;

      return (
        <React.Fragment>
          <SummaryHeader
            header={t("views.signUp.components.summery.uploadIdInfo")}
            editButtonKey={!isEditButtonDisabled && EOnboardingForms.UPLOAD_ID}
          />
          <SummaryFieldBlock
            question={`${t("views.signUp.forms.UploadIdForm.idType")}:`}
            answer={t(
              getTranslationKeyWithValue(
                GOVERNMENT_ID_TYPES,
                getFormData("IdType"),
              ),
            )}
          />
          <SummaryFieldBlock
            question={`${t("views.signUp.forms.UploadIdForm.idNumber")}:`}
            answer={getFormData("IdNumber")}
          />
          <SummaryFieldBlock
            question={`${t(
              "views.signUp.forms.UploadIdForm.countryOfIssuance",
            )}:`}
            answer={getFormData("CountryOfIssuance")}
          />
          <SummaryFieldBlock
            question={`${t("views.signUp.forms.UploadIdForm.dateIssued")}:`}
            answer={formattedIssued}
          />
          <SummaryFieldBlock
            question={`${t("views.signUp.forms.UploadIdForm.expirationDate")}:`}
            answer={formattedExpiration}
          />
        </React.Fragment>
      );
    };

    return <React.Fragment>{renderSummaryData()}</React.Fragment>;
  },
);

export default SummaryUploadIdForm;
