import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Col, Row, Select } from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { useTranslation } from "react-i18next";

// ==== Utilities ====
import { cleanString } from "utilities/genericUtilities";

const { Option } = Select;

interface IProps extends FormComponentProps {
  adultsOnly?: boolean; // 18 years or older
  dayInitialValue?: string;
  disabled?: boolean;
  fieldNameDay: string;
  fieldNameMonth: string;
  fieldNameYear: string;
  isFuture?: boolean; // Better name?? Basically years go up instead of down
  isRequired?: boolean;
  monthInitialValue?: string;
  yearInitialValue?: string;
}

const DateOfBirth: React.FunctionComponent<IProps> = ({
  fieldNameDay,
  fieldNameMonth,
  fieldNameYear,
  dayInitialValue,
  monthInitialValue,
  yearInitialValue,
  form,
  disabled,
  isFuture,
  isRequired = true,
  adultsOnly,
}) => {
  const { getFieldDecorator, getFieldValue, resetFields } = form;
  const { t } = useTranslation();

  const [DAYS] = React.useState<number[]>(() => {
    let day = [];

    for (let i = 1; i <= 31; i++) {
      day.push(i);
    }

    return day;
  });

  const MONTHS: {
    key: number;
    value: string;
  }[] = [
    {
      key: 1,
      value: t("views.signUp.components.dateOfBirth.january"),
    },
    {
      key: 2,
      value: t("views.signUp.components.dateOfBirth.february"),
    },
    {
      key: 3,
      value: t("views.signUp.components.dateOfBirth.march"),
    },
    {
      key: 4,
      value: t("views.signUp.components.dateOfBirth.april"),
    },
    {
      key: 5,
      value: t("views.signUp.components.dateOfBirth.may"),
    },
    {
      key: 6,
      value: t("views.signUp.components.dateOfBirth.june"),
    },
    {
      key: 7,
      value: t("views.signUp.components.dateOfBirth.july"),
    },
    {
      key: 8,
      value: t("views.signUp.components.dateOfBirth.august"),
    },
    {
      key: 9,
      value: t("views.signUp.components.dateOfBirth.september"),
    },
    {
      key: 10,
      value: t("views.signUp.components.dateOfBirth.october"),
    },
    {
      key: 11,
      value: t("views.signUp.components.dateOfBirth.november"),
    },
    {
      key: 12,
      value: t("views.signUp.components.dateOfBirth.december"),
    },
  ];

  const [YEARS] = React.useState<number[]>(() => {
    let years = [];

    for (
      let i = new Date().getFullYear();
      i >= new Date().getFullYear() - 120;
      i--
    ) {
      years.push(i);
    }
    return years;
  });

  const [YEARS_INVERTED] = React.useState<number[]>(() => {
    let years = [];

    for (
      let i = new Date().getFullYear();
      i <= new Date().getFullYear() + 50;
      i++
    ) {
      years.push(i);
    }
    return years;
  });

  const handleSelectMonth = React.useCallback(() => {
    resetFields([fieldNameDay]);
  }, [fieldNameDay, resetFields]);

  const isLeapYear = React.useCallback((year: number): boolean => {
    // Is exactly divisible by 4 and it is not exactly divisible by 100 unless is also exactly divisible by 400
    return year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
  }, []);

  const renderYears = () => {
    let optionYears = YEARS;

    // The fuuuuuture!
    if (isFuture) {
      optionYears = YEARS_INVERTED;
    }

    // Removes all years where someone under 18 years old could select
    if (adultsOnly) {
      optionYears = optionYears.filter(
        (year) => new Date().getFullYear() - year >= 18,
      );
    }

    return optionYears.map((year) => (
      <Option label={year} value={year} key={year}>
        {year}
      </Option>
    ));
  };

  const renderMonths = () => {
    let optionMonths = MONTHS;

    // If adults only, remove any future months
    if (adultsOnly) {
      const today = new Date();

      //  Only remove months if the year is equal to 18
      if (today.getFullYear() - 18 === getFieldValue(fieldNameYear)) {
        optionMonths = optionMonths.filter(
          (month) => today.getMonth() + 1 >= month.key,
        );
      }
    }

    return optionMonths.map(({ key, value }) => (
      <Option label={value} value={key} key={key}>
        {value}
      </Option>
    ));
  };

  const renderDays = () => {
    let optionDays = DAYS;

    let filteredDays = optionDays.filter((day) => {
      if (parseInt(getFieldValue(fieldNameMonth)) === 2) {
        // Limit February to 28 days unless it is a leap year, then its 29
        return (
          day <= 28 || (isLeapYear(getFieldValue(fieldNameYear)) && day <= 29)
        );
      } else if ([4, 6, 9].includes(parseInt(getFieldValue(fieldNameMonth)))) {
        // Limit April, June, and September to 30 days
        return day <= 30;
      } else {
        // The other months have 31 days
        return day;
      }
    });

    if (adultsOnly) {
      const today = new Date();

      // Only applies if the selected month and year matches the current date
      if (today.getFullYear() - 18 === getFieldValue(fieldNameYear)) {
        if (today.getMonth() + 1 === getFieldValue(fieldNameMonth)) {
          filteredDays = filteredDays.filter((day) => {
            return day <= today.getDate();
          });
        }
      }
    }

    return filteredDays.map((day) => (
      <Option label={day} value={day} key={day}>
        {day}
      </Option>
    ));
  };

  return (
    <Row gutter={12}>
      <Col xs={24} sm={8}>
        <Form.Item required={false}>
          {getFieldDecorator(fieldNameYear, {
            initialValue: yearInitialValue,
            rules: [
              {
                message: t("views.signUp.components.dateOfBirth.yearRequired"),
                required: isRequired,
              },
            ],
          })(
            <Select
              tabIndex={0}
              placeholder={t("views.signUp.components.dateOfBirth.year")}
              style={{ width: "100%" }}
              disabled={disabled}
              optionFilterProp="label"
              filterOption={(input, option) =>
                option.label.toString().includes(input)
              }
              showSearch
              allowClear
            >
              {renderYears()}
            </Select>,
          )}
        </Form.Item>
      </Col>
      <Col xs={24} sm={8}>
        <Form.Item required={false}>
          {getFieldDecorator(fieldNameMonth, {
            initialValue: monthInitialValue,
            rules: [
              {
                message: t("views.signUp.components.dateOfBirth.monthRequired"),
                required: isRequired,
              },
            ],
          })(
            <Select
              tabIndex={0}
              placeholder={t("views.signUp.components.dateOfBirth.month")}
              style={{ width: "100%" }}
              disabled={!getFieldValue(fieldNameYear) || disabled}
              onSelect={handleSelectMonth}
              optionFilterProp="label"
              filterOption={(input, option) =>
                cleanString(option.label.toString()).includes(
                  cleanString(input),
                )
              }
              allowClear
              showSearch
            >
              {renderMonths()}
            </Select>,
          )}
        </Form.Item>
      </Col>
      <Col xs={24} sm={8}>
        <Form.Item required={false}>
          {getFieldDecorator(fieldNameDay, {
            initialValue: dayInitialValue,
            rules: [
              {
                message: t("views.signUp.components.dateOfBirth.dayRequired"),
                required: isRequired,
              },
            ],
          })(
            <Select
              tabIndex={0}
              placeholder={t("views.signUp.components.dateOfBirth.day")}
              disabled={!getFieldValue(fieldNameMonth) || disabled}
              style={{ width: "100%" }}
              optionFilterProp="label"
              filterOption={(input, option) =>
                option.label.toString().includes(input)
              }
              allowClear
              showSearch
            >
              {renderDays()}
            </Select>,
          )}
        </Form.Item>
      </Col>
    </Row>
  );
};

export default DateOfBirth;
