import {
  EAccountInfoStatus,
  EUserTypeStatus
} from "views/SignUp/SelfEnroll/types/selfEnrollTypes";

export const isResponseStatusError = (status: string) => {
  switch (status) {
    // Valid Status
    case EUserTypeStatus.EXISTING_USER:
    case EUserTypeStatus.NEW_USER:
    case EAccountInfoStatus.VALID:
    case EAccountInfoStatus.VALID_FOREIGN_INDIVIDUAL:
    case EAccountInfoStatus.VALID_FOREIGN_NON_INDIVIDUAL:
    case EAccountInfoStatus.VALID_CORPORATE:
    case EAccountInfoStatus.VALID_INDIVIDUAL:
      return false;
    // Error Status
    case EUserTypeStatus.ERROR:
    case EUserTypeStatus.ERROR_EMAIL_NO_TAX_INFO:
    case EUserTypeStatus.ERROR_EMAIL_NO_MATCH_TAX_INFO:
    case EUserTypeStatus.ERROR_PIN_NO_MATCH:
    case EUserTypeStatus.ERROR_PIN_NOT_SET:
    case EAccountInfoStatus.ERROR:
    case EAccountInfoStatus.ERROR_FOREIGN_INDIVIDUAL:
    case EAccountInfoStatus.ERROR_FOREIGN_NON_INDIVIDUAL:
    case EAccountInfoStatus.ERROR_CORPORATE:
    case EAccountInfoStatus.ERROR_INDIVIDUAL:
    default:
      return true;
  }
};

// Returns the string key used by useTranslation()
export const getStatusErrorMessage = (status: string) => {
  switch (status) {
    case EUserTypeStatus.ERROR_EMAIL_NO_TAX_INFO:
      return "error.emailNoTaxInfo";
    case EUserTypeStatus.ERROR_EMAIL_NO_MATCH_TAX_INFO:
      return "error.emailNoMatchTaxInfo";
    case EUserTypeStatus.ERROR_PIN_NO_MATCH:
      return "error.pinNoMatch";
    case EUserTypeStatus.ERROR_PIN_NOT_SET:
      return "error.pinNotSet";
    case EAccountInfoStatus.ERROR_FOREIGN_INDIVIDUAL:
    case EAccountInfoStatus.ERROR_FOREIGN_NON_INDIVIDUAL:
    case EAccountInfoStatus.ERROR_CORPORATE:
    case EAccountInfoStatus.ERROR_INDIVIDUAL:
      return "error.noMatchOrInvalid";
    case EUserTypeStatus.ERROR:
    case EAccountInfoStatus.ERROR:
    default:
      return "error.default";
  }
};
