import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Button, Input, Row } from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// ==== Stores ====
import SelfEnrollStore from "views/SignUp/SelfEnroll/store/SelfEnrollStore";
import FormsStore from "stores/FormsStore";

// ==== Components ====
import FormHeader from "components/FormHeader";

// ==== Types ====
import { IAccountNumberForm } from "views/SignUp/SelfEnroll/types/selfEnrollTypes";
import { ERoutePaths } from "types/globalTypes";

const AccountNumberForm: React.FunctionComponent<FormComponentProps> = observer(
  ({ form }) => {
    const { getFieldDecorator, validateFields } = form;
    const { t } = useTranslation();
    const history = useHistory();

    const handleSubmit = async (
      event: React.FormEvent<HTMLFormElement>,
    ): Promise<void> => {
      event.preventDefault();

      validateFields(async (err: string[]): Promise<void> => {
        if (!err) {
          await SelfEnrollStore.validateAccount();
        }
      });
    };

    const handleClick = (url: string): void => {
      history.push(url);
    };

    return (
      <Form
        className="slideInWrapper"
        layout="vertical"
        onSubmit={handleSubmit}
      >
        <FormHeader
          headerText={t("views.signUp.forms.AccountNumberForm.gettingStarted")}
          subheaderText={t("views.signUp.forms.AccountNumberForm.ifYouHave")}
        />
        <Form.Item label={t("forms.fields.accountNumber")}>
          {getFieldDecorator("accountNumber", {
            rules: [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: t("forms.fields.accountNumber"),
                }),
                required: true,
              },
              {
                len: 8,
                message: t("forms.validation.exact", {
                  number: 8,
                }),
              },
              {
                message: t("forms.validation.digits"),
                pattern: new RegExp(/\b(\d){8}\b/g),
              },
            ],
            validateTrigger: "onBlur",
          })(<Input allowClear />)}
        </Form.Item>
        <Row align="middle" justify="space-between" style={{ marginTop: 25 }}>
          <Button
            onClick={() => {
              SelfEnrollStore.handleFormReset();
              FormsStore.setCurrentScreen(undefined);
              handleClick(ERoutePaths.SIGNUP);
            }}
            type="default"
          >
            {t("forms.buttons.goBack")}
          </Button>
          <Button
            disabled={SelfEnrollStore.isDisabled}
            htmlType="submit"
            style={{ marginTop: 24 }}
            type="primary"
          >
            {t("forms.buttons.next")}
          </Button>
        </Row>
      </Form>
    );
  },
);

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: IAccountNumberForm) => {
    SelfEnrollStore.updateValidateAccountData(changedFields);
  },
})(AccountNumberForm);
