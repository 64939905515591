import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Alert, Input } from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import AuthenticationStore from "stores/AuthenticationStore";

// ==== Types ====
import { IVerificationCodeFormData } from "types/authenticationTypes";

// ==== Api Handlers ====
import { resendVerificationEmail } from "apiHandlers/SelfEnrollApiHandler";

// ==== Components ====
import FormHeader from "components/FormHeader";
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";
import PrivacyPolicyFooter from "components/PrivacyPolicyFooter";

// ==== Utilities ====
import { concatenateSentences } from "utilities/genericUtilities";

const VerificationCodeForm: React.FunctionComponent<FormComponentProps> =
  observer(({ form }) => {
    const { getFieldDecorator, validateFields } = form;
    const { t } = useTranslation();

    const handleSubmit = async (
      event: React.FormEvent<HTMLFormElement>,
    ): Promise<void> => {
      event.preventDefault();

      validateFields(async (err: string[]): Promise<void> => {
        if (!err) {
          await AuthenticationStore.handleVerificationCode();
        }
      });
    };

    // ==== Handlers ====
    const goForwardHandler = (e: React.FormEvent<HTMLFormElement>) => {
      handleSubmit(e);
    };

    const resendEmailHandler = () => {
      resendVerificationEmail(
        AuthenticationStore.newUserCreationFormData?.email,
      );
    };

    const generateButtonDisabledMessage = (): string => {
      let returnString = "";

      if (!AuthenticationStore.verificationCode) {
        // If the user has not entered anything, give a generic error message
        return t(
          "views.signUp.forms.VerificationCodeForm.errorMessages.codeMustBeEntered",
        );
      }

      if (!/^-?\d+$/.test(AuthenticationStore.verificationCode)) {
        returnString = t(
          "views.signUp.forms.VerificationCodeForm.errorMessages.codeMustBeNumber",
        );
      }

      if (AuthenticationStore.verificationCode?.length !== 6) {
        returnString = concatenateSentences(
          returnString,
          t(
            "views.signUp.forms.VerificationCodeForm.errorMessages.codeMustBe6digits",
          ),
        );
      }

      if (AuthenticationStore.verificationCodeError) {
        returnString = concatenateSentences(
          returnString,
          t(
            "views.signUp.forms.VerificationCodeForm.errorMessages.codeIncorrect",
          ),
        );
      }

      return returnString;
    };

    return (
      <div>
        <Form className="slideInWrapper" layout="vertical">
          <div>
            {AuthenticationStore.verificationCodeError && (
              <Alert
                message={t(
                  "views.signUp.forms.VerificationCodeForm.errorMessages.codeIncorrect",
                )}
                closable
                showIcon
                style={{ margin: "0 0 2em" }}
                type="error"
              />
            )}
            {AuthenticationStore.isVerifiedError && (
              <Alert
                message={t(
                  "views.signUp.forms.NewUserCreationForm.errorMessages.isVerified",
                )}
                closable
                showIcon
                style={{ margin: "0 0 2em" }}
                type="info"
              />
            )}
            <FormHeader
              headerText={t("views.signUp.forms.VerificationCodeForm.header")}
              subheaderText={t(
                "views.signUp.forms.VerificationCodeForm.subheader",
              )}
              largeHeader
            />
          </div>
          {AuthenticationStore.isVerifiedError && (
            <Form.Item
              label={t("forms.fields.confirm", {
                fieldName: t("forms.fields.password"),
              })}
            >
              {getFieldDecorator("password", {
                initialValue: AuthenticationStore.loginFormData?.ConfirmEmail,
                rules: [
                  {
                    message: t("forms.validation.isRequired", {
                      fieldName: t("forms.fields.password"),
                    }),
                    required: true,
                  },
                ],
                validateTrigger: "onBlur",
              })(
                <Input.Password
                  placeholder={t("forms.fields.password")}
                  allowClear
                />,
              )}
            </Form.Item>
          )}
          <Form.Item>
            {getFieldDecorator("verificationCode", {
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.verificationCode"),
                  }),
                  required: true,
                },
                {
                  len: 6,
                  message: t("forms.validation.exact", {
                    number: 6,
                  }),
                },
                {
                  message: t("forms.validation.digits"),
                  pattern: new RegExp("^\\d+$"),
                },
              ],
              validateTrigger: "onBlur",
            })(
              <Input
                placeholder={t("forms.fields.verificationCode")}
                allowClear
              />,
            )}
          </Form.Item>
          <div>
            <p className="meta">
              {t(
                "views.signUp.forms.VerificationCodeForm.verificationEmail.line1",
              )}
              <a onClick={resendEmailHandler}>
                {t(
                  "views.signUp.forms.VerificationCodeForm.verificationEmail.line2",
                )}
              </a>
              {t(
                "views.signUp.forms.VerificationCodeForm.verificationEmail.line3",
              )}
            </p>
            <PrivacyPolicyFooter />
            <FormButtonFooter
              goForwardDisabledMessage={generateButtonDisabledMessage()}
              isGoForwardDisabled={!!generateButtonDisabledMessage()}
              goForwardHandler={goForwardHandler}
            />
          </div>
        </Form>
      </div>
    );
  });

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: IVerificationCodeFormData) => {
    AuthenticationStore.setVerificationFormData(changedFields);
  },
})(VerificationCodeForm);
