import * as React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Types ====
import { EOnboardingScreens } from "views/SignUp/Onboarding/types/onboardingTypes";
import { ACCOUNT_OPENING_TYPES } from "views/SignUp/Onboarding/types/onboardingEnums";

// ==== Components ====
import SummaryFieldBlock from "components/SummaryFieldBlock";
import SummaryHeader from "views/SignUp/Onboarding/forms/SummaryPage/SummaryHeader";

// ==== Utilities ====
import { getTranslationKeyWithValue } from "utilities/typeUtilities";

interface IProps {
  isEditButtonDisabled?: boolean;
}

const SummaryAccountCreation: React.FunctionComponent<IProps> = observer(
  ({ isEditButtonDisabled }) => {
    const { t } = useTranslation();

    return (
      <React.Fragment>
        <SummaryHeader
          header={t("views.signUp.components.summery.accountSelection")}
          editButtonKey={
            !isEditButtonDisabled && EOnboardingScreens.ACCOUNT_CREATION
          }
        />
        <SummaryFieldBlock
          question={`${t("views.signUp.components.summery.accountType")}:`}
          answer={t(
            getTranslationKeyWithValue(
              ACCOUNT_OPENING_TYPES,
              OnboardingStore.getAccountOpeningType(),
            ),
          )}
        />
      </React.Fragment>
    );
  },
);

export default SummaryAccountCreation;
