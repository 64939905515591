import * as React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// ==== Components ====
import Lander from "Lander";

// ==== Views ====
import Contact from "views/Contact/Contact";
import NotFound from "views/NotFound/NotFound";
import Register from "views/Register/Register";
import SignUp from "views/SignUp/SignUp";
import InvestorRelations from "views/InvestorRelations/InvestorRelations";

// ==== Containers ====
import SelfEnrollContainer from "views/SignUp/SelfEnroll/SelfEnrollContainer";
import OnboardingContainer from "views/SignUp/Onboarding/OnboardingContainer";
import MarginContainer from "views/Margin/MarginContainer";
import OptionsContainer from "views/Options/OptionsContainer";

// ==== Types ====
import { IConfig, ERoutePaths } from "types/globalTypes";
import { EAuthenticationScreens } from "types/authenticationTypes";
import { EQueryStringValues } from "views/SignUp/SelfEnroll/types/selfEnrollTypes";
import { EMarginScreens } from "views/Margin/types/marginTypes";

// ==== Forms ====
import FormsStore from "stores/FormsStore";

// ==== Utilities ====
import {
  setQueryStringsIntoState,
  getQueryString,
} from "utilities/apiUtilities";

interface IProps {
  config: IConfig;
}

const Routes: React.FunctionComponent<IProps> = ({ config }) => {
  const doesUrlHaveAccountNumber = (): boolean => {
    const { search } = location;
    // Check for account number

    const accountNumber = getQueryString(
      search,
      EQueryStringValues.ACCOUNT_NUMBER,
    );

    setQueryStringsIntoState(search);

    if (accountNumber) {
      return true;
    }

    return false;
  };

  const renderOnboardingRoutes = () => (
    <React.Fragment>
      <Route
        render={() => <OnboardingContainer />}
        exact
        path={`${ERoutePaths.ONBOARDING}`}
      />
      <Route
        render={({
          match: {
            params: { formId },
          },
        }) => {
          FormsStore.setCurrentScreen(formId);
          return <OnboardingContainer />;
        }}
        exact
        path={`${ERoutePaths.ONBOARDING}/:formId`}
      />
      <Route
        render={() => {
          // If the user has URL params, keep them on the options/ margin workflow instead
          // of redirecting them
          if (doesUrlHaveAccountNumber()) {
            FormsStore.initRedirectScreen = ERoutePaths.MARGIN;
            FormsStore.setCurrentScreen(EMarginScreens.WELCOME);
            return <OnboardingContainer />;
          }

          FormsStore.setCurrentScreen(EAuthenticationScreens.ACCOUNT_SELECTION);
          return <OnboardingContainer />;
        }}
        exact
        path={`${ERoutePaths.MARGIN}`}
      />
      <Route
        render={({
          match: {
            params: { formId },
          },
        }) => {
          FormsStore.setCurrentScreen(formId);
          return <MarginContainer />;
        }}
        exact
        path={`${ERoutePaths.MARGIN}/:formId`}
      />
      <Route
        render={() => {
          // If the user has URL params, keep them on the options/ margin workflow instead
          // of redirecting them
          if (doesUrlHaveAccountNumber()) {
            FormsStore.initRedirectScreen = ERoutePaths.OPTIONS;
            FormsStore.setCurrentScreen(EMarginScreens.WELCOME);
            return <OptionsContainer />;
          }

          FormsStore.setCurrentScreen(EAuthenticationScreens.ACCOUNT_SELECTION);
          return <OnboardingContainer />;
        }}
        exact
        path={`${ERoutePaths.OPTIONS}`}
      />
      <Route
        render={({
          match: {
            params: { formId },
          },
        }) => {
          FormsStore.setCurrentScreen(formId);

          return <OptionsContainer />;
        }}
        exact
        path={`${ERoutePaths.OPTIONS}/:formId`}
      />
    </React.Fragment>
  );

  return (
    <BrowserRouter>
      <Switch>
        <Route
          exact
          path={ERoutePaths.BASE}
          render={() => <Lander config={config} />}
        />
        <Route component={Contact} exact path={ERoutePaths.CONTACT} />
        <Route
          component={InvestorRelations}
          exact
          path={ERoutePaths.INVESTOR_RELATIONS}
        />
        <Route
          component={Register}
          exact
          path={[ERoutePaths.REGISTER, ERoutePaths.MIGRATION_WEALTH_ACCESS]}
        />
        <Route component={SignUp} exact path={ERoutePaths.SIGNUP} />
        <Route
          component={SelfEnrollContainer}
          exact
          path={ERoutePaths.SELF_ENROLL}
        />
        {renderOnboardingRoutes()}
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
