import * as React from "react";
interface IProps {
  answer: string;
  question: string;
}

const SummaryFieldBlock: React.FunctionComponent<IProps> = ({
  question,
  answer,
}) => {
  return (
    <div className="summaryFieldBlock">
      <div className="questionColumn">{question}</div>
      <div className="answerColumn">
        <strong>{answer}</strong>
      </div>
    </div>
  );
};

export default SummaryFieldBlock;
