import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken,
  CancelTokenSource,
} from "axios";

// ==== Stores ====
import AutocompleteStore from "views/SignUp/Onboarding/store/AutocompleteStore";

// ==== Types ====
import { EHttpMethods } from "types/globalTypes";
import {
  ISuggestion,
  IAddressLookupResponse,
} from "views/SignUp/Onboarding/types/autocompleteTypes";

// ==== Utilities ====
import { handleErrorTracking } from "utilities/apiUtilities";

class AutocompleteApi {
  newTokenSource = (): CancelTokenSource => {
    return axios.CancelToken.source();
  };

  getLookup = async (
    cancelToken: CancelToken,
    query: string,
  ): Promise<{
    suggestions: ISuggestion[];
  }> => {
    const response = await this._call(
      {
        cancelToken,
        method: EHttpMethods.GET,
        url: `lookup?key=${AutocompleteStore.smartyStreetsKey}&search=${query}`,
      },
      `https://us-autocomplete-pro.api.smartystreets.com`,
    );

    return response ? response.data : [];
  };

  getUsStreetApi = async (
    cancelToken: CancelToken,
    { street_line, city, state, zipcode }: ISuggestion,
  ): Promise<IAddressLookupResponse[]> => {
    const response = await this._call(
      {
        cancelToken,
        method: EHttpMethods.GET,
        url: `street-address?key=${AutocompleteStore.smartyStreetsKey}&street=${street_line}&city=${city}&state=${state}&zipcode=${zipcode}`,
      },
      `https://us-street.api.smartystreets.com`,
    );

    return response ? response.data : [];
  };

  private _call = async (
    config: AxiosRequestConfig,
    baseUrl: string,
  ): Promise<AxiosResponse> => {
    try {
      let _axios = axios.create({
        baseURL: baseUrl,
        responseType: "json",
        timeout: 60000,
      });
      const response = await _axios(config);
      return response;
    } catch (e) {
      handleErrorTracking(e, "AutocompleteApi", "_call");
    }
  };
}

export default new AutocompleteApi();
