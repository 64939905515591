import * as React from "react";
import { Select } from "antd";
import i18next from "i18next";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import ConfigurationStore from "stores/ConfigurationStore";

// ==== Components ====
import HeaderLogo from "components/sidebar/HeaderLogo";

// ==== Types ====
import { EApplicationThemes } from "types/globalTypes";

const { Option } = Select;

interface IProps {
  isLogoHidden?: boolean;
  isLogoStonexOne?: boolean;
}

const TopBar: React.FunctionComponent<IProps> = ({
  isLogoHidden,
  isLogoStonexOne,
}) => {
  const { t } = useTranslation();

  const handleChangeLanguage = (language: string): void => {
    i18next.changeLanguage(language);
  };

  const handleThemeSelect = (theme: EApplicationThemes): void => {
    document.cookie = `theme=${theme}; Secure`;
    ConfigurationStore.setApplicationTheme(theme);
  };

  return (
    <div className={`topBar ${isLogoHidden ? "logoHidden" : ""}`}>
      <HeaderLogo isLogoStonexOne={isLogoStonexOne} />
      <div className="topBarOptions">
        {ConfigurationStore.isDarkModeEnabled && (
          <div>
            <span style={{ paddingRight: 5 }}>{t("theme.name")}: </span>
            <Select
              defaultValue={ConfigurationStore.applicationTheme}
              onSelect={handleThemeSelect}
              size="small"
              style={{ marginRight: 5, width: 140 }}
            >
              <Option key="lightMode" value={EApplicationThemes.LightMode}>
                {t("theme.lightMode")}
              </Option>
              <Option key="darkMode" value={EApplicationThemes.DarkMode}>
                {t("theme.darkMode")}
              </Option>
            </Select>
          </div>
        )}
        <div>
          <span style={{ paddingRight: 5 }}>{t("languages.label")}:</span>
          <Select
            defaultValue={i18next.language}
            onSelect={handleChangeLanguage}
            size="small"
            style={{ width: 100 }}
          >
            <Option key="en-US" value="en-US">
              {t("languages.en")}
            </Option>
            <Option key="es-US" value="es-US">
              {t("languages.es")}
            </Option>
          </Select>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
