import * as React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { Row } from "antd";

// ==== Stores ====
import OptionsStore from "views/Options/OptionsStore";
import FormsStore from "stores/FormsStore";

// ==== Components ====
import FormHeader from "components/FormHeader";
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";
import SummaryFieldBlock from "components/SummaryFieldBlock";
import { getTranslationKeyWithValue } from "utilities/typeUtilities";

// ==== Types ====
import {
  EOptionScreens,
  EOptionsForms,
} from "views/Options/types/optionsTypes";
import {
  INVESTMENT_OBJECTIVES,
  OPTIONS_LEVELS,
  INVESTMENT_EXPERIENCE,
} from "views/Options/types/optionsEnums";
import { MONEY_OPTIONS } from "views/SignUp/Onboarding/types/onboardingEnums";

const SummaryContainer: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();

  const getFormData = (formKey: EOptionsForms, key: string) =>
    OptionsStore.getFormItemData(formKey, key);

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    nextScreenKey: string,
  ): Promise<void> => {
    event.preventDefault();

    await OptionsStore.validateSummaryContainer(nextScreenKey);
  };

  const getFinancialProfileString = (key: string) =>
    t(
      getTranslationKeyWithValue(
        MONEY_OPTIONS,
        getFormData(EOptionScreens.FINANCIAL_PROFILE, key),
      ),
    );

  const getInvestmentObjectiveString = (key: string) =>
    t(
      getTranslationKeyWithValue(
        INVESTMENT_OBJECTIVES,
        getFormData(EOptionScreens.OPTION_INVESTMENT_OBJECTIVES, key),
      ),
    );

  const getTradingLevelString = (key: string) =>
    t(
      getTranslationKeyWithValue(
        OPTIONS_LEVELS,
        getFormData(EOptionScreens.OPTION_INVESTMENT_OBJECTIVES, key),
      ),
    );

  const renderSummeryHeader = (header: string) => (
    <React.Fragment>
      <Row align="middle" justify="space-between" style={{ marginTop: 25 }}>
        <div className="header">{header}</div>
      </Row>
      <hr />
    </React.Fragment>
  );

  return (
    <div className="slideInWrapper">
      <FormHeader
        headerText={t("views.signUp.components.summery.header")}
        subheaderText={t("views.signUp.components.summery.subheader")}
      />
      {renderSummeryHeader(t("views.Options.FinancialProfileForm.header"))}
      <SummaryFieldBlock
        question={`${t("views.Options.FinancialProfileForm.annualIncome")}`}
        answer={getFinancialProfileString("annualIncome")}
      />
      <SummaryFieldBlock
        question={`${t("views.Options.FinancialProfileForm.netWorth")}`}
        answer={getFinancialProfileString("netWorth")}
      />
      {renderSummeryHeader(t("views.Options.TradingExperience.header"))}
      <SummaryFieldBlock
        question={`${t("views.Options.TradingExperience.stockAndBonds")}:`}
        answer={t(
          getTranslationKeyWithValue(
            INVESTMENT_EXPERIENCE,
            getFormData(EOptionScreens.TRADING_EXPERIENCE, "stockAndBonds"),
          ),
        )}
      />
      <SummaryFieldBlock
        question={`${t("views.Options.TradingExperience.mutualFunds")}:`}
        answer={t(
          getTranslationKeyWithValue(
            INVESTMENT_EXPERIENCE,
            getFormData(EOptionScreens.TRADING_EXPERIENCE, "mutualFunds"),
          ),
        )}
      />
      <SummaryFieldBlock
        question={`${t("views.Options.TradingExperience.options")}:`}
        answer={t(
          getTranslationKeyWithValue(
            INVESTMENT_EXPERIENCE,
            getFormData(EOptionScreens.TRADING_EXPERIENCE, "options"),
          ),
        )}
      />
      <SummaryFieldBlock
        question={`${t("views.Options.TradingExperience.annuities")}:`}
        answer={t(
          getTranslationKeyWithValue(
            INVESTMENT_EXPERIENCE,
            getFormData(EOptionScreens.TRADING_EXPERIENCE, "annuities"),
          ),
        )}
      />
      <SummaryFieldBlock
        question={`${t(
          "views.Options.TradingExperience.alternativeInvestments",
        )}:`}
        answer={t(
          getTranslationKeyWithValue(
            INVESTMENT_EXPERIENCE,
            getFormData(
              EOptionScreens.TRADING_EXPERIENCE,
              "alternativeInvestments",
            ),
          ),
        )}
      />
      {renderSummeryHeader(t("enums.optionScreens.optionInvestmentObjectives"))}
      <SummaryFieldBlock
        question={`${t(
          "views.Options.OptionInvestmentObjectives.summeryHeader",
        )}`}
        answer={getInvestmentObjectiveString("investmentObjectives")}
      />
      {renderSummeryHeader(t("enums.optionScreens.tradingLevel"))}
      <SummaryFieldBlock
        question={`${t(
          "views.Options.OptionInvestmentObjectives.tradingLevelHeader",
        )}:`}
        answer={getTradingLevelString("level")}
      />
      <FormButtonFooter
        goForwardHandler={(e) =>
          handleSubmit(e, EOptionScreens.DOCUMENT_REVIEW)
        }
        goBackHandler={() => {
          FormsStore.setCurrentScreen(
            EOptionScreens.OPTION_INVESTMENT_OBJECTIVES,
          );
        }}
      />
    </div>
  );
});

export default SummaryContainer;
