import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Radio, Tooltip } from "antd";
import { useTranslation } from "react-i18next";

interface IProps {
  fieldDecoratorValue: string;
  getFieldDecorator: any;
  initialValue?: string;
  isNoDisabled?: boolean;
  isYesDisabled?: boolean;
  label?: string;
  radioTooltipMessage?: string;
  tooltip?: React.ReactNode;
}

const YesNoQuestionFormElement: React.FunctionComponent<IProps> = ({
  getFieldDecorator,
  isNoDisabled = false,
  isYesDisabled = false,
  fieldDecoratorValue,
  initialValue,
  label,
  radioTooltipMessage,
  tooltip,
}) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      className="yesNoFormElement"
      label={
        label && (
          <span className="subheader">
            {label}
            {tooltip && <span style={{ paddingLeft: 10 }}>{tooltip}</span>}
          </span>
        )
      }
    >
      {getFieldDecorator(fieldDecoratorValue, {
        initialValue: initialValue,
        rules: [
          {
            message: t("forms.validation.isRequired", {
              fieldName: t("forms.fields.field"),
            }),
            required: true,
          },
        ],
        validateTrigger: "onBlur",
      })(
        <Radio.Group className="buttonGroupWrapper">
          <Tooltip
            placement="left"
            title={isYesDisabled && radioTooltipMessage}
          >
            <Radio.Button disabled={isYesDisabled} value={true}>
              {t("forms.options.yes")}
            </Radio.Button>
          </Tooltip>{" "}
          <Tooltip
            placement="right"
            title={isNoDisabled && radioTooltipMessage}
          >
            <Radio.Button disabled={isNoDisabled} value={false}>
              {t("forms.options.no")}
            </Radio.Button>
          </Tooltip>{" "}
        </Radio.Group>,
      )}
    </Form.Item>
  );
};

export default YesNoQuestionFormElement;
