import * as React from "react";
import { UserOutlined } from "@ant-design/icons";
import { Tooltip, Alert, Button } from "antd";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { toJS } from "mobx";
import { useHistory, useLocation } from "react-router-dom";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import AuthenticationStore from "stores/AuthenticationStore";
import FormsStore from "stores/FormsStore";

// ==== Components ====
import FormHeader from "components/FormHeader";
import HomeFooterButton from "components/HomeFooterButton";

// ==== Types ====
import {
  IAccountOpening,
  EOnboardingScreens,
} from "views/SignUp/Onboarding/types/onboardingTypes";
import {
  ACCOUNT_OPENING_TYPES,
  ACCOUNT_OPEN_STATUSES,
  FEATURE_TYPES,
} from "views/SignUp/Onboarding/types/onboardingEnums";
import { EOptionScreens } from "views/Options/types/optionsTypes";
import { EMarginScreens } from "views/Margin/types/marginTypes";
import { ERoutePaths } from "types/globalTypes";

// ==== Utilities ====
import {
  getTranslationKeyWithValue,
  getIndexByValue,
} from "utilities/typeUtilities";
import { setQueryStringsIntoState } from "utilities/apiUtilities";
import {
  getTranslationKeyFromBool,
  concatenateSentences,
} from "utilities/genericUtilities";
import { assignNewUrl } from "utilities/googleTagsUtilities";

interface IProps {
  featureTypeKey?: {
    key: string;
    translationKey: string;
  };
}

const AccountSelectionForm: React.FunctionComponent<IProps> = observer(
  ({ featureTypeKey }) => {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();

    React.useEffect(() => {
      const { search } = location;

      setQueryStringsIntoState(search);

      if (FormsStore.initRedirectScreen) {
        if (FormsStore.initRedirectScreen === ERoutePaths.MARGIN) {
          FormsStore.initRedirectScreen = undefined;
          FormsStore.setGuardedCurrentScreen(EMarginScreens.WELCOME);

          history.push(`${ERoutePaths.MARGIN}/${EMarginScreens.WELCOME}`);
        } else if (FormsStore.initRedirectScreen === ERoutePaths.OPTIONS) {
          FormsStore.initRedirectScreen = undefined;
          FormsStore.setGuardedCurrentScreen(EOptionScreens.LANDING);

          history.push(`${ERoutePaths.OPTIONS}/${EOptionScreens.LANDING}`);
        }
      }
    }, [history, location]);

    const isEditAccountButtonDisabled = (
      account: IAccountOpening,
    ): { disabledMessage: string; isDisabled: boolean } => {
      let isDisabled = false;
      let disabledMessage = "";

      // Is the user trying to add options/ margin AND the account isn't submitted yet?
      if (
        account.status === ACCOUNT_OPEN_STATUSES.SUBMITTED.value ||
        account.status === ACCOUNT_OPEN_STATUSES.OPENED.value
      ) {
        isDisabled = true;
        disabledMessage = t(
          "views.signUp.forms.AccountSelection.errorMessages.cantEditSubmittedAccounts",
        );
      }

      return {
        disabledMessage,
        isDisabled,
      };
    };

    // if the user has ANY accounts that are approved, button should point to
    // StoneX One, else refresh
    const isAnyAccountApproved = (): boolean => {
      let isAnyAccountApproved = false;

      profile?.accountOpenings?.forEach((account) => {
        if (
          account.status === ACCOUNT_OPEN_STATUSES.SUBMITTED.value ||
          account.status === ACCOUNT_OPEN_STATUSES.OPENED.value
        ) {
          isAnyAccountApproved = true;
        }
      });

      return isAnyAccountApproved;
    };

    const isOptionButtonDisabled = (
      account: IAccountOpening,
    ): { disabledMessage: string; isDisabled: boolean } => {
      let isDisabled = false;
      let disabledMessage = "";

      // Is the user trying to add options/ margin AND the account isn't submitted yet?
      if (
        account.status !== ACCOUNT_OPEN_STATUSES.SUBMITTED.value &&
        account.status !== ACCOUNT_OPEN_STATUSES.OPENED.value
      ) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t("components.accountSelections.errorMessages.mustBeSubmitted"),
        );
      }

      const accountType = getIndexByValue(
        ACCOUNT_OPENING_TYPES,
        account.openType,
      );

      if (!accountType.features.options) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t("components.accountSelections.errorMessages.optionsNotSupported"),
        );
      }

      return {
        disabledMessage,
        isDisabled,
      };
    };

    const handleOnClick = () => {
      assignNewUrl(AuthenticationStore.config.oneStonexUri);
    };

    const isMarginButtonDisabled = (
      account: IAccountOpening,
    ): { disabledMessage: string; isDisabled: boolean } => {
      let isDisabled = false;
      let disabledMessage = "";

      // Disable the margin button if the user already has options
      if (account.accountFeature?.margin) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t("components.accountSelections.errorMessages.alreadyEnabledMargin"),
        );
      }

      // Is the user trying to add options/ margin AND the account isn't submitted yet?
      if (
        account.status !== ACCOUNT_OPEN_STATUSES.SUBMITTED.value &&
        account.status !== ACCOUNT_OPEN_STATUSES.OPENED.value
      ) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t("components.accountSelections.errorMessages.mustBeSubmitted"),
        );
      }

      const accountType = getIndexByValue(
        ACCOUNT_OPENING_TYPES,
        account.openType,
      );

      if (!accountType.features.margin) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t("components.accountSelections.errorMessages.marginNotSupported"),
        );
      }

      return {
        disabledMessage,
        isDisabled,
      };
    };

    const { profile, setAccountOpening, setFormItemData } = OnboardingStore;

    // ==== Render Methods ====
    const renderNewAccount = () => {
      const handleNewAccountOnClick = () => {
        OnboardingStore.isCreatingMultipleAccounts = true;

        // Create new Account Opening
        setAccountOpening(undefined);
        setFormItemData(EOnboardingScreens.ACCOUNT_CREATION, {
          accountType: undefined,
        });

        FormsStore.setGuardedCurrentScreen(EOnboardingScreens.ACCOUNT_CREATION);
      };

      return (
        <div className="accountCard" onClick={handleNewAccountOnClick}>
          <UserOutlined style={{ fontSize: 20, paddingRight: 10 }} />
          <strong>
            {t("views.signUp.forms.AccountSelection.headers.createNewAccount")}
          </strong>
        </div>
      );
    };

    const renderAccountButton = (account: IAccountOpening) => {
      // ==== OnClick Handlers ====
      const handleEditAccount = () => {
        AuthenticationStore.handleAccountSelection(account);
        FormsStore.setGuardedCurrentScreen(EOnboardingScreens.INDIVIDUAL);

        history.push(ERoutePaths.ONBOARDING);
      };

      const handleAddOptions = () => {
        AuthenticationStore.handleAccountSelection(account);
        FormsStore.setGuardedCurrentScreen(EOptionScreens.LANDING);

        history.push(`${ERoutePaths.OPTIONS}/${EOptionScreens.LANDING}`);
      };

      const handleAddMargins = () => {
        AuthenticationStore.handleAccountSelection(account);
        FormsStore.setGuardedCurrentScreen(EMarginScreens.WELCOME);

        history.push(`${ERoutePaths.MARGIN}/${EMarginScreens.WELCOME}`);
      };

      return (
        <div key={account.id} className="accountSelectionCard">
          <div>
            <div>
              {`${
                account.status === ACCOUNT_OPEN_STATUSES.OPENED.value
                  ? t(
                      "views.signUp.forms.AccountSelection.headers.accountNumber",
                    )
                  : t(
                      "views.signUp.forms.AccountSelection.headers.pendingAccountNumber",
                    )
              }: `}
              <strong>{account.accountNumber}</strong>
            </div>
            <div>
              {`${t(
                "views.signUp.forms.AccountSelection.headers.accountType",
              )}: `}
              <strong>
                {t(
                  getTranslationKeyWithValue(
                    ACCOUNT_OPENING_TYPES,
                    account.openType,
                  ),
                )}
              </strong>
            </div>
            <div>
              {`${t(
                "views.signUp.forms.AccountSelection.headers.accountStatus",
              )}: `}
              <strong>
                {t(
                  getTranslationKeyWithValue(
                    ACCOUNT_OPEN_STATUSES,
                    account.status,
                  ),
                )}
              </strong>
            </div>
            <div>
              {`${t(
                "views.signUp.forms.AccountSelection.headers.accountFeatures",
              )}: `}
              <div style={{ paddingLeft: 20 }}>
                {`${t("views.signUp.forms.AccountSelection.headers.margin")}: `}
                <strong>
                  {t(getTranslationKeyFromBool(account.accountFeature?.margin))}
                </strong>
              </div>
              <div style={{ paddingLeft: 20 }}>
                {`${t(
                  "views.signUp.forms.AccountSelection.headers.options",
                )}: `}
                <strong>
                  {t(
                    getTranslationKeyFromBool(account.accountFeature?.options),
                  )}
                </strong>
              </div>
            </div>
          </div>
          <div className="buttonRowWrapper">
            <Tooltip
              title={isEditAccountButtonDisabled(account).disabledMessage}
            >
              <Button
                disabled={isEditAccountButtonDisabled(account).isDisabled}
                onClick={handleEditAccount}
              >
                {t("views.signUp.forms.AccountSelection.buttons.editAccount")}
              </Button>
            </Tooltip>
            <Tooltip title={isOptionButtonDisabled(account).disabledMessage}>
              <Button
                disabled={isOptionButtonDisabled(account).isDisabled}
                onClick={handleAddOptions}
              >
                {account.accountFeature?.options
                  ? t(
                      "views.signUp.forms.AccountSelection.buttons.updateOptions",
                    )
                  : t("views.signUp.forms.AccountSelection.buttons.addOptions")}
              </Button>
            </Tooltip>
            <Tooltip title={isMarginButtonDisabled(account).disabledMessage}>
              <Button
                disabled={isMarginButtonDisabled(account).isDisabled}
                onClick={handleAddMargins}
              >
                {t("views.signUp.forms.AccountSelection.buttons.addMargin")}
              </Button>
            </Tooltip>
          </div>
        </div>
      );
    };

    const getHeaderWithFeatureKey = (key: string) => {
      switch (key) {
        case FEATURE_TYPES.MARGIN.key:
          return t("views.signUp.forms.AccountSelection.marginHeader");
        case FEATURE_TYPES.OPTIONS.key:
          return t("views.signUp.forms.AccountSelection.optionsHeader");
        default:
          return t("views.signUp.forms.AccountSelection.defaultHeader");
      }
    };

    return (
      <div className="slideInWrapper accountSelectionForm">
        {AuthenticationStore?.accountSelectionMessage && (
          <Alert
            message={t(AuthenticationStore?.accountSelectionMessage, {
              accountNumber: AuthenticationStore?.urlAccountNumber,
            })}
            showIcon={true}
            style={{ marginBottom: 16 }}
            type="error"
          />
        )}
        <FormHeader
          headerText={getHeaderWithFeatureKey(featureTypeKey?.key)}
          subheaderText={t("views.signUp.forms.AccountSelection.subheader")}
        />
        {renderNewAccount()}
        {toJS(profile?.accountOpenings)?.map((account) =>
          renderAccountButton(account),
        )}

        <div className="centeredContent flexColumn">
          <p className="subheader">
            {t("views.signUp.forms.AccountSelection.legaleseHeader")}
          </p>
          <p className="reminderText">
            {t("views.signUp.forms.AccountSelection.legalese")}
          </p>
        </div>
        <HomeFooterButton
          handleOnClick={handleOnClick}
          buttonText={
            !isAnyAccountApproved() &&
            t("views.signUp.forms.AccountSelection.buttons.refresh")
          }
        />
      </div>
    );
  },
);

export default AccountSelectionForm;
