import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Input, Select, Radio, Row, Col } from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Forms ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import FormsStore from "stores/FormsStore";

// ==== Types ====
import {
  EOnboardingForms,
  EOnboardingScreens,
  IEmploymentInformationForm,
} from "views/SignUp/Onboarding/types/onboardingTypes";
import {
  OCCUPATIONS,
  EMPLOYMENT_SOURCE_OF_INCOME,
  EMPLOYMENT_STATUSES,
  INDUSTRIES,
  JOB_FUNCTIONS,
  MONEY_OPTIONS,
} from "views/SignUp/Onboarding/types/onboardingEnums";

// ==== Components ====
import AddressForm from "views/SignUp/Onboarding/components/AddressForm";
import FormHeader from "components/FormHeader";
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";

// ==== Utilities ====
import { renderSelectOptions } from "utilities/formItemUtilities";
import { concatenateSentences, isEnumFalsey } from "utilities/genericUtilities";

const EmploymentInformationForm: React.FunctionComponent<FormComponentProps> =
  observer(({ form }) => {
    const { getFieldDecorator, validateFields, getFieldValue } = form;
    const { t } = useTranslation();

    const handleSubmit = async (
      event: React.FormEvent<HTMLFormElement>,
      nextScreenKey: string,
    ): Promise<void> => {
      event.preventDefault();

      validateFields(async (err: string[]): Promise<void> => {
        if (!err) {
          await OnboardingStore.validateEmploymentInfo(nextScreenKey);
        }
      });
    };

    const getInitialValue = (key: string) =>
      OnboardingStore.getFormItemData(
        EOnboardingForms.EMPLOYMENT_INFORMATION,
        key,
      );

    const goForwardHandler = (e: React.FormEvent<HTMLFormElement>) =>
      handleSubmit(e, EOnboardingScreens.TRUSTED_CONTACT);

    const goBackHandler = () =>
      FormsStore.setCurrentScreen(
        OnboardingStore.isUserUSCitizen()
          ? EOnboardingForms.ADDRESS_COLLECTION
          : EOnboardingScreens.UPLOAD_ID,
      );

    const isNextButtonDisabled = (): {
      disabledMessage: string;
      isDisabled: boolean;
    } => {
      let isDisabled = false;
      let disabledMessage = "";

      const sourceOfIncome = getInitialValue("SourceOfIncome");

      if (!isEnumFalsey(sourceOfIncome)) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t(
            "views.signUp.forms.EmploymentInformationForm.validation.sourceOfIncome",
          ),
        );
      }

      // If the user is employed, all employment fields are required
      if (
        getInitialValue("EmploymentStatus") ===
        EMPLOYMENT_STATUSES.EMPLOYED.value
      ) {
        // if country !== us, state is NOT required
        if (getInitialValue("EmployerCountry") === "US") {
          if (!getInitialValue("EmployerStateProvince")) {
            isDisabled = true;
            disabledMessage = concatenateSentences(
              disabledMessage,
              t(
                "views.signUp.forms.EmploymentInformationForm.validation.requiredState",
              ),
            );
          }
        }

        if (
          !getInitialValue("EmployerCountry") ||
          !getInitialValue("EmployerStreetAddress1") ||
          !getInitialValue("EmployerCity") ||
          !getInitialValue("EmployerZipPostal")
        ) {
          isDisabled = true;
          disabledMessage = concatenateSentences(
            disabledMessage,
            t(
              "views.signUp.forms.EmploymentInformationForm.validation.employerAddress",
            ),
          );
        }

        if (getFieldValue("Industry") === INDUSTRIES.OTHER.value) {
          if (!getFieldValue("IndustryDesc")) {
            isDisabled = true;
            disabledMessage = concatenateSentences(
              disabledMessage,
              t(
                "views.signUp.forms.EmploymentInformationForm.validation.industryDesc",
              ),
            );
          }
        }

        if (getFieldValue("JobFunction") === JOB_FUNCTIONS.OTHER.value) {
          if (!getFieldValue("JobFunctionDesc")) {
            isDisabled = true;
            disabledMessage = concatenateSentences(
              disabledMessage,
              t(
                "views.signUp.forms.EmploymentInformationForm.validation.jobFunctionDesc",
              ),
            );
          }
        }

        if (getFieldValue("Occupation") === OCCUPATIONS.OTHER.value) {
          if (!getFieldValue("OccupationDesc")) {
            isDisabled = true;
            disabledMessage = concatenateSentences(
              disabledMessage,
              t(
                "views.signUp.forms.EmploymentInformationForm.validation.occupationDesc",
              ),
            );
          }
        }

        if (
          !getInitialValue("Employer") ||
          !getInitialValue("JobTitle") ||
          !isEnumFalsey(getFieldValue("Industry")) ||
          !isEnumFalsey(getFieldValue("JobFunction")) ||
          !isEnumFalsey(getFieldValue("Occupation"))
        ) {
          isDisabled = true;
          disabledMessage = concatenateSentences(
            disabledMessage,
            t(
              "views.signUp.forms.EmploymentInformationForm.validation.employmentInfo",
            ),
          );
        }
      }

      if (
        getInitialValue("EmployerStreetAddress1")?.length > 30 ||
        getInitialValue("EmployerStreetAddress2")?.length > 30
      ) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t("views.signUp.forms.AddressCollectionForm.errorMessages.tooLong"),
        );
      }

      return {
        disabledMessage,
        isDisabled,
      };
    };

    const renderSourceOfIncomeFields = () => (
      <React.Fragment>
        <p className="subheader">
          {t(
            "views.signUp.forms.EmploymentInformationForm.whatIsSourceOfIncome",
          )}
        </p>
        <Form.Item>
          {getFieldDecorator("SourceOfIncome", {
            initialValue: getInitialValue("SourceOfIncome"),
            rules: [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: t("forms.fields.field"),
                }),
                required: true,
              },
            ],
          })(
            <Select
              placeholder={t(
                "views.signUp.forms.EmploymentInformationForm.sourceOfIncome",
              )}
              filterOption={(input, option) => {
                return option.children
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              style={{ width: "100%" }}
            >
              {renderSelectOptions(EMPLOYMENT_SOURCE_OF_INCOME, t)}
            </Select>,
          )}
        </Form.Item>
        <p className="subheader">
          {t(
            "views.signUp.forms.EmploymentInformationForm.liquidNetWorthHeader",
          )}
        </p>
        <Form.Item>
          {getFieldDecorator("LiquidNetWorth", {
            initialValue: getInitialValue("LiquidNetWorth"),
            rules: [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: t("forms.fields.liquidNet"),
                }),
                required: true,
              },
            ],
          })(
            <Select
              placeholder={t("enums.optionScreens.selectOne")}
              filterOption={(input, option) => {
                return option.children
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
            >
              {renderSelectOptions(MONEY_OPTIONS, t)}
            </Select>,
          )}
        </Form.Item>
        <p style={{ marginBottom: 0 }}>
          {t("views.Options.FinancialProfileForm.whatIsLiquid")}
        </p>
      </React.Fragment>
    );

    const renderEmploymentForm = () => (
      <React.Fragment>
        <p className="subheader">
          {t("views.signUp.forms.EmploymentInformationForm.subheader")}
        </p>
        <Form.Item>
          {getFieldDecorator("Occupation", {
            initialValue: getInitialValue("Occupation"),
            rules: [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: t("forms.fields.occupation"),
                }),
                required: true,
              },
            ],
          })(
            <Select
              placeholder={t("forms.fields.occupation")}
              filterOption={(input, option) => {
                return option.children
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              style={{ width: "100%" }}
            >
              {renderSelectOptions(OCCUPATIONS, t)}
            </Select>,
          )}
        </Form.Item>
        {getFieldValue("Occupation") === OCCUPATIONS.OTHER.value && (
          <Form.Item>
            {getFieldDecorator("OccupationDesc", {
              initialValue: getInitialValue("OccupationDesc"),
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.occupationDescription"),
                  }),
                  required: true,
                },
              ],
              validateTrigger: "onBlur",
            })(
              <Input
                placeholder={t("forms.fields.occupationDescription")}
                allowClear
              />,
            )}
          </Form.Item>
        )}
        <Form.Item>
          {getFieldDecorator("Employer", {
            initialValue: getInitialValue("Employer"),
            rules: [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: t("forms.fields.employer"),
                }),
                required: true,
              },
            ],
            validateTrigger: "onBlur",
          })(<Input placeholder={t("forms.fields.employer")} allowClear />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("JobTitle", {
            initialValue: getInitialValue("JobTitle"),
            rules: [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: t("forms.fields.jobTitle"),
                }),
                required: true,
              },
            ],
            validateTrigger: "onBlur",
          })(<Input placeholder={t("forms.fields.jobTitle")} allowClear />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("Industry", {
            initialValue: getInitialValue("Industry"),
            rules: [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: t("forms.fields.industry"),
                }),
                required: true,
              },
            ],
          })(
            <Select
              placeholder={t("forms.fields.industry")}
              filterOption={(input, option) => {
                return option.children
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              style={{ width: "100%" }}
            >
              {renderSelectOptions(INDUSTRIES, t)}
            </Select>,
          )}
        </Form.Item>
        {getFieldValue("Industry") === INDUSTRIES.OTHER.value && (
          <Form.Item>
            {getFieldDecorator("IndustryDesc", {
              initialValue: getInitialValue("IndustryDesc"),
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.industryDescription"),
                  }),
                  required: true,
                },
              ],
              validateTrigger: "onBlur",
            })(
              <Input
                placeholder={t("forms.fields.industryDescription")}
                allowClear
              />,
            )}
          </Form.Item>
        )}
        <Form.Item>
          {getFieldDecorator("JobFunction", {
            initialValue: getInitialValue("JobFunction"),
            rules: [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: t("forms.fields.jobFunction"),
                }),
                required: true,
              },
            ],
          })(
            <Select
              placeholder={t("forms.fields.jobFunction")}
              filterOption={(input, option) => {
                return option.children
                  .toString()
                  .toLowerCase()
                  .includes(input.toLowerCase());
              }}
              style={{ width: "100%" }}
            >
              {renderSelectOptions(JOB_FUNCTIONS, t)}
            </Select>,
          )}
        </Form.Item>
        {getFieldValue("JobFunction") === JOB_FUNCTIONS.OTHER.value && (
          <Form.Item>
            {getFieldDecorator("JobFunctionDesc", {
              initialValue: getInitialValue("JobFunctionDesc"),
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.jobFunctionDescription"),
                  }),
                  required: true,
                },
              ],
              validateTrigger: "onBlur",
            })(
              <Input
                placeholder={t("forms.fields.jobFunctionDescription")}
                allowClear
              />,
            )}
          </Form.Item>
        )}
        {renderSourceOfIncomeFields()}
        <div
          className="subheader"
          style={{ paddingBottom: 20, paddingTop: 20 }}
        >
          {t("views.signUp.forms.EmploymentInformationForm.wouldYou")}
        </div>
        <AddressForm
          form={form}
          decoratorPrefix="Employer"
          formKey={EOnboardingForms.EMPLOYMENT_INFORMATION}
          isRequired
        />
      </React.Fragment>
    );

    return (
      <div className="slideInWrapper">
        <Form layout="vertical" className="employmentInfoForm" hideRequiredMark>
          <FormHeader
            headerText={t(
              "views.signUp.forms.EmploymentInformationForm.header",
            )}
            subheaderText={t(
              "views.signUp.forms.EmploymentInformationForm.whatIsEmploymentStatus",
            )}
          />
          <Form.Item>
            {getFieldDecorator("EmploymentStatus", {
              initialValue: getInitialValue("EmploymentStatus"),
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.employmentStatus"),
                  }),
                  required: true,
                },
              ],
              validateTrigger: "onBlur",
            })(
              <Radio.Group size="large" className="buttonGroupWrapper">
                <Row justify="space-between">
                  <Col xs={24} sm={12}>
                    <Radio.Button value={EMPLOYMENT_STATUSES.EMPLOYED.value}>
                      {t(EMPLOYMENT_STATUSES.EMPLOYED.translationKey)}
                    </Radio.Button>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Radio.Button value={EMPLOYMENT_STATUSES.UNEMPLOYED.value}>
                      {t(EMPLOYMENT_STATUSES.UNEMPLOYED.translationKey)}
                    </Radio.Button>
                  </Col>
                </Row>
                <Row justify="space-between">
                  <Col xs={24} sm={12}>
                    <Radio.Button value={EMPLOYMENT_STATUSES.STUDENT.value}>
                      {t(EMPLOYMENT_STATUSES.STUDENT.translationKey)}
                    </Radio.Button>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Radio.Button value={EMPLOYMENT_STATUSES.RETIRED.value}>
                      {t(EMPLOYMENT_STATUSES.RETIRED.translationKey)}
                    </Radio.Button>
                  </Col>
                </Row>
              </Radio.Group>,
            )}
          </Form.Item>
          {/* Rendered after the employmentStatus question has been answered */}
          {typeof getInitialValue("EmploymentStatus") !== "undefined" && (
            <React.Fragment>
              {getInitialValue("EmploymentStatus") ===
              EMPLOYMENT_STATUSES.EMPLOYED.value
                ? renderEmploymentForm()
                : renderSourceOfIncomeFields()}
            </React.Fragment>
          )}
          <FormButtonFooter
            goForwardHandler={goForwardHandler}
            isGoForwardDisabled={isNextButtonDisabled().isDisabled}
            goForwardDisabledMessage={isNextButtonDisabled().disabledMessage}
            goBackHandler={goBackHandler}
          />
        </Form>
      </div>
    );
  });

export default Form.create<FormComponentProps>({
  onValuesChange: (_: any, changedFields: IEmploymentInformationForm) => {
    OnboardingStore.updateFormData(
      changedFields,
      EOnboardingForms.EMPLOYMENT_INFORMATION,
    );
  },
})(EmploymentInformationForm);
