import { TFunction } from "i18next";
import {
  IMoneyOption,
  IMoneyOptionsType,
} from "views/Options/types/optionsTypes";
import {
  IAccount,
  IAccountType,
} from "views/SignUp/Onboarding/types/onboardingEnums";

export interface IEnum {
  key: string;
  translationKey: string;
  value: number;
}

export interface IEnumType {
  [index: string]: IEnum;
}

// e.g. (OCCUPATIONS, 4) => enums.occupations.computers
export const getTranslationKeyWithValue = (
  object: IEnumType | any,
  searchValue: number | string,
): string => {
  let returnKey =
    "ERROR - translationKey not found, check getTranslationKeyWithValue ";

  Object.keys(object).forEach((index) => {
    const { value, translationKey } = object[index];

    if (value === searchValue) {
      returnKey = translationKey;
    }
  });

  return returnKey;
};

// e.g. (ACCOUNT_OPEN_STATUSES, "submitting") => 3
export const getValueWithKey = (
  object: IEnumType,
  searchKey: string,
): number => {
  let returnKey = undefined;

  Object.keys(object).forEach((index) => {
    const { key, value } = object[index];

    if (key === searchKey) {
      returnKey = value;
    }
  });

  return returnKey;
};

// e.g. (ACCOUNT_OPENING_TYPES, 2) => { key: individual, value: 2, translationKey: forms...}
export const getIndexByValue = (
  object: IAccountType | IEnumType,
  value: number,
): IAccount => {
  let returnVal = undefined;
  Object.keys(object).forEach((key) => {
    if (object[key].value === value) {
      returnVal = object[key];
    }
  });

  return returnVal;
};

export const getIndexByTranslation = (
  object: IEnumType | IMoneyOptionsType,
  translation: string,
  t: TFunction,
): IEnum | IMoneyOption => {
  const returnIndex = Object.keys(object).find((index) => {
    return t(object[index]?.translationKey) === translation;
  });

  return object[returnIndex];
};
