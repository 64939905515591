import { makeAutoObservable, get } from "mobx";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import FormsStore from "stores/FormsStore";

// ==== API Handlers ====
import {
  putUpdateFinancialProfile,
  putTradingExperience,
} from "apiHandlers/ProfileApiHandler";
import {
  putTradingLevel,
  putInvestmentObjective,
  putTermsAgreements,
  putAccountFeature,
} from "apiHandlers/AccountOpeningApiHandler";

// ==== Types ====
import { DOCUMENTS } from "views/SignUp/Onboarding/types/onboardingEnums";
import {
  EOptionsForms,
  IOptionsFormData,
  IReviewDocuments,
  IFinancialProfile,
  ITradingExperience,
  IOptionInvestmentObjectives,
} from "views/Options/types/optionsTypes";
import {
  INVESTMENT_EXPERIENCE,
  INVESTMENT_OBJECTIVES,
} from "views/Options/types/optionsEnums";

class OptionsStore {
  // ==== Notifications ====
  // On the TradingLevelScreen this flag lets us know that the user clicked it
  isMarginLevelSelectButtonClicked: boolean = false;

  // === Review Documents ===
  isODDRead: boolean = false;
  isOARead: boolean = false;

  // === Form Data ===
  formData: {
    [EOptionsForms.DOCUMENT_REVIEW]: Partial<IReviewDocuments>;
    [EOptionsForms.FINANCIAL_PROFILE]: Partial<IFinancialProfile>;
    [EOptionsForms.OPTION_INVESTMENT_OBJECTIVES]: Partial<IOptionInvestmentObjectives>;
    [EOptionsForms.TRADING_EXPERIENCE]: Partial<ITradingExperience>;
  } = {
    // Default form values
    [EOptionsForms.FINANCIAL_PROFILE]: {},
    [EOptionsForms.TRADING_EXPERIENCE]: {
      alternativeInvestments: INVESTMENT_EXPERIENCE.NONE.value,
      annuities: INVESTMENT_EXPERIENCE.NONE.value,
      mutualFunds: INVESTMENT_EXPERIENCE.NONE.value,
      options: INVESTMENT_EXPERIENCE.NONE.value,
      stockAndBonds: INVESTMENT_EXPERIENCE.NONE.value,
    },
    [EOptionsForms.DOCUMENT_REVIEW]: {},
    [EOptionsForms.OPTION_INVESTMENT_OBJECTIVES]: {
      investmentObjectives: INVESTMENT_OBJECTIVES.CONSERVATIVE.value,
    },
  };

  constructor() {
    makeAutoObservable(this);
  }

  // === Getters/ Setters ===
  // Gets the data for a specific key in a form
  // e.g. (NEW_ACCOUNT_INFO, firstName) -> 'Bob'
  getFormItemData = (formKey: EOptionsForms, key: string) => {
    return get(this.formData[formKey], key);
  };

  // New value must be in the form of "{key: value}"
  // e.g. {country: 'US'}
  setFormItemData = (
    formKey: EOptionsForms,
    newValue: Partial<IOptionsFormData>,
  ) => {
    this.formData[formKey] = {
      ...this.formData[formKey],
      ...newValue,
    };
  };

  setIsODDRead = (isODDRead: boolean) => {
    this.isODDRead = isODDRead;
  };

  // Gets the data for a specific form as a mobx data object
  //e.g. (NEW_ACCOUNT_INFO) -> { firstName: "bob", lastName: "smith"... }
  getFormData = (formKey: EOptionsForms) => {
    return this.formData[formKey];
  };

  // ==== Form OnSubmit Functions ====
  validateFinancialProfile = async (nextScreenKey: string) => {
    await putUpdateFinancialProfile();

    FormsStore.setGuardedCurrentScreen(nextScreenKey);
  };

  validateTradingExperience = async (nextScreenKey: string) => {
    await putTradingExperience();

    FormsStore.setGuardedCurrentScreen(nextScreenKey);
  };

  validateOptionInvestmentObjectives = async (nextScreenKey: string) => {
    await putInvestmentObjective();
    await putTradingLevel();

    FormsStore.setGuardedCurrentScreen(nextScreenKey);
  };

  validateReviewDocument = async (nextScreenKey: string) => {
    if (OnboardingStore.getAccountOpening()) {
      // Let the API know that the user as read the documents
      await putTermsAgreements(
        DOCUMENTS.DIRECT_OPTION_APPLICATION_AND_AGREEMENT.documentName,
      );

      // Only making the call if they don't already have margin enabled
      await putAccountFeature(OnboardingStore.getAccountOpeningId(), {
        options: true,
      });
    }

    FormsStore.setGuardedCurrentScreen(nextScreenKey);
  };

  validateSummaryContainer = (nextScreenKey: string) => {
    FormsStore.setGuardedCurrentScreen(nextScreenKey);
  };

  // ==== Form Update Functions ====
  handleFormReset = () => {
    FormsStore.resetErrorHandler();

    this.isMarginLevelSelectButtonClicked = false;
    this.isODDRead = false;
    this.isOARead = false;
    this.formData = {
      [EOptionsForms.FINANCIAL_PROFILE]: {},
      [EOptionsForms.TRADING_EXPERIENCE]: {
        alternativeInvestments: INVESTMENT_EXPERIENCE.NONE.value,
        annuities: INVESTMENT_EXPERIENCE.NONE.value,
        mutualFunds: INVESTMENT_EXPERIENCE.NONE.value,
        options: INVESTMENT_EXPERIENCE.NONE.value,
        stockAndBonds: INVESTMENT_EXPERIENCE.NONE.value,
      },
      [EOptionsForms.DOCUMENT_REVIEW]: {},
      [EOptionsForms.OPTION_INVESTMENT_OBJECTIVES]: {
        investmentObjectives: INVESTMENT_OBJECTIVES.CONSERVATIVE.value,
      },
    };
  };

  updateFormData = (
    changedFields: Partial<IOptionsFormData>,
    formKey: EOptionsForms,
  ) => {
    this.formData[formKey] = {
      ...this.formData[formKey],
      ...changedFields,
    };

    // If the user updates the form, reset the error state
    FormsStore.resetErrorHandler();

    // This resets the trading level if a user changes the investment objective
    switch (formKey) {
      case EOptionsForms.OPTION_INVESTMENT_OBJECTIVES:
        this.handleOptionInvestmentObjectivesReset(changedFields);
        break;
      default:
        break;
    }
  };

  // ==== Form Data Reset Functions ====
  handleOptionInvestmentObjectivesReset = (
    changedFields: Partial<IOptionsFormData>,
  ) => {
    if (
      Object.prototype.hasOwnProperty.call(
        changedFields,
        "investmentObjectives",
      )
    ) {
      this.formData[EOptionsForms.OPTION_INVESTMENT_OBJECTIVES] = {
        ...this.formData[EOptionsForms.OPTION_INVESTMENT_OBJECTIVES],
        level: undefined,
      };
    }
  };
}

export default new OptionsStore();
