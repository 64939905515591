import { makeAutoObservable } from "mobx";

// ==== Types ====
import {
  EOnboardingForms,
  EOnboardingScreens,
  IBeneficiaries,
  IBeneficiaryAccount,
} from "views/SignUp/Onboarding/types/onboardingTypes";
import { BENEFICIARY_ACCOUNT_TYPES } from "views/SignUp/Onboarding/types/onboardingEnums";

// ==== Stores ====
import FormsStore from "stores/FormsStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import AuthenticationStore from "stores/AuthenticationStore";

// ==== Apis ====
import AccountOpeningApi from "apis/AccountOpeningApi";
import { getProfileById } from "apiHandlers/ProfileApiHandler";

// ==== Utilities ====
import { getDateOfBirth } from "utilities/genericUtilities";
import { handleErrorTracking } from "utilities/apiUtilities";

class BeneficiariesStore {
  isModalOpen: boolean = false;
  isUserAllocatingPercentages: boolean = false;

  allocatedPrimaryPercentage: number = 0;
  allocatedContingentPercentage: number = 0;

  primaryAccounts: IBeneficiaryAccount[] = [];
  contingentAccounts: IBeneficiaryAccount[] = [];

  // Form data
  addBeneficiaryData: Partial<IBeneficiaryAccount> = {};

  // AccountOpening Data fetched by our brother in arms, OnboardingStore
  accountType: number = undefined;
  accountOpeningId: string = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  handleFormReset = () => {
    this.isModalOpen = false;
    this.isUserAllocatingPercentages = false;
    this.allocatedPrimaryPercentage = 0;
    this.allocatedContingentPercentage = 0;
    this.primaryAccounts = [];
    this.contingentAccounts = [];
    this.addBeneficiaryData = {};
    this.accountType = undefined;
    this.accountOpeningId = undefined;
  };

  clearFormData = () => {
    this.addBeneficiaryData = {};
    this.accountType = undefined;
  };

  // ==== Helper Functions ====
  toggleModalState = () => {
    this.isModalOpen = !this.isModalOpen;
  };

  preloadBeneficiaries = (beneficiaries: IBeneficiaries[]) => {
    beneficiaries.forEach((beneficiary) => {
      //Date comes from the API in the format YYYY-MM-DDThh:ss:mm
      const rawBirthday = beneficiary.dateOfBirth.split("T");
      const birthday = rawBirthday[0].split("-");

      const accountData = {
        accountType: beneficiary.beneficiaryType,
        beneficiaryRelationshipType: 1,
        dobDay: birthday[2],
        dobMonth: birthday[1],
        dobYear: birthday[0],
        firstName: beneficiary.firstName,
        id: beneficiary.id,
        lastName: beneficiary.lastName,
        maritalStatus: beneficiary.maritalStatus,
        percentageAllocated: beneficiary.percentage,
      };

      if (
        OnboardingStore.getFormItemData(
          EOnboardingForms.BENEFICIARIES,
          "maritalStatus",
        ) === undefined &&
        beneficiary.maritalStatus !== undefined
      ) {
        OnboardingStore.setFormItemData(EOnboardingForms.BENEFICIARIES, {
          maritalStatus: beneficiary.maritalStatus,
        });
      }

      if (
        beneficiary.beneficiaryType ===
        BENEFICIARY_ACCOUNT_TYPES.CONTINGENT.value
      ) {
        const accounts = this.contingentAccounts;

        this.setAllocatedContingentPercentage(
          this.allocatedContingentPercentage + beneficiary.percentage,
        );

        this.contingentAccounts = accounts.concat([accountData]);
      } else if (
        beneficiary.beneficiaryType === BENEFICIARY_ACCOUNT_TYPES.PRIMARY.value
      ) {
        const accounts = this.primaryAccounts;

        this.setAllocatedPrimaryPercentage(
          this.allocatedPrimaryPercentage + beneficiary.percentage,
        );

        this.primaryAccounts = accounts.concat([accountData]);
      }
    });
  };

  // ==== Getter and Setters ====
  getAllocatedPercentage = (type: number) => {
    if (type === BENEFICIARY_ACCOUNT_TYPES.PRIMARY.value) {
      return this.allocatedPrimaryPercentage;
    }
    if (type === BENEFICIARY_ACCOUNT_TYPES.CONTINGENT.value) {
      return this.allocatedContingentPercentage;
    }
  };

  getBeneficiaries = (type: number) => {
    if (type === BENEFICIARY_ACCOUNT_TYPES.PRIMARY.value) {
      return this.primaryAccounts;
    }
    if (type === BENEFICIARY_ACCOUNT_TYPES.CONTINGENT.value) {
      return this.contingentAccounts;
    }
  };

  setAccountType = (accountType: number, accountId?: string) => {
    this.accountType = accountType;
    this.accountOpeningId = accountId;
  };

  setAllocatedPrimaryPercentage = (primaryPercentage: number) => {
    this.allocatedPrimaryPercentage = primaryPercentage;
  };

  setAllocatedContingentPercentage = (contingentPercentage: number) => {
    this.allocatedContingentPercentage = contingentPercentage;
  };

  // ==== Form Functions ====
  isBeneficiaryPercentageValid = () => {
    if (
      // If the user has any contingent beneficiary, the total contigent percentage should be 100%
      // But, if the user does not have any contingent benificiaries, only the primary percentage needs to equal 100%
      this.allocatedPrimaryPercentage === 100 &&
      (this.allocatedContingentPercentage === 100 ||
        this.allocatedContingentPercentage === 0)
    ) {
      return true;
    } else {
      return false;
    }
  };

  createBeneficiaryAccount = async (): Promise<void> => {
    AuthenticationStore.checkTokenSource();

    try {
      FormsStore.setLoading(true);

      const {
        firstName,
        lastName,
        dobDay,
        dobMonth,
        dobYear,
        percentageAllocated,
        beneficiaryRelationshipType,
        accountType,
      } = this.addBeneficiaryData;
      const maritalStatus = OnboardingStore.getFormItemData(
        EOnboardingScreens.BENEFICIARIES,
        "maritalStatus",
      );
      const response = await AccountOpeningApi.putBeneficiary(
        AuthenticationStore._tokenSource.token,
        {
          accountOpeningId: this.accountOpeningId,
          beneficiaryType: accountType,
          dateOfBirth: getDateOfBirth(dobDay, dobMonth, dobYear),
          firstName,
          lastName,
          maritalStatus,
          percentage: percentageAllocated,
          relationship: beneficiaryRelationshipType,
        },
      );

      if (response) {
        const accountData = {
          accountType,
          beneficiaryRelationshipType,
          dobDay,
          dobMonth,
          dobYear,
          firstName,
          id: response.id,
          lastName,
          maritalStatus,
          percentageAllocated,
        };

        if (accountType === BENEFICIARY_ACCOUNT_TYPES.PRIMARY.value) {
          const accounts = this.primaryAccounts;

          this.setAllocatedPrimaryPercentage(
            this.allocatedPrimaryPercentage + percentageAllocated,
          );

          this.primaryAccounts = accounts.concat([accountData]);
        } else if (accountType === BENEFICIARY_ACCOUNT_TYPES.CONTINGENT.value) {
          const accounts = this.contingentAccounts;

          this.setAllocatedContingentPercentage(
            this.allocatedContingentPercentage + percentageAllocated,
          );

          this.contingentAccounts = accounts.concat([accountData]);
        }

        await getProfileById();
      } else {
        FormsStore.setErrorHandler("createBeneficiaryAccount");
      }
    } catch (e) {
      handleErrorTracking(e, "BeneficiariesStores", "createBeneficiaryAccount");
    } finally {
      FormsStore.setLoading(false);
    }

    this.toggleModalState();
    this.clearFormData();
  };

  removeAccount = async (type: number, id: string): Promise<void> => {
    AuthenticationStore.checkTokenSource();

    try {
      FormsStore.setLoading(true);

      const response = await AccountOpeningApi.deleteBeneficiary(
        AuthenticationStore._tokenSource.token,
        id,
      );

      if (response) {
        if (type === BENEFICIARY_ACCOUNT_TYPES.PRIMARY.value) {
          const foundAccount = this.primaryAccounts.find(
            (obj) => obj.id === id,
          );

          // Reallocating our percentage
          this.setAllocatedPrimaryPercentage(
            this.allocatedPrimaryPercentage - foundAccount.percentageAllocated,
          );

          // Remove item from array of accounts
          this.primaryAccounts = this.primaryAccounts.filter(
            (obj) => obj.id !== id,
          );
        } else if (type === BENEFICIARY_ACCOUNT_TYPES.CONTINGENT.value) {
          const foundAccount = this.contingentAccounts.find(
            (obj) => obj.id === id,
          );

          // Reallocating our percentage
          this.setAllocatedContingentPercentage(
            this.allocatedContingentPercentage -
              foundAccount.percentageAllocated,
          );

          // Remove item from array of accounts
          this.contingentAccounts = this.contingentAccounts.filter(
            (obj) => obj.id !== id,
          );
        }

        await getProfileById();
      } else {
        FormsStore.setErrorHandler("removeAccount");
      }
    } catch (e) {
      handleErrorTracking(e, "BeneficiariesStores", "removeAccount");
    } finally {
      FormsStore.setLoading(false);
    }
  };

  updateAddBeneficiaryFormData = (changedFields: IBeneficiaryAccount) => {
    this.addBeneficiaryData = {
      ...this.addBeneficiaryData,
      ...changedFields,
    };

    if (Object.prototype.hasOwnProperty.call(changedFields, "accountType")) {
      this.addBeneficiaryData = {
        ...this.addBeneficiaryData,
        percentageAllocated: undefined,
      };
    }
  };
}

export default new BeneficiariesStore();
