import * as React from "react";
import { Spin } from "antd";
import { observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import AuthenticationStore from "stores/AuthenticationStore";
import FormsStore from "stores/FormsStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Forms ====
import LandingForm from "views/Options/forms/LandingForm";
import FinancialProfileForm from "views/Options/forms/FinancialProfileForm";
import TradingExperience from "views/Options/forms/TradingExperience";
import OptionInvestmentObjectives from "views/Options/forms/OptionInvestmentObjectives";
import ReviewDocuments from "views/Options/forms/ReviewDocuments";
import OptionSuccessScreen from "views/Options/forms/OptionSuccessScreen";
import AccountSelectionForm from "views/SignUp/Onboarding/forms/AccountSelectionForm";
import LoginForm from "components/authenticationForms/LoginForm";

// ==== Components ====
import SidebarWrapper from "components/contentWrappers/SidebarWrapper";
import SummaryContainer from "views/Options/forms/SummaryContainer";

// ==== Types ====
import { EAuthenticationScreens } from "types/authenticationTypes";
import { EOptionScreens } from "views/Options/types/optionsTypes";
import { FEATURE_TYPES } from "views/SignUp/Onboarding/types/onboardingEnums";
import { ERoutePaths } from "types/globalTypes";

// ==== Utilities ====
import { scrollUserToTopOfWindow } from "utilities/genericUtilities";
import { setQueryStringsIntoState } from "utilities/apiUtilities";

const OptionsContainer: React.FunctionComponent = observer(() => {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  React.useEffect(() => {
    const { search } = location;

    if (!window.location.href.includes(FormsStore.currentScreen)) {
      FormsStore.setCurrentScreen(FormsStore.currentScreen);
    }

    // Handles init auth of user if they start cold on the /options sub routes
    if (!OnboardingStore.profile) {
      AuthenticationStore.handleInitialTokenFetch(EOptionScreens.LANDING);
    }

    setQueryStringsIntoState(search);
  }, [location]);

  const renderForms = () => {
    scrollUserToTopOfWindow();

    switch (FormsStore.currentScreen) {
      // Auth Forms
      case EAuthenticationScreens.LOGIN:
        return <LoginForm />;
      case EAuthenticationScreens.ACCOUNT_SELECTION:
        return <AccountSelectionForm featureTypeKey={FEATURE_TYPES.OPTIONS} />;
      // Options Forms
      case EOptionScreens.LANDING:
        return <LandingForm />;
      case EOptionScreens.FINANCIAL_PROFILE:
        return <FinancialProfileForm />;
      case EOptionScreens.TRADING_EXPERIENCE:
        return <TradingExperience />;
      case EOptionScreens.OPTION_INVESTMENT_OBJECTIVES:
        return <OptionInvestmentObjectives />;
      case EOptionScreens.SUMMARY:
        return <SummaryContainer />;
      case EOptionScreens.DOCUMENT_REVIEW:
        return <ReviewDocuments />;
      case EOptionScreens.SUCCESS:
        return <OptionSuccessScreen />;

      default:
        FormsStore.setInfoSignUpMessage(
          t("views.Options.OptionsContainer.redirectMessage"),
        );
        history.push(ERoutePaths.ONBOARDING);
        return <div />;
    }
  };

  // If the user does NOT have a current screen set it to the landing page
  // and send them through the init auth flow
  if (!FormsStore.currentScreen) {
    AuthenticationStore.handleInitialFeatureLoad(EOptionScreens.LANDING);
  }

  return (
    <SidebarWrapper>
      <Spin spinning={FormsStore.isLoading}>{renderForms()}</Spin>
    </SidebarWrapper>
  );
});

export default OptionsContainer;
