import * as React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Types ====
import { EOnboardingForms } from "views/SignUp/Onboarding/types/onboardingTypes";

// ==== Components ====
import SummaryFieldBlock from "components/SummaryFieldBlock";
import SummaryHeader from "views/SignUp/Onboarding/forms/SummaryPage/SummaryHeader";

// ==== Utilities ====
import { getTranslationKeyFromBool } from "utilities/genericUtilities";

interface IProps {
  isEditButtonDisabled?: boolean;
}

const SummaryTrustedContactForm: React.FunctionComponent<IProps> = observer(
  ({ isEditButtonDisabled }) => {
    const { t } = useTranslation();

    const getFormData = (key: string) =>
      OnboardingStore.getFormItemData(EOnboardingForms.TRUSTED_CONTACT, key);

    return (
      <React.Fragment>
        <SummaryHeader
          header={t("views.signUp.components.summery.trustedContact")}
          editButtonKey={
            !isEditButtonDisabled && EOnboardingForms.TRUSTED_CONTACT
          }
        />
        <SummaryFieldBlock
          question={t("views.signUp.forms.TrustedContactForm.wouldYou")}
          answer={t(
            getTranslationKeyFromBool(getFormData("HasTrustedContact")),
          )}
        />
        {getFormData("ContactFirstName") && (
          <SummaryFieldBlock
            question={`${t("forms.fields.firstName")}:`}
            answer={getFormData("ContactFirstName") || ""}
          />
        )}
        {getFormData("ContactLastName") && (
          <SummaryFieldBlock
            question={`${t("forms.fields.lastName")}:`}
            answer={getFormData("ContactLastName") || ""}
          />
        )}
        {getFormData("ContactRelationship") && (
          <SummaryFieldBlock
            question={`${t("forms.fields.relationship")}:`}
            answer={getFormData("ContactRelationship") || ""}
          />
        )}
        {getFormData("ContactCountryCode") && (
          <SummaryFieldBlock
            question={`${t("forms.fields.countryCode")}:`}
            answer={getFormData("ContactCountryCode") || ""}
          />
        )}
        {getFormData("ContactPhone") && (
          <SummaryFieldBlock
            question={`${t(
              "views.signUp.forms.TrustedContactForm.contactPhoneNumber",
            )}:`}
            answer={getFormData("ContactPhone") || ""}
          />
        )}
        {getFormData("ContactEmail") && (
          <SummaryFieldBlock
            question={`${t(
              "views.signUp.forms.TrustedContactForm.contactEmail",
            )}:`}
            answer={getFormData("ContactEmail") || ""}
          />
        )}
        {getFormData("HasTrustedContact") && (
          <SummaryFieldBlock
            question={t("views.signUp.forms.TrustedContactForm.addAddress")}
            answer={t(
              getTranslationKeyFromBool(
                getFormData("HasTrustedContactAddress"),
              ),
            )}
          />
        )}
        {getFormData("ContactStreetAddress1") && (
          <SummaryFieldBlock
            question={`${t("forms.fields.address1")}:`}
            answer={getFormData("ContactStreetAddress1") || ""}
          />
        )}
        {getFormData("ContactStreetAddress2") && (
          <SummaryFieldBlock
            question={`${t("forms.fields.address2")}:`}
            answer={getFormData("ContactStreetAddress2") || ""}
          />
        )}
        {getFormData("ContactCity") && (
          <SummaryFieldBlock
            question={`${t("forms.fields.city")}:`}
            answer={getFormData("ContactCity") || ""}
          />
        )}
        {getFormData("ContactState") && (
          <SummaryFieldBlock
            question={`${t("forms.fields.state")}:`}
            answer={getFormData("ContactState") || ""}
          />
        )}
        {getFormData("ContactZipPostal") && (
          <SummaryFieldBlock
            question={`${
              OnboardingStore.isUserUSCitizen()
                ? t("forms.fields.zipCode")
                : t("forms.fields.postalCode")
            }:`}
            answer={getFormData("ContactZipPostal") || ""}
          />
        )}
        {getFormData("ContactCountry") && (
          <SummaryFieldBlock
            question={`${t("forms.fields.country")}:`}
            answer={getFormData("ContactCountry") || ""}
          />
        )}
      </React.Fragment>
    );
  },
);

export default SummaryTrustedContactForm;
