import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Select } from "antd";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { renderSelectOptions } from "utilities/formItemUtilities";

// ==== Stores ====
import OptionsStore from "views/Options/OptionsStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import FormsStore from "stores/FormsStore";

// ==== Components ====
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";
import FormHeader from "components/FormHeader";

// ==== Types ====
import {
  EOptionScreens,
  IFinancialProfile,
} from "views/Options/types/optionsTypes";
import { MONEY_OPTIONS } from "views/SignUp/Onboarding/types/onboardingEnums";
import {
  EOnboardingForms,
  EOnboardingScreens,
} from "views/SignUp/Onboarding/types/onboardingTypes";

// ==== Utilities ====
import { isEnumFalsey, concatenateSentences } from "utilities/genericUtilities";

const FinancialProfileForm: React.FunctionComponent<FormComponentProps> =
  observer(({ form }) => {
    const { getFieldDecorator, validateFields } = form;
    const { t } = useTranslation();

    const getInitialValue = (key: string) =>
      OptionsStore.getFormItemData(EOptionScreens.FINANCIAL_PROFILE, key);

    const handleSubmit = async (
      event: React.FormEvent<HTMLFormElement>,
      nextScreenKey: string,
    ): Promise<void> => {
      event.preventDefault();

      validateFields(async (err: string[]): Promise<void> => {
        if (!err) {
          await OptionsStore.validateFinancialProfile(nextScreenKey);
        }
      });
    };

    const handleLiquidNetworthSelect = (networth: string) => {
      OnboardingStore.setFormItemData(
        EOnboardingScreens.EMPLOYMENT_INFORMATION,
        {
          LiquidNetWorth: networth,
        },
      );
    };

    const isNextButtonDisabled = (): {
      disabledMessage: string;
      isDisabled: boolean;
    } => {
      let isDisabled = false;
      let disabledMessage = "";

      if (!isEnumFalsey(getInitialValue("annualIncome"))) {
        isDisabled = true;
        disabledMessage = t(
          "views.Options.FinancialProfileForm.validationMessages.annualIncome",
        );
      }

      const liquidNetWorth = OnboardingStore.getFormItemData(
        EOnboardingForms.EMPLOYMENT_INFORMATION,
        "LiquidNetWorth",
      );

      if (!isEnumFalsey(liquidNetWorth)) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t(
            "views.Options.FinancialProfileForm.validationMessages.liquidNetworth",
          ),
        );
      }

      if (!isEnumFalsey(getInitialValue("netWorth"))) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t("views.Options.FinancialProfileForm.validationMessages.netWorth"),
        );
      }

      return {
        disabledMessage,
        isDisabled,
      };
    };

    return (
      <React.Fragment>
        <div className="slideInWrapper">
          <Form layout="vertical">
            <FormHeader
              headerText={t("views.Options.FinancialProfileForm.header")}
              subheaderText={t("views.Options.FinancialProfileForm.subheader")}
            />
            <div className="financialInfoForm">
              <div className="body financialInfoHeader">
                {t("forms.fields.annualIncome")}
              </div>
              <div className="financialInfoSelectWrapper">
                <div className="financialInfoSelect">
                  <Form.Item>
                    {getFieldDecorator("annualIncome", {
                      initialValue: getInitialValue("annualIncome"),
                      rules: [
                        {
                          message: t("forms.validation.isRequired", {
                            fieldName: t("forms.fields.annualIncome"),
                          }),
                          required: true,
                        },
                      ],
                    })(
                      <Select
                        placeholder={t("enums.optionScreens.selectOne")}
                        filterOption={(input, option) => {
                          return option.children
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                      >
                        {renderSelectOptions(MONEY_OPTIONS, t)}
                      </Select>,
                    )}
                  </Form.Item>
                </div>
                <p style={{ marginBottom: 0 }}>
                  {t(
                    "views.Options.FinancialProfileForm.annualIncomeNote.line1",
                  )}
                </p>
                <p>
                  {t(
                    "views.Options.FinancialProfileForm.annualIncomeNote.line2",
                  )}
                </p>
              </div>
            </div>
            <div className="financialInfoForm">
              <div className="body financialInfoHeader">
                {t("forms.fields.netWorth")}
              </div>
              <div className="financialInfoSelectWrapper">
                <div className="financialInfoSelect">
                  <Form.Item>
                    {getFieldDecorator("netWorth", {
                      initialValue: getInitialValue("netWorth"),
                      rules: [
                        {
                          message: t("forms.validation.isRequired", {
                            fieldName: t("forms.fields.netWorth"),
                          }),
                          required: true,
                        },
                      ],
                    })(
                      <Select
                        placeholder={t("enums.optionScreens.selectOne")}
                        filterOption={(input, option) => {
                          return option.children
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                      >
                        {renderSelectOptions(MONEY_OPTIONS, t)}
                      </Select>,
                    )}
                  </Form.Item>
                </div>
                <p style={{ marginBottom: 10 }}>
                  {t("views.Options.FinancialProfileForm.whatIsNetWorth")}
                </p>
              </div>
            </div>
            <div className="financialInfoForm">
              <div className="body financialInfoHeader">
                {t("forms.fields.liquidNet")}
              </div>
              <div className="financialInfoSelectWrapper">
                <div className="financialInfoSelect">
                  <Form.Item>
                    {getFieldDecorator("liquidNetWorth", {
                      initialValue: OnboardingStore.getFormItemData(
                        EOnboardingForms.EMPLOYMENT_INFORMATION,
                        "LiquidNetWorth",
                      ),
                      rules: [
                        {
                          message: t("forms.validation.isRequired", {
                            fieldName: t("forms.fields.liquidNet"),
                          }),
                          required: true,
                        },
                      ],
                    })(
                      <Select
                        onSelect={handleLiquidNetworthSelect}
                        placeholder={t("enums.optionScreens.selectOne")}
                        filterOption={(input, option) => {
                          return option.children
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                      >
                        {renderSelectOptions(MONEY_OPTIONS, t)}
                      </Select>,
                    )}
                  </Form.Item>
                </div>
                <p style={{ marginBottom: 0 }}>
                  {t("views.Options.FinancialProfileForm.whatIsLiquid")}
                </p>
              </div>
            </div>
            <FormButtonFooter
              goForwardHandler={(e) =>
                handleSubmit(e, EOptionScreens.TRADING_EXPERIENCE)
              }
              isGoForwardDisabled={isNextButtonDisabled().isDisabled}
              goForwardDisabledMessage={isNextButtonDisabled().disabledMessage}
              goBackHandler={() => {
                FormsStore.setCurrentScreen(EOptionScreens.LANDING);
              }}
            />
          </Form>
        </div>
      </React.Fragment>
    );
  });

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: IFinancialProfile) => {
    OptionsStore.updateFormData(
      changedFields,
      EOptionScreens.FINANCIAL_PROFILE,
    );
  },
})(FinancialProfileForm);
