// ==== Stores ====
import AuthenticationStore from "stores/AuthenticationStore";
import AutocompleteStore from "views/SignUp/Onboarding/store/AutocompleteStore";

// ==== Apis ====
import AutocompleteApi from "apis/AutocompleteApi";

// ==== Types ====
import { ISuggestion } from "views/SignUp/Onboarding/types/autocompleteTypes";

// ==== Utilities ====
import { handleErrorTracking } from "utilities/apiUtilities";

const FILE_NAME = "AutocompleteApiHandler";

export const autocompleteLookup = async (query: string): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    AutocompleteStore.setIsLoadingSuggestions(true);
    const response = await AutocompleteApi.getLookup(
      AuthenticationStore._tokenSource.token,
      query,
    );

    if (response.suggestions) {
      AutocompleteStore.setSuggestions(response.suggestions);
    } else {
      AutocompleteStore.setSuggestions([]);
    }
  } catch (e) {
    AutocompleteStore.setSuggestions([]);
    handleErrorTracking(e, FILE_NAME, "autocompleteLookup");
  } finally {
    AutocompleteStore.setIsLoadingSuggestions(false);
  }
};

export const addressValidation = async (
  address: ISuggestion,
): Promise<void> => {
  AuthenticationStore.checkTokenSource();
  try {
    AutocompleteStore.setIsLoadingSuggestions(true);
    const response = await AutocompleteApi.getUsStreetApi(
      AuthenticationStore._tokenSource.token,
      address,
    );

    if (response.length === 1) {
      const addressInfo = response[0];

      // p === po box
      if (addressInfo.metadata.record_type === "P") {
        AutocompleteStore.setIsPoBox(true);
      } else {
        AutocompleteStore.setIsPoBox(false);
      }
    }
  } catch (e) {
    AutocompleteStore.setSuggestions([]);
    handleErrorTracking(e, FILE_NAME, "addressValidation");
  } finally {
    AutocompleteStore.setIsLoadingSuggestions(false);
  }
};
