import * as React from "react";
import { Alert, Layout } from "antd";
import { useTranslation } from "react-i18next";

const { Footer } = Layout;

const BrowserWarningFooter: React.FunctionComponent = () => {
  const { t } = useTranslation();

  // Returns: "For the best user experience with StoneX, we recommend using Chrome, Edge, or Firefox"
  return "-ms-scroll-limit" in document.documentElement.style &&
    "-ms-ime-align" in document.documentElement.style ? (
    <Footer style={{ padding: "5vh 0", textAlign: "center" }}>
      <Alert message={t("ieWarning")} type="warning" />
    </Footer>
  ) : null;
};

export default BrowserWarningFooter;
