// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
//
// Disabling TypeScript because it does not know that window.dataLayer exists
// (Google's Tag code in the <Script/> tags in index.html adds support for it)

export const pushToDataLayer = (
  eventLabel: string,
  eventCategory?: string = "eventTracker",
): void => {
  const ev = {
    env: window.location.host,
    event: eventCategory,
    eventCategory: "applicationForm",
    eventLabel: eventLabel,
  };

  // If dataLayer does NOT exist, create an empty array or instantiate it
  window.dataLayer = window.dataLayer || [];

  // 🎵 Push me, and then just touch me, till I can get my satisfaction 🎵
  window.dataLayer.push(ev);
};

// Update Google Tag Manager for StoneX One and then assign a new url
export const assignNewUrl = (newUrl: string): void => {
  pushToDataLayer(newUrl);
  window.location.assign(newUrl);
};
