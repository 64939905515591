import * as React from "react";
import { Spin } from "antd";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import FormsStore from "stores/FormsStore";

// ==== Components ====
import ErrorMessageAlert from "views/SignUp/Onboarding/components/ErrorMessageAlert";
import CenteredWrapper from "components/contentWrappers/CenteredWrapper";

// ==== Types ====
import { ESelfEnrollForms } from "views/SignUp/SelfEnroll/types/selfEnrollTypes";
import { ERoutePaths } from "types/globalTypes";

// ==== Forms ====
import AccountNumberForm from "views/SignUp/SelfEnroll/forms/AccountNumberForm";
import AccountInformationForm from "views/SignUp/SelfEnroll/forms/AccountInformationForm";
import EmailValidateForm from "views/SignUp/SelfEnroll/forms/EmailValidateForm";
import NewAccountForm from "views/SignUp/SelfEnroll/forms/NewAccountForm";
import PinValidateForm from "views/SignUp/SelfEnroll/forms/PinValidateForm";
import ConfirmationScreenForm from "views/SignUp/SelfEnroll/forms/ConfirmationScreenForm";

const SelfEnrollContainer: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const location = useLocation();

  React.useEffect(() => {
    if (!FormsStore.currentScreen) {
      FormsStore.setCurrentScreen(ESelfEnrollForms.ACCOUNT_NUMBER_FORM);
    }
  }, [location]);

  const renderForms = () => {
    switch (FormsStore.currentScreen) {
      // First step
      case ESelfEnrollForms.ACCOUNT_NUMBER_FORM:
        return <AccountNumberForm />;
      // Second step
      case ESelfEnrollForms.ACCOUNT_INFORMATION_FORM:
        return <AccountInformationForm />;
      // Third Step
      case ESelfEnrollForms.EMAIL_VALIDATION_FORM:
        return <EmailValidateForm />;
      // Fourth step  (Depends on the response from email Validation)
      // 4a
      case ESelfEnrollForms.PIN_VALIDATION_FORM:
        return <PinValidateForm />;
      // 4b
      case ESelfEnrollForms.NEW_ACCOUNT_FORM:
        return <NewAccountForm />;
      // Fifth step (Shows success screen depending on path from step 4)
      // 5a
      case ESelfEnrollForms.CONFIRMATION_SCREEN_FORM:
        return <ConfirmationScreenForm />;
      // 5b
      case ESelfEnrollForms.NEW_ACCOUNT_SUCCESS_SCREEN:
        return renderNewAccountSuccessScreen();
      default:
        return <ErrorMessageAlert resetPath={ERoutePaths.SIGNUP} />;
    }
  };

  const renderNewAccountSuccessScreen = () => (
    <div className="slideInWrapper">
      {t("views.signUp.SelfEnrollContainer.checkEmail")}
    </div>
  );

  return (
    <CenteredWrapper>
      <Spin spinning={FormsStore.isLoading}>
        {renderForms()}
        <ErrorMessageAlert
          defaultErrorMessage={t(
            "views.signUp.components.notEligibleAccount.message",
          )}
          resetPath={ERoutePaths.SIGNUP}
        />
      </Spin>
    </CenteredWrapper>
  );
});

export default SelfEnrollContainer;
