import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Input, Select } from "antd";
import { useTranslation } from "react-i18next";
import { FormComponentProps } from "@ant-design/compatible/lib/form";

// ==== Stores ====
import ConfigurationStore from "stores/ConfigurationStore";

// ==== Utilities ====
import {
  phoneValidator,
  countryCodeValidator,
  getMaxPhoneNumberLength,
  getMinPhoneNumberLength,
} from "utilities/formValidators";

const { Option } = Select;

interface IProps extends FormComponentProps {
  // Ant Form Props
  confirmCountryCode: string;
  confirmPhoneNumber: string;
  countryCode: string;
  disabled?: boolean;
  getFieldDecorator: any;
  getFieldValue: (arg: string) => string;
  isConfirmVisible?: boolean;
  isFieldTouched: (arg: string) => boolean;
  isFieldValidating: (arg: string) => boolean;
  isFieldsTouched: (arg: string[]) => boolean;
  phoneNumber: string;
  validateFields: any;
}

// PhoneNumbersForm is an abstraction for the phone/ country code fields and
// their validation
//
// Assumptions made by the form:
// - Expects that the AuthenticationStore has already fetched the country code data
// - That <Observer/> is wrapping it in the parent
// - That a <Form /> is wrapping it in the parent
//
// For keys, this form uses:
// CountryCode
// ConfirmCountryCode
// Phone
// ConfirmPhone
const PhoneNumbersForm: React.FunctionComponent<IProps> = (props) => {
  const {
    // Ant Form Props
    getFieldDecorator,
    getFieldValue,
    isFieldTouched,
    isFieldsTouched,
    validateFields,
    //
    isConfirmVisible = true,
    countryCode,
    confirmCountryCode,
    phoneNumber,
    confirmPhoneNumber,
    disabled,
  } = props;

  const { t } = useTranslation();

  // ==== Phone Validation ====
  const phoneField = getFieldValue("Phone");
  const confirmPhoneField = getFieldValue("ConfirmPhone");

  const validatePhoneNumber = React.useCallback(
    (rule: string, value: string, callback: (response?: string) => void) => {
      phoneValidator(
        value,
        getFieldValue("ConfirmPhone"),
        t,
        callback,
        validateFields,
        isFieldsTouched,
        isFieldTouched,
        "Phone",
        "ConfirmPhone",
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [phoneField],
  );

  const validatePhoneNumberConfirm = React.useCallback(
    (rule: string, value: string, callback: (response?: string) => void) => {
      phoneValidator(
        value,
        getFieldValue("Phone"),
        t,
        callback,
        validateFields,
        isFieldsTouched,
        isFieldTouched,
        "Phone",
        "ConfirmPhone",
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmPhoneField],
  );

  // ==== Country Code Validation ====
  const countryField = getFieldValue("CountryCode");
  const confirmCountryField = getFieldValue("ConfirmCountryCode");

  const validateCountryCode = React.useCallback(
    (rule: string, value: string, callback: (response?: string) => void) => {
      countryCodeValidator(
        value,
        getFieldValue("ConfirmCountryCode"),
        t,
        callback,
        validateFields,
        isFieldsTouched,
        isFieldTouched,
        "CountryCode",
        "ConfirmCountryCode",
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [countryField],
  );

  const validateCountryCodeConfirm = React.useCallback(
    (rule: string, value: string, callback: (response?: string) => void) => {
      countryCodeValidator(
        value,
        getFieldValue("CountryCode"),
        t,
        callback,
        validateFields,
        isFieldsTouched,
        isFieldTouched,
        "CountryCode",
        "ConfirmCountryCode",
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmCountryField],
  );

  return (
    <div className="phoneNumberWrapper">
      <div>
        <Form.Item className="countryCodeItem" required={true}>
          {getFieldDecorator("CountryCode", {
            initialValue: countryCode || "1",
            rules: [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: t("forms.fields.countryCode"),
                }),
                required: true,
              },
              {
                validator: validateCountryCode,
              },
            ],
          })(
            <Select
              tabIndex={0}
              placeholder={t("forms.fields.countryCode")}
              disabled={disabled}
              showSearch
              optionFilterProp="children"
              filterOption={(inputValue, option) =>
                option.props.children
                  .toString()
                  .toLowerCase()
                  .includes(inputValue.toLowerCase())
              }
            >
              {ConfigurationStore?.phoneCountryCodes?.map(
                ({ countryCode, countryName }) => (
                  <Option key={countryName} value={countryCode}>
                    +{countryCode.trim()} ({countryName})
                  </Option>
                ),
              )}
            </Select>,
          )}
        </Form.Item>
        <Form.Item className="phoneItem">
          {getFieldDecorator("Phone", {
            initialValue: phoneNumber,
            rules: [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: t("forms.fields.phoneNumber"),
                }),
                required: true,
              },
              {
                message: t("forms.validation.min", {
                  number: getMinPhoneNumberLength(countryCode),
                }),
                min: getMinPhoneNumberLength(countryCode),
              },
              {
                max: getMaxPhoneNumberLength(countryCode),
                message: t("forms.validation.max", {
                  number: getMaxPhoneNumberLength(countryCode),
                }),
              },
              {
                message: t("forms.validation.digits"),
                pattern: new RegExp("^\\d+$"),
              },
              {
                validator: validatePhoneNumber,
              },
            ],
            validateTrigger: "onBlur",
          })(
            <Input
              placeholder={t("forms.fields.phoneNumber")}
              disabled={disabled}
              inputMode="tel"
            />,
          )}
        </Form.Item>
      </div>
      {isConfirmVisible && (
        <div>
          <Form.Item className="countryCodeItem" required={true}>
            {getFieldDecorator("ConfirmCountryCode", {
              initialValue: confirmCountryCode || "1",
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.confirm", {
                      fieldName: t("forms.fields.countryCode"),
                    }),
                  }),
                  required: true,
                },
                {
                  validator: validateCountryCodeConfirm,
                },
              ],
            })(
              <Select
                tabIndex={0}
                placeholder={t("forms.fields.countryCode")}
                disabled={disabled}
                showSearch
                optionFilterProp="children"
                filterOption={(inputValue, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
                }
              >
                {ConfigurationStore?.phoneCountryCodes?.map(
                  ({ countryCode, countryName }) => (
                    <Option key={countryName} value={countryCode}>
                      +{countryCode.trim()} ({countryName})
                    </Option>
                  ),
                )}
              </Select>,
            )}
          </Form.Item>
          <Form.Item className="phoneItem">
            {getFieldDecorator("ConfirmPhone", {
              initialValue: confirmPhoneNumber,
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.confirm", {
                      fieldName: t("forms.fields.phoneNumber"),
                    }),
                  }),
                  required: true,
                },
                {
                  message: t("forms.validation.min", {
                    number: getMinPhoneNumberLength(confirmCountryCode),
                  }),
                  min: getMinPhoneNumberLength(confirmCountryCode),
                },
                {
                  max: getMaxPhoneNumberLength(confirmCountryCode),
                  message: t("forms.validation.max", {
                    number: getMaxPhoneNumberLength(confirmCountryCode),
                  }),
                },
                {
                  message: t("forms.validation.digits"),
                  pattern: new RegExp("^\\d+$"),
                },
                {
                  validator: validatePhoneNumberConfirm,
                },
              ],
              validateTrigger: "onBlur",
            })(
              <Input
                placeholder={t("forms.fields.confirm", {
                  fieldName: t("forms.fields.phoneNumber"),
                })}
                disabled={disabled}
                inputMode="tel"
              />,
            )}
          </Form.Item>
        </div>
      )}
    </div>
  );
};

export default Form.create<IProps>()(PhoneNumbersForm);
