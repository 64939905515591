import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Slider } from "antd";
import { observer } from "mobx-react";
import { TFunction, useTranslation } from "react-i18next";
import { FormComponentProps } from "@ant-design/compatible/lib/form";

// ==== Stores ====
import OptionsStore from "views/Options/OptionsStore";
import FormsStore from "stores/FormsStore";

// ==== Components ====
import FormHeader from "components/FormHeader";
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";

// ==== Types ====
import {
  EOptionScreens,
  ITradingExperience,
} from "views/Options/types/optionsTypes";
import { INVESTMENT_EXPERIENCE } from "views/Options/types/optionsEnums";
import { IEnumType } from "utilities/typeUtilities";

// ==== Utilities ====
import { sliderStyle } from "utilities/styleConstants";

const TradingExperience: React.FunctionComponent<FormComponentProps> = observer(
  ({ form }) => {
    const { getFieldDecorator, validateFields } = form;
    const { t } = useTranslation();

    const getInitialValue = (key: string) =>
      OptionsStore.getFormItemData(EOptionScreens.TRADING_EXPERIENCE, key);

    const handleSubmit = async (
      event: React.FormEvent<HTMLFormElement>,
      nextScreenKey: string,
    ): Promise<void> => {
      event.preventDefault();

      validateFields(async (err: string[]): Promise<void> => {
        if (!err) {
          await OptionsStore.validateTradingExperience(nextScreenKey);
        }
      });
    };

    const renderRangeSlider = (options: IEnumType, t: TFunction) => {
      let marks = {
        0: "",
        1: "",
        2: "",
        3: "",
      };
      let optionsArray = Object.keys(options);

      return (
        <Slider
          handleStyle={sliderStyle}
          tooltipVisible={false}
          trackStyle={sliderStyle}
          min={0}
          max={optionsArray.length - 1}
          marks={marks}
        />
      );
    };

    const renderRangeSliderLabels = () => {
      const optionsArray = Object.keys(INVESTMENT_EXPERIENCE);

      return (
        <div className="labelWrapper">
          {optionsArray.map((option) => (
            <div key={option} className="optionsMarkLabelStyle">
              <strong>{t(INVESTMENT_EXPERIENCE[option].translationKey)}</strong>
              <br />
              <strong>
                {t(`${INVESTMENT_EXPERIENCE[option].translationKey + "Time"}`)}
              </strong>
            </div>
          ))}
        </div>
      );
    };

    const renderExperienceField = (key: string) => (
      <div className="fieldWrapper">
        <div className="body experienceHeader">
          {t(`views.Options.TradingExperience.${key}`)}
        </div>
        <div className="sliderWrapper">
          <div className="experienceSlider">
            <Form.Item>
              {getFieldDecorator(key, {
                initialValue: getInitialValue(key),
                rules: [
                  {
                    message: t("forms.validation.isRequired", {
                      fieldName: t(`views.Options.TradingExperience.${key}`),
                    }),
                    required: true,
                  },
                ],
              })(renderRangeSlider(INVESTMENT_EXPERIENCE, t))}
            </Form.Item>
          </div>
        </div>
      </div>
    );

    return (
      <React.Fragment>
        <div className="investmentExperienceForm slideInWrapper">
          <Form layout="vertical">
            <div style={{ marginBottom: "25px" }}>
              <FormHeader
                headerText={t("views.Options.TradingExperience.header")}
                subheaderText={t("views.Options.TradingExperience.subheader")}
              />
            </div>
            <div>
              <div className="experienceLabels">
                <div className="body experienceHeader"></div>
                {renderRangeSliderLabels()}
              </div>
              {renderExperienceField("stockAndBonds")}
              {renderExperienceField("mutualFunds")}
              {renderExperienceField("options")}
              {renderExperienceField("annuities")}
              {renderExperienceField("alternativeInvestments")}
            </div>

            <FormButtonFooter
              goForwardHandler={(e) =>
                handleSubmit(e, EOptionScreens.OPTION_INVESTMENT_OBJECTIVES)
              }
              goBackHandler={() => {
                FormsStore.setCurrentScreen(EOptionScreens.FINANCIAL_PROFILE);
              }}
            />
          </Form>
        </div>
      </React.Fragment>
    );
  },
);

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: ITradingExperience) => {
    OptionsStore.updateFormData(
      changedFields,
      EOptionScreens.TRADING_EXPERIENCE,
    );
  },
})(TradingExperience);
