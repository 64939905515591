import * as React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Types ====
import { EOnboardingForms } from "views/SignUp/Onboarding/types/onboardingTypes";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Components ====
import SummaryFieldBlock from "components/SummaryFieldBlock";
import SummaryHeader from "views/SignUp/Onboarding/forms/SummaryPage/SummaryHeader";

// ==== Utilities ====
import { getTranslationKeyFromBool } from "utilities/genericUtilities";

interface IProps {
  isEditButtonDisabled?: boolean;
}

const SummaryStockExchangeForm: React.FunctionComponent<IProps> = observer(
  ({ isEditButtonDisabled }) => {
    const { t } = useTranslation();

    const getFormData = (key: string) =>
      OnboardingStore.getFormItemData(
        EOnboardingForms.STOCK_EXCHANGE_ASSOCIATION,
        key,
      );

    return (
      <React.Fragment>
        <SummaryHeader
          header={t("views.signUp.components.summery.stockExchangeAssociation")}
          editButtonKey={
            !isEditButtonDisabled && EOnboardingForms.STOCK_EXCHANGE_ASSOCIATION
          }
        />
        <SummaryFieldBlock
          question={t(
            "views.signUp.forms.StockExchangeAssociationForm.areYouAssociated",
          )}
          answer={t(getTranslationKeyFromBool(getFormData("IsFINRA")))}
        />
        {getFormData("BusinessName") && (
          <SummaryFieldBlock
            question={`${t(
              "views.signUp.forms.StockExchangeAssociationForm.businessName",
            )}:`}
            answer={getFormData("BusinessName") || ""}
          />
        )}
        <SummaryFieldBlock
          question={`${t(
            "views.signUp.forms.StockExchangeAssociationForm.rule114.line1",
          )}${t(
            "views.signUp.forms.StockExchangeAssociationForm.rule114.line2",
          )}`}
          answer={t(getTranslationKeyFromBool(getFormData("Rule114")))}
        />
        {getFormData("CompanyName") && (
          <SummaryFieldBlock
            question={`${t(
              "views.signUp.forms.StockExchangeAssociationForm.companyName",
            )}:`}
            answer={getFormData("CompanyName") || ""}
          />
        )}
        {getFormData("CUSIP") && (
          <SummaryFieldBlock
            question={t(
              "views.signUp.forms.StockExchangeAssociationForm.whatIsCUSP",
            )}
            answer={getFormData("CUSIP") || ""}
          />
        )}
      </React.Fragment>
    );
  },
);

export default SummaryStockExchangeForm;
