import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Alert } from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import FormsStore from "stores/FormsStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import AutocompleteStore from "views/SignUp/Onboarding/store/AutocompleteStore";

// ==== Types ====
import {
  EOnboardingForms,
  EOnboardingScreens,
  IAddressCollectionForm,
} from "views/SignUp/Onboarding/types/onboardingTypes";

// ==== Components ====
import AddressForm from "views/SignUp/Onboarding/components/AddressForm";
import FormHeader from "components/FormHeader";
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";

// ==== Utilities ====
import { concatenateSentences, cleanString } from "utilities/genericUtilities";

const AddressCollectionForm: React.FunctionComponent<FormComponentProps> =
  observer(({ form }) => {
    const { validateFields } = form;
    const { t } = useTranslation();

    const handleSubmit = async (
      event: React.FormEvent<HTMLFormElement>,
      nextScreenKey: string,
    ): Promise<void> => {
      event.preventDefault();

      validateFields(async (err: string[]): Promise<void> => {
        if (!err) {
          await OnboardingStore.validateAddressInfo(nextScreenKey);
        }
      });
    };

    const getInitialValue = (key: string) =>
      OnboardingStore.getFormItemData(EOnboardingForms.ADDRESS_COLLECTION, key);

    const isAddressValid = (): {
      isValid: boolean;
      validationMessage: string;
    } => {
      let isValid = true;
      let validationMessage = "";

      // https://stackoverflow.com/questions/5680050/po-box-regular-expression-validation
      let regexMagic = /\b(?:p\.?\s*o\.?|post\s+office)\s+box\b/i;

      // User's address CAN NOT be a PO Box
      if (
        AutocompleteStore?.isPoBox ||
        regexMagic.test(cleanString(getInitialValue("StreetAddress1")))
      ) {
        isValid = false;
        validationMessage = t(
          "views.signUp.forms.AddressCollectionForm.noPOBox",
        );
      }

      // If the user is an American Citizen, they must have an address in the US
      if (
        OnboardingStore.isUserUSCitizen() &&
        getInitialValue("Country") !== "US"
      ) {
        isValid = false;
        validationMessage = concatenateSentences(
          validationMessage,
          t("views.signUp.forms.AddressCollectionForm.domesticAddressErr"),
        );
      }

      // If the user is NOT an American Citizen, they must NOT live inside the US
      if (
        !OnboardingStore.isUserUSCitizen() &&
        getInitialValue("Country") === "US"
      ) {
        isValid = false;
        validationMessage = concatenateSentences(
          validationMessage,
          t("views.signUp.forms.AddressCollectionForm.foreignAddressErr"),
        );
      }

      return { isValid, validationMessage };
    };

    const isNextButtonDisabled = (): {
      disabledMessage: string;
      isDisabled: boolean;
    } => {
      let disabledMessage: string = "";
      let isDisabled: boolean = false;

      if (isAddressValid().validationMessage) {
        isDisabled = true;
        disabledMessage = isAddressValid().validationMessage;
      }

      if (
        getInitialValue("Country") === "US" ||
        getInitialValue("Country") === "CA"
      ) {
        if (!getInitialValue("StateProvince")) {
          isDisabled = true;
          disabledMessage = concatenateSentences(
            disabledMessage,
            t("views.signUp.forms.AddressCollectionForm.errorMessages.state"),
          );
        }

        if (!getInitialValue("ZipPostal")) {
          isDisabled = true;
          disabledMessage = concatenateSentences(
            disabledMessage,
            t("views.signUp.forms.AddressCollectionForm.errorMessages.zip"),
          );
        }
      }

      if (!getInitialValue("StreetAddress1")) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t("views.signUp.forms.AddressCollectionForm.errorMessages.address"),
        );
      }

      if (
        getInitialValue("StreetAddress1")?.length > 30 ||
        getInitialValue("StreetAddress2")?.length > 30
      ) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t("views.signUp.forms.AddressCollectionForm.errorMessages.tooLong"),
        );
      }

      if (!getInitialValue("City")) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t("views.signUp.forms.AddressCollectionForm.errorMessages.city"),
        );
      }

      return { disabledMessage, isDisabled };
    };

    return (
      <div className="slideInWrapper">
        <FormHeader
          headerText={t("views.signUp.forms.AddressCollectionForm.header")}
          subheaderText={t(
            "views.signUp.forms.AddressCollectionForm.subheader",
          )}
        />
        <Form layout="vertical" hideRequiredMark>
          {!isAddressValid().isValid && (
            <Alert
              message={isAddressValid().validationMessage}
              closable
              showIcon
              style={{ margin: "0 0 2em" }}
              type="error"
            />
          )}
          <AddressForm
            form={form}
            decoratorPrefix=""
            formKey={EOnboardingForms.ADDRESS_COLLECTION}
            isRequired
          />
          <FormButtonFooter
            goForwardHandler={(e) =>
              handleSubmit(
                e,
                OnboardingStore.isUserUSCitizen()
                  ? EOnboardingScreens.EMPLOYMENT_INFORMATION
                  : EOnboardingScreens.UPLOAD_ID,
              )
            }
            isGoForwardDisabled={isNextButtonDisabled().isDisabled}
            goForwardDisabledMessage={isNextButtonDisabled().disabledMessage}
            goBackHandler={() =>
              FormsStore.setCurrentScreen(EOnboardingScreens.ACCOUNT_CREATION)
            }
          />
        </Form>
      </div>
    );
  });

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: IAddressCollectionForm) => {
    OnboardingStore.updateFormData(
      changedFields,
      EOnboardingForms.ADDRESS_COLLECTION,
    );
  },
})(AddressCollectionForm);
