import { toJS } from "mobx";
import queryString from "query-string";
import { SeverityLevel } from "@microsoft/applicationinsights-web";

// ==== Stores ====
import AuthenticationStore from "stores/AuthenticationStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import FormsStore from "stores/FormsStore";

// ==== Types ====
import { ELocalStorageKeys } from "types/globalTypes";
import { EQueryStringValues } from "views/SignUp/SelfEnroll/types/selfEnrollTypes";
import { EOnboardingScreens } from "views/SignUp/Onboarding/types/onboardingTypes";

// ==== Utilities ====
import { ai } from "configuration/appInsights";

export const generateBaseUrl = (): string => {
  let untrimmedUrl = toJS(AuthenticationStore?.config?.torchUri);

  if (!untrimmedUrl) {
    FormsStore.setErrorHandler("generateBaseUrl", "No untrimmed url");

    // DEV MAGIC
    untrimmedUrl = "https://vulcan.dev.stonex.com/torchapi/";
  }

  // TODO - Need to remove this from the API
  return untrimmedUrl.replace("torchapi/", "");
};

export const setQueryStringsIntoState = (search: any): void => {
  // Small hack - If the user is making the return trip from the auth flow, we need to grab their email
  // so we can auto log them in and pre-pop their email.
  // This code only exists here because this all use to be in the url
  const storedEmail = localStorage.getItem(ELocalStorageKeys.USER_EMAIL);
  if (storedEmail) {
    AuthenticationStore.setAuthEmail(storedEmail);
    localStorage.removeItem(ELocalStorageKeys.USER_EMAIL);
  }

  // ==== PrivateLabelCode ====
  // Small cheat, sometimes privateLabelCode comes back as all lowercase and we are
  // checking for that here
  const privateLabelCode =
    getQueryString(search, "privatelabelcode") ||
    getQueryString(search, EQueryStringValues.PRIVATE_LABEL_CODE);

  if (privateLabelCode) {
    AuthenticationStore.setPrivateLabelCode(privateLabelCode);
  }

  // ==== AccountNumber ====
  // If they have an account pre-selected
  const urlAccountNumber = getQueryString(
    search,
    EQueryStringValues.ACCOUNT_NUMBER,
  );

  if (urlAccountNumber) {
    AuthenticationStore.setUrlAccountNumber(urlAccountNumber);
  }

  // ==== State ====
  const state = getQueryString(search, EQueryStringValues.STATE);

  if (state) {
    AuthenticationStore.setUrlState(state);
  }

  // ==== Email ====
  const email = getQueryString(search, EQueryStringValues.EMAIL);

  if (email) {
    AuthenticationStore.setAuthEmail(email);
  }

  // ==== Profile Id ====
  const profileId = getQueryString(search, EQueryStringValues.PROFILE_ID);

  if (profileId) {
    AuthenticationStore.setUrlProfileId(profileId);
  }

  // ==== Redirect Url ====
  const redirectUrl = getQueryString(search, EQueryStringValues.REDIRECT_URL);

  if (redirectUrl) {
    AuthenticationStore.setUrlRedirectUrl(redirectUrl);
  }

  // ==== Google Tag Manager Key ====
  const gtmKey = getQueryString(search, EQueryStringValues.GOOGLE_TAG_MANAGER);

  if (gtmKey) {
    AuthenticationStore.setGtmKey(gtmKey);
  }
};

export const getQueryString = (search: any, searchValue: string): string => {
  const values = queryString.parse(search);
  const foundQueryStringValue = values[searchValue];

  if (Array.isArray(foundQueryStringValue)) {
    return foundQueryStringValue[0];
  }

  return foundQueryStringValue;
};

// For all existing URL search params, add them to the url
export const generateUrlWithExistingQueryStrings = (
  baseUrl: string,
): string => {
  let urlParams = [];

  // Don't pass email to query string as it's PII info in the url
  if (AuthenticationStore.urlPrivateLabelCode) {
    urlParams.push({
      key: EQueryStringValues.PRIVATE_LABEL_CODE,
      value: AuthenticationStore.urlPrivateLabelCode,
    });
  }
  if (AuthenticationStore.urlState) {
    urlParams.push({
      key: EQueryStringValues.STATE,
      value: AuthenticationStore.urlState,
    });
  }
  if (AuthenticationStore.urlAccountNumber) {
    urlParams.push({
      key: EQueryStringValues.ACCOUNT_NUMBER,
      value: AuthenticationStore.urlAccountNumber,
    });
  }
  if (AuthenticationStore.urlRedirectUrl) {
    urlParams.push({
      key: EQueryStringValues.REDIRECT_URL,
      value: AuthenticationStore.urlRedirectUrl,
    });
  }

  let url = baseUrl;

  urlParams.forEach(({ key, value }, index) => {
    if (index === 0) {
      url = `${url}?${key}=${value}`;
    } else {
      url = `${url}&${key}=${value}`;
    }
  });

  return url;
};

export const injectDevToken = (): void => {
  const devTokenData = AuthenticationStore.getDevToken();

  if (devTokenData && !AuthenticationStore?.newUserCreationFormData?.email) {
    // Inject data into login form
    AuthenticationStore.updateLoginFormData({
      ConfirmEmail: devTokenData.email,
      Email: devTokenData.email,
    });

    // Inject data into New User Creation form
    AuthenticationStore.updateNewUserCreationFormData({
      confirmEmail: devTokenData.email,
      email: devTokenData.email,
      firstName: devTokenData.firstName,
      lastName: devTokenData.lastName,
    });

    OnboardingStore.setFormItemData(EOnboardingScreens.INDIVIDUAL, {
      ConfirmEmail: devTokenData.email,
      Email: devTokenData.email,
      FirstName: devTokenData.firstName,
      LastName: devTokenData.lastName,
    });
  }
};

export const debounce = (func: any, wait: number) => {
  let timeout: ReturnType<typeof setTimeout>;
  const returnFunction = (...args: any) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
  return returnFunction;
};

export const handleErrorTracking = (
  e: unknown,
  fileName: string,
  functionName: string,
): void => {
  ai?.appInsights?.trackException({
    error: new Error(`ERROR: ${fileName} ${functionName}`),
    properties: { apiResponse: e },
    severityLevel: SeverityLevel.Error,
  });
};
