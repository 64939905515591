import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Button, InputNumber } from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Components ====
import FormHeader from "components/FormHeader";

// ==== Store ====
import SelfEnrollStore from "views/SignUp/SelfEnroll/store/SelfEnrollStore";

// ==== Types ====
import { IPinValidationForm } from "views/SignUp/SelfEnroll/types/selfEnrollTypes";

const PinValidateForm: React.FunctionComponent<FormComponentProps> = observer(
  ({ form }) => {
    const { getFieldDecorator, validateFields } = form;
    const { t } = useTranslation();

    const handleSubmit = async (
      event: React.FormEvent<HTMLFormElement>,
    ): Promise<void> => {
      event.preventDefault();

      validateFields(async (err: string[]): Promise<void> => {
        if (!err) {
          await SelfEnrollStore.validatePin();
        }
      });
    };

    return (
      <div className="slideInWrapper">
        <FormHeader
          subheaderText={t("views.signUp.forms.PinValidateForm.perfect")}
        />
        <Form layout="vertical" onSubmit={handleSubmit}>
          <Form.Item label="PIN">
            {getFieldDecorator("pin", {
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.pin"),
                  }),
                  required: true,
                },
                {
                  len: 5,
                  message: t("forms.validation.exact", {
                    number: 5,
                  }),
                },
              ],
              validateTrigger: "onBlur",
            })(<InputNumber maxLength={5} placeholder="xxxxx" />)}
          </Form.Item>
          <Button
            disabled={SelfEnrollStore.isDisabled}
            htmlType="submit"
            type="primary"
          >
            {t("forms.buttons.next")}
          </Button>
        </Form>
      </div>
    );
  },
);

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: IPinValidationForm) => {
    SelfEnrollStore.updatePinValidationData(changedFields);
  },
})(PinValidateForm);
