import * as React from "react";
import { Button } from "antd";
import { useTranslation } from "react-i18next";

interface IProps {
  buttonText?: string;
  handleOnClick: () => void;
}

const HomeFooterButton: React.FunctionComponent<IProps> = ({
  buttonText,
  handleOnClick,
}) => {
  const { t } = useTranslation();
  return (
    <div className="homeFooterButtonWrapper">
      <Button
        className="homeFooterButton"
        onClick={handleOnClick}
        type="primary"
      >
        {buttonText
          ? buttonText
          : t("views.signUp.forms.SuccessScreen.homeButton")}
      </Button>
    </div>
  );
};

export default HomeFooterButton;
