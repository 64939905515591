import { ITelemetryItem } from "@microsoft/applicationinsights-web";

// ==== Stores ====
import FormsStore from "stores/FormsStore";
import ConfigurationStore from "stores/ConfigurationStore";
import AuthenticationStore from "stores/AuthenticationStore";

// ==== Apis ====
import ConfigurationApi from "apis/ConfigurationApi";

// ==== Utilities ====
import { ai } from "configuration/appInsights";
import { handleErrorTracking } from "utilities/apiUtilities";

const FILE_NAME = "ConfigurationApiHandler";

export const getCountryCodeData = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const response = await ConfigurationApi.getCountryCodeData(
      AuthenticationStore._tokenSource.token,
    );

    if (response) {
      ConfigurationStore.setCountryCodeData(response);
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "getCountryCodeData");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const getPhoneCountryCodeData = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const response = await ConfigurationApi.getPhoneCountryCodeData(
      AuthenticationStore._tokenSource.token,
    );

    if (response) {
      ConfigurationStore.setPhoneCountryCodeData(response);
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "getPhoneCountryCodeData");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const getDevToken = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const tokenResponse = await ConfigurationApi.getDevToken(
      AuthenticationStore._tokenSource.token,
    );

    if (tokenResponse?.data) {
      AuthenticationStore.setDevToken(tokenResponse.data);
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "getDevToken");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const getConfigData = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);
    const response = await ConfigurationApi.getConfig(
      AuthenticationStore._tokenSource.token,
    );

    if (response?.data) {
      const data = response.data;

      AuthenticationStore.setConfigData(data);
      AuthenticationStore.setPrivateLabelCode(data.defaultPrivateLabelCode);

      ai.initialize(response.data.instrumentationKey);
      ai.appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
        envelope.tags = envelope.tags || [];
        envelope.tags.push({ "ai.cloud.role": "LanderJS" });
      });
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "getConfigData");
  } finally {
    FormsStore.setLoading(false);
  }
};
