// Globally Scoped Types
// ==== Enums ====
export enum EPlatforms {
  StoneXClient = "StoneX Client",
  StoneXPro = "Vulcan Pro",
  StoneXOne = "StoneX One",
}

export enum EApplicationThemes {
  LightMode = "lightMode",
  DarkMode = "darkMode",
}

export enum ERoutePaths {
  // ==== Global ====
  BASE = "/l",
  CONTACT = "/l/contact",
  SIGNUP = "/l/signup",
  // ==== Migration ====
  REGISTER = "/l/register",
  MIGRATION_WEALTH_ACCESS = "/l/migration/wealthaccess/:id/queue",
  INVESTOR_RELATIONS = "/l/investor-relations",
  // ==== SelfEnroll ====
  SELF_ENROLL = "/l/signup/selfenroll",
  // ==== Onboarding ====
  ONBOARDING = "/l/signup/onboarding",
  MARGIN = "/l/margin",
  OPTIONS = "/l/options",
}

export enum ELocalStorageKeys {
  GTM_KEY = "gtmKey",
  USER_EMAIL = "userEmail",
}

export enum EHttpMethods {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}

export enum FEATURE_FLAGS {
  MAXIT = "ff-maxit",
  FOREIGN_ACCOUNT = "ff-foreignAccount",
}

// ==== Interfaces ====
export interface IConfig {
  authRedirect: string;
  clientUri: string;
  defaultPrivateLabelCode: string;
  featureFlags: {
    defaultPrivateLabelCode: string;
    "ff-foreignAccount": boolean;
    "ff-maxit": boolean;
  };
  instrumentationKey: string;
  oneStonexUri: string;
  proUri: string;
  torchUri: string;
}

export interface IPrivateLabelSetting {
  blsUserId: string;
  id: string;
  privateLabelCode: string;
  repCode: string;
  returnLink: string;
  subNumber: string;
}

export interface IFormData {
  [field: string]: any;
}

export interface IPhoneNumber {
  countryCode: string;
  phone: string;
}

export interface IRequestPreview {
  id: string;
  metadataModel: {
    migrationValue: string;
    pin: null;
    timestamp: Date;
    userId: string;
    userInfo: {
      accounts: {
        close: IRequestPreviewAccounts[];
        open: IRequestPreviewAccounts[];
      };
      email: string;
      firstName: string;
      lastName: string;
      phoneNumber: IPhoneNumber;
      userId: string;
    };
  };
  metadataName: string;
}

interface IRequestPreviewAccounts {
  acctNo: string;
  disabled: boolean;
  entitlement: "PRIMARY" | "SECONDARY" | null;
  isSubscriptionAccount: boolean;
  name: string | null;
}

export interface ICountryCode {
  code: string;
  name: string;
}

export interface IPhoneCountryCode {
  countryCode: string;
  countryName: string;
}
