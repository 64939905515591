import * as React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Components ====
import FormHeader from "components/FormHeader";
import { renderFeaturesByAccountType } from "views/SignUp/Onboarding/forms/SuccessContainer/SuccessContainer";

const ApplicationStatusForm: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();

  return (
    <div className="slideInWrapper">
      <FormHeader
        headerText={t("views.margin.applicationSubmitted")}
        subheaderText={t("views.margin.applicationSubmittedSubheader")}
      />
      {renderFeaturesByAccountType(OnboardingStore.getAccountType())}
    </div>
  );
});

export default ApplicationStatusForm;
