import "core-js";
import * as React from "react";
import * as ReactDOM from "react-dom";
import { Col, Row, Spin } from "antd";

import "configuration/mobxConfigure";
import "configuration/i18n";
import App from "App";

ReactDOM.render(
  <React.Suspense
    fallback={
      <Row align="middle" justify="center" style={{ minHeight: "100vh" }}>
        <Col>
          <Spin />
        </Col>
      </Row>
    }
  >
    <App />
  </React.Suspense>,
  document.getElementById("root"),
);
