import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Button, Radio } from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Store ====
import SelfEnrollStore from "views/SignUp/SelfEnroll/store/SelfEnrollStore";
import AuthenticationStore from "stores/AuthenticationStore";

// ==== Types ====
import { IConfirmationScreenForm } from "views/SignUp/SelfEnroll/types/selfEnrollTypes";

const ConfirmationScreenForm: React.FunctionComponent<FormComponentProps> =
  observer(({ form }) => {
    const { getFieldDecorator, validateFields } = form;
    const { t } = useTranslation();

    const handleSubmit = async (
      event: React.MouseEvent<HTMLElement, MouseEvent>,
    ): Promise<void> => {
      event.preventDefault();

      validateFields(async (err: string[]): Promise<void> => {
        if (!err) {
          await SelfEnrollStore.handleConfirmationScreenFormSubmit();
        }
      });
    };

    return (
      <div className="slideInWrapper">
        {SelfEnrollStore.isAccountSubmitted ? (
          t("views.signUp.forms.ConfirmationScreenForm.success")
        ) : (
          <Form layout="vertical">
            <Form.Item
              label={t(
                "views.signUp.forms.ConfirmationScreenForm.pleaseConfirm",
              )}
            >
              {getFieldDecorator("isUserSure", {
                rules: [
                  {
                    message: t("forms.validation.isRequired", {
                      fieldName: t("forms.fields.field"),
                    }),
                    required: true,
                  },
                ],
              })(
                <Radio.Group>
                  <Radio style={{ display: "block" }} value={true}>{`${t(
                    "views.signUp.forms.ConfirmationScreenForm.yesAdd",
                  )} ${
                    SelfEnrollStore?.validateAccountData?.accountNumber
                  }`}</Radio>
                  <Radio style={{ display: "block" }} value={false}>
                    {t("views.signUp.forms.ConfirmationScreenForm.noExit")}
                  </Radio>
                </Radio.Group>,
              )}
            </Form.Item>
            <Button
              disabled={SelfEnrollStore.isDisabled}
              onClick={handleSubmit}
              htmlType="submit"
              type="primary"
            >
              {t("forms.buttons.submit")}
            </Button>
          </Form>
        )}
        <Button
          disabled={!AuthenticationStore.config.clientUri}
          onClick={() => {
            SelfEnrollStore.handleFormReset();
            window.location.assign(AuthenticationStore.config.clientUri);
          }}
          type="link"
        >
          {t("views.signUp.forms.ConfirmationScreenForm.goBack")}
        </Button>
      </div>
    );
  });

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: IConfirmationScreenForm) => {
    SelfEnrollStore.updateConfirmationScreenData(changedFields);
  },
})(ConfirmationScreenForm);
