import * as React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Types ====
import { EOnboardingScreens } from "views/SignUp/Onboarding/types/onboardingTypes";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Components ====
import SummaryFieldBlock from "components/SummaryFieldBlock";
import SummaryHeader from "views/SignUp/Onboarding/forms/SummaryPage/SummaryHeader";

interface IProps {
  isEditButtonDisabled?: boolean;
}

const IndividualForm: React.FunctionComponent<IProps> = observer(
  ({ isEditButtonDisabled }) => {
    const { t } = useTranslation();

    const getFormData = (key: string) =>
      OnboardingStore.getFormItemData(EOnboardingScreens.INDIVIDUAL, key) || "";

    const renderSummaryData = () => {
      const firstName = getFormData("FirstName");
      const middleName = getFormData("MiddleName");
      const lastName = getFormData("LastName");
      const suffix = getFormData("Suffix");

      const formattedName = `${firstName} ${
        middleName ? middleName + " " : ""
      } ${lastName}${suffix ? `, ${suffix} ` : ""}`;

      const formattedPhone = `+${getFormData("CountryCode")} ${getFormData(
        "Phone",
      )}`;

      const formattedDOB = `${getFormData("DobYear")}/${getFormData(
        "DobMonth",
      )}/${getFormData("DobDay")} `;

      return (
        <React.Fragment>
          <SummaryHeader
            header={t("views.signUp.components.summery.accountInfo")}
            editButtonKey={
              !isEditButtonDisabled && EOnboardingScreens.INDIVIDUAL
            }
          />
          <SummaryFieldBlock
            question={`${t("forms.fields.name")}:`}
            answer={formattedName}
          />
          <SummaryFieldBlock
            question={`${t("forms.fields.email")}:`}
            answer={getFormData("Email")}
          />
          <SummaryFieldBlock
            question={`${t("forms.fields.phoneNumber")}:`}
            answer={formattedPhone}
          />
          <SummaryFieldBlock
            question={`${t("forms.fields.dateOfBirth")}:`}
            answer={formattedDOB}
          />
        </React.Fragment>
      );
    };

    return <React.Fragment>{renderSummaryData()}</React.Fragment>;
  },
);

export default IndividualForm;
