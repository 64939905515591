import * as React from "react";
import { Form, Checkbox, Tooltip } from "antd";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import FormsStore from "stores/FormsStore";

// ==== Types ====
import {
  EOnboardingForms,
  EOnboardingScreens,
  E_CAIS_Types,
} from "views/SignUp/Onboarding/types/onboardingTypes";

// ==== Components ====
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";
import FormHeader from "components/FormHeader";

const CAISForm: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
    nextScreenKey: string,
  ): Promise<void> => {
    event.preventDefault();

    await OnboardingStore.validateCaisInfo(nextScreenKey);
  };

  const isNextButtonDisabled = (): {
    disabledMessage: string;
    isDisabled: boolean;
  } => {
    let rawFormData = OnboardingStore.formData[EOnboardingForms.CAIS];
    let disabledMessage = undefined;
    let isDisabled = false;

    // Removing the api profile ID from our form data
    const formData = Object.fromEntries(
      Object.entries(rawFormData).filter(([key, value]) => {
        if (key !== "id") return value;
      }),
    );

    // Filtering form data for just selected (true) checkboxes
    let selectedKeys = Object.fromEntries(
      Object.entries(formData).filter(([key, value]) => {
        if (value) return key;
      }),
    );

    // Throw error if we don't have any selected checkboxes
    if (Object.keys(selectedKeys).length === 0) {
      disabledMessage = t(
        "views.signUp.forms.CAISForm.errorMessages.mustSelect",
      );
      isDisabled = true;
    }

    // If user selects Not Applicable, nothing else can be selected
    if (formData.notApplicable) {
      for (const [key, value] of Object.entries(formData)) {
        if (value && key !== E_CAIS_Types.NotApplicable) {
          disabledMessage = t(
            "views.signUp.forms.CAISForm.errorMessages.notApplicable",
          );
          isDisabled = true;
        }
      }
    }

    return {
      disabledMessage,
      isDisabled,
    };
  };

  const setFormValue = (key: string, value: boolean) => {
    OnboardingStore.setFormItemData(EOnboardingForms.CAIS, {
      [key]: value,
    });
  };

  const getInitialValue = (key: string) =>
    OnboardingStore.getFormItemData(EOnboardingForms.CAIS, key);

  return (
    <div className="slideInWrapper caisForm">
      <FormHeader
        headerText={t("views.signUp.forms.CAISForm.headers.header")}
        subheaderText={`${t("views.signUp.forms.CAISForm.headers.subheader")}:`}
      />
      <Form layout="vertical" hideRequiredMark>
        <div>
          <Checkbox
            checked={getInitialValue(E_CAIS_Types.OtherBroker)}
            onChange={(value: CheckboxChangeEvent) =>
              setFormValue(E_CAIS_Types.OtherBroker, value.target.checked)
            }
          >
            {`${t("views.signUp.forms.CAISForm.questions.otherBroker1")} `}
            <strong>
              <Tooltip
                color="black"
                title={t("views.signUp.forms.CAISForm.tooltips.otherBroker1")}
              >
                {`${t("views.signUp.forms.CAISForm.questions.otherBroker2")} `}
              </Tooltip>
              <Tooltip
                color="black"
                title={t("views.signUp.forms.CAISForm.tooltips.otherBroker1")}
              >
                {t("views.signUp.forms.CAISForm.questions.otherBroker3")}
              </Tooltip>
            </strong>
            .
          </Checkbox>
          <div className="checkboxRow">
            <Checkbox
              checked={getInitialValue(E_CAIS_Types.Accredited)}
              onChange={(value: CheckboxChangeEvent) =>
                setFormValue(E_CAIS_Types.Accredited, value.target.checked)
              }
            >
              {`${t("views.signUp.forms.CAISForm.questions.accredited1")} `}
              <strong>
                <Tooltip
                  color="black"
                  title={t("views.signUp.forms.CAISForm.tooltips.accredited1")}
                >
                  {`${t("views.signUp.forms.CAISForm.questions.accredited2")} `}
                </Tooltip>
              </strong>
              {`${t("views.signUp.forms.CAISForm.questions.accredited3")}.`}
            </Checkbox>
          </div>
          <div className="checkboxRow">
            <Checkbox
              checked={getInitialValue(E_CAIS_Types.Advisor)}
              onChange={(value: CheckboxChangeEvent) =>
                setFormValue(E_CAIS_Types.Advisor, value.target.checked)
              }
            >
              {`${t("views.signUp.forms.CAISForm.questions.advisor1")} `}
              <strong>
                <Tooltip
                  color="black"
                  title={t("views.signUp.forms.CAISForm.tooltips.advisor1")}
                >
                  {`${t("views.signUp.forms.CAISForm.questions.advisor2")}`}
                </Tooltip>
              </strong>
              .
            </Checkbox>
          </div>
          <div className="checkboxRow">
            <Checkbox
              checked={getInitialValue(E_CAIS_Types.Cp)}
              onChange={(value: CheckboxChangeEvent) =>
                setFormValue(E_CAIS_Types.Cp, value.target.checked)
              }
            >
              {t("views.signUp.forms.CAISForm.questions.cp1")}
            </Checkbox>
          </div>
          <div className="checkboxRow">
            <Checkbox
              checked={getInitialValue(E_CAIS_Types.Employee)}
              onChange={(value: CheckboxChangeEvent) =>
                setFormValue(E_CAIS_Types.Employee, value.target.checked)
              }
            >
              {`${t("views.signUp.forms.CAISForm.questions.employee1")} `}
              <strong>
                <Tooltip
                  color="black"
                  title={t("views.signUp.forms.CAISForm.tooltips.employee1")}
                >
                  {`${t("views.signUp.forms.CAISForm.questions.employee2")} `}
                </Tooltip>
              </strong>
              {` ${t("views.signUp.forms.CAISForm.questions.employee3")}.`}
            </Checkbox>
          </div>
          <div className="checkboxRow">
            <Checkbox
              checked={getInitialValue(E_CAIS_Types.Foreign)}
              onChange={(value: CheckboxChangeEvent) =>
                setFormValue(E_CAIS_Types.Foreign, value.target.checked)
              }
            >
              {`${t("views.signUp.forms.CAISForm.questions.foreign1")} `}
              <strong>
                <Tooltip
                  color="black"
                  title={t("views.signUp.forms.CAISForm.tooltips.foreign1")}
                >
                  {`${t("views.signUp.forms.CAISForm.questions.foreign2")} `}
                </Tooltip>
              </strong>
              {`${t("views.signUp.forms.CAISForm.questions.foreign3")} `}
              <strong>
                <Tooltip
                  color="black"
                  title={t("views.signUp.forms.CAISForm.tooltips.foreign2")}
                >
                  {`${t("views.signUp.forms.CAISForm.questions.foreign4")} `}
                </Tooltip>
              </strong>
              {`${t("views.signUp.forms.CAISForm.questions.foreign5")}.`}
            </Checkbox>
          </div>
          <div className="checkboxRow">
            <Checkbox
              checked={getInitialValue(E_CAIS_Types.NotApplicable)}
              onChange={(value: CheckboxChangeEvent) =>
                setFormValue(E_CAIS_Types.NotApplicable, value.target.checked)
              }
            >
              {t("views.signUp.forms.CAISForm.questions.notApplicable")}
            </Checkbox>
          </div>
        </div>
        <FormButtonFooter
          goForwardHandler={(e) =>
            handleSubmit(e, EOnboardingScreens.STOCK_EXCHANGE_ASSOCIATION)
          }
          isGoForwardDisabled={isNextButtonDisabled().isDisabled}
          goForwardDisabledMessage={isNextButtonDisabled().disabledMessage}
          goBackHandler={() =>
            FormsStore.setCurrentScreen(EOnboardingScreens.STONEX_AFFILIATE)
          }
        />
      </Form>
    </div>
  );
});

export default CAISForm;
