import * as React from "react";
import { observer } from "mobx-react";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";

// ==== Types ====
import { ERoutePaths, EApplicationThemes } from "types/globalTypes";

// ==== Components ====
import Spinner from "components/Spinner";
import Routes from "Routes";

// ==== Stores ====
import AuthenticationStore from "stores/AuthenticationStore";
import FormsStore from "stores/FormsStore";
import ConfigurationStore from "stores/ConfigurationStore";

// TODO Remove once v3 of Ant is removed
import "@ant-design/compatible/assets/index.css";
import "assets/index.css";

const App: React.FunctionComponent = observer(() => {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const { t } = useTranslation();

  // https://javascript.plainenglish.io/how-to-create-and-read-a-value-from-a-cookie-with-javascript-18a9e5cdc301
  const getThemeCookie = (): EApplicationThemes => {
    const themeCookie = document.cookie.split("; ").reduce((r, v) => {
      const parts = v.split("=");
      return parts[0] === "theme" ? decodeURIComponent(parts[1]) : r;
    }, "");

    // This is unnecessary but the things we do to keep TypeScript happy 🤖
    switch (themeCookie) {
      case EApplicationThemes.LightMode:
        return EApplicationThemes.LightMode;
      case EApplicationThemes.DarkMode:
        return EApplicationThemes.DarkMode;
    }
  };

  const handleThemePreload = () => {
    // default to light mode... for now
    let theme: EApplicationThemes = EApplicationThemes.LightMode;

    // First, check user's browsers settings for any default preferences
    if (window.matchMedia) {
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        //
        theme = EApplicationThemes.DarkMode;
      } else if (window.matchMedia("(prefers-color-scheme: light)").matches) {
        theme = EApplicationThemes.LightMode;
      }
    }

    // Second, check user's cookies
    const themeCookie = getThemeCookie();
    if (themeCookie) {
      theme = themeCookie;
    }

    ConfigurationStore.setApplicationTheme(theme);
  };

  React.useEffect(() => {
    const initialize = async (): Promise<void> => {
      // Preloading Dev Token
      await AuthenticationStore.handleGetToken();

      // Preload Static Data
      await ConfigurationStore.getConfigData();
      await ConfigurationStore.getCountryCodeData();
      await ConfigurationStore.getPhoneCountryCodeData();

      setIsLoading(false);
    };
    initialize();

    // Preload Theming
    if (ConfigurationStore.isDarkModeEnabled) {
      handleThemePreload();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleInactivityReset = () => {
    FormsStore.resetInactivityTimer();
  };

  // When the alert is rendered, kick off a second timer
  const startLogoutTimer = () => {
    setTimeout(() => {
      // Check to make sure user is still inactive before reloading the app
      if (FormsStore.isUserInactive) {
        // Clearing Auth Token Locally
        AuthenticationStore.accessToken = undefined;
        // Pushing user to /signout to clear cookies/ token
        window.location.replace(
          `${AuthenticationStore.config.authRedirect}l/signout`,
        );
      }
    }, 60000); // 1 minute
  };

  const shouldRenderTimeoutAlert = (): boolean => {
    const url = window.location.href;

    // This alert should only render if the user is in an auth'd area AND they are inactive
    if (FormsStore.isUserInactive) {
      if (url.includes(ERoutePaths.ONBOARDING)) {
        return true;
      } else if (url.includes(ERoutePaths.MARGIN)) {
        return true;
      } else if (url.includes(ERoutePaths.OPTIONS)) {
        return true;
      }
    }

    return false;
  };

  return isLoading ? (
    <Spinner />
  ) : (
    <React.Fragment>
      {shouldRenderTimeoutAlert() && (
        <div className="centeredContent">
          <Alert
            className="timeoutAlert"
            message={
              <div>
                {`${t("inactiveTimer.youAreAboutToBe")} `}
                <a onClick={handleInactivityReset}>{`${t(
                  "inactiveTimer.here",
                )}`}</a>
                {` ${t("inactiveTimer.toStayLoggedIn")}`}
                {startLogoutTimer()}
              </div>
            }
            closable
            showIcon
            type="warning"
          />
        </div>
      )}
      <Routes config={AuthenticationStore.config} />
    </React.Fragment>
  );
});

export default App;
