import * as React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// ==== Components ====
import FormHeader from "components/FormHeader";
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";

// ==== Store ====
import AuthenticationStore from "stores/AuthenticationStore";
import FormsStore from "stores/FormsStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Types ====
import { EOptionScreens } from "views/Options/types/optionsTypes";
import { ERoutePaths } from "types/globalTypes";

const OptionRow: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleGoForward = () => {
    FormsStore.setCurrentScreen(EOptionScreens.LANDING);
    history.push(`${ERoutePaths.OPTIONS}/${EOptionScreens.LANDING}`);
  };

  // If the user already has an options agreement, don't render anything
  if (OnboardingStore.getAccountFeatures().options) {
    return null;
  }

  return (
    <div style={{ marginBottom: 40 }}>
      <FormHeader
        headerText={t("views.signUp.forms.SuccessScreen.headerOptions")}
      />
      <div style={{ paddingBottom: 20 }}>
        {t("views.signUp.forms.SuccessScreen.optionsBody")}
      </div>
      <div style={{ paddingBottom: 20 }}>
        {t("views.signUp.forms.SuccessScreen.optionsQuestion")}
      </div>
      <div style={{ display: "flex", justifyContent: "end", marginTop: 25 }}>
        <FormButtonFooter
          isGoForwardDisabled={!AuthenticationStore.config.clientUri}
          goForwardHandler={handleGoForward}
          goForwardButtonText={t(
            "views.signUp.forms.SuccessScreen.optionsButton",
          )}
        />
      </div>
    </div>
  );
};

export default OptionRow;
