import { IPhoneNumber } from "types/globalTypes";

// ==== Enums ====
export enum EAccountType {
  NOT_ELIGIBLE = "NotEligible",
  FOREIGN_INDIVIDUAL = "ForeignIndividual",
  FOREIGN_NON_INDIVIDUAL = "ForeignNonIndividual",
  CORPORATE = "Corporate",
  INDIVIDUAL = "Individual",
}

export enum EQueryStringValues {
  PRIVATE_LABEL_CODE = "privateLabelCode",
  ACCOUNT_NUMBER = "accountNumber",
  STATE = "state",
  EMAIL = "email",
  PROFILE_ID = "profileId",
  REDIRECT_URL = "redirectUrl",
  GOOGLE_TAG_MANAGER = "custom_ga",
}

export enum EIdTypes {
  SSN = "SSN",
  US_TAX_ID = "USTaxID",
  PASSPORT = "Passport",
  GOV = "Govt",
  ALIEN = "AlienID",
}

export enum EUserTypeStatus {
  EXISTING_USER = "ExistingUser",
  NEW_USER = "NewUser",
  ERROR = "Error",
  ERROR_EMAIL_NO_TAX_INFO = "ErrorEmailNoTaxInfo",
  ERROR_EMAIL_NO_MATCH_TAX_INFO = "ErrorEmailNoMatchTaxInfo",
  ERROR_PIN_NO_MATCH = "ErrorPinNoMatch",
  ERROR_PIN_NOT_SET = "ErrorPinNotSet",
}

export enum EAccountInfoStatus {
  VALID = "Valid",
  VALID_FOREIGN_INDIVIDUAL = "ValidForeignIndividual",
  VALID_FOREIGN_NON_INDIVIDUAL = "ValidForeignNonIndividual",
  VALID_CORPORATE = "ValidCorporate",
  VALID_INDIVIDUAL = "ValidIndividual",
  ERROR = "Error",
  ERROR_FOREIGN_INDIVIDUAL = "ErrorForeignIndividual",
  ERROR_FOREIGN_NON_INDIVIDUAL = "ErrorForeignNonIndividual",
  ERROR_CORPORATE = "ErrorCorporate",
  ERROR_INDIVIDUAL = "ErrorIndividual",
}

export enum ESelfEnrollForms {
  ACCOUNT_NUMBER_FORM = "AccountNumberForm",
  ACCOUNT_INFORMATION_FORM = "AccountInformationForm",
  EMAIL_VALIDATION_FORM = "EmailValidationForm",
  CONFIRMATION_SCREEN_FORM = "ConfirmationScreenForm",
  PIN_VALIDATION_FORM = "PinValidationForm",
  NEW_ACCOUNT_SUCCESS_SCREEN = "NewAccountSuccessScreen",
  NEW_ACCOUNT_FORM = "NewAccountForm",
}

// === API Interfaces ===
export interface IValidateUser {
  AcctNo: string;
  CorporateOrTrustId?: string;
  DateOfBirth: string;
  Email?: string;
  Pin?: string;
  TaxId: string;
  TaxType: string;
}

export interface IValidateAccountInfo {
  AcctNo: string;
  CorporateOrTrustId?: string;
  DateOfBirth: string;
  TaxId: string;
  TaxType: string;
}

export interface ICreateUserAccount {
  AcctNo: string;
  CorporateOrTrustId: string;
  DateOfBirth: string;
  Email: string;
  Pin: string;
  TaxId: string;
  TaxType: string;
}

export interface ICreateNewUserAccount {
  AcctNo: string;
  CorporateOrTrustId: string;
  DateOfBirth: string;
  Email: string;
  FirstName: string;
  LastName: string;
  PhoneNumber: {
    CountryCode: string;
    Phone: string;
  };
  TaxId: string;
  TaxType: string;
}

// The API only returns the values that exist, so these are all nullable
export interface IUserExistsResponse {
  corporateId?: string;
  dateOfBirth?: string;
  disabled?: true;
  email?: string;
  existingUser?: boolean;
  firstName?: string;
  hashedPassword?: string;
  id?: string;
  isVerified?: true;
  lastName?: string;
  locked?: true;
  phoneNumber?: IPhoneNumber;
  pin?: string;
  sourceType?: string;
  taxId?: string;
  taxType?: string;
}

// === Form Interfaces ===
export interface IAccountInformationForm {
  CorporateOrTrustId: string;
  DobDay: string;
  DobMonth: string;
  DobYear: string;
  Pin?: string;
  TaxId: string;
  TaxType: string;
}

export interface IEmailValidationForm {
  ConfirmEmail: string;
  Email: string;
}

export interface INewProfileForm {
  ConfirmCountryCode: string;
  ConfirmPhone: string;
  CountryCode: string;
  Email: string;
  FirstName: string;
  LastName: string;
  Phone: string;
}

export interface IPinValidationForm {
  pin: string;
}

export interface IAccountNumberForm {
  accountNumber: string;
}

export interface IConfirmationScreenForm {
  isUserSure: boolean;
}
