import * as React from "react";
import { useTranslation } from "react-i18next";

const NewAccountDisclosure: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="centeredContent">
      <p className="meta">{t("components.newAccountDisclosure.legalese")}</p>
    </div>
  );
};

export default NewAccountDisclosure;
