// ==== Data ====
import COLORS from "assets/styles/config/colors.json";

export const markLabelStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  marginLeft: "15px",
};

export const sliderStyle: React.CSSProperties = {
  backgroundColor: COLORS.utilities.successGreen,
  border: `2px solid ${COLORS.utilities.successGreen}`,
};
