import { makeAutoObservable } from "mobx";
import { History } from "history";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Types ====
import { EAuthenticationScreens } from "types/authenticationTypes";
import { ERoutePaths } from "types/globalTypes";
import { EOnboardingForms } from "views/SignUp/Onboarding/types/onboardingTypes";

// ==== Utilities ====
import { getUrlPathByScreenKey } from "utilities/onboardingUtilities";
import { pushToDataLayer } from "utilities/googleTagsUtilities";
import { ai, APP_INSIGHTS_KEYS } from "configuration/appInsights";

class FormsStore {
  // ==== Global Form Variables ====
  // Used control the loading spinner
  isLoading: boolean = false;
  // Magic bad override loading spinner during the init app auth flow
  isInitialAuthLoading: boolean = false;

  // The magic string used to render the current form
  currentScreen: string = undefined;
  // Handles rendering error messages to the user
  errorHandler: {
    message: string;
    source: string;
  } = {
    message: undefined,
    source: undefined,
  };
  // Used for info messages on the /signup page
  infoSignUpMessage: string = undefined;

  // Used for when the user does a cold launch on the /margin /options workflows
  initRedirectScreen: string = "";

  // ==== Inactivity Timer ====
  // The ID of the timer is stored
  inactivityTimer: NodeJS.Timeout = undefined;
  isUserInactive: boolean = false;

  constructor() {
    makeAutoObservable(this);

    this.startInactivityTimer();
  }

  resetErrorHandler = (): void => {
    this.errorHandler = {
      message: undefined,
      source: undefined,
    };
  };

  startInactivityTimer = (): void => {
    // Start a timer instance and store the ID in the state
    this.inactivityTimer = setTimeout(() => {
      this.isUserInactive = true;
    }, 3600000); // one hour
  };

  resetInactivityTimer = (): void => {
    this.isUserInactive = false;

    // On user action, stop and restart the timer
    window.clearTimeout(this.inactivityTimer);
    this.startInactivityTimer();
  };

  // === Getters/ Setters ===
  setLoading = (loading: boolean) => {
    this.isLoading = loading;
  };

  setInitAuthLoading = (loading: boolean) => {
    this.isInitialAuthLoading = loading;
  };

  setErrorHandler = (source: string, message?: string) => {
    // Throwing an error into the void
    ai.customEvent(
      `${APP_INSIGHTS_KEYS.ERROR_HANDLER}${source}_${message}_email:${
        OnboardingStore?.formData[EOnboardingForms.INDIVIDUAL]?.Email
      }`,
    );

    this.errorHandler = {
      message,
      source,
    };
  };

  setInfoSignUpMessage = (infoSignUpMessage: string) => {
    this.infoSignUpMessage = infoSignUpMessage;
  };

  setCurrentScreen = (formKey: string) => {
    // Used for StoneX One's Google Tag analytical tracker
    pushToDataLayer(formKey);

    // Update URL Code
    if (!window.location.href.includes(formKey)) {
      const baseUrl = window.location.origin;
      const urlPath = getUrlPathByScreenKey(formKey);

      if (urlPath) {
        window.history.pushState({}, "", `${baseUrl}${urlPath}/${formKey}`);
      }
    }

    this.currentScreen = formKey;

    // If the user changes screens, reset the inactivity timer
    this.resetInactivityTimer();
  };

  // Only updates the current screen if there are NOT any errors
  setGuardedCurrentScreen = (formKey: string) => {
    if (!this.errorHandler.source && !this.errorHandler.message) {
      this.setCurrentScreen(formKey);
    }
  };

  handleSignUpRedirect = (history: History, redirectMessage: string) => {
    if (OnboardingStore.profile?.accountOpenings?.length === 0) {
      this.setCurrentScreen(EAuthenticationScreens.EMAIL_VALIDATION);
      this.setInfoSignUpMessage(redirectMessage);
      history.push(ERoutePaths.SIGNUP);
    }
  };
}

export default new FormsStore();
