import { makeAutoObservable } from "mobx";

// ==== ApiHandlers ====
import {
  getCountryCodeData,
  getPhoneCountryCodeData,
  getConfigData,
} from "apiHandlers/ConfigurationApiHandler";

// ==== Types ====
import {
  ICountryCode,
  IPhoneCountryCode,
  EApplicationThemes,
} from "types/globalTypes";

// ==== Utilities ====
import { isApplicationRunningLocally } from "utilities/genericUtilities";

class ConfigurationStore {
  // ==== Feature Flags ====
  // darkMode
  isDarkModeEnabled: boolean = isApplicationRunningLocally();

  // ==== Theming ====
  // The global theme attached to the <body> tag
  applicationTheme: EApplicationThemes = EApplicationThemes.LightMode;

  // ==== Application Data Pulled From API ====
  countryCodes: ICountryCode[] = undefined;
  phoneCountryCodes: IPhoneCountryCode[] = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  // === Getters ===
  getCountryCodeData = async (): Promise<void> => {
    if (!this.countryCodes) {
      await getCountryCodeData();
    }
  };

  getCountryWithCountryCode = (countryCode: string): string => {
    if (this.countryCodes) {
      let foundCountryName = undefined;

      this.countryCodes.forEach(({ name, code }) => {
        if (code === countryCode) {
          foundCountryName = name;
        }
      });
      return foundCountryName;
    }
  };

  getPhoneCountryCodeData = async (): Promise<void> => {
    if (!this.phoneCountryCodes) {
      await getPhoneCountryCodeData();
    }
  };

  getConfigData = async (): Promise<void> => {
    await getConfigData();
  };

  // === Setters ===
  setCountryCodeData = (countryCodes: ICountryCode[]): void => {
    // Getting the backend to do anything is truly sisyphean task
    // Moving 🦅 USA 🦅 to the top
    const usaIndex = countryCodes.findIndex(({ code }) => code == "US");
    const usa = countryCodes[usaIndex];

    countryCodes.splice(usaIndex, 1);
    countryCodes.unshift(usa);

    this.countryCodes = countryCodes;
  };

  setPhoneCountryCodeData = async (
    phoneCountryCodes: IPhoneCountryCode[],
  ): Promise<void> => {
    this.phoneCountryCodes = phoneCountryCodes;
  };

  setApplicationTheme = (theme: EApplicationThemes) => {
    // Remove old application theme
    document.body.classList.remove(this.applicationTheme);

    // Store new theme
    this.applicationTheme = theme;

    // Set new theme
    document.body.classList.add(theme);
  };
}

export default new ConfigurationStore();
