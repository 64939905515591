// ==== Enums ====
export enum EOptionsForms {
  FINANCIAL_PROFILE = "FinancialProfile",
  TRADING_EXPERIENCE = "TradingExperience",
  OPTION_INVESTMENT_OBJECTIVES = "OptionInvestmentObjectives",
  DOCUMENT_REVIEW = "DocumentReview",
}

export const EOptionScreens = {
  ...EOptionsForms,
  LANDING: "landing",
  SUCCESS: "Success",
  SUMMARY: "Summary",
};

export interface IMoneyOption {
  key: string;
  translationKey: string;
  value: number | string;
}

export interface IMoneyOptionsType {
  [index: string]: IMoneyOption;
}

export interface IOptionsTradingLevelType {
  [index: string]: {
    childText: string[];
    headerText: string;
    marginRequired: boolean;
    value: number;
  };
}

// ==== Forms ====
export interface IOptionsFormData
  extends IFinancialProfile,
    IOptionInvestmentObjectives,
    IReviewDocuments,
    ITradingExperience {}

export interface IFinancialProfile {
  annualIncome: string;
  netWorth: string;
}

export interface ITradingExperience {
  alternativeInvestments: number;
  annuities: number;
  mutualFunds: number;
  options: number;
  stockAndBonds: number;
}

export interface IOptionInvestmentObjectives {
  investmentObjectives: number;
  level: number;
}

export interface IReviewDocuments {
  doesUserAgree: boolean;
}
