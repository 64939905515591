import { TFunction } from "i18next";

// ==== Stores ====
import AuthenticationStore from "stores/AuthenticationStore";

// ==== Utilities ====
import { concatenateSentences, cleanString } from "utilities/genericUtilities";

// ==== V3 Validators ====
export const emailFormValidator = (
  // Field specific data
  value: string,
  comparisonFieldValue: string,
  // Ant's callback functions
  t: TFunction,
  callback: any,
  validateFields: any,
  isFieldsTouched: any,
  isFieldTouched: any,
  // Field Keys
  emailKey: string,
  emailConfirmKey: string,
) => {
  // I'm sure there is a better way to do this but here we are...
  if (!isFieldsTouched([emailKey, emailConfirmKey])) {
    callback();
  }

  if (isFieldsTouched([emailKey, emailConfirmKey])) {
    // Ignore validation if the user has not entered anything yet
    if (!comparisonFieldValue?.trim() && !value?.trim()) {
      callback();
    } else {
      let errors = undefined;

      // Make sure email and confirmEmails match
      if (cleanString(comparisonFieldValue) !== cleanString(value)) {
        errors = t("forms.validation.match", {
          fieldName: t(`forms.fields.email`),
        });
      }

      //https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
      const regexMagic =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const input = cleanString(value);
      // Only validate format if input exists
      if (input && !regexMagic.test(String(input))) {
        if (errors) {
          errors = `${errors} ${t(`forms.validation.emailFormat`)}`;
        } else {
          errors = `${t(`forms.validation.emailFormat`)}`;
        }
      }

      callback(errors);
      validateFields([emailKey, emailConfirmKey]);
    }
  }
};

const MIN_PASSWORD_LENGTH = 14;
export const passwordValidator = (
  value: string,
  comparisonFieldValue: string,
  // Ant's callback functions
  t: TFunction,
  callback: any,
  validateFields: any,
  isFieldsTouched: any,
  isFieldTouched: any,
  // Field Keys
  passwordKey: string,
  passwordConfirmKey: string,
) => {
  if (!isFieldsTouched([passwordKey, passwordConfirmKey])) {
    callback();
  }

  if (isFieldsTouched([passwordKey, passwordConfirmKey])) {
    // Ignore validation if the user has not entered anything yet
    if (!comparisonFieldValue?.trim() && !value?.trim()) {
      callback();
    } else {
      let errors = undefined;

      // Checking the content of the password
      if (value) {
        const cleanValue = cleanString(value);

        // Check Length
        if (cleanValue.length < MIN_PASSWORD_LENGTH) {
          errors = concatenateSentences(
            errors,
            t("forms.validation.passwordLength", {
              number: MIN_PASSWORD_LENGTH,
            }),
            false,
          );
        }

        // Can't contain "password"
        if (
          cleanValue.includes("password") ||
          cleanValue.includes("contraseña")
        ) {
          errors = concatenateSentences(
            errors,
            t("forms.validation.noPassword"),
            false,
          );
        }

        // Can't contain first name
        const firstName =
          AuthenticationStore?.newUserCreationFormData?.firstName;
        if (cleanValue.includes(cleanString(firstName))) {
          errors = concatenateSentences(
            errors,
            t("forms.validation.noFirstName"),
            false,
          );
        }

        // Can't contain last name
        const lastName = AuthenticationStore?.newUserCreationFormData?.lastName;
        if (cleanValue.includes(cleanString(lastName))) {
          errors = concatenateSentences(
            errors,
            t("forms.validation.noLastName"),
            false,
          );
        }

        // Can't contain user's email
        const userEmail = AuthenticationStore?.newUserCreationFormData?.email;
        if (userEmail) {
          const userName = userEmail.split("@")[0];

          if (cleanValue.includes(cleanString(userName))) {
            errors = concatenateSentences(
              errors,
              t("forms.validation.noPasswordEmail"),
              false,
            );
          }
        }
      }

      // Only validate for matching if passwordConfirm has been touched
      if (isFieldTouched(passwordKey) && isFieldTouched(passwordConfirmKey)) {
        if (comparisonFieldValue?.trim() !== value?.trim()) {
          errors = concatenateSentences(
            errors,
            t("forms.validation.match", {
              fieldName: t(`forms.fields.password`),
            }),
            false,
          );
        }
      }

      callback(errors);
      validateFields([passwordKey, passwordConfirmKey]);
    }
  }
};

export const phoneValidator = (
  value: string,
  comparisonFieldValue: string,
  // Ant's callback functions
  t: TFunction,
  callback: any,
  validateFields: any,
  isFieldsTouched: any,
  isFieldTouched: any,
  // Field Keys
  phoneKey: string,
  phoneConfirmKey: string,
) => {
  if (!isFieldsTouched([phoneKey, phoneConfirmKey])) {
    callback();
  }

  if (isFieldsTouched([phoneKey, phoneConfirmKey])) {
    // Ignore validation if the user has not entered anything yet
    if (!comparisonFieldValue?.trim() && !value?.trim()) {
      callback();
    } else {
      let errors = undefined;

      // Only validate for matching if phoneConfirm has been entered
      if (comparisonFieldValue && value) {
        if (comparisonFieldValue?.trim() !== value?.trim()) {
          if (errors) {
            errors = `${errors} ${t("forms.validation.match", {
              fieldName: t(`forms.fields.phoneNumber`),
            })}`;
          } else {
            errors = t("forms.validation.match", {
              fieldName: t(`forms.fields.phoneNumber`),
            });
          }
        }
      }

      callback(errors);
      validateFields([phoneKey, phoneConfirmKey]);
    }
  }
};

export const countryCodeValidator = (
  value: string,
  comparisonFieldValue: string,
  // Ant's callback functions
  t: TFunction,
  callback: any,
  validateFields: any,
  isFieldsTouched: any,
  isFieldTouched: any,
  // Field Keys
  countryKey: string,
  countryConfirmKey: string,
) => {
  if (!isFieldsTouched([countryKey, countryConfirmKey])) {
    callback();
  }

  if (isFieldsTouched([countryKey, countryConfirmKey])) {
    // Ignore validation if the user has not entered anything yet
    if (!comparisonFieldValue?.trim() && !value?.trim()) {
      callback();
    } else {
      let errors = undefined;

      // Only validate for matching if countryConfirm has been touched
      if (isFieldTouched(countryKey) && isFieldTouched(countryConfirmKey)) {
        if (comparisonFieldValue?.trim() !== value?.trim()) {
          if (errors) {
            errors = `${errors} ${t("forms.validation.match", {
              fieldName: t(`forms.fields.countryCode`),
            })}`;
          } else {
            errors = t("forms.validation.match", {
              fieldName: t(`forms.fields.countryCode`),
            });
          }
        }
      }

      callback(errors);
      validateFields([countryKey, countryConfirmKey]);
    }
  }
};

export const getMinPhoneNumberLength = (countryCode: string): number => {
  // If user is from USA/Canada return 10 digits min
  if (countryCode === "1") {
    return 10;
  }

  // Else return 6 min
  return 6;
};

export const getMaxPhoneNumberLength = (countryCode: string): number => {
  // If user is from USA/Canada return 10 digits max
  if (countryCode === "1") {
    return 10;
  }

  // Else return 15 max
  return 15;
};

// ==== V4 Validators ====

// Used to make Ant's validator messages happy
/* eslint-disable no-template-curly-in-string */
export const emailValidatorMessages = {
  required: "${label} is required!",
  types: {
    email: "${label} is not a valid email!",
  },
};
