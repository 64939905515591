import * as React from "react";
import { useTranslation } from "react-i18next";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { ai } from "configuration/appInsights";

const InvestorRelations: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="investorRelations flexColumnWrap">
      <div className="title">
        {t("views.investorRelations.actionRequiredHeader")}
      </div>
      <div className="corporateMessage">
        {t("views.investorRelations.actionRequiredMessage1")}
      </div>
      <div className="corporateMessage">
        {t("views.investorRelations.actionRequiredMessage2")}
      </div>
    </div>
  );
};

export default withAITracking(ai.reactPlugin, InvestorRelations);
