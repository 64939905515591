import * as React from "react";

interface ICheckmarkProps extends React.SVGProps<SVGElement> {
  height?: string;
  width?: string;
}

const Checkmark: React.FunctionComponent<ICheckmarkProps> = ({
  height,
  width,
}) => {
  const svgWidth = isNaN(parseInt(width)) ? "24" : width;
  const svgHeight = isNaN(parseInt(height)) ? "24" : height;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={svgWidth}
      height={svgHeight}
      viewBox={`0 0 ${svgWidth} ${svgHeight}`}
    >
      <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
    </svg>
  );
};

export default Checkmark;
