/* eslint-disable sort-keys */
// Disabled to keep our keys in value order

// ==== Types ====
import { IEnumType } from "utilities/typeUtilities";
import { EMarginScreens } from "views/Margin/types/marginTypes";
import {
  EOptionScreens,
  IMoneyOptionsType,
} from "views/Options/types/optionsTypes";
import { EOnboardingScreens } from "views/SignUp/Onboarding/types/onboardingTypes";

// ==== Data Objects ====
export const FEATURE_TYPES: {
  [index: string]: {
    key: string;
    translationKey: string;
  };
} = {
  MARGIN: {
    key: "margin",
    translationKey: "enums.features.margin",
  },
  OPTIONS: {
    key: "options",
    translationKey: "enums.features.options",
  },
};

export const MONEY_OPTIONS: IMoneyOptionsType = {
  LESS_THAN_50K: {
    key: "less than $50,000",
    translationKey: "enums.moneyOptions.lessThan50k",
    value: "A",
  },
  _50K_99K: {
    key: "$50,000 - $99,999",
    translationKey: "enums.moneyOptions.$50kto99k",
    value: "B",
  },
  _100K_199K: {
    key: "$100,000 - $199,999",
    translationKey: "enums.moneyOptions.$100to199k",
    value: "C",
  },
  _200K_499K: {
    key: "$200,000 - $499,999",
    translationKey: "enums.moneyOptions.$200to499k",
    value: "D",
  },
  _500K_999K: {
    key: "$500,000 - $999,999",
    translationKey: "enums.moneyOptions.$500kto999k",
    value: "E",
  },
  _1000K_2490K: {
    key: "$1 million - $2.49 million",
    translationKey: "enums.moneyOptions.$1mto2_49m",
    value: "F",
  },
  MORE_THAN_2500K: {
    key: "$2.5 million+",
    translationKey: "enums.moneyOptions.moreThan2_5m",
    value: "G",
  },
};

export const OCCUPATIONS: IEnumType = {
  ACCOUNT_OR_AUDITING: {
    key: "accountingOrAuditing",
    translationKey: "enums.occupations.accountingOrAuditing",
    value: 0,
  },
  ADVERTISING_OR_MARKING_SVCE: {
    key: "advertisingOrMarketingSVCE",
    translationKey: "enums.occupations.advertisingOrMarketingSVCE",
    value: 1,
  },
  CIVIL_SERVICE: {
    key: "civilService",
    translationKey: "enums.occupations.civilService",
    value: 2,
  },
  IT: {
    key: "it",
    translationKey: "enums.occupations.it",
    value: 4,
  },
  DOMESTIC_HELP: {
    key: "domesticHelp",
    translationKey: "enums.occupations.domesticHelp",
    value: 5,
  },
  EDUCATION: {
    key: "education",
    translationKey: "enums.occupations.education",
    value: 6,
  },
  TRAINING_EMPLOYMENT: {
    key: "trainingEmployment",
    translationKey: "enums.occupations.trainingEmployment",
    value: 7,
  },
  ENGINEERING: {
    key: "engineering",
    translationKey: "enums.occupations.engineering",
    value: 8,
  },
  EXECUTIVE_MANAGEMENT: {
    key: "executiveManagement",
    translationKey: "enums.occupations.executiveManagement",
    value: 9,
  },
  FINANCE: {
    key: "finance",
    translationKey: "enums.occupations.finance",
    value: 10,
  },
  INSURANCE: {
    key: "insurance",
    translationKey: "enums.occupations.insurance",
    value: 12,
  },
  DOCTOR: {
    key: "doctor",
    translationKey: "enums.occupations.doctor",
    value: 13,
  },
  DENTAL: {
    key: "dental",
    translationKey: "enums.occupations.dental",
    value: 14,
  },
  NURSE: {
    key: "nurse",
    translationKey: "enums.occupations.nurse",
    value: 15,
  },
  SOCIAL_SERVICES: {
    key: "socialServices",
    translationKey: "enums.occupations.socialServices",
    value: 18,
  },
  HOMEMAKER: {
    key: "homemaker",
    translationKey: "enums.occupations.homemaker",
    value: 19,
  },
  HOSPITALITY: {
    key: "hospitality",
    translationKey: "enums.occupations.hospitality",
    value: 20,
  },
  TOURISM: {
    key: "tourism",
    translationKey: "enums.occupations.tourism",
    value: 21,
  },
  TRANSPORT: {
    key: "transport",
    translationKey: "enums.occupations.transport",
    value: 22,
  },
  HUMAN_RESOURCES: {
    key: "humanResources",
    translationKey: "enums.occupations.humanResources",
    value: 23,
  },
  MANUFACTURING: {
    key: "manufacturing",
    translationKey: "enums.occupations.manufacturing",
    value: 24,
  },
  MILITARY: {
    key: "military",
    translationKey: "enums.occupations.military",
    value: 25,
  },
  ADMIN_SECRETARY: {
    key: "adminSecty",
    translationKey: "enums.occupations.adminSecty",
    value: 26,
  },
  CUSTOMER_SERVICE: {
    key: "customerService",
    translationKey: "enums.occupations.customerService",
    value: 27,
  },
  OTHER: {
    key: "other",
    translationKey: "enums.occupations.other",
    value: 28,
  },
  SALES_OR_RETAIL: {
    key: "salesOrRetail",
    translationKey: "enums.occupations.salesOrRetail",
    value: 32,
  },
  SCIENCE: {
    key: "science",
    translationKey: "enums.occupations.science",
    value: 33,
  },
  AGENT: {
    key: "agent",
    translationKey: "enums.occupations.agent",
    value: 35,
  },
  BANKER: {
    key: "banker",
    translationKey: "enums.occupations.banker",
    value: 36,
  },
  BUSINESS: {
    key: "business",
    translationKey: "enums.occupations.business",
    value: 37,
  },
  POLICE_FIRE_LAW: {
    key: "policeFireLaw",
    translationKey: "enums.occupations.policeFireLaw",
    value: 38,
  },
  ATTORNEY_JUDGE_LEGAL: {
    key: "attorneyJudgeLegal",
    translationKey: "enums.occupations.attorneyJudgeLegal",
    value: 39,
  },
  POLITICIAN: {
    key: "politician",
    translationKey: "enums.occupations.politician",
    value: 40,
  },
};

export const ACCOUNT_OPEN_STATUSES: IEnumType = {
  // Created is deprecated
  CREATED: {
    key: "created",
    translationKey: "enums.accountStatus.created",
    value: 0,
  },
  PENDING: {
    key: "pending",
    translationKey: "enums.accountStatus.pending",
    value: 1,
  },
  SUBMIT: {
    key: "submit",
    translationKey: "enums.accountStatus.submit",
    value: 2,
  },
  SUBMITTING: {
    key: "submitting",
    translationKey: "enums.accountStatus.submitting",
    value: 3,
  },
  SUBMITTED: {
    key: "submitted",
    translationKey: "enums.accountStatus.submitted",
    value: 4,
  },
  OPENED: {
    key: "opened",
    translationKey: "enums.accountStatus.opened",
    value: 5,
  },
};

export const EMPLOYMENT_STATUSES: IEnumType = {
  EMPLOYED: {
    key: "employed",
    translationKey: "forms.employmentTypes.employed",
    value: 0,
  },
  STUDENT: {
    key: "student",
    translationKey: "forms.employmentTypes.student",
    value: 1,
  },
  UNEMPLOYED: {
    key: "unEmployed",
    translationKey: "forms.employmentTypes.unemployed",
    value: 2,
  },
  RETIRED: {
    key: "retired",
    translationKey: "forms.employmentTypes.retired",
    value: 3,
  },
};

export const INDUSTRIES: IEnumType = {
  ACCOUNTING: {
    key: "accounting",
    translationKey: "enums.industries.accounting",
    value: 0,
  },
  ADVERTISING_MARKETING: {
    key: "advertisingMarketing",
    translationKey: "enums.industries.advertisingMarketing",
    value: 1,
  },
  AEROSPACE_DEFENSE: {
    key: "aerospaceDefense",
    translationKey: "enums.industries.aerospaceDefense",
    value: 2,
  },
  ARCHITECTURE_DESIGN: {
    key: "architectureDesign",
    translationKey: "enums.industries.architectureDesign",
    value: 3,
  },
  AUTOMOTIVE: {
    key: "automotive",
    translationKey: "enums.industries.automotive",
    value: 4,
  },
  CHILDCARE: {
    key: "childcare",
    translationKey: "enums.industries.childcare",
    value: 5,
  },
  COMMUNICATIONS_TELECOMMUNICATIONS: {
    key: "communicationsTelecommunications",
    translationKey: "enums.industries.communicationsTelecommunications",
    value: 6,
  },
  CONSTRUCTION_CARPENTRY_LANDSCAPING: {
    key: "constructionCarpentryLandscaping",
    translationKey: "enums.industries.constructionCarpentryLandscaping",
    value: 7,
  },
  EDUCATION: {
    key: "education",
    translationKey: "enums.industries.education",
    value: 8,
  },
  ENERGY: {
    key: "energy",
    translationKey: "enums.industries.energy",
    value: 9,
  },
  ENGINEERING: {
    key: "engineering",
    translationKey: "enums.industries.engineering",
    value: 10,
  },
  FINANCIAL_SERVICES: {
    key: "financialServices",
    translationKey: "enums.industries.financialServices",
    value: 11,
  },
  GAMING_CASINO_CARD_CLUB: {
    key: "gamingCasinoCardClub",
    translationKey: "enums.industries.gamingCasinoCardClub",
    value: 12,
  },
  GOVERNMENT_PUBLIC_ADMINISTRATION: {
    key: "governmentPublicAdministration",
    translationKey: "enums.industries.governmentPublicAdministration",
    value: 13,
  },
  HEALTHCARE_MEDICAL_SERVICES: {
    key: "healthcareMedicalServices",
    translationKey: "enums.industries.healthcareMedicalServices",
    value: 14,
  },
  HOTEL_HOSPITALITY: {
    key: "hotelHospitality",
    translationKey: "enums.industries.hotelHospitality",
    value: 15,
  },
  INFORMATION_TECHNOLOGY: {
    key: "informationTechnology",
    translationKey: "enums.industries.informationTechnology",
    value: 16,
  },
  INSURANCE: {
    key: "insurance",
    translationKey: "enums.industries.insurance",
    value: 17,
  },
  LEGAL_SERVICES_PUBLIC_SAFETY: {
    key: "legalServicesPublicSafety",
    translationKey: "enums.industries.legalServicesPublicSafety",
    value: 18,
  },
  MANUFACTURING: {
    key: "manufacturing",
    translationKey: "enums.industries.manufacturing",
    value: 19,
  },
  MEDIA_ENTERTAINMENT: {
    key: "mediaEntertainment",
    translationKey: "enums.industries.mediaEntertainment",
    value: 20,
  },
  NONPROFIT_CHARITY: {
    key: "nonProfitCharity",
    translationKey: "enums.industries.nonProfitCharity",
    value: 21,
  },
  PRINTING_PUBLISHING: {
    key: "printingPublishing",
    translationKey: "enums.industries.printingPublishing",
    value: 22,
  },
  REAL_ESTATE: {
    key: "realEstate",
    translationKey: "enums.industries.realEstate",
    value: 23,
  },
  RESTAURANT_FOOD_SERVICE: {
    key: "restaurantFoodService",
    translationKey: "enums.industries.restaurantFoodService",
    value: 24,
  },
  TRANSPORTATION: {
    key: "transportation",
    translationKey: "enums.industries.transportation",
    value: 25,
  },
  TRAVEL: {
    key: "travel",
    translationKey: "enums.industries.travel",
    value: 26,
  },
  OTHER: {
    key: "other",
    translationKey: "enums.industries.other",
    value: 27,
  },
};

export const JOB_FUNCTIONS: IEnumType = {
  CREDIT_COLLECTIONS: {
    key: "creditCollections",
    translationKey: "enums.jobFunctions.creditCollections",
    value: 0,
  },
  FINANCIAL_PLANNING_ANALYSIS: {
    key: "financialPlanning",
    translationKey: "enums.jobFunctions.financialPlanning",
    value: 1,
  },
  FINANCIAL_ACCOUNTING_CLERICAL: {
    key: "financialAccounting",
    translationKey: "enums.jobFunctions.financialAccounting",
    value: 2,
  },
  PAYROLL: {
    key: "payroll",
    translationKey: "enums.jobFunctions.payroll",
    value: 3,
  },
  PROCUREMENT: {
    key: "procurement",
    translationKey: "enums.jobFunctions.procurement",
    value: 4,
  },
  RISK_MANAGEMENT: {
    key: "riskManagement",
    translationKey: "enums.jobFunctions.riskManagement",
    value: 5,
  },
  TAX: {
    key: "tax",
    translationKey: "enums.jobFunctions.tax",
    value: 6,
  },
  TREASURY: {
    key: "treasury",
    translationKey: "enums.jobFunctions.treasury",
    value: 7,
  },
  BUSINESS_MANAGEMENT: {
    key: "businessManagement",
    translationKey: "enums.jobFunctions.businessManagement",
    value: 8,
  },
  ADMIN_MANAGER_SUPERVISOR: {
    key: "adminManager",
    translationKey: "enums.jobFunctions.adminManager",
    value: 9,
  },
  EXECUTIVE_ADMIN_ASSISTANTS: {
    key: "executiveAssistant",
    translationKey: "enums.jobFunctions.executiveAssistant",
    value: 10,
  },
  OFFICE_INFO_RECORDS_CLERKS: {
    key: "officeClerks",
    translationKey: "enums.jobFunctions.officeClerks",
    value: 11,
  },
  COUNSELING_PSYCHOLOGY: {
    key: "counseling",
    translationKey: "enums.jobFunctions.counseling",
    value: 12,
  },
  RECRUITING_HIRING: {
    key: "recruiting",
    translationKey: "enums.jobFunctions.recruiting",
    value: 13,
  },
  TRAINING_DEVELOPMENT: {
    key: "training",
    translationKey: "enums.jobFunctions.training",
    value: 14,
  },
  APPLICATIONS_SYSTEMS: {
    key: "applicationsSystems",
    translationKey: "enums.jobFunctions.applicationsSystems",
    value: 15,
  },
  BUSINESS_SYSTEMS: {
    key: "businessSystems",
    translationKey: "enums.jobFunctions.businessSystems",
    value: 16,
  },
  DATABASE_ADMIN: {
    key: "databaseAdmin",
    translationKey: "enums.jobFunctions.databaseAdmin",
    value: 17,
  },
  IT_ARCHITECTURE: {
    key: "ITArchitecture",
    translationKey: "enums.jobFunctions.ITArchitecture",
    value: 18,
  },
  IT_PROJECT_MANAGEMENT: {
    key: "ITProjectManagement",
    translationKey: "enums.jobFunctions.ITProjectManagement",
    value: 19,
  },
  NETWORK_SERVICES: {
    key: "networkServices",
    translationKey: "enums.jobFunctions.networkServices",
    value: 20,
  },
  SOFTWARE_DEVELOPMENT_ENGINEERING: {
    key: "softwareDevelopment",
    translationKey: "enums.jobFunctions.softwareDevelopment",
    value: 21,
  },
  SYSTEMS_SUPPORT_ADMIN: {
    key: "systemsSupport",
    translationKey: "enums.jobFunctions.systemsSupport",
    value: 22,
  },
  TECHNICAL_SERVICES: {
    key: "technicalServices",
    translationKey: "enums.jobFunctions.technicalServices",
    value: 23,
  },
  WEB_SERVICES: {
    key: "webServices",
    translationKey: "enums.jobFunctions.webServices",
    value: 24,
  },
  GENERAL_COUNSEL: {
    key: "generalCounsel",
    translationKey: "enums.jobFunctions.generalCounsel",
    value: 25,
  },
  LEGAL_SERVICES: {
    key: "legalServices",
    translationKey: "enums.jobFunctions.legalServices",
    value: 26,
  },
  LEGAL_SUPPORT: {
    key: "legalSupport",
    translationKey: "enums.jobFunctions.legalSupport",
    value: 27,
  },
  AUDIO_VISUAL_SERVICES: {
    key: "audioVisual",
    translationKey: "enums.jobFunctions.audioVisual",
    value: 28,
  },
  COMMUNICATIONS: {
    key: "communications",
    translationKey: "enums.jobFunctions.communications",
    value: 29,
  },
  DESIGN_SERVICES: {
    key: "designServices",
    translationKey: "enums.jobFunctions.designServices",
    value: 30,
  },
  MARKETING: {
    key: "marketing",
    translationKey: "enums.jobFunctions.marketing",
    value: 31,
  },
  MEDIA_RELATIONS: {
    key: "mediaRelations",
    translationKey: "enums.jobFunctions.mediaRelations",
    value: 32,
  },
  PUBLICATIONS_PRODUCTIONS: {
    key: "publications",
    translationKey: "enums.jobFunctions.publications",
    value: 33,
  },
  WRITING_EDITORIAL_SERVICES: {
    key: "writingEditorial",
    translationKey: "enums.jobFunctions.writingEditorial",
    value: 34,
  },
  OTHER: {
    key: "other",
    translationKey: "enums.jobFunctions.other",
    value: 35,
  },
};

export const BENEFICIARY_ACCOUNT_TYPES: IEnumType = {
  PRIMARY: {
    key: "primary",
    translationKey: "views.signUp.forms.BeneficiariesForm.primary",
    value: 0,
  },
  CONTINGENT: {
    key: "contingent",
    translationKey: "views.signUp.forms.BeneficiariesForm.contingent",
    value: 1,
  },
};

export const GOVERNMENT_ID_TYPES: IEnumType = {
  // foreignId = nationalIdentityCard
  FOREIGN_ID: {
    key: "foreignId",
    translationKey: "enums.governmentIdType.nationalIdentityCard",
    value: 0,
  },
  PASSPORT: {
    key: "passport",
    translationKey: "enums.governmentIdType.passport",
    value: 1,
  },
  DRIVERS_LICENSE: {
    key: "driversLicense",
    translationKey: "enums.governmentIdType.driversLicense",
    value: 2,
  },
  MILITARY_ID: {
    key: "militaryId",
    translationKey: "enums.governmentIdType.militaryId",
    value: 3,
  },
  OTHER: {
    key: "other",
    translationKey: "enums.governmentIdType.other",
    value: 4,
  },
};

export const EMPLOYMENT_SOURCE_OF_INCOME: IEnumType = {
  WAGE: {
    key: "wages",
    translationKey: "enums.sourceOfIncome.wages",
    value: 0,
  },
  SALE: {
    key: "sale",
    translationKey: "enums.sourceOfIncome.sale",
    value: 1,
  },
  INHERITANCE: {
    key: "inheritance",
    translationKey: "enums.sourceOfIncome.inheritance",
    value: 2,
  },
  GIFT: {
    key: "gift",
    translationKey: "enums.sourceOfIncome.gift",
    value: 3,
  },
  INSURANCE: {
    key: "insurance",
    translationKey: "enums.sourceOfIncome.insurance",
    value: 4,
  },
  LEGAL_SETTLEMENT: {
    key: "legal_Settlement",
    translationKey: "enums.sourceOfIncome.legal_Settlement",
    value: 5,
  },
  PENSION: {
    key: "pension",
    translationKey: "enums.sourceOfIncome.pension",
    value: 6,
  },
  RETIREMENT: {
    key: "retirement",
    translationKey: "enums.sourceOfIncome.retirement",
    value: 7,
  },
  BROKER: {
    key: "broker",
    translationKey: "enums.sourceOfIncome.broker",
    value: 8,
  },
};

export const RELATIONSHIP_TYPES: IEnumType = {
  CHILD: {
    key: "child",
    translationKey: "forms.relationshipTypes.child",
    value: 0,
  },
  OTHER: {
    key: "other",
    translationKey: "forms.relationshipTypes.other",
    value: 1,
  },
  SELF: {
    key: "self",
    translationKey: "forms.relationshipTypes.self",
    value: 2,
  },
  SPOUSE: {
    key: "spouse",
    translationKey: "forms.relationshipTypes.spouse",
    value: 3,
  },
};

export interface IAccount {
  features?: {
    margin: boolean;
    options: boolean;
  };
  key: string;
  translationKey: string;
  value: number;
}

export interface IAccountType {
  [index: string]: IAccount;
}

export const ACCOUNT_OPENING_TYPES: IAccountType = {
  INDIVIDUAL: {
    features: {
      margin: true,
      options: true,
    },
    key: "individual",
    translationKey: "forms.accountOpeningTypes.individual",
    value: 0,
  },
  INDIVIDUAL_FOREIGN: {
    features: {
      margin: true,
      options: true,
    },
    key: "individualForeign",
    translationKey: "forms.accountOpeningTypes.individualForeign",
    value: 1,
  },
  JOINT: {
    features: {
      margin: false,
      options: false,
    },
    key: "joint",
    translationKey: "forms.accountOpeningTypes.joint",
    value: 2,
  },
  JOINT_FOREIGN: {
    features: {
      margin: false,
      options: false,
    },
    key: "jointForeign",
    translationKey: "forms.accountOpeningTypes.jointForeign",
    value: 3,
  },
  TRADITIONAL_IRA: {
    features: {
      margin: false,
      options: true,
    },
    key: "traditionalIRA",
    translationKey: "forms.accountOpeningTypes.traditionalIRA",
    value: 4,
  },
  ROTH_IRA: {
    features: {
      margin: false,
      options: true,
    },
    key: "rothIRA",
    translationKey: "forms.accountOpeningTypes.rothIRA",
    value: 5,
  },
};

export const ADDRESS_TYPES: IEnumType = {
  INDIVIDUAL: {
    key: "individual",
    translationKey: "forms.addressTypes.individual",
    value: 0,
  },
  EMPLOYMENT: {
    key: "employment",
    translationKey: "forms.addressTypes.employment",
    value: 1,
  },
  TRUSTED_CONTACT: {
    key: "trustedContact",
    translationKey: "forms.addressTypes.trustedContact",
    value: 2,
  },
};

export interface IDocumentEnumType {
  documentName: string;
  key: string;
  translationKey: string;
  version: string;
}

export const DOCUMENTS = {
  CUSTOMER_RELATIONSHIP_SUMMARY: {
    documentName: "Customer Relationship Summary",
    key: "Customer_Relationship_Summary",
    translationKey: "documents.customerRelationshipSummary",
    version: "1.0.2",
  },
  DIRECT_CUSTOMER_ACCOUNT_AGREEMENT: {
    documentName: "Direct Customer Agreement",
    key: "Direct_Customer_Account_Agreement",
    translationKey: "documents.directCustomerAccountAgreement",
    version: "1.0.2",
  },
  DIRECT_MARGIN_AGREEMENT: {
    documentName: "Direct Margin Agreement",
    key: "Direct_Margin_Agreement",
    translationKey: "documents.directMarginAgreement",
    version: "1.0.1",
  },
  DIRECT_OPTION_APPLICATION_AND_AGREEMENT: {
    documentName: "Direct Options Agreement",
    key: "Direct_Options_Application_and_Agreement",
    translationKey: "documents.directOptionsApplicationAndAgreement",
    version: "1.0.2",
  },
  PRIVACY_POLICY: {
    documentName: "Privacy Policy",
    key: "Privacy_Policy",
    translationKey: "documents.privacyPolicy",
    version: "1.0.1",
  },
  REGULATION_BEST_INTEREST: {
    documentName: "Regulation Best Interest Disclosure",
    key: "Regulation_Best_Interest_Disclosure",
    translationKey: "documents.regulationBestInterest",
    version: "1.0.1",
  },
  RISKS_ASSOCIATED_WITH_ELECTRONIC_TRADING: {
    documentName: "Risks Associated with Electronic Trading Disclosure",
    key: "Risks_Associated_with_Electronic_Trading",
    translationKey: "documents.risksAssociatedWithElectronicTrading",
    version: "1.0.1",
  },
};

export interface IScreen {
  formKey: string;
  isNotShown?: boolean;
  isSecondaryScreen?: boolean;
  orderIndex: number;
  translationKey: string;
}

export interface IScreenType {
  [index: string]: IScreen;
}

export const ONBOARDING_SCREENS: IScreenType = {
  INDIVIDUAL: {
    formKey: EOnboardingScreens.INDIVIDUAL,
    orderIndex: 0,
    translationKey: "enums.onboardingScreens.contactInfo",
  },
  CHECK_EMAIL: {
    formKey: EOnboardingScreens.CHECK_EMAIL,
    orderIndex: 1,
    translationKey: "enums.onboardingScreens.checkEmail",
    isNotShown: true,
  },
  PERSONAL_INFO: {
    formKey: EOnboardingScreens.PERSONAL_INFO,
    orderIndex: 2,
    translationKey: "enums.onboardingScreens.personalInfo",
  },
  ACCOUNT_CREATION: {
    formKey: EOnboardingScreens.ACCOUNT_CREATION,
    orderIndex: 3,
    translationKey: "enums.onboardingScreens.accountType",
  },
  ADDRESS_COLLECTION: {
    formKey: EOnboardingScreens.ADDRESS_COLLECTION,
    orderIndex: 4,
    translationKey: "enums.onboardingScreens.address",
  },
  UPLOAD_ID: {
    formKey: EOnboardingScreens.UPLOAD_ID,
    orderIndex: 5,
    translationKey: "enums.onboardingScreens.uploadId",
    isSecondaryScreen: true,
  },
  EMPLOYMENT_INFORMATION: {
    formKey: EOnboardingScreens.EMPLOYMENT_INFORMATION,
    orderIndex: 6,
    translationKey: "enums.onboardingScreens.employerInfo",
  },
  TRUSTED_CONTACT: {
    formKey: EOnboardingScreens.TRUSTED_CONTACT,
    orderIndex: 7,
    translationKey: "enums.onboardingScreens.trustedContact",
  },
  STONEX_AFFILIATION: {
    formKey: EOnboardingScreens.STONEX_AFFILIATE,
    orderIndex: 8,
    translationKey: "enums.onboardingScreens.stonexAffiliation",
  },
  CAIS: {
    formKey: EOnboardingScreens.CAIS,
    orderIndex: 9,
    translationKey: "enums.onboardingScreens.cais",
  },
  STOCK_EXCHANGE_ASSOCIATION: {
    formKey: EOnboardingScreens.STOCK_EXCHANGE_ASSOCIATION,
    orderIndex: 10,
    translationKey: "enums.onboardingScreens.stockExchangeAssociation",
  },
  BENEFICIARIES: {
    formKey: EOnboardingScreens.BENEFICIARIES,
    orderIndex: 11,
    translationKey: "enums.onboardingScreens.beneficiaries",
    isSecondaryScreen: true,
  },
  SUMMARY: {
    formKey: EOnboardingScreens.SUMMARY,
    orderIndex: 12,
    translationKey: "enums.onboardingScreens.review",
  },
  DOCUMENTS: {
    formKey: EOnboardingScreens.DOCUMENTS,
    orderIndex: 13,
    translationKey: "enums.onboardingScreens.termsAndAgreements",
  },
  SUCCESS: {
    formKey: EOnboardingScreens.SUCCESS,
    orderIndex: 14,
    translationKey: "enums.onboardingScreens.success",
    isNotShown: true,
  },
};

export const MARGIN_SCREENS: IScreenType = {
  WELCOME: {
    formKey: EMarginScreens.WELCOME,
    orderIndex: 0,
    translationKey: "enums.marginScreens.welcome",
  },
  APPLICATION_STATUS: {
    formKey: EMarginScreens.APPLICATION_STATUS,
    orderIndex: 1,
    translationKey: "enums.marginScreens.applicationStatus",
  },
};

export const OPTIONS_SCREENS: IScreenType = {
  LANDING: {
    formKey: EOptionScreens.LANDING,
    orderIndex: 0,
    translationKey: "enums.optionScreens.optionsInfo",
  },
  FINANCIAL_PROFILE: {
    formKey: EOptionScreens.FINANCIAL_PROFILE,
    orderIndex: 1,
    translationKey: "enums.optionScreens.financialProfile",
  },
  TRADING_EXPERIENCE: {
    formKey: EOptionScreens.TRADING_EXPERIENCE,
    orderIndex: 2,
    translationKey: "enums.optionScreens.tradingExperience",
  },
  OPTION_INVESTMENT_OBJECTIVES: {
    formKey: EOptionScreens.OPTION_INVESTMENT_OBJECTIVES,
    orderIndex: 3,
    translationKey: "enums.optionScreens.optionInvestmentObjectives",
  },
  SUMMARY: {
    formKey: EOptionScreens.SUMMARY,
    orderIndex: 4,
    translationKey: "enums.optionScreens.summary",
  },
  DOCUMENT_REVIEW: {
    formKey: EOptionScreens.DOCUMENT_REVIEW,
    orderIndex: 5,
    translationKey: "enums.optionScreens.termsAndAgreements",
  },
  SUCCESS: {
    formKey: EOptionScreens.SUCCESS,
    orderIndex: 6,
    translationKey: "enums.optionScreens.success",
    isNotShown: true,
  },
};

export const MARITAL_STATUS_TYPES: IEnumType = {
  MARRIED: {
    key: "married",
    translationKey:
      "views.signUp.forms.BeneficiariesForm.maritalStatusTypes.married",
    value: 0,
  },
  WIDOWED: {
    key: "widowed",
    translationKey:
      "views.signUp.forms.BeneficiariesForm.maritalStatusTypes.widowed",
    value: 1,
  },
  SEPARATED: {
    key: "separated",
    translationKey:
      "views.signUp.forms.BeneficiariesForm.maritalStatusTypes.separated",
    value: 2,
  },
  DIVORCED: {
    key: "divorced",
    translationKey:
      "views.signUp.forms.BeneficiariesForm.maritalStatusTypes.divorced",
    value: 3,
  },
  SINGLE: {
    key: "single",
    translationKey:
      "views.signUp.forms.BeneficiariesForm.maritalStatusTypes.single",
    value: 4,
  },
};

export const FIRM_AFFILIATED_ENTITIES: IEnumType = {
  STNX: {
    key: "STNX",
    translationKey: "enums.firmAffiliatedEntities.stnx",
    value: 0,
  },
  INFA: {
    key: "INFA",
    translationKey: "enums.firmAffiliatedEntities.infa",
    value: 1,
  },
  SSWM: {
    key: "SSWM",
    translationKey: "enums.firmAffiliatedEntities.sswm",
    value: 2,
  },
  SSIA: {
    key: "SSIA",
    translationKey: "enums.firmAffiliatedEntities.ssia",
    value: 3,
  },
};
