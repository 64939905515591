import * as React from "react";
import { Form } from "@ant-design/compatible";
import { observer } from "mobx-react";
import { Button, Input } from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";

// ==== Components ====
import FormHeader from "components/FormHeader";

// ==== Store ====
import SelfEnrollStore from "views/SignUp/SelfEnroll/store/SelfEnrollStore";

// ==== Types ====
import { IEmailValidationForm } from "views/SignUp/SelfEnroll/types/selfEnrollTypes";

const EmailValidateForm: React.FunctionComponent<FormComponentProps> = observer(
  ({ form }) => {
    const { getFieldDecorator, validateFields } = form;
    const { t } = useTranslation();

    const handleSubmit = async (
      event: React.FormEvent<HTMLFormElement>,
    ): Promise<void> => {
      event.preventDefault();

      validateFields(async (err: string[]): Promise<void> => {
        if (!err) {
          await SelfEnrollStore.validateEmail();
        }
      });
    };

    const validateEmail = (rule: any, value: string, callback: any) => {
      const email = toJS(SelfEnrollStore?.emailValidationData?.Email);
      const confirmEmail = toJS(
        SelfEnrollStore?.emailValidationData?.ConfirmEmail,
      );

      if (!email || !confirmEmail) {
        callback();
      } else {
        if (email === confirmEmail) {
          form.validateFields(["confirm"], { force: true });
          callback();
        } else {
          callback(
            t("forms.validation.match", {
              fieldName: t("forms.fields.email"),
            }),
          );
        }
      }
    };

    return (
      <div className="slideInWrapper">
        <FormHeader
          headerText={t("views.signUp.forms.EmailValidateForm.checkYourEmail")}
          subheaderText={t(
            "views.signUp.forms.EmailValidateForm.useStonexUsername",
          )}
        />
        <Form layout="vertical" onSubmit={handleSubmit}>
          <Form.Item label={t("forms.fields.email")}>
            {getFieldDecorator("Email", {
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.email"),
                  }),
                  required: true,
                },
                {
                  message: t("forms.validation.emailFormat"),
                  required: true,
                  type: "email",
                },
                {
                  validator: validateEmail,
                },
              ],
              validateTrigger: "onBlur",
            })(<Input allowClear />)}
          </Form.Item>
          <Form.Item
            label={t("forms.fields.confirm", {
              fieldName: t("forms.fields.email"),
            })}
          >
            {getFieldDecorator("ConfirmEmail", {
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.confirm", {
                      fieldName: t("forms.fields.email"),
                    }),
                  }),
                  required: true,
                },
                {
                  message: t("forms.validation.emailFormat"),
                  required: true,
                  type: "email",
                },
                {
                  validator: validateEmail,
                },
              ],
              validateTrigger: "onBlur",
            })(<Input allowClear />)}
          </Form.Item>
          <Button
            disabled={SelfEnrollStore.isDisabled}
            htmlType="submit"
            type="primary"
          >
            {t("forms.buttons.next")}
          </Button>
        </Form>
      </div>
    );
  },
);

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: IEmailValidationForm) => {
    SelfEnrollStore.updateEmailValidationData(changedFields);
  },
})(EmailValidateForm);
