import { get } from "mobx";

// Lovingly called the "junk drawer" of functions
export const getDateOfBirth = (day: string, month: string, year: string) => {
  // Just not enough, I need more, nothing seems to satisfy
  if (!day || !month || !year) {
    return;
  }

  if (day.length === 1) {
    day = `0${day}`;
  }

  if (month.length === 1) {
    month = `0${month}`;
  }

  return `${year}-${month}-${day}`;
};

export const getTranslationKeyFromBool = (bool: boolean): string =>
  bool ? "forms.options.yes" : "forms.options.no";

export const isEnumFalsey = (enumValue: number): boolean => {
  if (enumValue === 0) {
    return true;
  }

  return !!enumValue;
};

// I never sleep, 'cause sleep is the cousin of death
export const sleep = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

// Guarded Get does two things:
// 1. Handles cases where we are using numbers and zero is an acceptable value
// 2. Prevents null keys from being sent to the API
//
// Note - We should only need to use this in cases where the data is a number OR could be undefined
export const guardedGet = (mobxData: any, apiKey: string, mobxKey: string) => {
  const data = get(mobxData, mobxKey);
  let guard = data;

  if (typeof data === "number") {
    guard = isEnumFalsey(data);
  }

  return (
    guard && {
      [apiKey]: data,
    }
  );
};

// Used to combine random error messages and tooltips
// Adds white space and a period for two strings, handles the case of a string not existing
//
// EG concatenateStrings('Has it come to this', 'Original pirate material')
// => "Has it come to this. Original pirate material"
export const concatenateSentences = (
  string1?: string,
  string2?: string,
  punctuation: boolean = true,
): string => {
  if (string1 && !string2) {
    return string1;
  } else if (!string1 && string2) {
    return string2;
  } else {
    return `${string1}${punctuation ? "." : ""} ${string2}`;
  }
};

export const addOptionalTag = (
  translate: (msg: string) => string,
  messageKey: string,
): string => {
  return `${translate(messageKey)} (${translate("forms.fields.optional")})`;
};

export const capitalize = (text: string): string => {
  return `${text[0].toUpperCase()}${text.slice(1).toLowerCase()}`;
};

export const cleanString = (input: string): string =>
  input?.toString()?.trim()?.toLowerCase();

export const isApplicationRunningInDevMode = (): boolean => {
  // Whitelist of servers we are okay with our debugging data being pooped out to
  const LOCAL_HOST = "localhost:3000";
  const DEV = "advisor.dev.stonex.com";
  const TEST = "advisor.test.stonex.com";

  switch (window.location.host) {
    case LOCAL_HOST:
    case DEV:
    case TEST:
      return true;
    default:
      return false;
  }
};

// This func should return true ONLY if we are running on localhost
export const isApplicationRunningLocally = (): boolean => {
  const LOCAL_HOST = "localhost:3000";

  if (window.location.host === LOCAL_HOST) {
    return true;
  }
  return false;
};

// To help smoooooth that thang out, you know what I'm talkin' 'bout?
export const scrollUserToTopOfWindow = (): void => {
  window.scrollTo({
    behavior: "smooth",
    left: 0,
    top: 0,
  });
};
