import { makeAutoObservable, toJS } from "mobx";

// ==== Types ====
import { ISuggestion } from "views/SignUp/Onboarding/types/autocompleteTypes";
import { EOnboardingForms } from "views/SignUp/Onboarding/types/onboardingTypes";

// ==== Api Handlers ====
import {
  autocompleteLookup,
  addressValidation,
} from "apiHandlers/AutocompleteApiHandler";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

class AutocompleteStore {
  // ==== SmartyStreets ====
  // Embedded Key - https://www.smartystreets.com/account/keys
  // MUST HAVE HOST SET
  smartyStreetsKey: string = "22102732881906514";

  // The suggested addresses coming back from the SmartyAPI
  suggestions: ISuggestion[] = [];

  // Used by the address form to update the correct field
  decoratorPrefix: string = undefined;
  formKey: EOnboardingForms = undefined;

  // ==== Address data ====
  address1: string = undefined;
  address2: string = undefined;
  city: string = undefined;
  state: string = undefined;
  zipCode: string = undefined;
  country: string = undefined;

  // ==== Address Metadata ====
  isPoBox: boolean = false;

  errorMessage: string = undefined;

  // For the Spinner when suggestions are loading
  isLoadingSuggestions: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setErrorMessage = (errorMessage: string) => {
    this.errorMessage = errorMessage;
  };

  setSuggestions = (suggestions: ISuggestion[]) => {
    this.suggestions = suggestions;
  };

  setState = (state: string) => {
    this.state = state;
  };

  setAddressFormData = (decoratorPrefix: string, formKey: EOnboardingForms) => {
    this.decoratorPrefix = decoratorPrefix;
    this.formKey = formKey;
  };

  setIsLoadingSuggestions = (isLoading: boolean) => {
    this.isLoadingSuggestions = isLoading;
  };

  setIsPoBox = (isPoBox: boolean) => {
    this.isPoBox = isPoBox;
  };

  // ==== SmartyStreet Handlers ====
  getSuggestions = async (query: string) => {
    this.address1 = query;

    await autocompleteLookup(query);
  };

  getIsLoadingSuggestions = (): boolean => {
    return this.isLoadingSuggestions;
  };

  handleSuggestionSelection = async (index: string) => {
    // Let me tell you the tail on why we need to cast our number index to a weird string...
    //
    // Originally we were using a normal index number, this worked great until the user
    // entered in a number. Ant's Autocomplete considers this a 1 to 1 match of the DataSource
    // and will auto select that value. Eg (User starts typing and enters a 5, Api fetches
    // suggestions, <AutoComplete> says "5?!! That's a perfect match! Here you go, dear user!")
    // thus typing any number will auto select whatever the suggestion is at that index
    //
    // Chapter 2 - The bad solution
    //
    // To get around this we wrap the index with a dummy string "key" that we strip out when we actually
    // go and fetch the suggestion. This isn't ideal nor is it good code, but here we are...
    const cleanIndex = parseInt(index.replace("key", ""));
    const selectedSuggestion = toJS(this.suggestions[cleanIndex]);

    if (selectedSuggestion) {
      OnboardingStore.setFormItemData(this.formKey, {
        [`${this.decoratorPrefix}StreetAddress1`]:
          selectedSuggestion.street_line,
        [`${this.decoratorPrefix}StreetAddress2`]: selectedSuggestion.secondary,
        [`${this.decoratorPrefix}City`]: selectedSuggestion.city,
        [`${this.decoratorPrefix}StateProvince`]: selectedSuggestion.state,
        [`${this.decoratorPrefix}ZipPostal`]: selectedSuggestion.zipcode,
        [`${this.decoratorPrefix}Country`]: "US",
      });
      OnboardingStore.handleBeneficiaryFormReset();
      // Clear suggestions after making a selection
      this.setSuggestions([]);

      await addressValidation(selectedSuggestion);
    }
  };
}

export default new AutocompleteStore();
