import * as React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Types ====
import { EOnboardingForms } from "views/SignUp/Onboarding/types/onboardingTypes";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Components ====
import SummaryFieldBlock from "components/SummaryFieldBlock";
import SummaryHeader from "views/SignUp/Onboarding/forms/SummaryPage/SummaryHeader";

interface IProps {
  isEditButtonDisabled?: boolean;
}

const SummaryAccountInfoForm: React.FunctionComponent<IProps> = observer(
  ({ isEditButtonDisabled }) => {
    const { t } = useTranslation();

    const getFormData = (key: string) =>
      OnboardingStore.getFormItemData(
        EOnboardingForms.ADDRESS_COLLECTION,
        key,
      ) || "";

    return (
      <React.Fragment>
        <SummaryHeader
          header={t("views.signUp.components.summery.addressInformation")}
          editButtonKey={
            !isEditButtonDisabled && EOnboardingForms.ADDRESS_COLLECTION
          }
        />
        <SummaryFieldBlock
          question={`${t("forms.fields.address1")}:`}
          answer={getFormData("StreetAddress1")}
        />
        <SummaryFieldBlock
          question={`${t("forms.fields.address2")}:`}
          answer={getFormData("StreetAddress2")}
        />
        <SummaryFieldBlock
          question={`${t("forms.fields.city")}:`}
          answer={getFormData("City")}
        />
        {getFormData("StateProvince") && (
          <SummaryFieldBlock
            question={`${t("forms.fields.stateProvince")}:`}
            answer={getFormData("StateProvince")}
          />
        )}
        {getFormData("ZipPostal") && (
          <SummaryFieldBlock
            question={`${
              OnboardingStore.isUserUSCitizen()
                ? t("forms.fields.zipCode")
                : t("forms.fields.postalCode")
            }:`}
            answer={getFormData("ZipPostal")}
          />
        )}
        {getFormData("Country") && (
          <SummaryFieldBlock
            question={`${t("forms.fields.country")}:`}
            answer={getFormData("Country")}
          />
        )}
      </React.Fragment>
    );
  },
);

export default SummaryAccountInfoForm;
