import * as React from "react";

// ==== Components ====
import TopBar from "components/TopBar";
import HeaderLogo from "components/sidebar/HeaderLogo";

const SiteHeader: React.FunctionComponent = () => {
  return (
    <div>
      <div className="topBarWrapper">
        <TopBar isLogoHidden />
      </div>
      <div className="headerLogoWrapper">
        <HeaderLogo isLarge />
      </div>
    </div>
  );
};

export default SiteHeader;
