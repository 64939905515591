import * as React from "react";
import { observer } from "mobx-react";

// ==== Components ====
import BrowserWarningFooter from "components/BrowserWarningFooter";
import TopBar from "components/TopBar";
import ErrorMessageAlert from "views/SignUp/Onboarding/components/ErrorMessageAlert";
import SalesForceChat from "components/SalesForceChat";

// ==== Stores ====
import ConfigurationStore from "stores/ConfigurationStore";

// ==== Types ====
import { ERoutePaths, EApplicationThemes } from "types/globalTypes";

// StoneX Zero
const splashScreen = require("../../assets/images/splashScreen.webm").default;
const splashScreen_Dark =
  require("../../assets/images/splashScreen_dark.gif").default;

// StoneX One
const stoneXOne_SplashScreen_Dark =
  require("../../assets/images/splashScreenSX1_dark.webm").default;

const stoneXOne_SplashScreen =
  require("../../assets/images/newSplashScreen.png").default;

interface IProps {
  isLogoStonexOne?: boolean;
}

const SplashScreenWrapper: React.FunctionComponent<IProps> = observer(
  ({ children, isLogoStonexOne }) => {
    const getSplashScreenVideo = () => {
      if (isLogoStonexOne) {
        if (
          ConfigurationStore.applicationTheme === EApplicationThemes.DarkMode
        ) {
          return (
            <video src={stoneXOne_SplashScreen_Dark} autoPlay loop muted />
          );
        } else {
          return <img src={stoneXOne_SplashScreen} alt="Stone X One Logo" />;
        }
      } else {
        if (
          ConfigurationStore.applicationTheme === EApplicationThemes.DarkMode
        ) {
          return <img src={splashScreen_Dark} alt="fml" />;
        } else {
          return <video src={splashScreen} autoPlay loop muted />;
        }
      }

      return splashScreen;
    };

    return (
      <div className="pageWrapper">
        <TopBar isLogoStonexOne={isLogoStonexOne} />
        <div className="splashScreenWrapper">
          <div className="splashScreen">
            {/* Videos need to be muted to autoplay */}
            {/* https://stackoverflow.com/questions/50259734/video-autoplay-not-working-trying-to-find-a-fix */}
            {getSplashScreenVideo()}
          </div>
          <div className="contentBody">{children}</div>
        </div>
        <ErrorMessageAlert resetPath={ERoutePaths.SIGNUP} />
        <div style={{ paddingTop: 12 }}>
          <SalesForceChat isLink />
        </div>
        <BrowserWarningFooter />
      </div>
    );
  },
);

export default SplashScreenWrapper;
