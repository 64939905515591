import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Input, Alert } from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import AuthenticationStore from "stores/AuthenticationStore";
import FormsStore from "stores/FormsStore";

// ==== Types ====
import {
  INewUserCreationFormData,
  EAuthenticationScreens,
} from "types/authenticationTypes";
import { FEATURE_FLAGS } from "types/globalTypes";

// ==== Components ====
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";
import FormHeader from "components/FormHeader";
import PrivacyPolicyFooter from "components/PrivacyPolicyFooter";
import PhoneNumberForm from "views/SignUp/components/PhoneNumbersForm";

// ==== Utilities ====
import {
  emailFormValidator,
  passwordValidator,
} from "utilities/formValidators";
import { concatenateSentences } from "utilities/genericUtilities";

const NewUserCreationForm: React.FunctionComponent<FormComponentProps> =
  observer(({ form }) => {
    const {
      getFieldDecorator,
      getFieldValue,
      validateFields,
      isFieldsTouched,
      isFieldTouched,
      isFieldValidating,
    } = form;
    const { t } = useTranslation();

    React.useEffect(() => {
      // Handles the edge case where an email is preloaded via url params but is
      // not in the auth store yet
      if (AuthenticationStore.loginFormData?.Email && !email) {
        AuthenticationStore.updateNewUserCreationFormData({
          confirmEmail: AuthenticationStore.loginFormData?.Email,
          email: AuthenticationStore.loginFormData?.Email,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (
      event: React.FormEvent<HTMLFormElement>,
    ): Promise<void> => {
      event.preventDefault();

      validateFields(async (err: string[]): Promise<void> => {
        if (!err) {
          await AuthenticationStore.handleNewUserCreation();
        }
      });
    };

    // ==== Handlers ====
    const goForwardHandler = (e: React.FormEvent<HTMLFormElement>) => {
      handleSubmit(e);
    };

    const goBackHandler = () => {
      FormsStore.setCurrentScreen(EAuthenticationScreens.EMAIL_VALIDATION);
    };

    // ==== Email Validation ====
    const emailField = getFieldValue("email");

    const validateEmail = React.useCallback(
      (rule: string, value: string, callback: (response?: string) => void) => {
        emailFormValidator(
          value,
          getFieldValue("confirmEmail"),
          t,
          callback,
          validateFields,
          isFieldsTouched,
          isFieldTouched,
          "email",
          "confirmEmail",
        );
      },

      // For some reason TSLint really wants me to include emailFormValidator in the
      // dependency array but adding it just throws a chain of weird, errors
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [emailField],
    );

    // ==== Password Validation ====
    const passwordField = getFieldValue("password");
    const confirmPasswordField = getFieldValue("confirmPassword");

    const validatePassword = React.useCallback(
      (rule: string, value: string, callback: (response?: string) => void) => {
        passwordValidator(
          value,
          getFieldValue("confirmPassword"),
          t,
          callback,
          validateFields,
          isFieldsTouched,
          isFieldTouched,
          "confirmPassword",
          "password",
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [passwordField],
    );

    const validateConfirmPassword = React.useCallback(
      (rule: string, value: string, callback: (response?: string) => void) => {
        passwordValidator(
          value,
          getFieldValue("password"),
          t,
          callback,
          validateFields,
          isFieldsTouched,
          isFieldTouched,
          "confirmPassword",
          "password",
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [confirmPasswordField],
    );

    const isNextButtonDisabled = (): {
      disabledMessage: string;
      isDisabled: boolean;
    } => {
      const {
        newUserCreationFormData: {
          Phone,
          ConfirmPhone,
          CountryCode,
          ConfirmCountryCode,
        },
        newUserCreationFormError,
      } = AuthenticationStore;

      let disabledMessage: string = "";
      let isDisabled: boolean = false;

      if (!Phone || !ConfirmPhone) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t("views.signUp.forms.NewUserCreationForm.validation.phoneNumber"),
        );
      }

      // 🦅 USA 🦅
      if (CountryCode === "1") {
        if (Phone?.length !== 10 || ConfirmPhone?.length !== 10) {
          isDisabled = true;
          disabledMessage = concatenateSentences(
            disabledMessage,
            t(
              "views.signUp.forms.NewUserCreationForm.validation.phoneNumberLengthUS",
            ),
          );
        }
      }

      // 🌎 Non-US users 🌏
      if (CountryCode !== "1") {
        if (AuthenticationStore.getFeatureFlag(FEATURE_FLAGS.FOREIGN_ACCOUNT)) {
          if (Phone?.length < 6 || Phone?.length > 15) {
            isDisabled = true;
            disabledMessage = concatenateSentences(
              disabledMessage,
              t(
                "views.signUp.forms.NewUserCreationForm.validation.phoneNumberLengthForeign",
              ),
            );
          } else if (ConfirmPhone?.length < 6 || ConfirmPhone?.length > 15) {
            isDisabled = true;
            disabledMessage = concatenateSentences(
              disabledMessage,
              t(
                "views.signUp.forms.NewUserCreationForm.validation.phoneNumberLengthForeign",
              ),
            );
          }
        } else {
          isDisabled = true;
          disabledMessage = concatenateSentences(
            disabledMessage,
            t(
              "views.signUp.forms.NewUserCreationForm.validation.countryCodeForeignDisable",
            ),
          );
        }
      }

      if (!CountryCode || !ConfirmCountryCode) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t(
            "views.signUp.forms.NewUserCreationForm.validation.countryCodeRequired",
          ),
        );
      }

      if (CountryCode !== ConfirmCountryCode) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t(
            "views.signUp.forms.NewUserCreationForm.validation.countryCodeMatch",
          ),
        );
      }

      if (newUserCreationFormError) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t(
            "views.signUp.forms.NewUserCreationForm.errorMessages.genericError",
          ),
        );
      }

      return {
        disabledMessage,
        isDisabled,
      };
    };

    const {
      ConfirmCountryCode,
      ConfirmPhone,
      CountryCode,
      Phone,
      firstName,
      lastName,
      email,
    } = AuthenticationStore.newUserCreationFormData;

    return (
      <Form className="slideInWrapper" layout="vertical">
        {AuthenticationStore.newUserCreationFormError && (
          <Alert
            message={t(
              "views.signUp.forms.NewUserCreationForm.errorMessages.emailNotSent",
            )}
            closable
            showIcon
            style={{ margin: "0 0 2em" }}
            type="error"
          />
        )}
        <FormHeader
          headerText={t("views.signUp.forms.NewAccount.header")}
          subheaderText={t("views.signUp.forms.NewAccount.subheader")}
          largeHeader
        />
        <Form.Item>
          {getFieldDecorator("email", {
            initialValue: AuthenticationStore.loginFormData?.Email || email,
            rules: [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: t("forms.fields.email"),
                }),
                required: true,
              },
              {
                validator: validateEmail,
              },
            ],
            validateTrigger: "onBlur",
          })(
            <Input disabled placeholder={t("forms.fields.email")} allowClear />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("firstName", {
            initialValue: firstName,
            rules: [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: t("forms.fields.firstName"),
                }),
                required: true,
              },
            ],
            validateTrigger: "onBlur",
          })(
            <Input
              placeholder={t("forms.fields.firstName")}
              disabled={!!OnboardingStore.profileId}
              allowClear
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("lastName", {
            initialValue: lastName,
            rules: [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: t("forms.fields.lastName"),
                }),
                required: true,
              },
            ],
            validateTrigger: "onBlur",
          })(
            <Input
              placeholder={t("forms.fields.lastName")}
              disabled={!!OnboardingStore.profileId}
              allowClear
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("password", {
            rules: [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: t("forms.fields.password"),
                }),
                required: true,
              },
              {
                validator: validatePassword,
              },
            ],
            validateTrigger: "onBlur",
          })(
            <Input.Password
              placeholder={t("forms.fields.password")}
              allowClear
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("confirmPassword", {
            rules: [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: t("forms.fields.password"),
                }),
                required: true,
              },
              {
                validator: validateConfirmPassword,
              },
            ],
            validateTrigger: "onBlur",
          })(
            <Input.Password
              placeholder={t("forms.fields.confirm", {
                fieldName: t("forms.fields.password"),
              })}
              allowClear
            />,
          )}
        </Form.Item>
        <PhoneNumberForm
          getFieldDecorator={getFieldDecorator}
          getFieldValue={getFieldValue}
          isFieldTouched={isFieldTouched}
          isFieldsTouched={isFieldsTouched}
          isFieldValidating={isFieldValidating}
          validateFields={validateFields}
          phoneNumber={Phone}
          confirmPhoneNumber={ConfirmPhone}
          countryCode={CountryCode}
          confirmCountryCode={ConfirmCountryCode}
        />
        <div>
          <PrivacyPolicyFooter />
          <FormButtonFooter
            isGoForwardDisabled={isNextButtonDisabled().isDisabled}
            goForwardDisabledMessage={isNextButtonDisabled().disabledMessage}
            goForwardHandler={goForwardHandler}
            goBackHandler={goBackHandler}
          />
        </div>
      </Form>
    );
  });

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: INewUserCreationFormData) => {
    AuthenticationStore.updateNewUserCreationFormData(changedFields);
  },
})(NewUserCreationForm);
