import * as React from "react";

interface IProps {
  headerText?: JSX.Element | string;
  largeHeader?: boolean;
  subheaderText?: JSX.Element | string;
}

const FormHeader: React.FunctionComponent<IProps> = ({
  headerText,
  subheaderText,
  largeHeader,
}) => {
  return (
    <div style={{ paddingBottom: 25 }}>
      <div className={largeHeader ? "largeHeader" : "header"}>{headerText}</div>
      <div className="subheader">{subheaderText}</div>
    </div>
  );
};

export default FormHeader;
