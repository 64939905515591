// ==== Types ====
import { EOnboardingScreens } from "views/SignUp/Onboarding/types/onboardingTypes";
import { EMarginScreens } from "views/Margin/types/marginTypes";
import { EOptionScreens } from "views/Options/types/optionsTypes";
import { ERoutePaths } from "types/globalTypes";
import { EAuthenticationScreens } from "types/authenticationTypes";

// "personalInfo" -> true
// "welcome" -> false
export const isFormKeyOnboarding = (formKey: string): boolean => {
  if (Object.values(EOnboardingScreens).includes(formKey)) {
    return true;
  } else if (Object.values(EAuthenticationScreens).includes(formKey)) {
    return true;
  }

  return false;
};

export const isFormKeyMargin = (formKey: string): boolean => {
  return Object.values(EMarginScreens).includes(formKey);
};

export const isFormKeyOptions = (formKey: string): boolean => {
  return Object.values(EOptionScreens).includes(formKey);
};

// Gets the url path by key
// "welcome" => "/l/margin"
export const getUrlPathByScreenKey = (formKey: string): string => {
  if (isFormKeyOnboarding(formKey)) {
    return ERoutePaths.ONBOARDING;
  }

  if (isFormKeyMargin(formKey)) {
    return ERoutePaths.MARGIN;
  }

  if (isFormKeyOptions(formKey)) {
    return ERoutePaths.OPTIONS;
  }
};
