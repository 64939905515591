import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Input, Alert } from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import AuthenticationStore from "stores/AuthenticationStore";

// ==== Types ====
import { INewUserCreationFormData } from "types/authenticationTypes";

// ==== Components ====
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";
import FormHeader from "components/FormHeader";
import PrivacyPolicyFooter from "components/PrivacyPolicyFooter";

// ==== Utilities ====
import { emailFormValidator } from "utilities/formValidators";
import { setQueryStringsIntoState } from "utilities/apiUtilities";

const EmailValidateForm: React.FunctionComponent<FormComponentProps> = observer(
  ({ form }) => {
    const {
      getFieldDecorator,
      getFieldValue,
      validateFields,
      isFieldsTouched,
      isFieldTouched,
    } = form;
    const { t } = useTranslation();

    React.useEffect(() => {
      const { search } = location;

      setQueryStringsIntoState(search);
    }, []);

    const handleSubmit = async (
      event: React.FormEvent<HTMLFormElement>,
    ): Promise<void> => {
      event.preventDefault();

      validateFields(async (err: string[]): Promise<void> => {
        if (!err) {
          await AuthenticationStore.handleEmailValidation();
        }
      });
    };

    // ==== Handlers ====
    const goForwardHandler = (e: React.FormEvent<HTMLFormElement>) => {
      handleSubmit(e);
    };

    // ==== Email Validation ====
    const emailField = getFieldValue("email");
    const confirmEmailField = getFieldValue("confirmEmail");

    const validateEmail = React.useCallback(
      (rule: string, value: string, callback: (response?: string) => void) => {
        emailFormValidator(
          value,
          getFieldValue("confirmEmail"),
          t,
          callback,
          validateFields,
          isFieldsTouched,
          isFieldTouched,
          "email",
          "confirmEmail",
        );
      },
      // For some reason TSLint really wants me to include emailFormValidator in the
      // dependency array but adding it just throws a chain of weird, errors
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [emailField],
    );

    const validateConfirmEmail = React.useCallback(
      (rule: string, value: string, callback: (response?: string) => void) => {
        emailFormValidator(
          value,
          getFieldValue("email"),
          t,
          callback,
          validateFields,
          isFieldsTouched,
          isFieldTouched,
          "email",
          "confirmEmail",
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [confirmEmailField],
    );

    const { email, confirmEmail } = AuthenticationStore.newUserCreationFormData;

    return (
      <Form className="slideInWrapper" layout="vertical">
        <FormHeader
          headerText={t("forms.emailValidateForm.header")}
          subheaderText={t("forms.emailValidateForm.subheader")}
          largeHeader
        />
        {AuthenticationStore.accountAlreadyExistsError && (
          <Alert
            message={t("forms.emailValidateForm.errorAccountAlreadyExists")}
            closable
            showIcon
            style={{ margin: "0 0 2em" }}
            type="error"
          />
        )}
        {AuthenticationStore.genericAuthError && (
          <Alert
            message={t("error.default")}
            closable
            showIcon
            style={{ margin: "0 0 2em" }}
            type="error"
          />
        )}
        <Form.Item>
          {getFieldDecorator("email", {
            initialValue: AuthenticationStore.loginFormData?.Email || email,
            rules: [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: t("forms.fields.email"),
                }),
                required: true,
              },
              {
                validator: validateEmail,
              },
            ],
            validateTrigger: "onBlur",
          })(<Input allowClear placeholder={t("forms.fields.email")} />)}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("confirmEmail", {
            initialValue:
              AuthenticationStore.loginFormData?.ConfirmEmail || confirmEmail,
            rules: [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: t("forms.fields.email"),
                }),
                required: true,
              },
              {
                validator: validateConfirmEmail,
              },
            ],
            validateTrigger: "onBlur",
          })(
            <Input
              allowClear
              placeholder={t("forms.fields.confirm", {
                fieldName: t("forms.fields.email"),
              })}
            />,
          )}
        </Form.Item>
        <div>
          <PrivacyPolicyFooter />
          <FormButtonFooter goForwardHandler={goForwardHandler} />
        </div>
      </Form>
    );
  },
);

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: INewUserCreationFormData) => {
    AuthenticationStore.updateNewUserCreationFormData(changedFields);
  },
})(EmailValidateForm);
