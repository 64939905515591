import * as React from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import { Button, Row, Checkbox } from "antd";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { FormComponentProps } from "@ant-design/compatible/lib/form";

// ==== Stores ====
import OptionsStore from "views/Options/OptionsStore";
import FormsStore from "stores/FormsStore";

// ==== Components ====
import FormHeader from "components/FormHeader";
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";

// ==== Types ====
import {
  EOptionScreens,
  IReviewDocuments,
} from "views/Options/types/optionsTypes";

// ==== ApiHandlers ====
import { getMasterDocumentDownload } from "apiHandlers/ProfileApiHandler";

import { DOCUMENTS } from "views/SignUp/Onboarding/types/onboardingEnums";

const ReviewDocuments: React.FunctionComponent<FormComponentProps> = observer(
  ({ form }) => {
    const { t } = useTranslation();
    const { getFieldDecorator } = form;

    const getInitialValue = (key: string) =>
      OptionsStore.getFormItemData(EOptionScreens.DOCUMENT_REVIEW, key);

    const generateButtonDisabledMessage = (): string => {
      let errorMessage = "";

      if (!getInitialValue("doesUserAgree")) {
        errorMessage = t(
          "views.Options.ReviewDocuments.tooltipMessages.doesUserAgreeSolo",
        );
      }

      if (!OptionsStore.isODDRead) {
        if (errorMessage) {
          errorMessage = errorMessage.concat(
            t(
              "views.Options.ReviewDocuments.tooltipMessages.isRiskOptionsDocRead",
            ),
          );
        } else {
          errorMessage = t(
            "views.Options.ReviewDocuments.tooltipMessages.isRiskOptionsDocReadSolo",
          );
        }
      }

      return errorMessage;
    };

    const downloadDirectOptionAppAgreement = async () => {
      OptionsStore.isOARead = true;
      await getMasterDocumentDownload(
        DOCUMENTS.DIRECT_OPTION_APPLICATION_AND_AGREEMENT.documentName,
      );
    };

    return (
      <React.Fragment>
        <div className="paddingBelow">
          <FormHeader
            headerText={t("views.Options.ReviewDocuments.header")}
            subheaderText={t("views.Options.ReviewDocuments.beforeSubmitting")}
          />
          <div>
            <ul>
              <li>
                <u>{t("views.Options.ReviewDocuments.optionsAgreement")}</u>
              </li>
              <li>
                <u>
                  {t(
                    "views.Options.ReviewDocuments.characteristicsAndRiskAgreement",
                  )}
                </u>
              </li>
            </ul>
          </div>
          <Row align="middle" justify="center">
            <Button onClick={downloadDirectOptionAppAgreement}>
              {t("views.Options.ReviewDocuments.optionsAgreement")}
            </Button>
            {OptionsStore.isOARead && (
              <CheckCircleOutlined
                className="successGreen"
                style={{
                  fontSize: 20,
                  paddingLeft: 20,
                }}
              />
            )}
          </Row>
          <div>
            <div>
              <div className="centeredContent" style={{ paddingTop: 10 }}>
                <a
                  href="https://www.theocc.com/Company-Information/Documents-and-Archives/Options-Disclosure-Document"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button onClick={() => OptionsStore.setIsODDRead(true)}>
                    {t(
                      "views.Options.ReviewDocuments.characteristicsAndRiskAgreement",
                    )}
                  </Button>
                </a>
                <div style={{ display: "flex" }}>
                  {OptionsStore.isODDRead && (
                    <CheckCircleOutlined
                      className="successGreen"
                      style={{
                        fontSize: 20,
                        paddingLeft: 20,
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            <strong>
              {t("views.Options.ReviewDocuments.acceptAndContinue")}
            </strong>
          </div>
          <ol>
            <li>{t("views.Options.ReviewDocuments.disclosures.line1")}</li>
            <li>{t("views.Options.ReviewDocuments.disclosures.line2")}</li>
            <li>{t("views.Options.ReviewDocuments.disclosures.line3")}</li>
            <li>{t("views.Options.ReviewDocuments.disclosures.line4")}</li>
            <li>{t("views.Options.ReviewDocuments.disclosures.line5")}</li>
            <li>
              <strong>
                {t("views.Options.ReviewDocuments.disclosures.line6")}
              </strong>
            </li>
          </ol>
          <Row align="middle" justify="center">
            <Form>
              <Form.Item>
                {getFieldDecorator("doesUserAgree", {
                  initialValue: getInitialValue("doesUserAgree"),
                })(
                  <Checkbox checked={getInitialValue("doesUserAgree")}>
                    <div style={{ display: "inline" }}>
                      {t(
                        "views.Options.ReviewDocuments.disclosures.checkboxAgree",
                      )}
                    </div>
                  </Checkbox>,
                )}
              </Form.Item>
            </Form>
          </Row>
        </div>
        <FormButtonFooter
          goForwardButtonText={t("forms.buttons.acceptAndContinue")}
          goForwardHandler={() =>
            OptionsStore.validateReviewDocument(EOptionScreens.SUCCESS)
          }
          goBackHandler={() =>
            FormsStore.setCurrentScreen(EOptionScreens.SUMMARY)
          }
          isGoForwardDisabled={!!generateButtonDisabledMessage()}
          goForwardDisabledMessage={generateButtonDisabledMessage()}
        />
      </React.Fragment>
    );
  },
);

export default Form.create<FormComponentProps>({
  onValuesChange: (_: any, changedFields: IReviewDocuments) => {
    OptionsStore.updateFormData(changedFields, EOptionScreens.DOCUMENT_REVIEW);
  },
})(ReviewDocuments);
