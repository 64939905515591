import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Input, Alert } from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import FormsStore from "stores/FormsStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import AuthenticationStore from "stores/AuthenticationStore";

// ==== Types ====
import {
  EOnboardingForms,
  EOnboardingScreens,
  IIndividualForm,
} from "views/SignUp/Onboarding/types/onboardingTypes";
import { TOKEN_USER_STATES } from "types/authenticationTypes";
import { FEATURE_FLAGS } from "types/globalTypes";

// ==== Components ====
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";
import FormHeader from "components/FormHeader";
import PhoneNumberForm from "views/SignUp/components/PhoneNumbersForm";
import DateOfBirth from "views/SignUp/components/DateOfBirth";
import PrivacyPolicyFooter from "components/PrivacyPolicyFooter";

// ==== Utilities ====
import {
  addOptionalTag,
  concatenateSentences,
} from "utilities/genericUtilities";
import { emailFormValidator } from "utilities/formValidators";

const IndividualForm: React.FunctionComponent<FormComponentProps> = observer(
  ({ form }) => {
    const {
      getFieldDecorator,
      getFieldValue,
      isFieldTouched,
      isFieldValidating,
      validateFields,
      isFieldsTouched,
    } = form;
    const { t } = useTranslation();

    const handleSubmit = async (
      event: React.ChangeEvent<HTMLInputElement>,
      nextScreenKey: string,
    ): Promise<void> => {
      event.preventDefault();

      validateFields(async (err: string[]): Promise<void> => {
        if (!err) {
          await OnboardingStore.validateNewAccountInfo(nextScreenKey);
        }
      });
    };

    // ==== Email Validation ====
    const emailField = getFieldValue("email");
    const confirmEmailField = getFieldValue("confirmEmail");

    const validateEmail = React.useCallback(
      (rule: string, value: string, callback: (response?: string) => void) => {
        emailFormValidator(
          value,
          getFieldValue("ConfirmEmail"),
          t,
          callback,
          validateFields,
          isFieldsTouched,
          isFieldTouched,
          "Email",
          "ConfirmEmail",
        );
      },
      // For some reason TSLint really wants me to include emailFormValidator in the
      // dependency array but adding it just throws a chain of weird, errors
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [emailField],
    );

    const validateConfirmEmail = React.useCallback(
      (rule: string, value: string, callback: (response?: string) => void) => {
        emailFormValidator(
          value,
          getFieldValue("Email"),
          t,
          callback,
          validateFields,
          isFieldsTouched,
          isFieldTouched,
          "Email",
          "ConfirmEmail",
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [confirmEmailField],
    );

    const { Phone, ConfirmPhone, CountryCode, ConfirmCountryCode } =
      AuthenticationStore.newUserCreationFormData;

    const renderBanner = () => {
      if (FormsStore.infoSignUpMessage) {
        return (
          <Alert
            message={FormsStore.infoSignUpMessage}
            closable
            showIcon
            style={{ margin: "0 0 2em" }}
            type="info"
          />
        );
      }
    };

    const hasFoundUser =
      AuthenticationStore.tokenUserStats !== TOKEN_USER_STATES.USER_NOT_FOUND;

    const getInitialValue = (key: string) =>
      OnboardingStore.getFormItemData(EOnboardingForms.INDIVIDUAL, key);

    const isNextButtonDisabled = (): {
      disabledMessage: string;
      isDisabled: boolean;
    } => {
      let disabledMessage: string = "";
      let isDisabled: boolean = false;

      const Phone = getInitialValue("Phone");
      const ConfirmPhone = getInitialValue("ConfirmPhone");
      const CountryCode = getInitialValue("CountryCode");
      const ConfirmCountryCode = getInitialValue("ConfirmCountryCode");

      if (!Phone || !ConfirmPhone) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t("views.signUp.forms.NewUserCreationForm.validation.phoneNumber"),
        );
      }

      // 🦅 USA 🦅
      if (CountryCode === "1") {
        if (Phone?.length !== 10 || ConfirmPhone?.length !== 10) {
          isDisabled = true;
          disabledMessage = concatenateSentences(
            disabledMessage,
            t(
              "views.signUp.forms.NewUserCreationForm.validation.phoneNumberLengthUS",
            ),
          );
        }
      }

      // 🌎 Non-US users 🌏
      if (CountryCode !== "1") {
        // Disabled non US phone numbers per ticket 35382
        if (AuthenticationStore.getFeatureFlag(FEATURE_FLAGS.FOREIGN_ACCOUNT)) {
          if (
            Phone?.length < 6 ||
            Phone?.length > 15 ||
            ConfirmPhone?.length < 6 ||
            ConfirmPhone?.length > 15
          ) {
            isDisabled = true;
            disabledMessage = concatenateSentences(
              disabledMessage,
              t(
                "views.signUp.forms.NewUserCreationForm.validation.phoneNumberLengthForeign",
              ),
            );
          }
        } else {
          isDisabled = true;
          disabledMessage = concatenateSentences(
            disabledMessage,
            t(
              "views.signUp.forms.NewUserCreationForm.validation.countryCodeForeignDisable",
            ),
          );
        }
      }

      if (!CountryCode || !ConfirmCountryCode) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t(
            "views.signUp.forms.NewUserCreationForm.validation.countryCodeRequired",
          ),
        );
      }

      if (CountryCode !== ConfirmCountryCode) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t(
            "views.signUp.forms.NewUserCreationForm.validation.countryCodeMatch",
          ),
        );
      }

      const day = getInitialValue("DobDay");
      const month = getInitialValue("DobMonth");
      const year = getInitialValue("DobYear");

      if (!day || !month || !year) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t("views.signUp.forms.IndividualForm.needDobErr"),
        );
      }

      return {
        disabledMessage,
        isDisabled,
      };
    };

    const isConfirmEmailDisabled = (): boolean =>
      !!OnboardingStore.profileId ||
      AuthenticationStore.tokenUserStats === TOKEN_USER_STATES.USER_NOT_FOUND;

    return (
      <div className="slideInWrapper">
        <FormHeader
          headerText={t("views.signUp.forms.IndividualForm.header")}
          subheaderText={t("views.signUp.forms.IndividualForm.letsStart")}
        />
        {renderBanner()}
        <Form layout="vertical" hideRequiredMark>
          <Form.Item>
            {getFieldDecorator("Email", {
              initialValue: getInitialValue("Email"),
              rules: [
                {
                  required: true,
                },
                {
                  validator: validateEmail,
                },
              ],
              validateTrigger: "onBlur",
            })(
              <Input
                placeholder={t("forms.fields.email")}
                disabled={!!OnboardingStore.profileId || !hasFoundUser}
                allowClear
              />,
            )}
          </Form.Item>
          {!isConfirmEmailDisabled() && (
            <React.Fragment>
              <div className="meta">
                {t("views.signUp.forms.IndividualForm.emailSubheader")}
              </div>
              <Form.Item>
                {getFieldDecorator("ConfirmEmail", {
                  initialValue: getInitialValue("ConfirmEmail"),
                  rules: [
                    {
                      message: t("forms.validation.isRequired", {
                        fieldName: t("forms.fields.email"),
                      }),
                      required: true,
                    },
                    {
                      validator: validateConfirmEmail,
                    },
                  ],
                  validateTrigger: "onBlur",
                })(
                  <Input
                    placeholder={t("forms.fields.confirm", {
                      fieldName: t("forms.fields.email"),
                    })}
                    disabled={isConfirmEmailDisabled()}
                    allowClear
                  />,
                )}
              </Form.Item>
            </React.Fragment>
          )}
          <Form.Item>
            {getFieldDecorator("FirstName", {
              initialValue: getInitialValue("FirstName"),
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.firstName"),
                  }),
                  required: true,
                },
              ],
              validateTrigger: "onBlur",
            })(
              <Input
                placeholder={t("forms.fields.firstName")}
                disabled={!!OnboardingStore.profileId}
                allowClear
              />,
            )}
          </Form.Item>
          {hasFoundUser && (
            <Form.Item>
              {getFieldDecorator("MiddleName", {
                initialValue: getInitialValue("MiddleName"),
              })(
                <Input
                  placeholder={addOptionalTag(t, "forms.fields.middleName")}
                  allowClear
                />,
              )}
            </Form.Item>
          )}
          <Form.Item>
            {getFieldDecorator("LastName", {
              initialValue: getInitialValue("LastName"),
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.lastName"),
                  }),
                  required: true,
                },
              ],
              validateTrigger: "onBlur",
            })(
              <Input
                placeholder={t("forms.fields.lastName")}
                disabled={!!OnboardingStore.profileId}
                allowClear
              />,
            )}
          </Form.Item>
          {hasFoundUser && (
            <Form.Item>
              {getFieldDecorator("Suffix", {
                initialValue: getInitialValue("Suffix"),
              })(
                <Input
                  placeholder={addOptionalTag(t, "forms.fields.suffix")}
                  allowClear
                />,
              )}
            </Form.Item>
          )}
          <Form.Item
            label={
              <div>
                <div className="centeredContent">
                  <div style={{ paddingRight: 10 }}>
                    {t("forms.fields.dateOfBirth")}
                  </div>
                  <div className="smallMeta">
                    {t("views.signUp.forms.IndividualForm.youMustBe18")}
                  </div>
                </div>
              </div>
            }
            required={true}
          >
            <DateOfBirth
              fieldNameDay="DobDay"
              fieldNameMonth="DobMonth"
              fieldNameYear="DobYear"
              dayInitialValue={getInitialValue("DobDay")}
              monthInitialValue={getInitialValue("DobMonth")}
              yearInitialValue={getInitialValue("DobYear")}
              form={form}
              adultsOnly
            />
          </Form.Item>
          <PhoneNumberForm
            getFieldDecorator={getFieldDecorator}
            getFieldValue={getFieldValue}
            isFieldTouched={isFieldTouched}
            isFieldsTouched={isFieldsTouched}
            isFieldValidating={isFieldValidating}
            isConfirmVisible={!isConfirmEmailDisabled()}
            validateFields={validateFields}
            phoneNumber={getInitialValue("Phone") || Phone}
            confirmPhoneNumber={getInitialValue("ConfirmPhone" || ConfirmPhone)}
            countryCode={getInitialValue("CountryCode") || CountryCode}
            confirmCountryCode={
              getInitialValue("ConfirmCountryCode") || ConfirmCountryCode
            }
          />
          <PrivacyPolicyFooter />
          <FormButtonFooter
            isGoForwardDisabled={isNextButtonDisabled().isDisabled}
            goForwardDisabledMessage={isNextButtonDisabled().disabledMessage}
            goForwardHandler={(e) => {
              handleSubmit(e, EOnboardingScreens.PERSONAL_INFO);
            }}
          />
        </Form>
      </div>
    );
  },
);

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: IIndividualForm) => {
    OnboardingStore.updateFormData(changedFields, EOnboardingForms.INDIVIDUAL);
  },
})(IndividualForm);
