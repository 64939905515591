import React from "react";
import { Form } from "@ant-design/compatible";
import { Select, Input, Alert, Button, Tooltip } from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Types ===
import {
  EOnboardingForms,
  EOnboardingScreens,
  IUploadIdForm,
} from "views/SignUp/Onboarding/types/onboardingTypes";
import { GOVERNMENT_ID_TYPES } from "views/SignUp/Onboarding/types/onboardingEnums";

// ==== Stores ===
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import FormsStore from "stores/FormsStore";
import ConfigurationStore from "stores/ConfigurationStore";

// ==== Components ===
import DateOfBirth from "views/SignUp/components/DateOfBirth";
import FormHeader from "components/FormHeader";
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";
import { removeDocument } from "apiHandlers/ProfileApiHandler";

// ==== Utilities ====
import { renderSelectOptions } from "utilities/formItemUtilities";

const { Option } = Select;

const UploadIdForm: React.FunctionComponent<FormComponentProps> = observer(
  ({ form }) => {
    const { getFieldDecorator, validateFields } = form;
    const { t } = useTranslation();

    const handleSubmit = async (
      event: React.FormEvent<HTMLFormElement>,
      nextScreenKey: string,
    ): Promise<void> => {
      event.preventDefault();

      validateFields(async (err: string[]): Promise<void> => {
        if (!err) {
          await OnboardingStore.validateUploadIdInfo(nextScreenKey);
        }
      });
    };

    const saveFile = (e: any) =>
      OnboardingStore.setUploadedFile(e.target.files[0]);

    const getInitialValue = (key: string) =>
      OnboardingStore.getFormItemData(EOnboardingForms.UPLOAD_ID, key);

    const getCountryInitialValue = () => {
      let country = "US";

      // First, check if the user has already set the country
      if (getInitialValue("CountryOfIssuance")) {
        country = getInitialValue("CountryOfIssuance");
      }

      // Using the information from the TaxForm we can attempt to pre-populate this form for the user
      if (OnboardingStore.isUserUSCitizen()) {
        country = "US";
      }
      // else, populate with whatever they entered in the TaxForm
      if (
        OnboardingStore.getFormItemData(
          EOnboardingForms.PERSONAL_INFO,
          "CountryOfCitizenship",
        )
      ) {
        country = OnboardingStore.getFormItemData(
          EOnboardingForms.PERSONAL_INFO,
          "CountryOfCitizenship",
        );
      }

      // If we do NOT have a country value in our store, set it
      if (!getInitialValue("Country")) {
        // set the country value into the onboarding store
        OnboardingStore.setFormItemData(EOnboardingForms.UPLOAD_ID, {
          Country: country,
        });
      }

      // If our country value is undefined, set it into the store
      if (
        !OnboardingStore.getFormItemData(
          EOnboardingForms.UPLOAD_ID,
          "CountryOfIssuance",
        )
      ) {
        OnboardingStore.setFormItemData(EOnboardingForms.UPLOAD_ID, {
          CountryOfIssuance: country,
        });
      }
      // Return the value to the component
      return country;
    };

    const isNextButtonDisabled = () => {
      if (OnboardingStore.uploadedFile) {
        return false;
      } else {
        if (getInitialValue("FileName")) {
          return false;
        } else {
          return true;
        }
      }
    };

    return (
      <div className="slideInWrapper">
        <FormHeader
          headerText={t("views.signUp.forms.UploadIdForm.header")}
          subheaderText={t("views.signUp.forms.UploadIdForm.subheader")}
        />
        <Form layout="vertical" hideRequiredMark>
          {getInitialValue("isFileUploaded") !== undefined && (
            <React.Fragment>
              {getInitialValue("isFileUploaded") ? (
                <Alert
                  message={`${getInitialValue("FileName")}${t(
                    "views.signUp.forms.UploadIdForm.fileUploaded",
                  )}`}
                  closable
                  showIcon
                  style={{ margin: "0 0 2em" }}
                  type="success"
                />
              ) : (
                <Alert
                  message={t("views.signUp.forms.UploadIdForm.fileNotUploaded")}
                  closable
                  showIcon
                  style={{ margin: "0 0 2em" }}
                  type="error"
                />
              )}
            </React.Fragment>
          )}
          <div style={{ marginBottom: 25, marginTop: 25 }}>
            {getInitialValue("isFileUploaded") ? (
              <Tooltip
                placement="bottom"
                title={t("views.signUp.forms.UploadIdForm.tooltipMessage")}
              >
                <Button type="primary" onClick={() => removeDocument()}>
                  {t("views.signUp.forms.UploadIdForm.removeFile")}
                </Button>
              </Tooltip>
            ) : (
              <Input className="fileInput" onChange={saveFile} type="file" />
            )}
          </div>
          <Form.Item>
            {getFieldDecorator("IdType", {
              initialValue: getInitialValue("IdType"),
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("views.signUp.forms.UploadIdForm.idType"),
                  }),
                  required: true,
                },
              ],
            })(
              <Select
                placeholder={t("views.signUp.forms.UploadIdForm.idType")}
                filterOption={(input, option) => {
                  return option.children
                    .toString()
                    .toLowerCase()
                    .includes(input.toLowerCase());
                }}
                style={{ width: "100%" }}
              >
                {renderSelectOptions(GOVERNMENT_ID_TYPES, t)}
              </Select>,
            )}
          </Form.Item>
          {getInitialValue("IdType") === GOVERNMENT_ID_TYPES.OTHER.value && (
            <Form.Item>
              {getFieldDecorator("Description", {
                initialValue: getInitialValue("Description"),
                rules: [
                  {
                    message: t("forms.validation.isRequired", {
                      fieldName: t("views.signUp.forms.UploadIdForm.idType"),
                    }),
                    required: true,
                  },
                ],
              })(
                <Input
                  placeholder={t("views.signUp.forms.UploadIdForm.idType")}
                  allowClear
                />,
              )}
            </Form.Item>
          )}
          <Form.Item>
            {getFieldDecorator("IdNumber", {
              initialValue: getInitialValue("IdNumber"),
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("views.signUp.forms.UploadIdForm.idNumber"),
                  }),
                  required: true,
                },
              ],
              validateTrigger: "onBlur",
            })(
              <Input
                placeholder={t("views.signUp.forms.UploadIdForm.idNumber")}
                allowClear
              />,
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator("CountryOfIssuance", {
              initialValue: getCountryInitialValue(),
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.country"),
                  }),
                  required: true,
                },
              ],
            })(
              <Select
                placeholder={t(
                  "views.signUp.forms.UploadIdForm.countryOfIssuance",
                )}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {ConfigurationStore?.countryCodes?.map((country) => (
                  <Option key={country.code} value={country.code}>
                    {country.name}
                  </Option>
                ))}
              </Select>,
            )}
          </Form.Item>
          <Form.Item
            label={t("views.signUp.forms.UploadIdForm.dateIssued")}
            required={true}
          >
            <DateOfBirth
              fieldNameDay="IssuedDay"
              fieldNameMonth="IssuedMonth"
              fieldNameYear="IssuedYear"
              dayInitialValue={getInitialValue("IssuedDay")}
              monthInitialValue={getInitialValue("IssuedMonth")}
              yearInitialValue={getInitialValue("IssuedYear")}
              isRequired={false}
              form={form}
            />
          </Form.Item>
          <Form.Item
            label={t("views.signUp.forms.UploadIdForm.expirationDate")}
            required={true}
          >
            <DateOfBirth
              fieldNameDay="ExpirationDay"
              fieldNameMonth="ExpirationMonth"
              fieldNameYear="ExpirationYear"
              dayInitialValue={getInitialValue("ExpirationDay")}
              monthInitialValue={getInitialValue("ExpirationMonth")}
              yearInitialValue={getInitialValue("ExpirationYear")}
              form={form}
              isRequired={false}
              isFuture
            />
          </Form.Item>
          <FormButtonFooter
            goForwardHandler={(e) =>
              handleSubmit(e, EOnboardingScreens.EMPLOYMENT_INFORMATION)
            }
            goBackHandler={() =>
              FormsStore.setCurrentScreen(EOnboardingScreens.ADDRESS_COLLECTION)
            }
            isGoForwardDisabled={isNextButtonDisabled()}
          />
        </Form>
      </div>
    );
  },
);

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: IUploadIdForm) => {
    OnboardingStore.updateFormData(changedFields, EOnboardingForms.UPLOAD_ID);
  },
})(UploadIdForm);
