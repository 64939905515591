import * as React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

// ==== Components ====
import Checkmark from "assets/images/Checkmark";
import SalesForceChat from "components/SalesForceChat";

// ==== Types ====
import {
  IScreen,
  IScreenType,
  ACCOUNT_OPENING_TYPES,
  MARGIN_SCREENS,
  ONBOARDING_SCREENS,
  OPTIONS_SCREENS,
} from "views/SignUp/Onboarding/types/onboardingEnums";
import { EOnboardingScreens } from "views/SignUp/Onboarding/types/onboardingTypes";

// ==== Stores ====
import FormsStore from "stores/FormsStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Utilities ====
import { isApplicationRunningLocally } from "utilities/genericUtilities";

const sortScreensByOrder = (screens: IScreenType): IScreen[] => {
  return Object.values(screens).sort(
    (screenA, screenB) => screenA.orderIndex - screenB.orderIndex,
  );
};

const isCurrentScreenInObject = (screens: IScreenType): boolean => {
  let isScreenInObject = false;

  Object.values(screens).forEach((screen) => {
    if (screen.formKey === FormsStore.currentScreen) {
      isScreenInObject = true;
    }
  });

  return isScreenInObject;
};

const SidebarContainer: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();

  const getCurrentScreenList = (): IScreenType => {
    let returnScreens = ONBOARDING_SCREENS;

    if (isCurrentScreenInObject(MARGIN_SCREENS)) {
      returnScreens = MARGIN_SCREENS;
    } else if (isCurrentScreenInObject(OPTIONS_SCREENS)) {
      returnScreens = OPTIONS_SCREENS;
    }

    // By default, return Onboarding screens
    return returnScreens;
  };

  const renderMinimap = (): JSX.Element[] => {
    const minimapElements: JSX.Element[] = [];
    const orderedScreens = sortScreensByOrder(getCurrentScreenList());

    let currentScreenIndex = -1;
    orderedScreens.forEach((screen) => {
      // Loop through our screens to find the user's current screen
      if (FormsStore.currentScreen === screen.formKey) {
        currentScreenIndex = screen.orderIndex;
      }
    });

    // This is a 'messy' list, need to remove screens outside the user's
    // current workflow
    const screensToDisplay = orderedScreens.filter((screen) => {
      if (screen.isNotShown) {
        return false;
      }

      // Weird edge cases go here
      if (screen.isSecondaryScreen) {
        switch (screen.formKey) {
          case EOnboardingScreens.UPLOAD_ID:
            const isUSCitizen = OnboardingStore.isUserUSCitizen();
            return isUSCitizen !== undefined ? !isUSCitizen : false;
          case EOnboardingScreens.BENEFICIARIES:
            return (
              OnboardingStore.getAccountType() ===
                ACCOUNT_OPENING_TYPES.TRADITIONAL_IRA.value ||
              OnboardingStore.getAccountType() ===
                ACCOUNT_OPENING_TYPES.ROTH_IRA.value
            );
          default:
            return false;
        }
      }

      return true;
    });

    screensToDisplay.forEach((screen) => {
      const isCurrentScreen = FormsStore.currentScreen === screen.formKey;
      let isPreviousScreen = screen.orderIndex < currentScreenIndex;
      let listItemClass = "";
      let checkMarkClass = "";

      if (isCurrentScreen) {
        listItemClass = "currentScreen";
        checkMarkClass = "openCheck";
      } else if (isPreviousScreen) {
        listItemClass = "complete";
        checkMarkClass = "successCheck";
      }

      const handleOnClick = () => {
        // If app is running in dev mode, allow unlocked sidebar navigation
        if (isApplicationRunningLocally()) {
          FormsStore.setCurrentScreen(screen.formKey);
        } else if (isPreviousScreen) {
          FormsStore.setCurrentScreen(screen.formKey);
        }
      };

      minimapElements.push(
        <li
          key={screen.formKey}
          className={`${listItemClass} flexColumnWrap subtitle`}
          onClick={handleOnClick}
        >
          <div className="screenNameContainer flexRowNoWrap flexJustifySpaceBetween">
            <div className="screenName">{t(screen.translationKey)}</div>
            {(isCurrentScreen || isPreviousScreen) && (
              <div className={`sidebarCheck centeredContent ${checkMarkClass}`}>
                {isPreviousScreen && <Checkmark />}
              </div>
            )}
          </div>
        </li>,
      );
    });

    return minimapElements;
  };

  return (
    <div>
      <div className="sidebarContainer">
        <div>
          <ul className="minimap">{renderMinimap()}</ul>
        </div>
      </div>
      <SalesForceChat />
    </div>
  );
});

export default SidebarContainer;
