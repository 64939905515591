import * as React from "react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { RightOutlined } from "@ant-design/icons";
import { Alert, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// ==== Stores ====
import BeneficiariesStore from "views/SignUp/Onboarding/store/BeneficiariesStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import OptionsStore from "views/Options/OptionsStore";
import MarginStore from "views/Margin/MarginStore";
import FormsStore from "stores/FormsStore";
import AuthenticationStore from "stores/AuthenticationStore";
import SelfEnrollStore from "views/SignUp/SelfEnroll/store/SelfEnrollStore";

// ==== Utilities ====
import { ai } from "configuration/appInsights";
import { isApplicationRunningInDevMode } from "utilities/genericUtilities";

const ErrorMessageAlert: React.FunctionComponent<{
  defaultErrorMessage?: string;
  resetPath: string;
}> = ({ resetPath, defaultErrorMessage }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isDebuggingOpen, setIsDebugging] = React.useState<boolean>(false);

  const generateDebugData = () => ({
    authenticationStore: {
      accountSelectionMessage: AuthenticationStore.accountSelectionMessage,
      isUserProfileExist: AuthenticationStore.isUserProfileExist,
      returnScreen: AuthenticationStore.returnScreen,
      tokenUserStats: AuthenticationStore.tokenUserStats,
      urlSearchParams: {
        authEmail: AuthenticationStore.authEmail,
        urlAccountNumber: AuthenticationStore.urlAccountNumber,
        urlPrivateLabelCode: AuthenticationStore.urlPrivateLabelCode,
        urlRedirectUrl: AuthenticationStore.urlRedirectUrl,
        urlState: AuthenticationStore.urlState,
      },
    },
    beneficiariesStore: {
      allocatedContingentPercentage:
        BeneficiariesStore.allocatedContingentPercentage,
      allocatedPrimaryPercentage: BeneficiariesStore.allocatedPrimaryPercentage,
      contingentAccounts: BeneficiariesStore.contingentAccounts,
      isModalOpen: BeneficiariesStore.isModalOpen,
      isUserAllocatingPercentages:
        BeneficiariesStore.isUserAllocatingPercentages,
      primaryAccounts: BeneficiariesStore.primaryAccounts,
    },
    formsStore: {
      currentScreen: FormsStore.currentScreen,
      errorHandler: {
        message: FormsStore?.errorHandler?.message,
        source: FormsStore?.errorHandler?.source,
      },
    },
    globalData: {
      currentUrl: window.location.href,
      functionThatCalledError: "",
    },
    onboardingStore: {
      accountOpening: OnboardingStore.accountOpening,
      errorMaxRetryFailed: OnboardingStore.errorMaxRetryFailed,
      hasPartialAccountData: OnboardingStore.hasPartialAccountData,
      profileId: OnboardingStore.profileId,
    },

    optionsStore: {
      isMarginLevelSelectButtonClicked:
        OptionsStore.isMarginLevelSelectButtonClicked,
      isODDRead: OptionsStore.isODDRead,
    },
  });

  const resetAll = () => {
    OnboardingStore.handleFormReset();
    BeneficiariesStore.handleFormReset();
    MarginStore.handleFormReset();
    OptionsStore.handleFormReset();
    SelfEnrollStore.handleFormReset();

    FormsStore.setCurrentScreen(undefined);

    history.push(resetPath);
  };

  // Disable error debug code outside localhost/ dev/ test
  if (!isApplicationRunningInDevMode()) {
    return <div />;
  }

  // Render Alert if there is an error
  if (!FormsStore.errorHandler.source) {
    return null;
  }

  const renderAlertDescription = () => {
    return (
      <div>
        <p>
          {defaultErrorMessage ? defaultErrorMessage : t("error.default")}
          <a style={{ marginLeft: "5px" }} className="link" onClick={resetAll}>
            {t("views.signUp.forms.ConfirmationScreenForm.goBack")}
          </a>
        </p>

        <Button
          type="link"
          className="link"
          onClick={() => setIsDebugging(!isDebuggingOpen)}
        >
          <RightOutlined />
          {t("forms.buttons.additionalInformation")}
        </Button>
        <div>
          {isDebuggingOpen && (
            <div className="code">
              <code>{JSON.stringify(generateDebugData())}</code>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="errorMessageAlert">
      <Alert
        style={{ marginBottom: "1.5em", marginTop: "1.5em" }}
        message={<div className="subheader">{t("forms.result.error")}</div>}
        description={renderAlertDescription()}
        type="warning"
        showIcon
      />
    </div>
  );
};

export default withAITracking(ai.reactPlugin, ErrorMessageAlert);
