import * as React from "react";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Components ====
import FormHeader from "components/FormHeader";
import { renderFeaturesByAccountType } from "views/SignUp/Onboarding/forms/SuccessContainer/SuccessContainer";

const OptionSuccessScreen: React.FunctionComponent = () => {
  const { t } = useTranslation();

  return (
    <div className="slideInWrapper">
      <FormHeader
        headerText={t("views.Options.OptionSuccessScreen.header")}
        subheaderText={t("views.Options.OptionSuccessScreen.subheader")}
      />
      <div>{renderFeaturesByAccountType(OnboardingStore.getAccountType())}</div>
    </div>
  );
};

export default OptionSuccessScreen;
