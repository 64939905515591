import { get } from "mobx";

// ==== Types ====
import { ELocalStorageKeys } from "types/globalTypes";
import {
  EOnboardingForms,
  EOnboardingModelTypes,
  E_CAIS_Types,
  IDocumentType,
} from "views/SignUp/Onboarding/types/onboardingTypes";
import { EOptionsForms } from "views/Options/types/optionsTypes";
import { ADDRESS_TYPES } from "views/SignUp/Onboarding/types/onboardingEnums";

// ==== Utilities ====
import { getDateOfBirth, guardedGet } from "utilities/genericUtilities";
import { handleErrorTracking } from "utilities/apiUtilities";

// ==== Stores ====
import FormsStore from "stores/FormsStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import OptionsStore from "views/Options/OptionsStore";
import AuthenticationStore from "stores/AuthenticationStore";

// ==== Apis ====
import ProfileApi from "apis/ProfileApi";

const FILE_NAME = "ProfileApiHandler";

export const getHasProfile = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const response = await ProfileApi.getHasProfile(
      AuthenticationStore._tokenSource.token,
    );

    AuthenticationStore.isUserProfileExist = response;
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "getHasProfile");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const putIndividualData = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const newAccountInfoData = OnboardingStore.getFormData(
      EOnboardingForms.INDIVIDUAL,
    );
    const taxData = OnboardingStore.getFormData(EOnboardingForms.PERSONAL_INFO);

    const day = get(newAccountInfoData, "DobDay");
    const month = get(newAccountInfoData, "DobMonth");
    const year = get(newAccountInfoData, "DobYear");

    const response = await ProfileApi.putIndividualProfile(
      AuthenticationStore._tokenSource.token,
      OnboardingStore.profileId,
      {
        birthDate: getDateOfBirth(day, month, year),
        citizenshipCountry: get(taxData, "CountryOfCitizenship") || null,
        countryCode: get(newAccountInfoData, "CountryCode") || "1",
        email: get(newAccountInfoData, "Email"),
        firstName: get(newAccountInfoData, "FirstName"),
        foreignTaxId: get(taxData, "ForeignTaxId") || null,
        id: OnboardingStore.getModelId(EOnboardingModelTypes.INDIVIDUAL),
        lastName: get(newAccountInfoData, "LastName"),
        middleName: get(newAccountInfoData, "MiddleName"),
        phoneNumber: get(newAccountInfoData, "Phone"),
        suffix: get(newAccountInfoData, "Suffix"),
        taxId: get(taxData, "USTaxId") || null,
      },
    );

    if (response) {
      await getProfileById();
    } else {
      FormsStore.setErrorHandler("putIndividualData");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "putIndividualData");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const putAddressFormData = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const addressData = OnboardingStore.getFormData(
      EOnboardingForms.ADDRESS_COLLECTION,
    );

    // If the input country is not the U.S or Canada, don't send a state/province value
    let stateProvince = get(addressData, "StateProvince");
    const country = get(addressData, "Country");

    if (country !== "US" && country !== "CA") {
      stateProvince = undefined;
    }

    const response = await ProfileApi.putAddress(
      AuthenticationStore._tokenSource.token,
      {
        city: get(addressData, "City") || null,
        country: country,
        id: OnboardingStore.getModelId(
          EOnboardingModelTypes.ADDRESSES,
          ADDRESS_TYPES.INDIVIDUAL.value,
        ),
        profileId: OnboardingStore.profileId,
        stateProvince: stateProvince,
        street1: get(addressData, "StreetAddress1") || null,
        street2: get(addressData, "StreetAddress2") || null,
        type: ADDRESS_TYPES.INDIVIDUAL.value,
        zipPostal: get(addressData, "ZipPostal") || null,
      },
    );

    if (response) {
      await getProfileById();
    } else {
      FormsStore.setErrorHandler("putAddressFormData");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "putAddressFormData");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const putTrustedContactFormData = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const trustedContactData = OnboardingStore.getFormData(
      EOnboardingForms.TRUSTED_CONTACT,
    );

    // If user already has a Trusted Contact, delete it
    if (OnboardingStore.getModelId(EOnboardingModelTypes.TRUSTED_CONTACTS)) {
      const response = await ProfileApi.deleteTrustedContact(
        AuthenticationStore._tokenSource.token,
        OnboardingStore.getModelId(EOnboardingModelTypes.TRUSTED_CONTACTS),
      );

      if (response) {
        await getProfileById();
      } else {
        FormsStore.setErrorHandler(
          "putTrustedContactFormData - deleteTrustedContact",
        );
      }
    }

    const response = await ProfileApi.putTrustedContact(
      AuthenticationStore._tokenSource.token,
      {
        city: get(trustedContactData, "ContactCity") || null,
        country: get(trustedContactData, "ContactCountry") || null,
        countryCode: get(trustedContactData, "ContactCountryCode") || null,
        email: get(trustedContactData, "ContactEmail") || null,
        firstName: get(trustedContactData, "ContactFirstName") || null,
        id: OnboardingStore.getModelId(EOnboardingModelTypes.TRUSTED_CONTACTS),
        lastName: get(trustedContactData, "ContactLastName") || null,
        phoneNumber: get(trustedContactData, "ContactPhone") || null,
        profileId: OnboardingStore.profileId,
        relation: get(trustedContactData, "ContactRelationship") || null,
        state: get(trustedContactData, "ContactStateProvince") || null,
        street1: get(trustedContactData, "ContactStreetAddress1") || null,
        street2: get(trustedContactData, "ContactStreetAddress2") || null,
        zipCode: get(trustedContactData, "ContactZipPostal") || null,
      },
    );

    if (response) {
      await getProfileById();
    } else {
      FormsStore.setErrorHandler("putTrustedContactFormData");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "putTrustedContactFormData");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const putEmploymentFormData = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const employmentData = OnboardingStore.getFormData(
      EOnboardingForms.EMPLOYMENT_INFORMATION,
    );

    const employmentResponse = await ProfileApi.putEmploymentByProfileId(
      AuthenticationStore._tokenSource.token,
      OnboardingStore.profileId,
      {
        id: OnboardingStore.getModelId(EOnboardingModelTypes.EMPLOYMENT),
        ...guardedGet(employmentData, "occupation", "Occupation"),
        ...guardedGet(employmentData, "occupationDesc", "OccupationDesc"),
        ...guardedGet(employmentData, "employer", "Employer"),
        ...guardedGet(employmentData, "industry", "Industry"),
        ...guardedGet(employmentData, "industryDesc", "IndustryDesc"),
        ...guardedGet(employmentData, "jobFunction", "JobFunction"),
        ...guardedGet(employmentData, "jobFunctionDesc", "JobFunctionDesc"),
        employmentStatus: get(employmentData, "EmploymentStatus"),
        jobTitle: get(employmentData, "JobTitle"),
      },
    );

    // If we have an employer address
    if (get(employmentData, "EmployerStreetAddress1")) {
      const addressResponse = await ProfileApi.putAddress(
        AuthenticationStore._tokenSource.token,
        {
          id: OnboardingStore.getModelId(
            EOnboardingModelTypes.ADDRESSES,
            ADDRESS_TYPES.EMPLOYMENT.value,
          ),
          type: ADDRESS_TYPES.EMPLOYMENT.value,
          ...guardedGet(employmentData, "street1", "EmployerStreetAddress1"),
          ...guardedGet(employmentData, "street2", "EmployerStreetAddress2"),
          ...guardedGet(employmentData, "city", "EmployerCity"),
          ...guardedGet(
            employmentData,
            "stateProvince",
            "EmployerStateProvince",
          ),
          ...guardedGet(employmentData, "zipPostal", "EmployerZipPostal"),
          ...guardedGet(employmentData, "country", "EmployerCountry"),

          profileId: OnboardingStore.profileId,
        },
      );

      if (addressResponse) {
        await getProfileById();
      } else {
        FormsStore.setErrorHandler("putEmploymentFormData - addressResponse");
      }
    }
    if (employmentResponse) {
      await getProfileById();
    } else {
      FormsStore.setErrorHandler("putEmploymentFormData - employmentResponse");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "putEmploymentFormData");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const putDemographicFormData = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const employmentData = OnboardingStore.getFormData(
      EOnboardingForms.EMPLOYMENT_INFORMATION,
    );

    const response = await ProfileApi.putDemographic(
      AuthenticationStore._tokenSource.token,
      OnboardingStore.profileId,
      {
        id: OnboardingStore.getModelId(EOnboardingModelTypes.DEMOGRAPHIC),
        ...guardedGet(employmentData, "incomePrimarySource", "SourceOfIncome"),
      },
    );

    if (response) {
      await getProfileById();
    } else {
      FormsStore.setErrorHandler("putDemographicFormData");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "putDemographicFormData");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const putCAISFormData = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const caisData = OnboardingStore.getFormData(EOnboardingForms.CAIS);

    const response = await ProfileApi.putCAISCustomerType(
      AuthenticationStore._tokenSource.token,
      OnboardingStore.profileId,
      {
        accredited: get(caisData, E_CAIS_Types.Accredited) || false,
        advisor: get(caisData, E_CAIS_Types.Advisor) || false,
        cp: get(caisData, E_CAIS_Types.Cp) || false,
        employee: get(caisData, E_CAIS_Types.Employee) || false,
        foreign: get(caisData, E_CAIS_Types.Foreign) || false,
        id: OnboardingStore.getModelId(EOnboardingModelTypes.CAIS) || null,
        notApplicable: get(caisData, E_CAIS_Types.NotApplicable) || false,
        otherBroker: get(caisData, E_CAIS_Types.OtherBroker) || false,
      },
    );

    if (response) {
      await getProfileById();
    } else {
      FormsStore.setErrorHandler("putDemographicFormData");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "putDemographicFormData");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const putProfileSettings = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const response = await ProfileApi.putProfileSettings(
      AuthenticationStore._tokenSource.token,
      OnboardingStore.profileId,
      AuthenticationStore.urlPrivateLabelCode,
    );

    if (response) {
      await getPrivateLabelSetting();
    } else {
      FormsStore.setErrorHandler("putProfileSettings");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "putProfileSettings");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const getPrivateLabelSetting = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const response = await ProfileApi.getPrivateLabelSetting(
      AuthenticationStore._tokenSource.token,
      AuthenticationStore.urlPrivateLabelCode,
    );

    if (response) {
      AuthenticationStore.setPrivateLabelSetting(response);
    } else {
      FormsStore.setErrorHandler("getPrivateLabelSetting");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "getPrivateLabelSetting");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const putUpdateFinancialProfile = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const incomeData = OptionsStore.getFormData(
      EOptionsForms.FINANCIAL_PROFILE,
    );

    const liquidNetWorth = OnboardingStore.getFormItemData(
      EOnboardingForms.EMPLOYMENT_INFORMATION,
      "LiquidNetWorth",
    );

    const response = await ProfileApi.putFinancialProfile(
      AuthenticationStore._tokenSource.token,
      OnboardingStore.profileId,
      {
        income: get(incomeData, "annualIncome"),
        liquidNetWorth: liquidNetWorth,
        networth: get(incomeData, "netWorth"),
      },
    );

    if (response) {
      await getProfileById();
    } else {
      FormsStore.setErrorHandler("putUpdateFinancialProfile");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "putUpdateFinancialProfile");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const putTradingExperience = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const tradingExperienceData = OptionsStore.getFormData(
      EOptionsForms.TRADING_EXPERIENCE,
    );

    const response = await ProfileApi.putTradingExperience(
      AuthenticationStore._tokenSource.token,
      OnboardingStore.profileId,
      {
        ...guardedGet(
          tradingExperienceData,
          "yearsStockBonds",
          "stockAndBonds",
        ),
        ...guardedGet(tradingExperienceData, "yearsMutualFund", "mutualFunds"),
        ...guardedGet(tradingExperienceData, "yearsOptions", "options"),
        ...guardedGet(tradingExperienceData, "yearsAnnuities", "annuities"),
        ...guardedGet(
          tradingExperienceData,
          "yearsAltInvestment",
          "alternativeInvestments",
        ),
      },
    );

    if (response) {
      await getProfileById();
    } else {
      FormsStore.setErrorHandler("putTradingExperience");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "putTradingExperience");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const generateUserProfile = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    // Grabbing marketing key from the local storage
    const storedMessage = localStorage.getItem(ELocalStorageKeys.GTM_KEY);

    const response = await ProfileApi.postProfile(
      AuthenticationStore._tokenSource.token,
      storedMessage || AuthenticationStore.urlGtmKey,
    );

    if (response) {
      OnboardingStore.setProfileId(response.id);
      // deleting the marketing key if post successful
      localStorage.removeItem(ELocalStorageKeys.GTM_KEY);
    } else {
      FormsStore.setErrorHandler("generateUserProfile");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "generateUserProfile");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const putIndustryAffiliationFormData = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const stockExchangeData = OnboardingStore.getFormData(
      EOnboardingForms.STOCK_EXCHANGE_ASSOCIATION,
    );

    const response = await ProfileApi.putIndustryAffiliation(
      AuthenticationStore._tokenSource.token,
      OnboardingStore.profileId,
      {
        associated: get(stockExchangeData, "IsFINRA"),
        companyName: get(stockExchangeData, "BusinessName") || null,
        id: OnboardingStore.getModelId(EOnboardingModelTypes.INDUSTRY),
      },
    );

    if (response) {
      await getProfileById();
    } else {
      FormsStore.setErrorHandler("putIndustryAffiliationFormData");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "putIndustryAffiliationFormData");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const putPublicCompanyAffiliation = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const stockExchangeData = OnboardingStore.getFormData(
      EOnboardingForms.STOCK_EXCHANGE_ASSOCIATION,
    );

    const response = await ProfileApi.putPublicCompanyAffiliation(
      AuthenticationStore._tokenSource.token,
      OnboardingStore.profileId,
      {
        associated: get(stockExchangeData, "Rule114"),
        id: OnboardingStore.getModelId(EOnboardingModelTypes.PUBLIC_COMPANY),
        name: get(stockExchangeData, "CompanyName") || null,
        symbol: get(stockExchangeData, "CUSIP") || null,
      },
    );

    if (response) {
      await getProfileById();
    } else {
      FormsStore.setErrorHandler("putPublicCompanyAffiliation");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "putPublicCompanyAffiliation");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const putGovernmentId = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const documentData = OnboardingStore.getFormData(
      EOnboardingForms.UPLOAD_ID,
    );

    const issuedDay = get(documentData, "IssuedDay") || null;
    const issuedMonth = get(documentData, "IssuedMonth") || null;
    const issuedYear = get(documentData, "IssuedYear") || null;
    const expirationDay = get(documentData, "ExpirationDay") || null;
    const expirationMonth = get(documentData, "ExpirationMonth") || null;
    const expirationYear = get(documentData, "ExpirationYear") || null;

    const response = await ProfileApi.putGovernmentId(
      AuthenticationStore._tokenSource.token,
      {
        country: get(documentData, "CountryOfIssuance") || null,
        description: get(documentData, "Description") || null,
        expirationDate: getDateOfBirth(
          expirationDay,
          expirationMonth,
          expirationYear,
        ),
        id: OnboardingStore.getModelId(EOnboardingModelTypes.DOCUMENTS),
        idNumber: get(documentData, "IdNumber") || null,
        idType: get(documentData, "IdType") || null,
        issueDate: getDateOfBirth(issuedDay, issuedMonth, issuedYear),
      },
      get(documentData, "DocumentId"),
    );

    if (response) {
      await getProfileById();
    } else {
      FormsStore.setErrorHandler("putDocumentData");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "putDocumentData");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const putFirmAffiliation = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const stockExchangeData = OnboardingStore.getFormData(
      EOnboardingForms.STONEX_AFFILIATE,
    );

    const response = await ProfileApi.putFirmAffiliation(
      AuthenticationStore._tokenSource.token,
      OnboardingStore.profileId,
      {
        affiliatedEntity: get(stockExchangeData, "AffiliatedEntity"),
        associated: get(stockExchangeData, "AreYouRelated"),
        firstName: get(stockExchangeData, "AffiliateFirstName") || null,
        id: OnboardingStore.getModelId(EOnboardingModelTypes.FIRM),
        lastName: get(stockExchangeData, "AffiliateLastName") || null,
        position: get(stockExchangeData, "WhatPosition") || null,
        relationship:
          get(stockExchangeData, "AssociationRelationshipType") || null,
      },
    );

    if (response) {
      await getProfileById();
    } else {
      FormsStore.setErrorHandler("putFirmAffiliation");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "putFirmAffiliation");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const removeDocument = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const response = await ProfileApi.deleteDocument(
      AuthenticationStore._tokenSource.token,
      OnboardingStore.getModelId(EOnboardingModelTypes.DOCUMENTS),
    );

    if (response) {
      // If file is deleted, reset form data
      OnboardingStore.formData[EOnboardingForms.UPLOAD_ID] = {
        ...OnboardingStore.formData[EOnboardingForms.UPLOAD_ID],
        CountryOfIssuance: undefined,
        Description: undefined,
        ExpirationDay: undefined,
        ExpirationMonth: undefined,
        ExpirationYear: undefined,
        IdNumber: undefined,
        IdType: undefined,
        isFileUploaded: undefined,
        IssuedDay: undefined,
        IssuedMonth: undefined,
        IssuedYear: undefined,
      };

      OnboardingStore.setUploadedFile(undefined);
      await getProfileById();
    } else {
      OnboardingStore.setFormItemData(EOnboardingForms.UPLOAD_ID, {
        isFileUploaded: true,
      });
      FormsStore.setErrorHandler("removeDocument");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "removeDocument");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const getProfileById = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  if (!OnboardingStore.profileId) {
    FormsStore.setErrorHandler("getProfileById - NoProfileId");
    return;
  }

  try {
    FormsStore.setLoading(true);
    const response = await ProfileApi.getProfileById(
      AuthenticationStore._tokenSource.token,
      OnboardingStore.profileId,
    );

    if (response) {
      OnboardingStore.setProfile(response);
      OnboardingStore.handleAccountOpeningUpdate(response);
    } else {
      FormsStore.setErrorHandler("getProfileById");
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "getProfileById");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const getMasterDocument = async (
  documentName: string,
): Promise<IDocumentType> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const response = await ProfileApi.getMasterDocument(
      AuthenticationStore._tokenSource.token,
      documentName,
    );

    if (response) {
      OnboardingStore.setPdfData(response);
      return response;
    } else {
      return undefined;
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "getMasterDocument");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const getMasterDocumentDownload = async (
  documentName: string,
): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    OnboardingStore.isFileDownloading = documentName;

    const rawFileData = await ProfileApi.getMasterDocumentDownload(
      AuthenticationStore._tokenSource.token,
      documentName,
    );

    // Downloads the raw file data, compiles it into a pdf and then
    // opens it into a new tab
    // https://stackoverflow.com/questions/21729451/pdf-blob-pop-up-window-not-showing-content/21730535#21730535
    let file = new Blob([rawFileData], { type: "application/pdf" });
    let fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "getMasterDocumentDownload");
  } finally {
    OnboardingStore.isFileDownloading = undefined;
  }
};
