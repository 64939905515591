import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken,
  CancelTokenSource,
} from "axios";

// ==== Stores ====
import AuthenticationStore from "stores/AuthenticationStore";

// ==== Types ====
import { EHttpMethods } from "types/globalTypes";
import { IDocument } from "views/SignUp/Onboarding/types/onboardingTypes";

// ==== Utilities ====
import { generateBaseUrl, handleErrorTracking } from "utilities/apiUtilities";

class DocumentUploadApi {
  newTokenSource = (): CancelTokenSource => {
    return axios.CancelToken.source();
  };

  postDocument = async (
    cancelToken: CancelToken,
    profileId: string,
    documentId: string,
    formData: any,
  ): Promise<IDocument> => {
    const response = await this._call({
      cancelToken,
      data: formData,
      method: EHttpMethods.POST,
      url: `/Document?ProfileId=${profileId}${
        documentId ? `&DocumentId=${documentId}` : ""
      }`,
    });

    return response ? response.data : undefined;
  };

  private _call = async (
    config: AxiosRequestConfig,
  ): Promise<AxiosResponse> => {
    try {
      let _axios = axios.create({
        baseURL: `${generateBaseUrl()}torchapi/v1/Profile`,
        headers: {
          Authorization: `Bearer ${AuthenticationStore.accessToken}`,
          "Cache-Control": "no-cache,no-store,max-age=-1",
          "Content-Type": "multipart/form-data",
          Pragma: "no-cache",
        },
        responseType: "json",
        timeout: 60000,
      });

      const response = await _axios(config);
      return response;
    } catch (e) {
      handleErrorTracking(e, "DocumentUploadApi", "_call");
    }
  };
}

export default new DocumentUploadApi();
