import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Alert, Button, Slider } from "antd";
import { observer } from "mobx-react";
import { TFunction, useTranslation } from "react-i18next";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { useHistory } from "react-router-dom";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import OptionsStore from "views/Options/OptionsStore";
import FormsStore from "stores/FormsStore";

// ==== Components ====
import FormHeader from "components/FormHeader";
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";

// ==== Types ====
import {
  EOptionScreens,
  EOptionsForms,
  IOptionInvestmentObjectives,
} from "views/Options/types/optionsTypes";
import { EMarginScreens } from "views/Margin/types/marginTypes";
import {
  INVESTMENT_OBJECTIVES,
  OPTIONS_TRADING_LEVEL,
} from "views/Options/types/optionsEnums";
import { ACCOUNT_OPENING_TYPES } from "views/SignUp/Onboarding/types/onboardingEnums";
import { ERoutePaths } from "types/globalTypes";

// ==== Utilities ====
import {
  IEnumType,
  getTranslationKeyWithValue,
  IEnum,
} from "utilities/typeUtilities";
import { sliderStyle } from "utilities/styleConstants";
import { isEnumFalsey, concatenateSentences } from "utilities/genericUtilities";

const OptionInvestmentObjectives: React.FunctionComponent<FormComponentProps> =
  observer(({ form }) => {
    const { validateFields, getFieldDecorator } = form;
    const history = useHistory();
    const { t } = useTranslation();

    // ==== Handlers ====
    const goBackHandler = () => {
      FormsStore.setCurrentScreen(EOptionScreens.TRADING_EXPERIENCE);
    };

    const marginOnClickHandler = () => {
      OptionsStore.isMarginLevelSelectButtonClicked = true;
      FormsStore.setCurrentScreen(EMarginScreens.WELCOME);
      history.push(`${ERoutePaths.MARGIN}/${EMarginScreens.WELCOME}`);
    };

    const goForwardHandler = (e: React.FormEvent<HTMLFormElement>) =>
      handleSubmit(e, EOptionScreens.SUMMARY);

    const handleSubmit = async (
      event: React.FormEvent<HTMLFormElement>,
      nextScreenKey: string,
    ): Promise<void> => {
      event.preventDefault();

      validateFields(async (err: string[]): Promise<void> => {
        if (!err) {
          await OptionsStore.validateOptionInvestmentObjectives(nextScreenKey);
        }
      });
    };

    const handleRadioUpdate = (e: React.FormEvent<HTMLInputElement>) => {
      OptionsStore.setFormItemData(
        EOptionScreens.OPTION_INVESTMENT_OBJECTIVES,
        {
          level: parseInt(e?.currentTarget?.value),
        },
      );
    };

    // ==== Getters/Setters ====
    const isNextButtonDisabled = (): {
      disabledMessage: string;
      isDisabled: boolean;
    } => {
      const investmentObjectivesValue = getInitialValue("investmentObjectives");
      const levelValue = getInitialValue("level");

      let isDisabled = false;
      let disabledMessage = "";

      if (!isEnumFalsey(investmentObjectivesValue)) {
        isDisabled = true;
        disabledMessage = t(
          "views.Options.OptionInvestmentObjectives.validationMessages.investmentObjective",
        );
      }

      if (!isEnumFalsey(levelValue)) {
        isDisabled = true;
        disabledMessage = concatenateSentences(
          disabledMessage,
          t(
            "views.Options.OptionInvestmentObjectives.validationMessages.levelValue",
          ),
        );
      }

      return {
        disabledMessage,
        isDisabled,
      };
    };

    const getInvestmentObjective = (): IEnum => {
      const riskLevels = Object.keys(INVESTMENT_OBJECTIVES);
      const objectiveIndex =
        riskLevels[getInitialValue("investmentObjectives")];
      const objective = INVESTMENT_OBJECTIVES[objectiveIndex];
      return objective;
    };

    const isMarginAgreementSet = (): boolean => {
      const accountOpening = OnboardingStore.getAccountOpening();

      return !!accountOpening?.accountFeature?.margin;
    };

    const getInitialValue = (key: string) => {
      return OptionsStore.getFormItemData(
        EOptionsForms.OPTION_INVESTMENT_OBJECTIVES,
        key,
      );
    };

    const isAccountTypeIra = (): boolean => {
      return (
        OnboardingStore.getAccountType() ===
          ACCOUNT_OPENING_TYPES.TRADITIONAL_IRA.value ||
        OnboardingStore.getAccountType() ===
          ACCOUNT_OPENING_TYPES.ROTH_IRA.value
      );
    };

    const isRadioButtonDisabled = (value: number): boolean => {
      const { LEVEL1, LEVEL2, LEVEL3, LEVEL4, LEVEL5 } = OPTIONS_TRADING_LEVEL;

      let isDisabled = false;

      // Check if user's selected risk tolerance allows for the radio option
      const riskLevel = getInvestmentObjective()?.value;
      // Trading level 2 is available without margin Agreement
      if (!isMarginAgreementSet() && value !== LEVEL2.value) {
        isDisabled = true;
      } else if (
        value === LEVEL2.value &&
        riskLevel < INVESTMENT_OBJECTIVES.MODERATE.value
      ) {
        isDisabled = true;
      } else if (
        value === LEVEL3.value &&
        riskLevel < INVESTMENT_OBJECTIVES.AGGRESSIVE.value
      ) {
        isDisabled = true;
      } else if (
        // Check if risk level is at least Speculative before enabling remaining levels
        (value === LEVEL4.value || value === LEVEL5.value) &&
        riskLevel < INVESTMENT_OBJECTIVES.SPECULATIVE.value
      ) {
        isDisabled = true;
      }

      // IRA accounts only have access to level 1 trading
      if (isAccountTypeIra()) {
        isDisabled = true;
      }

      if (value === LEVEL1.value) {
        // Trading level 1 is always enabled
        isDisabled = false;
      }

      return isDisabled;
    };

    // ==== Render Methods ====
    const renderAlert = () => {
      if (isAccountTypeIra()) {
        return (
          <Alert
            message={t(
              "views.Options.OptionInvestmentObjectives.AlertMessages.iraAlertMessage",
            )}
            showIcon
            type="info"
            style={{ marginBottom: 20 }}
          />
        );
      }

      if (!isMarginAgreementSet()) {
        return (
          <Alert
            message={
              <div className="marginAlertWrapper">
                <div>
                  {t(
                    "views.Options.OptionInvestmentObjectives.AlertMessages.marginAgreementMessage",
                  )}
                </div>
                <div className="marginAlertButtonWrapper">
                  <Button onClick={marginOnClickHandler} type="primary">
                    {t(
                      "views.Options.OptionInvestmentObjectives.marginAgreement.button",
                    )}
                  </Button>
                </div>
                <div>
                  <em>
                    {t(
                      "views.Options.OptionInvestmentObjectives.marginAgreement.footer",
                    )}
                  </em>
                </div>
              </div>
            }
            showIcon
            type="info"
            style={{ marginBottom: 20 }}
          />
        );
      }

      let message;
      const currentObjective = getInvestmentObjective();
      if (
        currentObjective &&
        currentObjective.value !== INVESTMENT_OBJECTIVES.SPECULATIVE.value
      ) {
        switch (currentObjective.key) {
          case INVESTMENT_OBJECTIVES.CONSERVATIVE.key:
            message = t(
              "views.Options.OptionInvestmentObjectives.AlertMessages.moderateMessage",
            );
            break;
          case INVESTMENT_OBJECTIVES.MODERATE.key:
            message = t(
              "views.Options.OptionInvestmentObjectives.AlertMessages.aggressiveMessage",
            );
            break;
          case INVESTMENT_OBJECTIVES.AGGRESSIVE.key:
            message = t(
              "views.Options.OptionInvestmentObjectives.AlertMessages.speculativeMessage",
            );
            break;
          default:
            break;
        }

        return (
          <Alert
            message={message}
            showIcon
            type="info"
            style={{ marginBottom: 20 }}
          />
        );
      }
    };

    const renderRadio = ({
      value,
      headerText,
      marginRequired,
      childText,
    }: {
      childText: string[];
      headerText: string;
      marginRequired: boolean;
      value: number;
    }) => (
      <div className="radioWrapper">
        <input
          disabled={isRadioButtonDisabled(value)}
          onClick={handleRadioUpdate}
          type="radio"
          name="radioGroup"
          defaultChecked={value === getInitialValue("level")}
          value={value}
        />
        <div
          className={`radioDescriptionBody ${
            isRadioButtonDisabled(value) ? "isRadioBtnDisabled" : ""
          }`}
        >
          <div className="descriptionHeader body">
            <strong>{t(headerText)}</strong>
          </div>
          <div className="descriptionBody">
            {childText.map((child, i) => (
              <div
                key={child}
                className={`${i === 0 ? "reminderText" : ""}`}
              >{`${i ? "-" : " "} ${t(child)}`}</div>
            ))}
            <div className="radioSubheader">
              {marginRequired && (
                <i>
                  (
                  {t(
                    "views.Options.OptionInvestmentObjectives.marginAgreement.required",
                  )}
                  )
                </i>
              )}
            </div>
          </div>
        </div>
      </div>
    );

    const renderRangeSlider = (options: IEnumType, t: TFunction) => {
      let marks = {
        0: {
          label: (
            <div className="optionsMarkLabelStyle">
              <strong>
                {t("views.Options.OptionInvestmentObjectives.low")}
              </strong>
            </div>
          ),
        },
        1: "",
        2: "",
        3: {
          label: (
            <div className="optionsMarkLabelStyle">
              <strong>
                {t("views.Options.OptionInvestmentObjectives.high")}
              </strong>
            </div>
          ),
        },
      };
      const optionsArray = Object.keys(options);

      return (
        <Slider
          handleStyle={sliderStyle}
          tooltipVisible={false}
          trackStyle={sliderStyle}
          min={0}
          max={optionsArray.length - 1}
          marks={marks}
        />
      );
    };

    return (
      <div className="slideInWrapper">
        <Form className="investmentObjectivesForm" layout="vertical">
          <FormHeader
            headerText={t(
              "views.Options.OptionInvestmentObjectives.optionsHeader",
            )}
          />
          <div className="body">
            <em>
              {t("views.Options.OptionInvestmentObjectives.subtitle1")}
              <strong>
                {t("views.Options.OptionInvestmentObjectives.subtitle2")}
              </strong>
            </em>
          </div>
          <div>
            <div className="subheader">
              <strong>
                {t(
                  "views.Options.OptionInvestmentObjectives.investmentObjectives",
                )}
              </strong>
            </div>
            <div className="body">
              {t("views.Options.OptionInvestmentObjectives.sliderControls")}
            </div>
          </div>
          <div>
            <Form.Item>
              {getFieldDecorator("investmentObjectives", {
                initialValue: getInitialValue("investmentObjectives"),
                rules: [
                  {
                    message: t("forms.validation.isRequired", {
                      fieldName: "Investment Objective",
                    }),
                    required: true,
                  },
                ],
              })(renderRangeSlider(INVESTMENT_OBJECTIVES, t))}
            </Form.Item>
            <div className="objectiveDescriptionWrapper">
              {isEnumFalsey(getInitialValue("investmentObjectives")) && (
                <div className="objectiveDescription">
                  <div className="header">
                    <strong>
                      {t(
                        getTranslationKeyWithValue(
                          INVESTMENT_OBJECTIVES,
                          getInitialValue("investmentObjectives"),
                        ),
                      )}
                    </strong>
                  </div>
                  <p style={{ textAlign: "center" }}>
                    {t(
                      `views.Options.OptionInvestmentObjectives.objectiveDescriptions.${
                        getInvestmentObjective().key
                      }`,
                    )}
                  </p>
                </div>
              )}
            </div>
          </div>
          <div>
            <div className="subheader">
              <strong>
                {t(
                  "views.Options.OptionInvestmentObjectives.tradingLevelHeader",
                )}
              </strong>
            </div>
            <div className="body">
              {t(
                "views.Options.OptionInvestmentObjectives.tradingLevelSubheader.line1",
              )}
              <strong>
                {t(
                  "views.Options.OptionInvestmentObjectives.tradingLevelSubheader.line2",
                )}
              </strong>
            </div>
          </div>
          {renderAlert()}
          <div className="radioGroupWrapper">
            {renderRadio(OPTIONS_TRADING_LEVEL.LEVEL1)}
            {renderRadio(OPTIONS_TRADING_LEVEL.LEVEL2)}
            {renderRadio(OPTIONS_TRADING_LEVEL.LEVEL3)}
            {renderRadio(OPTIONS_TRADING_LEVEL.LEVEL4)}
            {renderRadio(OPTIONS_TRADING_LEVEL.LEVEL5)}
          </div>
          <FormButtonFooter
            isGoForwardDisabled={isNextButtonDisabled().isDisabled}
            goForwardDisabledMessage={isNextButtonDisabled().disabledMessage}
            goForwardHandler={goForwardHandler}
            goBackHandler={goBackHandler}
          />
        </Form>
      </div>
    );
  });

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: IOptionInvestmentObjectives) => {
    OptionsStore.updateFormData(
      changedFields,
      EOptionScreens.OPTION_INVESTMENT_OBJECTIVES,
    );
  },
})(OptionInvestmentObjectives);
