import { get } from "mobx";

// ==== Stores ====
import FormsStore from "stores/FormsStore";
import AuthenticationStore, {
  DEFAULT_COMPANY_NAME,
} from "stores/AuthenticationStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Apis ====
import SelfEnrollApi from "apis/SelfEnrollApi";

// ==== Types ====
import { IUserExistsResponse } from "views/SignUp/SelfEnroll/types/selfEnrollTypes";
import {
  EOnboardingAPIResponseTypes,
  EOnboardingForms,
} from "views/SignUp/Onboarding/types/onboardingTypes";

// ==== Utilities ====
import { handleErrorTracking } from "utilities/apiUtilities";
import { getDateOfBirth } from "utilities/genericUtilities";

const FILE_NAME = "SelfEnrollApiHandler";

export const postSignUp = async (): Promise<boolean> => {
  AuthenticationStore.checkTokenSource();

  let response = undefined;

  try {
    FormsStore.setLoading(true);

    const formData = AuthenticationStore.newUserCreationFormData;

    const requestBody = {
      companyName: DEFAULT_COMPANY_NAME,
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      password: formData.password,
      phoneNumber: {
        countryCode: formData.CountryCode,
        phone: formData.Phone,
      },
    };
    response = await SelfEnrollApi.postSignUp(
      requestBody,
      AuthenticationStore._tokenSource.token,
    );
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "postSignUp");
  } finally {
    FormsStore.setLoading(false);
  }

  return response;
};

export const postExistingUserSignUp = async (): Promise<boolean> => {
  AuthenticationStore.checkTokenSource();

  let response = undefined;

  try {
    FormsStore.setLoading(true);

    const formData = AuthenticationStore.newUserCreationFormData;

    const requestBody = {
      companyName: DEFAULT_COMPANY_NAME,
      email: formData.email,
      firstName: formData.firstName,
      lastName: formData.lastName,
      password: formData.password,
      phoneNumber: {
        countryCode: formData.CountryCode,
        phone: formData.Phone,
      },
    };
    response = await SelfEnrollApi.postExistingUserSignUp(
      requestBody,
      AuthenticationStore._tokenSource.token,
    );
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "postExistingUserSignUp");
  } finally {
    FormsStore.setLoading(false);
  }

  return response;
};

export const postVerificationCode = async (): Promise<boolean> => {
  AuthenticationStore.checkTokenSource();

  let response = undefined;

  try {
    FormsStore.setLoading(true);

    const formData = AuthenticationStore.newUserCreationFormData;

    response = await SelfEnrollApi.postVerification(
      {
        activationCode: AuthenticationStore.verificationCode,
        email: formData.email,
        password: formData.password,
      },
      AuthenticationStore._tokenSource.token,
    );

    if (response.success) {
      AuthenticationStore.setJWTToken(response.jwt);
    }
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "postVerificationCode");
  } finally {
    FormsStore.setLoading(false);
  }

  return response.success;
};

export const getUserExists = async (): Promise<IUserExistsResponse> => {
  AuthenticationStore.checkTokenSource();

  let response = undefined;

  try {
    FormsStore.setLoading(true);

    response = await SelfEnrollApi.getUserExists(
      AuthenticationStore.newUserCreationFormData?.email,
      AuthenticationStore._tokenSource.token,
    );
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "getUserExists");
  } finally {
    FormsStore.setLoading(false);
  }

  return response;
};

export const getExistingEmail = async (): Promise<string> => {
  AuthenticationStore.checkTokenSource();

  let response = undefined;

  try {
    FormsStore.setLoading(true);

    response = await SelfEnrollApi.getExistingEmail(
      AuthenticationStore.urlProfileId,
      AuthenticationStore._tokenSource.token,
    );
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "getExistingEmail");
  } finally {
    FormsStore.setLoading(false);
  }

  return response;
};

export const postAuthenticate = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  let response = undefined;

  try {
    FormsStore.setLoading(true);

    await SelfEnrollApi.postAuthenticate();
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "postAuthenticate");
  } finally {
    FormsStore.setLoading(false);
  }

  return response;
};

export const resendVerificationEmail = async (email: string): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    await SelfEnrollApi.getVerificationEmailResend(
      AuthenticationStore._tokenSource.token,
      email,
    );
  } catch (e) {
    handleErrorTracking(e, FILE_NAME, "resendVerificationEmail");
  } finally {
    FormsStore.setLoading(false);
  }
};

export const addNewUserClient = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  try {
    FormsStore.setLoading(true);

    const newAccountInfoData = OnboardingStore.getFormData(
      EOnboardingForms.INDIVIDUAL,
    );

    const day = get(newAccountInfoData, "DobDay");
    const month = get(newAccountInfoData, "DobMonth");
    const year = get(newAccountInfoData, "DobYear");

    const response = await SelfEnrollApi.postAddNewUserClient(
      AuthenticationStore._tokenSource.token,
      {
        dateOfBirth: getDateOfBirth(day, month, year),
        email: get(newAccountInfoData, "Email"),
        firstName: get(newAccountInfoData, "FirstName"),
        lastName: get(newAccountInfoData, "LastName"),
        phoneNumber: {
          countryCode: get(newAccountInfoData, "CountryCode"),
          phone: get(newAccountInfoData, "Phone"),
        },
        privatelabelcode: AuthenticationStore.urlPrivateLabelCode,
      },
    );

    if (response.status !== EOnboardingAPIResponseTypes.VALID) {
      FormsStore.setErrorHandler("addNewUserClient");
      OnboardingStore.setHasNewAccountNotification(true);
    }
  } catch (e) {
    handleErrorTracking(e, "OnboardingApiHandler", "addNewUserClient");
  } finally {
    FormsStore.setLoading(false);
  }
};
