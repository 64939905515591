import * as React from "react";

// ==== Components ====
import BrowserWarningFooter from "components/BrowserWarningFooter";
import TopBar from "components/TopBar";
import SidebarContainer from "components/sidebar/SidebarContainer";
import NewAccountDisclosure from "components/NewAccountDisclosure";
import ErrorMessageAlert from "views/SignUp/Onboarding/components/ErrorMessageAlert";

// ==== Types ====
import { ERoutePaths } from "types/globalTypes";

const SidebarWrapper: React.FunctionComponent = ({ children }) => (
  <div className="pageWrapper">
    <TopBar isLogoStonexOne />
    <div className="contentWrapper">
      <div className="sidebar">
        <SidebarContainer />
      </div>
      <div className="contentBody">
        <div>{children}</div>
        <div className="contentDisclosure">
          <NewAccountDisclosure />
        </div>
      </div>
    </div>
    <ErrorMessageAlert resetPath={ERoutePaths.ONBOARDING} />
    <BrowserWarningFooter />
  </div>
);

export default SidebarWrapper;
