import * as React from "react";
import { Input, Form } from "antd";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import BeneficiariesStore from "views/SignUp/Onboarding/store/BeneficiariesStore";
import AuthenticationStore from "stores/AuthenticationStore";
import FormsStore from "stores/FormsStore";

// ==== Types ====
import { ERoutePaths } from "types/globalTypes";

// ==== Components ====
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";
import PrivacyPolicyFooter from "components/PrivacyPolicyFooter";

// ==== Utilities ====
import { setQueryStringsIntoState } from "utilities/apiUtilities";
import { emailValidatorMessages } from "utilities/formValidators";

const LoginForm: React.FunctionComponent = observer(() => {
  const [form] = Form.useForm();
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  const { validateFields } = form;

  const handleSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault();

    validateFields().then((values) => {
      AuthenticationStore.getTokenWithEmail(
        AuthenticationStore?.loginFormData?.Email,
      );
    });
  };

  React.useEffect(() => {
    const { search } = location;

    const {
      authEmail,
      returnScreen,
      updateLoginFormData,
      getTokenWithEmail,
      handleInitialTokenFetch,
    } = AuthenticationStore;

    setQueryStringsIntoState(search);

    if (authEmail) {
      FormsStore.setLoading(true);

      updateLoginFormData({
        ConfirmEmail: authEmail,
        Email: authEmail,
      });

      getTokenWithEmail(authEmail);
    } else {
      const initialize = async (): Promise<void> => {
        await handleInitialTokenFetch(returnScreen);
      };
      initialize();
    }
  }, [location]);

  const handleClick = (url: string): void => {
    history.push(url);
  };

  const goForwardHandler = (e: React.FormEvent<HTMLFormElement>) => {
    handleSubmit(e);
  };

  const goBackHandler = () => {
    OnboardingStore.handleFormReset();
    BeneficiariesStore.handleFormReset();
    FormsStore.setCurrentScreen(undefined);
    handleClick(ERoutePaths.SIGNUP);
  };

  return (
    <Form
      form={form}
      className="slideInWrapper"
      layout="vertical"
      onValuesChange={(fields) => {
        AuthenticationStore.updateLoginFormData(fields);
      }}
      validateMessages={emailValidatorMessages}
    >
      <div>
        <div style={{ paddingBottom: 25 }}>
          <div className="largeHeader">{t("views.loginForm.header")}</div>
          <div className="subheader">
            {t("views.loginForm.subheader1")}
            <span
              style={{ cursor: "pointer" }}
              className="link"
              onClick={() => {
                FormsStore.setCurrentScreen(undefined);
                handleClick(ERoutePaths.SIGNUP);
              }}
            >
              {` ${t("views.loginForm.subheader2")} `}
            </span>
            {t("views.loginForm.subheader3")}
          </div>
        </div>
        <Form.Item
          name="Email"
          validateTrigger="onBlur"
          initialValue={AuthenticationStore.loginFormData?.Email}
          rules={[
            {
              type: "email",
            },
          ]}
        >
          <Input placeholder={t("forms.fields.email")} allowClear />
        </Form.Item>
        <Form.Item
          name="ConfirmEmail"
          validateTrigger="onBlur"
          initialValue={AuthenticationStore.loginFormData?.ConfirmEmail}
          dependencies={["Email"]}
          rules={[
            {
              type: "email",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("Email") === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error("The two Email that you entered do not match!"),
                );
              },
            }),
          ]}
        >
          <Input
            placeholder={t("forms.fields.confirm", {
              fieldName: t("forms.fields.email"),
            })}
            allowClear
          />
        </Form.Item>
      </div>
      <div>
        <PrivacyPolicyFooter />
        <FormButtonFooter
          goForwardHandler={goForwardHandler}
          goBackHandler={goBackHandler}
        />
      </div>
    </Form>
  );
});

export default LoginForm;
