import { makeAutoObservable } from "mobx";

// ==== Types ====
import {
  EAccountType,
  EUserTypeStatus,
  ESelfEnrollForms,
  IAccountInformationForm,
  IEmailValidationForm,
  INewProfileForm,
  IPinValidationForm,
  IAccountNumberForm,
  IConfirmationScreenForm,
} from "views/SignUp/SelfEnroll/types/selfEnrollTypes";

// ==== APIs ====
import SelfEnrollApi from "apis/SelfEnrollApi";

// ==== Stores ====
import FormsStore from "stores/FormsStore";
import AuthenticationStore from "stores/AuthenticationStore";

// ==== Utilities ====
import { getDateOfBirth } from "utilities/genericUtilities";
import { handleErrorTracking } from "utilities/apiUtilities";
import {
  isResponseStatusError,
  getStatusErrorMessage,
} from "views/SignUp/Utilities/errorHandling";

const FILE_NAME = "SelfEnrollStore";

class SelfEnrollStore {
  // Validation
  isDisabled: boolean = false;
  accountStatus: string = undefined;
  userType: string = undefined;
  isUserSure: boolean = undefined;

  // Form Data
  accountType: string = undefined;

  accountInformationData: Partial<IAccountInformationForm>;
  emailValidationData: Partial<IEmailValidationForm>;
  newProfileData: Partial<INewProfileForm> = {
    ConfirmCountryCode: "1",
    CountryCode: "1",
  };
  pinValidationData: Partial<IPinValidationForm>;
  validateAccountData: Partial<IAccountNumberForm>;

  isAccountSubmitted: boolean = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  // ==== Getter and Setters ====
  setIsDisabled = (isDisabled: boolean) => {
    this.isDisabled = isDisabled;
  };

  setAccountStatus = (accountStatus: string) => {
    this.accountStatus = accountStatus;
  };

  getDateOfBirth = () => {
    if (!this.accountInformationData) return;

    const day = this.accountInformationData.DobDay;
    const month = this.accountInformationData.DobMonth;
    const year = this.accountInformationData.DobYear;

    // yyyy-mm-dd
    return getDateOfBirth(day, month, year);
  };

  // ==== Utility functions ====
  handleFormReset = (): void => {
    FormsStore.resetErrorHandler();

    // reset error handling
    this.setIsDisabled(false);

    // reset form data
    this.accountInformationData = undefined;
    this.emailValidationData = undefined;
    this.newProfileData = { ConfirmCountryCode: "1", CountryCode: "1" };
    this.pinValidationData = undefined;
    this.validateAccountData = undefined;
    this.isAccountSubmitted = undefined;

    // reset user status
    this.accountStatus = undefined;
    this.userType = undefined;
    this.accountType = undefined;
    this.isUserSure = undefined;
  };

  resetFormErrors = () => {
    this.setIsDisabled(false);
    FormsStore.resetErrorHandler();
  };

  // ==== Form OnSubmit Functions ====
  validateAccount = async (): Promise<void> => {
    AuthenticationStore.checkTokenSource();

    try {
      FormsStore.setLoading(true);

      const response = await SelfEnrollApi.getValidateAccount(
        this.validateAccountData.accountNumber,
        AuthenticationStore._tokenSource.token,
      );

      if (response) {
        this.accountType = response.acctType;

        // Only if the accountType is not eligible, disable/ throw err
        if (response.acctType === EAccountType.NOT_ELIGIBLE) {
          this.isDisabled = true;
          FormsStore.setErrorHandler(
            "validateUser",
            getStatusErrorMessage(response.status),
          );
        } else {
          FormsStore.setGuardedCurrentScreen(
            ESelfEnrollForms.ACCOUNT_INFORMATION_FORM,
          );
        }
      }
    } catch (e) {
      handleErrorTracking(e, FILE_NAME, "validateAccount");
    } finally {
      FormsStore.setLoading(false);
    }
  };

  validateUser = async (): Promise<void> => {
    AuthenticationStore.checkTokenSource();

    try {
      FormsStore.setLoading(true);

      const response = await SelfEnrollApi.getValidateAccountInfo(
        {
          AcctNo: this.validateAccountData.accountNumber,
          CorporateOrTrustId: this.accountInformationData.CorporateOrTrustId,
          DateOfBirth: this.getDateOfBirth(),
          TaxId: this.accountInformationData.TaxId,
          TaxType: this.accountInformationData.TaxType,
        },
        AuthenticationStore._tokenSource.token,
      );

      if (response) {
        if (isResponseStatusError(response.status)) {
          FormsStore.setErrorHandler(
            "validateUser",
            getStatusErrorMessage(response.status),
          );

          this.setIsDisabled(true);
        } else {
          FormsStore.setGuardedCurrentScreen(
            ESelfEnrollForms.EMAIL_VALIDATION_FORM,
          );
        }
      }
    } catch (e) {
      handleErrorTracking(e, FILE_NAME, "validateUser");
    } finally {
      FormsStore.setLoading(false);
    }
  };

  validateEmail = async (): Promise<void> => {
    AuthenticationStore.checkTokenSource();

    try {
      FormsStore.setLoading(true);

      const response = await SelfEnrollApi.getValidateUser(
        {
          AcctNo: this.validateAccountData.accountNumber,
          CorporateOrTrustId: this.accountInformationData.CorporateOrTrustId,
          DateOfBirth: this.getDateOfBirth(),
          Email: this.emailValidationData.Email,
          TaxId: this.accountInformationData.TaxId,
          TaxType: this.accountInformationData.TaxType,
        },
        AuthenticationStore._tokenSource.token,
      );

      if (response) {
        if (isResponseStatusError(response.status)) {
          // In cases where the PIN is null, we need to manually ask them to
          // check for their PIN
          if (
            response.status === EUserTypeStatus.ERROR_EMAIL_NO_MATCH_TAX_INFO
          ) {
            this.userType = EUserTypeStatus.EXISTING_USER;
            FormsStore.setGuardedCurrentScreen(
              ESelfEnrollForms.PIN_VALIDATION_FORM,
            );
          } else {
            FormsStore.setErrorHandler(
              "validateEmail",
              getStatusErrorMessage(response.status),
            );
            this.setIsDisabled(true);
          }
        } else {
          this.userType = response.status;

          switch (this.userType) {
            case EUserTypeStatus.EXISTING_USER:
              FormsStore.setGuardedCurrentScreen(
                ESelfEnrollForms.PIN_VALIDATION_FORM,
              );
              break;
            case EUserTypeStatus.NEW_USER:
              FormsStore.setGuardedCurrentScreen(
                ESelfEnrollForms.NEW_ACCOUNT_FORM,
              );
              break;
            case EUserTypeStatus.ERROR:
            default:
              this.setIsDisabled(true);
              return;
          }
        }
      }
    } catch (e) {
      handleErrorTracking(e, FILE_NAME, "validateEmail");
    } finally {
      FormsStore.setLoading(false);
    }
  };

  validatePin = async (): Promise<void> => {
    AuthenticationStore.checkTokenSource();

    try {
      FormsStore.setLoading(true);

      const response = await SelfEnrollApi.getValidateUser(
        {
          AcctNo: this.validateAccountData.accountNumber,
          CorporateOrTrustId: this.accountInformationData.CorporateOrTrustId,
          DateOfBirth: this.getDateOfBirth(),
          Email: this.emailValidationData.Email,
          Pin: this.pinValidationData.pin,
          TaxId: this.accountInformationData.TaxId,
          TaxType: this.accountInformationData.TaxType,
        },
        AuthenticationStore._tokenSource.token,
      );

      if (response) {
        if (isResponseStatusError(response.status)) {
          FormsStore.setErrorHandler(
            "validatePin",
            getStatusErrorMessage(response.status),
          );
          this.setIsDisabled(true);
        } else {
          this.accountStatus = response.status;
          FormsStore.setGuardedCurrentScreen(
            ESelfEnrollForms.CONFIRMATION_SCREEN_FORM,
          );
        }
      }
    } catch (e) {
      handleErrorTracking(e, FILE_NAME, "validatePin");
    } finally {
      FormsStore.setLoading(false);
    }
  };

  createNewUserProfile = async (): Promise<void> => {
    AuthenticationStore.checkTokenSource();

    try {
      FormsStore.setLoading(true);
      const response = await SelfEnrollApi.postAddNewUserAccount(
        {
          AcctNo: this.validateAccountData.accountNumber,
          CorporateOrTrustId: this.accountInformationData.CorporateOrTrustId,
          DateOfBirth: this.getDateOfBirth(),
          Email: this.emailValidationData.Email,
          FirstName: this.newProfileData.FirstName,
          LastName: this.newProfileData.LastName,
          PhoneNumber: {
            CountryCode: this.newProfileData.CountryCode,
            Phone: this.newProfileData.Phone,
          },
          TaxId: this.accountInformationData.TaxId,
          TaxType: this.accountInformationData.TaxType,
        },
        AuthenticationStore._tokenSource.token,
      );

      if (response) {
        if (isResponseStatusError(response.status)) {
          FormsStore.setErrorHandler(
            "createNewUserProfile",
            getStatusErrorMessage(response.status),
          );
          this.setIsDisabled(true);
        } else {
          this.accountStatus = response.status;
          FormsStore.setGuardedCurrentScreen(
            ESelfEnrollForms.NEW_ACCOUNT_SUCCESS_SCREEN,
          );
        }
      }
    } catch (e) {
      handleErrorTracking(e, FILE_NAME, "createNewUserProfile");
    } finally {
      FormsStore.setLoading(false);
    }
  };

  createUserAccount = async (): Promise<void> => {
    AuthenticationStore.checkTokenSource();

    try {
      FormsStore.setLoading(true);

      const response = await SelfEnrollApi.postAddUserAccount(
        {
          AcctNo: this.validateAccountData.accountNumber,
          CorporateOrTrustId: this.accountInformationData.CorporateOrTrustId,
          DateOfBirth: this.getDateOfBirth(),
          Email: this.emailValidationData.Email,
          Pin: this.pinValidationData.pin,
          TaxId: this.accountInformationData.TaxId,
          TaxType: this.accountInformationData.TaxType,
        },
        AuthenticationStore._tokenSource.token,
      );

      if (response) {
        if (isResponseStatusError(response.status)) {
          FormsStore.setErrorHandler(
            "createUserAccount",
            getStatusErrorMessage(response.status),
          );
          this.setIsDisabled(true);
        }
      }
    } catch (e) {
      handleErrorTracking(e, FILE_NAME, "createUserAccount");
    } finally {
      FormsStore.setLoading(false);
    }
  };

  handleConfirmationScreenFormSubmit = () => {
    if (this.isUserSure) {
      this.createUserAccount();
      this.isAccountSubmitted = true;
    } else {
      this.handleFormReset();
      window.location.assign(AuthenticationStore.config.clientUri);
    }
  };

  // ==== Form OnChange Update Functions ====
  updateConfirmationScreenData = (changedFields: IConfirmationScreenForm) => {
    this.isUserSure = changedFields.isUserSure;
  };

  updateValidateAccountData = (changedFields: IAccountNumberForm) => {
    this.validateAccountData = {
      ...this.validateAccountData,
      ...changedFields,
    };

    this.resetFormErrors();
  };

  updateEmailValidationData = (changedFields: IEmailValidationForm) => {
    this.emailValidationData = {
      ...this.emailValidationData,
      ...changedFields,
    };

    this.resetFormErrors();
  };

  updateAccountInformationData = (changedFields: IAccountInformationForm) => {
    this.accountInformationData = {
      ...this.accountInformationData,
      ...changedFields,
    };

    this.resetFormErrors();
  };

  updatePinValidationData = (changedFields: IPinValidationForm) => {
    this.pinValidationData = {
      ...this.pinValidationData,
      ...changedFields,
    };

    this.resetFormErrors();
  };

  updateNewProfileData = (changedFields: INewProfileForm) => {
    this.newProfileData = {
      ...this.newProfileData,
      ...changedFields,
    };

    this.resetFormErrors();
  };
}

export default new SelfEnrollStore();
