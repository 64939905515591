import * as React from "react";
import { Spin } from "antd";
import { observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

// ==== Store ====
import FormsStore from "stores/FormsStore";
import AuthenticationStore from "stores/AuthenticationStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Forms ====
import WelcomeForm from "views/Margin/forms/WelcomeForm";
import ApplicationStatusForm from "views/Margin/forms/ApplicationStatusForm";
import AccountSelectionForm from "views/SignUp/Onboarding/forms/AccountSelectionForm";
import LoginForm from "components/authenticationForms/LoginForm";

// ==== Components ====
import SidebarWrapper from "components/contentWrappers/SidebarWrapper";

// ==== Types ====
import { EAuthenticationScreens } from "types/authenticationTypes";
import { EMarginScreens } from "views/Margin/types/marginTypes";
import { FEATURE_TYPES } from "views/SignUp/Onboarding/types/onboardingEnums";
import { ERoutePaths } from "types/globalTypes";

// ==== Utilities ====
import { scrollUserToTopOfWindow } from "utilities/genericUtilities";
import { setQueryStringsIntoState } from "utilities/apiUtilities";

const MarginContainer: React.FunctionComponent = observer(() => {
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  React.useEffect(() => {
    const { search } = location;

    if (!window.location.href.includes(FormsStore.currentScreen)) {
      FormsStore.setCurrentScreen(FormsStore.currentScreen);
    }

    // Handles init auth of user if they start cold on the /options sub routes
    if (!OnboardingStore.profile) {
      AuthenticationStore.handleInitialTokenFetch(EMarginScreens.WELCOME);
    }

    setQueryStringsIntoState(search);
  }, [location]);

  const renderForms = () => {
    scrollUserToTopOfWindow();

    switch (FormsStore.currentScreen) {
      // Auth Forms
      case EAuthenticationScreens.LOGIN:
        return <LoginForm />;
      case EAuthenticationScreens.ACCOUNT_SELECTION:
        return <AccountSelectionForm featureTypeKey={FEATURE_TYPES.MARGIN} />;
      // Margin Forms
      case EMarginScreens.WELCOME:
        return <WelcomeForm />;
      case EMarginScreens.APPLICATION_STATUS:
        return <ApplicationStatusForm />;
      default:
        FormsStore.setInfoSignUpMessage(t("views.margin.redirectMessage"));
        history.push(ERoutePaths.ONBOARDING);
        return <div />;
    }
  };

  // If the user does NOT have a current screen set it to the welcome page
  // and send them through the init auth flow
  if (!FormsStore.currentScreen) {
    AuthenticationStore.handleInitialFeatureLoad(EMarginScreens.WELCOME);
  }

  return (
    <SidebarWrapper>
      <Spin spinning={FormsStore.isLoading}>{renderForms()}</Spin>
    </SidebarWrapper>
  );
});

export default MarginContainer;
