// ==== Stores ====
import FormsStore from "stores/FormsStore";
import AuthenticationStore from "stores/AuthenticationStore";

// ==== Types ====
import { IDevToken } from "types/authenticationTypes";

// ==== Apis ====
import TokenApi from "apis/TokenApi";

// ==== Utilities ====
import { handleErrorTracking } from "utilities/apiUtilities";

export const getAPIToken = async (email?: string): Promise<IDevToken> => {
  AuthenticationStore.checkTokenSource();

  let response = undefined;

  try {
    FormsStore.setLoading(true);

    response = await TokenApi.getToken(
      AuthenticationStore._tokenSource.token,
      email,
    );
  } catch (e) {
    handleErrorTracking(e, "TokenApiHandler", "getAPIToken");
  } finally {
    FormsStore.setLoading(false);
  }

  return response;
};
