import * as React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Types ====
import {
  E_CAIS_Types,
  EOnboardingForms,
} from "views/SignUp/Onboarding/types/onboardingTypes";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Components ====
import SummaryFieldBlock from "components/SummaryFieldBlock";
import SummaryHeader from "views/SignUp/Onboarding/forms/SummaryPage/SummaryHeader";

// ==== Utilities ====
import { getTranslationKeyFromBool } from "utilities/genericUtilities";

interface IProps {
  isEditButtonDisabled?: boolean;
}

const SummaryTaxForm: React.FunctionComponent<IProps> = observer(
  ({ isEditButtonDisabled }) => {
    const { t } = useTranslation();

    const getFormData = (key: string) =>
      OnboardingStore.getFormItemData(EOnboardingForms.CAIS, key);

    return (
      <React.Fragment>
        <SummaryHeader
          header={t("views.signUp.components.summery.cais")}
          editButtonKey={!isEditButtonDisabled && EOnboardingForms.CAIS}
        />
        <SummaryFieldBlock
          question={t("views.signUp.forms.CAISForm.summaryScreen.otherBroker")}
          answer={t(
            getTranslationKeyFromBool(getFormData(E_CAIS_Types.OtherBroker)),
          )}
        />
        <SummaryFieldBlock
          question={t("views.signUp.forms.CAISForm.summaryScreen.accredited")}
          answer={t(
            getTranslationKeyFromBool(getFormData(E_CAIS_Types.Accredited)),
          )}
        />
        <SummaryFieldBlock
          question={t("views.signUp.forms.CAISForm.summaryScreen.advisor")}
          answer={t(
            getTranslationKeyFromBool(getFormData(E_CAIS_Types.Advisor)),
          )}
        />
        <SummaryFieldBlock
          question={t("views.signUp.forms.CAISForm.summaryScreen.cp")}
          answer={t(getTranslationKeyFromBool(getFormData(E_CAIS_Types.Cp)))}
        />
        <SummaryFieldBlock
          question={t("views.signUp.forms.CAISForm.summaryScreen.employee")}
          answer={t(
            getTranslationKeyFromBool(getFormData(E_CAIS_Types.Employee)),
          )}
        />
        <SummaryFieldBlock
          question={t("views.signUp.forms.CAISForm.summaryScreen.foreign")}
          answer={t(
            getTranslationKeyFromBool(getFormData(E_CAIS_Types.Foreign)),
          )}
        />
        <SummaryFieldBlock
          question={t(
            "views.signUp.forms.CAISForm.summaryScreen.notApplicable",
          )}
          answer={t(
            getTranslationKeyFromBool(getFormData(E_CAIS_Types.NotApplicable)),
          )}
        />
      </React.Fragment>
    );
  },
);

export default SummaryTaxForm;
