import * as React from "react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { Divider, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

// ==== Components ====
import SplashScreenWrapper from "components/contentWrappers/SplashScreenWrapper";
import CenteredWrapper from "components/contentWrappers/CenteredWrapper";

// ==== Containers ====
import SelfEnrollContainer from "views/SignUp/SelfEnroll/SelfEnrollContainer";
import OnboardingContainer from "views/SignUp/Onboarding/OnboardingContainer";

// ==== Stores ====
import FormsStore from "stores/FormsStore";
import AuthenticationStore from "stores/AuthenticationStore";

// ==== Types ====
import { EAuthenticationScreens } from "types/authenticationTypes";
import { ESelfEnrollForms } from "views/SignUp/SelfEnroll/types/selfEnrollTypes";
import { ERoutePaths } from "types/globalTypes";

// ==== Utilities ====
import { ai } from "configuration/appInsights";
import {
  setQueryStringsIntoState,
  getQueryString,
} from "utilities/apiUtilities";

enum ERegistrationType {
  NEW = "newAccount",
  EXISTING = "existingAccount",
}

const SignUp: React.FunctionComponent = () => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const [registrationType, setRegistrationType] = React.useState<
    ERegistrationType.EXISTING | ERegistrationType.NEW
  >();

  React.useEffect(() => {
    const { search } = location;
    setQueryStringsIntoState(search);
    const state = getQueryString(search, "state");

    if (state) {
      AuthenticationStore.setUrlState(state);

      // This is a user returning from the "Set Password workflow"
      // TODO magic string
      if (state === "activated") {
        FormsStore.setLoading(true);
        FormsStore.setCurrentScreen(EAuthenticationScreens.VERIFICATION_CODE);
        history.push(ERoutePaths.ONBOARDING);
      }
    }
  }, []); // eslint-disable-line

  if (registrationType === ERegistrationType.EXISTING) {
    return (
      <CenteredWrapper>
        <SelfEnrollContainer />
      </CenteredWrapper>
    );
  }

  if (registrationType === ERegistrationType.NEW) {
    return (
      <CenteredWrapper>
        <OnboardingContainer />
      </CenteredWrapper>
    );
  }

  return (
    <SplashScreenWrapper>
      <div className="signUpBody">
        {!registrationType && (
          <div className="body">
            <div className="largeHeader">
              <strong>{t("views.signUp.SignUp.header")}</strong>
            </div>
            <div className="subheader" style={{ paddingBottom: 10 }}>
              {t("views.signUp.SignUp.started")}
            </div>
            <Divider className="hr" />
            <div className="largeHeader">
              <strong>{t("views.signUp.SignUp.existingCustomers")}</strong>
            </div>
            <div className="metaText">
              {t("views.signUp.SignUp.ifYouAlready")}
            </div>
            <Button
              style={{ marginTop: 10 }}
              onClick={() => {
                FormsStore.setCurrentScreen(
                  ESelfEnrollForms.ACCOUNT_NUMBER_FORM,
                );
                history.push(ERoutePaths.SELF_ENROLL);
                setRegistrationType(ERegistrationType.EXISTING);
              }}
              type="primary"
            >
              {t("views.signUp.SignUp.enroll")}
            </Button>
          </div>
        )}
      </div>
    </SplashScreenWrapper>
  );
};

export default withAITracking(ai.reactPlugin, SignUp);
