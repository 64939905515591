import * as React from "react";
import { Col, Row, Spin } from "antd";

function Spinner() {
  return (
    <Row align="middle" justify="center" style={{ minHeight: "100vh" }}>
      <Col>
        <Spin size="large" spinning={true} />
      </Col>
    </Row>
  );
}

export default Spinner;
