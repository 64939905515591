import * as React from "react";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Components ====
import SummaryFieldBlock from "components/SummaryFieldBlock";
import SummaryHeader from "views/SignUp/Onboarding/forms/SummaryPage/SummaryHeader";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Types ====
import { EOnboardingForms } from "views/SignUp/Onboarding/types/onboardingTypes";
import {
  FIRM_AFFILIATED_ENTITIES,
  RELATIONSHIP_TYPES,
} from "views/SignUp/Onboarding/types/onboardingEnums";

// ==== Utilities ====
import {
  getTranslationKeyFromBool,
  isEnumFalsey,
} from "utilities/genericUtilities";
import { getTranslationKeyWithValue } from "utilities/typeUtilities";

interface IProps {
  isEditButtonDisabled?: boolean;
}

const SummaryStonexAffiliateForm: React.FunctionComponent<IProps> = observer(
  ({ isEditButtonDisabled }) => {
    const { t } = useTranslation();

    const getFormData = (key: string) =>
      OnboardingStore.getFormItemData(EOnboardingForms.STONEX_AFFILIATE, key);

    return (
      <React.Fragment>
        <SummaryHeader
          header={t("views.signUp.components.summery.stonexAffiliate")}
          editButtonKey={
            !isEditButtonDisabled && EOnboardingForms.STONEX_AFFILIATE
          }
        />
        <SummaryFieldBlock
          question={t("views.signUp.forms.StonexAffiliateForm.areYouRelated")}
          answer={t(getTranslationKeyFromBool(getFormData("AreYouRelated")))}
        />{" "}
        {getFormData("AffiliateFirstName") && (
          <SummaryFieldBlock
            question={`${t("forms.fields.firstName")}:`}
            answer={getFormData("AffiliateFirstName") || ""}
          />
        )}
        {getFormData("AffiliateLastName") && (
          <SummaryFieldBlock
            question={`${t("forms.fields.lastName")}:`}
            answer={getFormData("AffiliateLastName") || ""}
          />
        )}
        {isEnumFalsey(getFormData("AffiliatedEntity")) && (
          <SummaryFieldBlock
            question={`${t(
              "views.signUp.forms.StonexAffiliateForm.whichEntity",
            )}:`}
            answer={t(
              getTranslationKeyWithValue(
                FIRM_AFFILIATED_ENTITIES,
                getFormData("AffiliatedEntity"),
              ),
            )}
          />
        )}
        {isEnumFalsey(getFormData("AssociationRelationshipType")) && (
          <SummaryFieldBlock
            question={`${t(
              "views.signUp.forms.StonexAffiliateForm.whatIsRelationship",
            )}:`}
            answer={t(
              getTranslationKeyWithValue(
                RELATIONSHIP_TYPES,
                getFormData("AssociationRelationshipType"),
              ),
            )}
          />
        )}
        {getFormData("WhatPosition") && (
          <SummaryFieldBlock
            question={`${t(
              "views.signUp.forms.StonexAffiliateForm.whatPosition",
            )}:`}
            answer={getFormData("WhatPosition") || ""}
          />
        )}
      </React.Fragment>
    );
  },
);

export default SummaryStonexAffiliateForm;
