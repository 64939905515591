import * as React from "react";
import { Spin } from "antd";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

// ==== Stores ====
import FormsStore from "stores/FormsStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";

// ==== Components ====
import FormHeader from "components/FormHeader";
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";

// ==== Types ====
import { EOptionScreens } from "views/Options/types/optionsTypes";
import { EOnboardingForms } from "views/SignUp/Onboarding/types/onboardingTypes";
import { EAuthenticationScreens } from "types/authenticationTypes";
import { ERoutePaths } from "types/globalTypes";

const LandingForm: React.FunctionComponent = observer(() => {
  const { t } = useTranslation();
  const history = useHistory();

  React.useEffect(() => {
    if (!OnboardingStore.getAccountOpeningAccountNumber()) {
      history.push(ERoutePaths.ONBOARDING);
      FormsStore.setCurrentScreen(EAuthenticationScreens.ACCOUNT_SELECTION);
    }
  }, [history]);

  const getFormData = (key: string) =>
    OnboardingStore.getFormItemData(EOnboardingForms.INDIVIDUAL, key) || "";

  const handleGoBackOnClick = () => {
    FormsStore.setCurrentScreen(EAuthenticationScreens.ACCOUNT_SELECTION);
  };

  const { getAccountOpeningAccountNumber, isAccountApproved } = OnboardingStore;

  return (
    <React.Fragment>
      <div className="slideInWrapper">
        {getAccountOpeningAccountNumber() ? (
          <React.Fragment>
            <FormHeader headerText={t("views.Options.LandingForm.header")} />
            <div style={{ paddingBottom: 20 }}>
              <strong>
                {`${getFormData("FirstName")} ${getFormData("LastName")} - ${
                  !isAccountApproved()
                    ? `${t("views.margin.pendingAccount")} `
                    : ""
                }${getAccountOpeningAccountNumber()}`}
              </strong>
            </div>
            <div style={{ paddingBottom: 20 }}>
              {t("views.Options.LandingForm.body")}
            </div>
            <FormButtonFooter
              goForwardHandler={() =>
                FormsStore.setGuardedCurrentScreen(
                  EOptionScreens.FINANCIAL_PROFILE,
                )
              }
              goBackHandler={handleGoBackOnClick}
            />
          </React.Fragment>
        ) : (
          <Spin spinning={true}>
            <div style={{ height: 200, width: 530 }} />
          </Spin>
        )}
      </div>
    </React.Fragment>
  );
});

export default LandingForm;
