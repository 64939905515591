import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Input, Radio, Select } from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import FormsStore from "stores/FormsStore";

// ==== Types ====
import {
  EOnboardingForms,
  EOnboardingScreens,
  IStonexAffiliateForm,
} from "views/SignUp/Onboarding/types/onboardingTypes";
import {
  FIRM_AFFILIATED_ENTITIES,
  RELATIONSHIP_TYPES,
} from "views/SignUp/Onboarding/types/onboardingEnums";

// ==== Components ====
import FormButtonFooter from "views/SignUp/components/FormButtonFooter";
import YesNoQuestionFormElement from "components/YesNoQuestionFormElement";
import FormHeader from "components/FormHeader";

// ==== Utilities ====
import { renderSelectOptions } from "utilities/formItemUtilities";
import { isEnumFalsey } from "utilities/genericUtilities";

const StonexAffiliateForm: React.FunctionComponent<FormComponentProps> =
  observer(({ form }) => {
    const { getFieldDecorator, validateFields } = form;
    const { t } = useTranslation();

    const handleSubmit = async (
      event: React.FormEvent<HTMLFormElement>,
      nextScreenKey: string,
    ): Promise<void> => {
      event.preventDefault();

      validateFields(async (err: string[]): Promise<void> => {
        if (!err) {
          await OnboardingStore.validateAffiliateInfo(nextScreenKey);
        }
      });
    };

    const getInitialValue = (key: string) =>
      OnboardingStore.getFormItemData(EOnboardingForms.STONEX_AFFILIATE, key);

    const isNextButtonDisabled = (): {
      disabledMessage: string;
      isDisabled: boolean;
    } => {
      let isDisabled = false;
      let disabledMessage = "";

      if (typeof getInitialValue("AreYouRelated") === "undefined") {
        isDisabled = true;
        disabledMessage = t(
          "views.signUp.forms.StonexAffiliateForm.validationMessages.default",
        );
      }

      // If the user is affiliated, they must fill out the rest of the form
      if (getInitialValue("AreYouRelated")) {
        if (
          !getInitialValue("AffiliateFirstName") ||
          !getInitialValue("AffiliateLastName") ||
          !isEnumFalsey(getInitialValue("AffiliatedEntity")) ||
          !isEnumFalsey(getInitialValue("AssociationRelationshipType")) ||
          !getInitialValue("WhatPosition")
        ) {
          disabledMessage = t(
            "views.signUp.forms.StonexAffiliateForm.validationMessages.affiliatedRelationship",
          );
          isDisabled = true;
        }
      }

      return {
        disabledMessage,
        isDisabled,
      };
    };

    return (
      <div className="slideInWrapper stonexAffiliateForm">
        <FormHeader
          headerText={t("views.signUp.forms.StonexAffiliateForm.header")}
        />
        <Form layout="vertical" hideRequiredMark>
          <YesNoQuestionFormElement
            getFieldDecorator={getFieldDecorator}
            fieldDecoratorValue={"AreYouRelated"}
            initialValue={getInitialValue("AreYouRelated")}
            label={t("views.signUp.forms.StonexAffiliateForm.areYouRelated")}
          />
          {getInitialValue("AreYouRelated") && (
            <div className="indentedContent">
              {getInitialValue("AreYouRelated") && (
                <React.Fragment>
                  <Form.Item>
                    {getFieldDecorator("AffiliateFirstName", {
                      initialValue: getInitialValue("AffiliateFirstName"),
                      rules: [
                        {
                          message: t("forms.validation.isRequired", {
                            fieldName: t("forms.fields.firstName"),
                          }),
                          required: true,
                        },
                      ],
                      validateTrigger: "onBlur",
                    })(
                      <Input
                        placeholder={t("forms.fields.firstName")}
                        allowClear
                      />,
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator("AffiliateLastName", {
                      initialValue: getInitialValue("AffiliateLastName"),
                      rules: [
                        {
                          message: t("forms.validation.isRequired", {
                            fieldName: t("forms.fields.lastName"),
                          }),
                          required: true,
                        },
                      ],
                      validateTrigger: "onBlur",
                    })(
                      <Input
                        placeholder={t("forms.fields.lastName")}
                        allowClear
                      />,
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator("WhatPosition", {
                      initialValue: getInitialValue("WhatPosition"),
                      rules: [
                        {
                          message: t("forms.validation.isRequired", {
                            fieldName: t("forms.fields.field"),
                          }),
                          required: true,
                        },
                      ],
                      validateTrigger: "onBlur",
                    })(
                      <Input
                        placeholder={t(
                          "views.signUp.forms.StonexAffiliateForm.whatPosition",
                        )}
                        allowClear
                      />,
                    )}
                  </Form.Item>
                  <Form.Item>
                    {getFieldDecorator("AffiliatedEntity", {
                      initialValue: getInitialValue("AffiliatedEntity"),
                      rules: [
                        {
                          message: t("forms.validation.isRequired", {
                            fieldName: t(
                              "views.signUp.forms.StonexAffiliateForm.stonexAffiliation",
                            ),
                          }),
                          required: true,
                        },
                      ],
                    })(
                      <Select
                        placeholder={t(
                          "views.signUp.forms.StonexAffiliateForm.whichEntity",
                        )}
                        filterOption={(input, option) => {
                          return option.children
                            .toString()
                            .toLowerCase()
                            .includes(input.toLowerCase());
                        }}
                        style={{ width: "100%" }}
                      >
                        {renderSelectOptions(FIRM_AFFILIATED_ENTITIES, t)}
                      </Select>,
                    )}
                  </Form.Item>
                  <Form.Item
                    label={t(
                      "views.signUp.forms.StonexAffiliateForm.whatIsRelationship",
                    )}
                  >
                    {getFieldDecorator("AssociationRelationshipType", {
                      initialValue: getInitialValue(
                        "AssociationRelationshipType",
                      ),
                      rules: [
                        {
                          message: t("forms.validation.isRequired", {
                            fieldName: t("forms.fields.field"),
                          }),
                          required: true,
                        },
                      ],
                      validateTrigger: "onChange",
                    })(
                      <Radio.Group>
                        <Radio value={RELATIONSHIP_TYPES.CHILD.value}>
                          {t("forms.relationshipTypes.child")}
                        </Radio>
                        <Radio value={RELATIONSHIP_TYPES.OTHER.value}>
                          {t("forms.relationshipTypes.other")}
                        </Radio>
                        <Radio value={RELATIONSHIP_TYPES.SELF.value}>
                          {t("forms.relationshipTypes.self")}
                        </Radio>
                        <Radio value={RELATIONSHIP_TYPES.SPOUSE.value}>
                          {t("forms.relationshipTypes.spouse")}
                        </Radio>
                      </Radio.Group>,
                    )}
                  </Form.Item>
                </React.Fragment>
              )}
            </div>
          )}
          <FormButtonFooter
            isGoForwardDisabled={isNextButtonDisabled().isDisabled}
            goForwardDisabledMessage={isNextButtonDisabled().disabledMessage}
            goForwardHandler={(e) => handleSubmit(e, EOnboardingScreens.CAIS)}
            goBackHandler={() =>
              FormsStore.setCurrentScreen(EOnboardingScreens.TRUSTED_CONTACT)
            }
          />
        </Form>
      </div>
    );
  });

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: IStonexAffiliateForm) => {
    OnboardingStore.updateFormData(
      changedFields,
      EOnboardingForms.STONEX_AFFILIATE,
    );
  },
})(StonexAffiliateForm);
