import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Button, Col, Select, Input, Row } from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import SelfEnrollStore from "views/SignUp/SelfEnroll/store/SelfEnrollStore";

// ==== Components ====
import DateOfBirth from "views/SignUp/components/DateOfBirth";
import ErrorMessageAlert from "views/SignUp/Onboarding/components/ErrorMessageAlert";
import FormHeader from "components/FormHeader";

// ==== Types ====
import {
  EIdTypes,
  EAccountType,
  IAccountInformationForm,
} from "views/SignUp/SelfEnroll/types/selfEnrollTypes";
import { ERoutePaths } from "types/globalTypes";

const { Option } = Select;

const AccountInformationForm: React.FunctionComponent<FormComponentProps> =
  observer(({ form }) => {
    const { getFieldDecorator, validateFields } = form;
    const { t } = useTranslation();

    const handleSubmit = async (
      event: React.FormEvent<HTMLFormElement>,
    ): Promise<void> => {
      event.preventDefault();

      validateFields(async (err: string[]): Promise<void> => {
        if (!err) {
          await SelfEnrollStore.validateUser();
        }
      });
    };

    const getIdTable = (key: string) => {
      switch (key) {
        case EIdTypes.SSN:
          return t("forms.idTypes.ssn");
        case EIdTypes.US_TAX_ID:
          return t("forms.idTypes.taxId");
        case EIdTypes.PASSPORT:
          return t("forms.idTypes.passport");
        case EIdTypes.ALIEN:
          return t("forms.idTypes.alienId");
        case EIdTypes.GOV:
          return t("forms.idTypes.gov");
      }
    };

    const createFormElement = ({
      key,
      label,
    }: {
      key: string;
      label: string;
    }) => {
      return (
        <Row>
          <Col span={24}>
            <Form.Item label={`${label}:`}>
              {getFieldDecorator(`${key}`, {
                rules: [
                  {
                    message: t("forms.validation.isRequired", {
                      fieldName: label,
                    }),
                    required: true,
                  },
                ],
                validateTrigger: "onBlur",
              })(<Input allowClear />)}
            </Form.Item>
          </Col>
        </Row>
      );
    };

    const createDateOfBirthElement = () => (
      <Form.Item label={t("forms.fields.dateOfBirth")} required={true}>
        <DateOfBirth
          fieldNameDay="DobDay"
          fieldNameMonth="DobMonth"
          fieldNameYear="DobYear"
          form={form}
        />
      </Form.Item>
    );

    const createIdSelectElement = (keys: string[]) => (
      <Form.Item
        label={t("views.signUp.forms.AccountInformationForm.selectIdType")}
        required={true}
      >
        {getFieldDecorator("TaxType", {
          rules: [
            {
              message: t("forms.validation.isRequired", {
                fieldName: t(
                  "views.signUp.forms.AccountInformationForm.taxType",
                ),
              }),
              required: true,
            },
          ],
        })(
          <Select>
            {keys.includes(EIdTypes.SSN) && (
              <Option value={EIdTypes.SSN}>{t("forms.idTypes.ssn")}</Option>
            )}
            {keys.includes(EIdTypes.US_TAX_ID) && (
              <Option value={EIdTypes.US_TAX_ID}>
                {t("forms.idTypes.taxId")}
              </Option>
            )}
            {keys.includes(EIdTypes.PASSPORT) && (
              <Option value={EIdTypes.PASSPORT}>
                {t("forms.idTypes.passport")}
              </Option>
            )}
            {keys.includes(EIdTypes.GOV) && (
              <Option value={EIdTypes.GOV}>{t("forms.idTypes.gov")}</Option>
            )}
            {keys.includes(EIdTypes.ALIEN) && (
              <Option value={EIdTypes.ALIEN}>
                {t("forms.idTypes.alienId")}
              </Option>
            )}
          </Select>,
        )}
      </Form.Item>
    );

    const createAccountInformationForm = (accountType: string) => {
      switch (accountType) {
        case EAccountType.FOREIGN_INDIVIDUAL:
          return (
            <React.Fragment>
              {createIdSelectElement([
                EIdTypes.PASSPORT,
                EIdTypes.GOV,
                EIdTypes.ALIEN,
              ])}
              {SelfEnrollStore.accountInformationData?.TaxType && (
                <React.Fragment>
                  {createFormElement({
                    key: "TaxId",
                    label: getIdTable(
                      SelfEnrollStore.accountInformationData?.TaxType,
                    ),
                  })}
                </React.Fragment>
              )}
              {createDateOfBirthElement()}
            </React.Fragment>
          );
        case EAccountType.FOREIGN_NON_INDIVIDUAL:
          return (
            <React.Fragment>
              {createFormElement({
                key: "CorporateOrTrustId",
                label: t("views.signUp.forms.AccountInformationForm.corpId"),
              })}
              {createIdSelectElement([
                EIdTypes.PASSPORT,
                EIdTypes.GOV,
                EIdTypes.ALIEN,
              ])}
              {SelfEnrollStore.accountInformationData?.TaxType && (
                <React.Fragment>
                  {createFormElement({
                    key: "TaxId",
                    label: getIdTable(
                      SelfEnrollStore.accountInformationData?.TaxType,
                    ),
                  })}
                </React.Fragment>
              )}
              {createDateOfBirthElement()}
            </React.Fragment>
          );
        case EAccountType.CORPORATE:
          return (
            <React.Fragment>
              {createFormElement({
                key: "CorporateOrTrustId",
                label: t("views.signUp.forms.AccountInformationForm.corpId"),
              })}
              {createIdSelectElement([EIdTypes.SSN, EIdTypes.US_TAX_ID])}
              {SelfEnrollStore.accountInformationData?.TaxType && (
                <React.Fragment>
                  {createFormElement({
                    key: "TaxId",
                    label: getIdTable(
                      SelfEnrollStore.accountInformationData?.TaxType,
                    ),
                  })}
                </React.Fragment>
              )}
              {createDateOfBirthElement()}
            </React.Fragment>
          );
        case EAccountType.INDIVIDUAL:
          return (
            <React.Fragment>
              {createIdSelectElement([EIdTypes.SSN])}
              {SelfEnrollStore.accountInformationData?.TaxType && (
                <React.Fragment>
                  {createFormElement({
                    key: "TaxId",
                    label: getIdTable(
                      SelfEnrollStore.accountInformationData?.TaxType,
                    ),
                  })}
                </React.Fragment>
              )}
              {createDateOfBirthElement()}
            </React.Fragment>
          );
        case EAccountType.NOT_ELIGIBLE:
        default:
          return;
      }
    };

    return (
      <div className="slideInWrapper">
        {SelfEnrollStore.accountType === EAccountType.NOT_ELIGIBLE ? (
          <ErrorMessageAlert
            defaultErrorMessage={t(
              "views.signUp.components.notEligibleAccount.message",
            )}
            resetPath={ERoutePaths.SELF_ENROLL}
          />
        ) : (
          <React.Fragment>
            <FormHeader
              subheaderText={t(
                "views.signUp.forms.AccountInformationForm.firstCheck",
              )}
            />

            <Form layout="vertical" onSubmit={handleSubmit}>
              {createAccountInformationForm(SelfEnrollStore.accountType)}
              <Button
                disabled={SelfEnrollStore.isDisabled}
                htmlType="submit"
                type="primary"
              >
                {t("forms.buttons.next")}
              </Button>
            </Form>
          </React.Fragment>
        )}
      </div>
    );
  });

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: IAccountInformationForm) => {
    SelfEnrollStore.updateAccountInformationData(changedFields);
  },
})(AccountInformationForm);
