import * as React from "react";
import {
  ExclamationCircleTwoTone,
  QuestionCircleTwoTone,
} from "@ant-design/icons";
import { Form } from "@ant-design/compatible";
import {
  Alert,
  Button,
  Card,
  Col,
  Divider,
  Input,
  InputNumber,
  Layout,
  Result,
  Row,
  Select,
  Spin,
  Tooltip,
} from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { CancelTokenSource } from "axios";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation, useParams } from "react-router-dom";

// ==== Stores ====
import AuthenticationStore from "stores/AuthenticationStore";

// ==== Components ====
import BrowserWarningFooter from "components/BrowserWarningFooter";
import SiteHeader from "components/SiteHeader";
import SalesForceChat from "components/SalesForceChat";
import Spinner from "components/Spinner";
import CenteredWrapper from "components/contentWrappers/CenteredWrapper";

// ==== Utilities ====
import { ai } from "configuration/appInsights";
import {
  emailFormValidator,
  phoneValidator,
  countryCodeValidator,
  getMaxPhoneNumberLength,
  getMinPhoneNumberLength,
} from "utilities/formValidators";

// ==== Apis ====
import ConfigurationApi from "apis/ConfigurationApi";
import RegisterApis from "apis/RegisterApi";

// ==== Types ====
import { IRequestPreview, ERoutePaths } from "types/globalTypes";

// ==== Data ====
import COLORS from "assets/styles/config/colors.json";
import ConfigurationStore from "stores/ConfigurationStore";

interface IParams {
  id: string;
}

let cancelToken: CancelTokenSource;
const { Meta } = Card;
const { Option } = Select;

const Register: React.FunctionComponent<FormComponentProps> = ({ form }) => {
  const history = useHistory();
  const { search } = useLocation();
  const { id: paramId } = useParams() as IParams;
  const { t, i18n } = useTranslation();
  const {
    getFieldDecorator,
    getFieldValue,
    isFieldTouched,
    isFieldsTouched,
    validateFields,
    validateFieldsAndScroll,
  } = form;
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [id, setId] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);
  const [requestPreview, setRequestPreview] = React.useState<IRequestPreview>();

  React.useEffect(() => {
    let cancelToken: CancelTokenSource;
    if (paramId || search?.includes("from=WealthAccess")) {
      const userId = atob(
        paramId || search.substring(search.indexOf("id=") + 3),
      );
      setId(userId);
      history.push(ERoutePaths.REGISTER);
      const initialize = async () => {
        cancelToken = ConfigurationApi.newTokenSource();
        setHasError(false);

        const getPreview = async (): Promise<void> => {
          const response = await RegisterApis.getPreview(
            userId,
            cancelToken.token,
          );
          if (response?.status === 200) {
            setRequestPreview(response.data);
          } else {
            setHasError(true);
          }
        };
        await Promise.all([getPreview()]);
        setIsLoading(false);
      };
      initialize();
    } else {
      setIsLoading(false);
    }

    return () => cancelToken?.cancel();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async () => {
    validateFieldsAndScroll(async (errors, values) => {
      if (errors === null || (errors && !Object.keys(errors).length)) {
        setIsSubmitting(true);
        setHasError(false);
        if (cancelToken) {
          cancelToken.cancel();
        }
        const formData = {
          email: getFieldValue("email"),
          phoneNumber: {
            countryCode: getFieldValue("countryCode"),
            phone: getFieldValue("phoneNumber"),
          },
          pin: getFieldValue("pin"),
        };
        cancelToken = RegisterApis.newTokenSource();
        const response = await RegisterApis.putMetaData(
          id,
          formData,
          cancelToken.token,
        );
        if (response?.status === 200) {
          cancelToken = RegisterApis.newTokenSource();
          const response = await RegisterApis.postQueue(id, cancelToken.token);
          if (response?.status === 200) {
            setIsSubmitted(true);
          } else {
            setHasError(true);
          }
        } else {
          setHasError(true);
        }
        setIsSubmitting(false);
      }
    });
  };

  // ==== Country Code Validation ====
  const countryField = getFieldValue("countryCode");
  const confirmCountryField = getFieldValue("countryCodeConfirm");

  const validateCountryCode = React.useCallback(
    (rule: string, value: string, callback: (response?: string) => void) => {
      countryCodeValidator(
        value,
        getFieldValue("countryCodeConfirm"),
        t,
        callback,
        validateFields,
        isFieldsTouched,
        isFieldTouched,
        "countryCode",
        "countryCodeConfirm",
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [countryField],
  );

  const validateCountryCodeConfirm = React.useCallback(
    (rule: string, value: string, callback: (response?: string) => void) => {
      countryCodeValidator(
        value,
        getFieldValue("countryCodeConfirm"),
        t,
        callback,
        validateFields,
        isFieldsTouched,
        isFieldTouched,
        "countryCode",
        "countryCodeConfirm",
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmCountryField],
  );

  // ==== Email Validation ====
  const emailField = getFieldValue("email");
  const confirmEmailField = getFieldValue("confirmEmail");

  const validateEmail = React.useCallback(
    (rule: string, value: string, callback: (response?: string) => void) => {
      emailFormValidator(
        value,
        getFieldValue("confirmEmail"),
        t,
        callback,
        validateFields,
        isFieldsTouched,
        isFieldTouched,
        "email",
        "confirmEmail",
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [emailField],
  );

  const validateEmailConfirm = React.useCallback(
    (rule: string, value: string, callback: (response?: string) => void) => {
      emailFormValidator(
        value,
        getFieldValue("email"),
        t,
        callback,
        validateFields,
        isFieldsTouched,
        isFieldTouched,
        "email",
        "confirmEmail",
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmEmailField],
  );

  // ==== Phone Validation ====
  const phoneField = getFieldValue("phoneNumber");
  const confirmPhoneField = getFieldValue("phoneNumberConfirm");

  const validatePhoneNumber = React.useCallback(
    (rule: string, value: string, callback: (response?: string) => void) => {
      phoneValidator(
        value,
        getFieldValue("phoneNumberConfirm"),
        t,
        callback,
        validateFields,
        isFieldsTouched,
        isFieldTouched,
        "phoneNumber",
        "phoneNumberConfirm",
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [phoneField],
  );

  const validatePhoneNumberConfirm = React.useCallback(
    (rule: string, value: string, callback: (response?: string) => void) => {
      phoneValidator(
        value,
        getFieldValue("phoneNumber"),
        t,
        callback,
        validateFields,
        isFieldsTouched,
        isFieldTouched,
        "phoneNumber",
        "phoneNumberConfirm",
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [confirmPhoneField],
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (!id?.length) {
    return (
      <CenteredWrapper>
        <Card>
          <Result status="404" subTitle={t("notAuthorized.subTitle")} />
        </Card>
      </CenteredWrapper>
    );
  }

  const {
    phoneNumber: { countryCode, phone },
    email,
    firstName,
    lastName,
    accounts,
  } = requestPreview?.metadataModel?.userInfo;

  return (
    <Layout className={`register ${i18n.language.substring(0, 2)}`}>
      <SiteHeader />
      <Layout.Content>
        <div className="cardWrapper">
          <Card>
            <div className="header">{t("register.infoCard.header")}</div>
            {t("register.infoCard.body")}
            <div className="subheader" style={{ marginTop: 10 }}>
              {t("register.infoCard.list1")}
            </div>
            <ul style={{ marginTop: 10 }}>
              <li>{t("register.infoCard.list1Item1")}</li>
              <li>{t("register.infoCard.list1Item2")}</li>
            </ul>
            <div className="subheader">{t("register.infoCard.list2")}</div>
            <ul style={{ marginTop: 10 }}>
              <li>
                {t("register.infoCard.list2Item1")}{" "}
                <a
                  className="link"
                  href={AuthenticationStore.config.clientUri}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("platforms.client")}
                </a>
              </li>
            </ul>
            <div className="subheader">{t("register.infoCard.list3")}</div>
            <ul style={{ marginTop: 10 }}>
              <li>
                {t("register.infoCard.list3Item1")}{" "}
                <a className="link" href="mailto:StoneXClient@stonex.com">
                  StoneXClient@stonex.com
                </a>
              </li>
              <li>
                {t("register.infoCard.list3Item2")}{" "}
                <a className="link" href="tel:+1-800-405-4106">
                  +1-800-405-4106
                </a>
              </li>
            </ul>
          </Card>
        </div>
      </Layout.Content>
      <Layout.Content>
        <div className="cardWrapper">
          <Card>
            <Meta
              title={
                <React.Fragment>
                  {t("register.title")},
                  <strong>{` ${firstName} ${lastName}`}</strong>!
                </React.Fragment>
              }
              description={
                isSubmitted ? (
                  <Result
                    extra={<SalesForceChat />}
                    status="success"
                    subTitle={t("register.success")}
                  />
                ) : (
                  <Form colon={false}>
                    <Spin spinning={isSubmitting}>
                      {hasError && (
                        <Alert
                          message={t("forms.result.error")}
                          showIcon={true}
                          style={{ marginBottom: 16 }}
                          type="error"
                        />
                      )}
                      <Form.Item
                        label={
                          <div>
                            <Row>
                              <Col className="email-label">
                                {t("forms.fields.email")}
                              </Col>
                              <Col className="email-labelExtra">
                                ({t("register.labelExtra.email")})
                              </Col>
                            </Row>
                          </div>
                        }
                      >
                        {getFieldDecorator("email", {
                          initialValue: email?.toLowerCase(),
                          rules: [
                            {
                              message: t("forms.validation.isRequired", {
                                fieldName: t("forms.fields.email"),
                              }),
                              required: true,
                            },
                            {
                              validator: validateEmail,
                            },
                          ],
                          validateTrigger: "onBlur",
                        })(<Input maxLength={100} />)}
                      </Form.Item>
                      <Form.Item
                        label={t("forms.fields.confirm", {
                          fieldName: t("forms.fields.email"),
                        })}
                      >
                        {getFieldDecorator("emailConfirm", {
                          rules: [
                            {
                              message: t("forms.validation.isRequired", {
                                fieldName: t("forms.fields.confirm", {
                                  fieldName: t("forms.fields.email"),
                                }),
                              }),
                              required: true,
                            },
                            {
                              validator: validateEmailConfirm,
                            },
                          ],
                          validateTrigger: "onBlur",
                        })(<Input maxLength={100} />)}
                      </Form.Item>
                      <Form.Item
                        label={
                          <div>
                            <Row gutter={0}>
                              <Col className="phone-label">
                                <Row>
                                  <Col>{t("forms.fields.phoneNumber")}</Col>
                                  <Col>
                                    <Tooltip
                                      title={t("register.tooltip.phone")}
                                    >
                                      <QuestionCircleTwoTone
                                        twoToneColor={COLORS.branding.secondary}
                                      />
                                    </Tooltip>
                                  </Col>
                                </Row>
                              </Col>
                              <Col className="phone-labelExtra">
                                ({t("register.labelExtra.phone")})
                              </Col>
                            </Row>
                          </div>
                        }
                        required={true}
                      >
                        <Row gutter={12}>
                          <Col xs={24} sm={12}>
                            <Form.Item style={{ marginBottom: 0 }}>
                              {getFieldDecorator("countryCode", {
                                initialValue: countryCode || "1",
                                rules: [
                                  {
                                    message: t("forms.validation.isRequired", {
                                      fieldName: t("forms.fields.countryCode"),
                                    }),
                                    required: true,
                                  },
                                  {
                                    validator: validateCountryCode,
                                  },
                                ],
                              })(
                                <Select
                                  filterOption={(inputValue, option) =>
                                    option.props.children
                                      .toString()
                                      .toLowerCase()
                                      .includes(inputValue.toLowerCase())
                                  }
                                  style={{ width: "100%" }}
                                >
                                  {ConfigurationStore?.phoneCountryCodes?.map(
                                    ({ countryCode, countryName }) => (
                                      <Option
                                        key={countryName}
                                        value={countryCode}
                                      >
                                        +{countryCode.trim()} ({countryName})
                                      </Option>
                                    ),
                                  )}
                                </Select>,
                              )}
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item style={{ marginBottom: 0 }}>
                              {getFieldDecorator("phoneNumber", {
                                initialValue: phone,
                                rules: [
                                  {
                                    message: t("forms.validation.isRequired", {
                                      fieldName: t("forms.fields.phoneNumber"),
                                    }),
                                    required: true,
                                  },
                                  {
                                    message: t("forms.validation.min", {
                                      number:
                                        getMinPhoneNumberLength(countryCode),
                                    }),
                                    min: getMinPhoneNumberLength(countryCode),
                                  },
                                  {
                                    max: getMaxPhoneNumberLength(countryCode),
                                    message: t("forms.validation.max", {
                                      number:
                                        getMaxPhoneNumberLength(countryCode),
                                    }),
                                  },
                                  {
                                    message: t("forms.validation.digits"),
                                    pattern: new RegExp("^\\d+$"),
                                  },
                                  {
                                    validator: validatePhoneNumber,
                                  },
                                ],
                                validateTrigger: "onBlur",
                              })(<Input inputMode="tel" />)}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item
                        label={t("forms.fields.confirm", {
                          fieldName: t("forms.fields.phoneNumber"),
                        })}
                        required={true}
                      >
                        <Row gutter={12}>
                          <Col xs={24} sm={12}>
                            <Form.Item style={{ marginBottom: 0 }}>
                              {getFieldDecorator("countryCodeConfirm", {
                                initialValue: "1",
                                rules: [
                                  {
                                    message: t("forms.validation.isRequired", {
                                      fieldName: t("forms.fields.confirm", {
                                        fieldName: t(
                                          "forms.fields.countryCode",
                                        ),
                                      }),
                                    }),
                                    required: true,
                                  },
                                  {
                                    validator: validateCountryCodeConfirm,
                                  },
                                ],
                              })(
                                <Select
                                  filterOption={(inputValue, option) =>
                                    option.props.children
                                      .toString()
                                      .toLowerCase()
                                      .includes(inputValue.toLowerCase())
                                  }
                                  style={{ width: "100%" }}
                                >
                                  {ConfigurationStore?.phoneCountryCodes?.map(
                                    ({ countryCode, countryName }) => (
                                      <Option
                                        key={countryName}
                                        value={countryCode}
                                      >
                                        +{countryCode.trim()} ({countryName})
                                      </Option>
                                    ),
                                  )}
                                </Select>,
                              )}
                            </Form.Item>
                          </Col>
                          <Col xs={24} sm={12}>
                            <Form.Item style={{ marginBottom: 0 }}>
                              {getFieldDecorator("phoneNumberConfirm", {
                                rules: [
                                  {
                                    message: t("forms.validation.isRequired", {
                                      fieldName: t("forms.fields.confirm", {
                                        fieldName: t(
                                          "forms.fields.phoneNumber",
                                        ),
                                      }),
                                    }),
                                    required: true,
                                  },
                                  {
                                    message: t("forms.validation.min", {
                                      number:
                                        getMinPhoneNumberLength(countryCode),
                                    }),
                                    min: getMinPhoneNumberLength(countryCode),
                                  },
                                  {
                                    max: getMaxPhoneNumberLength(countryCode),
                                    message: t("forms.validation.max", {
                                      number:
                                        getMaxPhoneNumberLength(countryCode),
                                    }),
                                  },
                                  {
                                    message: t("forms.validation.digits"),
                                    pattern: new RegExp("^\\d+$"),
                                  },
                                  {
                                    validator: validatePhoneNumberConfirm,
                                  },
                                ],
                                validateTrigger: "onBlur",
                              })(<Input inputMode="tel" />)}
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item
                        label={
                          <div>
                            <Row gutter={0}>
                              <Col className="pin-label">
                                <Row>
                                  <Col>{t("forms.fields.pin")}</Col>
                                  <Col>
                                    <Tooltip title={t("register.tooltip.pin")}>
                                      <ExclamationCircleTwoTone
                                        twoToneColor={
                                          COLORS.utilities.warningOrange
                                        }
                                      />
                                    </Tooltip>
                                  </Col>
                                </Row>
                              </Col>
                              <Col className="pin-labelExtra">
                                ({t("register.labelExtra.pin")})
                              </Col>
                            </Row>
                          </div>
                        }
                      >
                        {getFieldDecorator("pin", {
                          initialValue: requestPreview?.metadataModel?.pin,
                          rules: [
                            {
                              message: t("forms.validation.isRequired", {
                                fieldName: t("forms.fields.pin"),
                              }),
                              required: true,
                            },
                            {
                              len: 5,
                              message: t("forms.validation.exact", {
                                number: 5,
                              }),
                            },
                          ],
                          validateTrigger: "onBlur",
                        })(
                          <InputNumber
                            disabled={requestPreview?.metadataModel?.pin}
                            maxLength={5}
                            placeholder="xxxxx"
                            style={{ maxWidth: 80 }}
                          />,
                        )}
                      </Form.Item>
                      <Divider />
                      <div>{t("register.accounts.title")}asd</div>
                      <div>
                        {accounts?.open?.map((open) => {
                          const acctNo = `xxxx${open.acctNo.substring(4)}`;
                          return (
                            <div key={`open-${acctNo}`}>
                              {acctNo}{" "}
                              {open.entitlement === "PRIMARY"
                                ? ` - ${t(
                                    `register.accounts.access.${open.entitlement.toLowerCase()}`,
                                  )}`
                                : null}
                              {open.disabled
                                ? ` - ${t("register.accounts.access.closed")}`
                                : null}
                            </div>
                          );
                        })}
                      </div>
                      <div>
                        {accounts?.close?.map((close) => {
                          const acctNo = `xxxx${close.acctNo.substring(4)}`;
                          return (
                            <div key={`close-${acctNo}`}>
                              {acctNo}{" "}
                              {!close.isSubscriptionAccount
                                ? t("register.accounts.access.closed")
                                : null}
                            </div>
                          );
                        })}
                      </div>
                      <div
                        style={{ marginBottom: 32 }}
                        className="reminderText"
                      >
                        {t("register.accounts.disclaimer")}
                      </div>
                      <Row justify="space-between">
                        <Col>
                          <Button
                            onClick={handleSubmit}
                            size="large"
                            type="primary"
                          >
                            {t("forms.buttons.submit")}
                          </Button>
                        </Col>
                        <Col>
                          <SalesForceChat />
                        </Col>
                      </Row>
                    </Spin>
                  </Form>
                )
              }
            />
          </Card>
        </div>
        <BrowserWarningFooter />
      </Layout.Content>
    </Layout>
  );
};

export default withAITracking(ai.reactPlugin, Form.create()(Register));
