import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken,
  CancelTokenSource,
} from "axios";

// ==== Stores ====
import AuthenticationStore from "stores/AuthenticationStore";

// ==== Types ====
import { EHttpMethods } from "types/globalTypes";

// ==== Utilities ====
import { generateBaseUrl, handleErrorTracking } from "utilities/apiUtilities";

class UserApi {
  newTokenSource = (): CancelTokenSource => {
    return axios.CancelToken.source();
  };

  getProfile = async (cancelToken: CancelToken): Promise<any> => {
    const response = await this._call({
      cancelToken,
      method: EHttpMethods.GET,
      url: `/profile`,
    });

    return response ? response.data : undefined;
  };

  private _call = async (
    config: AxiosRequestConfig,
  ): Promise<AxiosResponse> => {
    try {
      let _axios = axios.create({
        baseURL: `${generateBaseUrl()}torchapi/v1/User`,
        headers: {
          Authorization: `Bearer ${AuthenticationStore.accessToken}`,
          "Cache-Control": "no-cache,no-store,max-age=-1",
          Pragma: "no-cache",
        },
        responseType: "json",
        timeout: 60000,
      });
      const response = await _axios(config);
      return response;
    } catch (e) {
      handleErrorTracking(e, "UserApi", "_call");
    }
  };
}

export default new UserApi();
