/* eslint-disable sort-keys */
// Disabled to keep the order of our enums
// Invest stuff

// ==== Types ====
import { IEnumType } from "utilities/typeUtilities";
import { IOptionsTradingLevelType } from "views/Options/types/optionsTypes";

export const TERMS_AGREEMENT_DOCUMENT_NAME: IEnumType = {
  ACCOUNT_HOLDER_RELEASE: {
    key: "accountHolderRelease",
    translationKey: "enums.termsAgreementDocumentName.accountHolderRelease",
    value: 0,
  },
  OPTIONS: {
    key: "options",
    translationKey: "enums.termsAgreementDocumentName.options",
    value: 1,
  },
  MARGIN: {
    key: "margin",
    translationKey: "enums.termsAgreementDocumentName.margin",
    value: 2,
  },
};

export const INVESTMENT_EXPERIENCE: IEnumType = {
  NONE: {
    key: "none",
    translationKey: "enums.investmentExperience.none",
    value: 0,
  },
  LIMITED: {
    key: "limited",
    translationKey: "enums.investmentExperience.limited",
    value: 1,
  },
  AVERAGE: {
    key: "average",
    translationKey: "enums.investmentExperience.average",
    value: 2,
  },
  EXTENSIVE: {
    key: "extensive",
    translationKey: "enums.investmentExperience.extensive",
    value: 3,
  },
};

export const INVESTMENT_OBJECTIVES: IEnumType = {
  CONSERVATIVE: {
    key: "conservative",
    translationKey: "enums.investmentObjectives.conservative",
    value: 0,
  },
  MODERATE: {
    key: "moderate",
    translationKey: "enums.investmentObjectives.moderate",
    value: 1,
  },
  AGGRESSIVE: {
    key: "aggressive",
    translationKey: "enums.investmentObjectives.aggressive",
    value: 2,
  },
  SPECULATIVE: {
    key: "speculative",
    translationKey: "enums.investmentObjectives.speculative",
    value: 3,
  },
};

export const OPTIONS_LEVELS: IEnumType = {
  LEVEL_1: {
    key: "level 1: writing covered calls",
    translationKey: "enums.optionsLevels.level1",
    value: 1,
  },
  LEVEL_2: {
    key: "level 2: buying calls and puts",
    translationKey: "enums.optionsLevels.level2",
    value: 2,
  },
  LEVEL_3: {
    key: "level 3: spreads and straddles",
    translationKey: "enums.optionsLevels.level3",
    value: 3,
  },
  LEVEL_4: {
    key: "level 4: put writing",
    translationKey: "enums.optionsLevels.level4",
    value: 4,
  },
  LEVEL_5: {
    key: "level 5: writing uncovered calls",
    translationKey: "enums.optionsLevels.level5",
    value: 5,
  },
};

export const OPTIONS_TRADING_LEVEL: IOptionsTradingLevelType = {
  LEVEL1: {
    childText: ["enums.tradingLevels.level1.line1"],
    headerText: "enums.tradingLevels.level1.header",
    marginRequired: false,
    value: OPTIONS_LEVELS.LEVEL_1.value,
  },
  LEVEL2: {
    childText: [
      "enums.tradingLevels.level2.line1",
      "enums.tradingLevels.level2.line2",
    ],
    headerText: "enums.tradingLevels.level2.header",
    marginRequired: false,
    value: OPTIONS_LEVELS.LEVEL_2.value,
  },
  LEVEL3: {
    childText: [
      "enums.tradingLevels.level3.line1",
      "enums.tradingLevels.level3.line2",
    ],
    headerText: "enums.tradingLevels.level3.header",
    marginRequired: false,
    value: OPTIONS_LEVELS.LEVEL_3.value,
  },
  LEVEL4: {
    childText: [
      "enums.tradingLevels.level4.line1",
      "enums.tradingLevels.level4.line2",
      "enums.tradingLevels.level4.line3",
    ],
    headerText: "enums.tradingLevels.level4.header",
    marginRequired: false,
    value: OPTIONS_LEVELS.LEVEL_4.value,
  },
  LEVEL5: {
    childText: [
      "enums.tradingLevels.level5.line1",
      "enums.tradingLevels.level5.line2",
    ],
    headerText: "enums.tradingLevels.level5.header",
    marginRequired: false,
    value: OPTIONS_LEVELS.LEVEL_5.value,
  },
};
