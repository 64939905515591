import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Button, Input } from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";

// ==== Stores ====
import SelfEnrollStore from "views/SignUp/SelfEnroll/store/SelfEnrollStore";

// ==== Types ====
import { INewProfileForm } from "views/SignUp/SelfEnroll/types/selfEnrollTypes";

// ==== Components ====
import FormHeader from "components/FormHeader";
import PhoneNumberForm from "views/SignUp/components/PhoneNumbersForm";

const NewAccountForm: React.FunctionComponent<FormComponentProps> = observer(
  ({ form }) => {
    const {
      getFieldDecorator,
      getFieldValue,
      isFieldTouched,
      isFieldsTouched,
      isFieldValidating,
      validateFields,
    } = form;
    const { t } = useTranslation();

    const handleSubmit = async (
      event: React.FormEvent<HTMLFormElement>,
    ): Promise<void> => {
      event.preventDefault();

      validateFields(async (err: string[]): Promise<void> => {
        if (!err) {
          await SelfEnrollStore.createNewUserProfile();
        }
      });
    };

    const renderPhoneNumberForm = () => {
      return (
        <PhoneNumberForm
          getFieldDecorator={getFieldDecorator}
          getFieldValue={getFieldValue}
          isFieldTouched={isFieldTouched}
          isFieldsTouched={isFieldsTouched}
          isFieldValidating={isFieldValidating}
          validateFields={validateFields}
          phoneNumber={SelfEnrollStore.newProfileData.Phone}
          confirmPhoneNumber={SelfEnrollStore.newProfileData.ConfirmPhone}
          countryCode={SelfEnrollStore.newProfileData.CountryCode}
          confirmCountryCode={SelfEnrollStore.newProfileData.ConfirmCountryCode}
        />
      );
    };

    return (
      <div className="slideInWrapper">
        <FormHeader
          subheaderText={t("views.signUp.forms.IndividualForm.great")}
        />
        <Form layout="vertical" onSubmit={handleSubmit}>
          <Form.Item label={t("forms.fields.email")}>
            {getFieldDecorator("Email", {
              initialValue: SelfEnrollStore.emailValidationData?.Email,
              rules: [
                {
                  required: true,
                },
              ],
              validateTrigger: "onBlur",
            })(<Input disabled allowClear />)}
          </Form.Item>
          <Form.Item label={t("forms.fields.firstName")}>
            {getFieldDecorator("FirstName", {
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.firstName"),
                  }),
                  required: true,
                },
              ],
              validateTrigger: "onBlur",
            })(<Input allowClear />)}
          </Form.Item>
          <Form.Item label={t("forms.fields.lastName")}>
            {getFieldDecorator("LastName", {
              rules: [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.lastName"),
                  }),
                  required: true,
                },
              ],
              validateTrigger: "onBlur",
            })(<Input allowClear />)}
          </Form.Item>
          {renderPhoneNumberForm()}
          <Button
            disabled={SelfEnrollStore.isDisabled}
            htmlType="submit"
            type="primary"
          >
            {t("forms.buttons.submit")}
          </Button>
        </Form>
      </div>
    );
  },
);

export default Form.create<FormComponentProps>({
  onValuesChange: (_, changedFields: INewProfileForm) => {
    SelfEnrollStore.updateNewProfileData(changedFields);
  },
})(NewAccountForm);
