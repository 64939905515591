import * as React from "react";
import { Form } from "@ant-design/compatible";
import { Alert, Input, Select, AutoComplete, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import type { SelectValue } from "antd/lib/select/index";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import AutocompleteStore from "views/SignUp/Onboarding/store/AutocompleteStore";
import FormsStore from "stores/FormsStore";
import ConfigurationStore from "stores/ConfigurationStore";

// ==== Types ====
import { EOnboardingForms } from "views/SignUp/Onboarding/types/onboardingTypes";
import { ISuggestion } from "views/SignUp/Onboarding/types/autocompleteTypes";

// ==== Utilities ====
import { debounce } from "utilities/apiUtilities";
import { addOptionalTag, cleanString } from "utilities/genericUtilities";

// ==== Data ====
import STATES from "assets/data/states.json";
import CANADA_STATES from "assets/data/canadaProvincesAndTerritories.json";

const { Option } = Select;

interface IProps {
  decoratorPrefix: string;
  form: any; // TODO - Figure out how to get the Ant PropType here
  formKey: EOnboardingForms;
  isRequired: boolean;
}

// AddressForm is an abstraction of a basic address collection form
//
// Assumptions made by the form:
// - Expects that the TaxForm has already been presented to the user and that they've selected a country
// - That <Observer/> is wrapping it in the parent
//
// keys use the "decoratorPrefix + Field" pattern
// the 'formKey' prop is used to hydrate the form with init values
const AddressForm: React.FunctionComponent<IProps> = observer(
  ({ form, decoratorPrefix, formKey, isRequired }) => {
    const { getFieldDecorator, setFieldsValue, getFieldValue } = form;
    const [previousCountry, setPreviousCountry] = React.useState(undefined);
    const { t } = useTranslation();

    React.useEffect(() => {
      AutocompleteStore.setAddressFormData(decoratorPrefix, formKey);
    }, [decoratorPrefix, formKey]);

    const getInitialValue = (key: string) =>
      OnboardingStore.getFormItemData(formKey, key);

    const handleFormUpdate = () => {
      // After selecting an address, populate the form with the selected values
      setFieldsValue({
        [`${decoratorPrefix}StreetAddress2`]: getInitialValue(
          `${decoratorPrefix}StreetAddress2`,
        ),
        [`${decoratorPrefix}City`]: getInitialValue(`${decoratorPrefix}City`),
        [`${decoratorPrefix}StateProvince`]: getInitialValue(
          `${decoratorPrefix}StateProvince`,
        ),
        [`${decoratorPrefix}ZipPostal`]: getInitialValue(
          `${decoratorPrefix}ZipPostal`,
        ),
      });
    };

    const handleCountrySelect = () => {
      const countryFieldValue = getFieldValue(`${decoratorPrefix}Country`);

      // Prevents clearing of the stateProvince field if the user clicks
      // on the country they already have selected
      if (previousCountry !== countryFieldValue) {
        setFieldsValue({
          [`${decoratorPrefix}StateProvince`]: undefined,
        });
      }

      setPreviousCountry(countryFieldValue);
    };

    const handleAddressInput = async (index: SelectValue) => {
      await AutocompleteStore.handleSuggestionSelection(index.toString());
      handleFormUpdate();
    };

    const handleAddressLookup = (searchQuery: SelectValue) =>
      AutocompleteStore.getSuggestions(searchQuery.toString());

    const getCountryInitialValue = () => {
      // First, check if the user has already set the country
      if (getInitialValue(`${decoratorPrefix}Country`)) {
        return getInitialValue(`${decoratorPrefix}Country`);
      } else {
        let country = undefined;

        // Using the information from the TaxForm we can attempt to pre-populate this form for the user
        if (OnboardingStore.isUserUSCitizen()) {
          country = "US";
        }
        // else, populate with whatever they entered in the TaxForm
        else if (
          OnboardingStore.getFormItemData(
            EOnboardingForms.PERSONAL_INFO,
            "CountryOfCitizenship",
          )
        ) {
          country = OnboardingStore.getFormItemData(
            EOnboardingForms.PERSONAL_INFO,
            "CountryOfCitizenship",
          );
        } else {
          // All else fails, fallback to the states
          country = "US";
        }

        // Now that we have the country value, we need to set it into state
        if (!getInitialValue(`${decoratorPrefix}Country`)) {
          OnboardingStore.setFormItemData(formKey, {
            [`${decoratorPrefix}Country`]: country,
          });
        }

        return country;
      }
    };

    const generateSuggestionString = ({
      street_line,
      secondary,
      city,
      state,
      zipcode,
    }: ISuggestion) =>
      `${street_line}${
        secondary && ` ${secondary}`
      }, ${city} ${state}, ${zipcode}`;

    const renderAutoCompleteData = (suggestions: ISuggestion[]) => {
      let suggestionsOptions: {
        label: string;
        value: string;
      }[] = [];

      suggestions.forEach((suggestion, i) => {
        suggestionsOptions.push({
          label: generateSuggestionString(suggestion),
          value: `key${i}`,
        });
      });

      return suggestionsOptions;
    };

    const handleStateSelect = (state: string): void => {
      AutocompleteStore.setState(state);
    };

    const renderStateOrProvince = () => {
      // 🦅 United States 🦅
      if (getInitialValue(`${decoratorPrefix}Country`) === "US") {
        return (
          <Form.Item>
            {getFieldDecorator(`${decoratorPrefix}StateProvince`, {
              initialValue: getInitialValue(`${decoratorPrefix}StateProvince`),
              rules: isRequired && [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.state"),
                  }),
                  required: true,
                },
              ],
            })(
              <Select
                placeholder={t("forms.fields.state")}
                showSearch
                onSelect={handleStateSelect}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  cleanString(option.props.children).indexOf(
                    cleanString(input),
                  ) >= 0
                }
              >
                {STATES.map((state: { code: string; name: string }) => (
                  <Option key={state.code} value={state.code}>
                    {state.name}
                  </Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        );
        // 🍁 Canada, eh! 🍁
      } else if (getInitialValue(`${decoratorPrefix}Country`) === "CA") {
        return (
          <Form.Item>
            {getFieldDecorator(`${decoratorPrefix}StateProvince`, {
              initialValue: getInitialValue(`${decoratorPrefix}StateProvince`),
              rules: isRequired && [
                {
                  message: t("forms.validation.isRequired", {
                    fieldName: t("forms.fields.provinceOrTerritory"),
                  }),
                  required: true,
                },
              ],
            })(
              <Select
                placeholder={t("forms.fields.provinceOrTerritory")}
                showSearch
                onSelect={handleStateSelect}
                optionFilterProp="children"
                filterOption={(input, option) =>
                  cleanString(option.props.children).indexOf(
                    cleanString(input),
                  ) >= 0
                }
              >
                {CANADA_STATES.map((state: { code: string; name: string }) => (
                  <Option key={state.code} value={state.code}>
                    {state.name}
                  </Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        );
      } else {
        // No field should be rendered for other countries
        return;
      }
    };

    const isZipPostalRequired = (): boolean => {
      let isReq = false;
      let formName = FormsStore.currentScreen;
      let country;

      // This logic accesses the selected country for the respective form - currently applies to Employment and Trusted Contact Addresses
      switch (formName) {
        case EOnboardingForms.ADDRESS_COLLECTION:
          country = OnboardingStore.getFormItemData(
            EOnboardingForms.ADDRESS_COLLECTION,
            `${decoratorPrefix}Country`,
          );
          break;
        case EOnboardingForms.EMPLOYMENT_INFORMATION:
          country = OnboardingStore.getFormItemData(
            EOnboardingForms.EMPLOYMENT_INFORMATION,
            `${decoratorPrefix}Country`,
          );
          break;
        case EOnboardingForms.TRUSTED_CONTACT:
          country = OnboardingStore.getFormItemData(
            EOnboardingForms.TRUSTED_CONTACT,
            `${decoratorPrefix}Country`,
          );
          break;
        default:
          country = "US";
          break;
      }

      // Zip/Postal code is only required for US and Canada addresses
      if (country === "US" || country === "CA") {
        isReq = true;
      }

      return isReq;
    };

    return (
      <React.Fragment>
        {FormsStore.errorHandler.source && (
          <Alert
            message={t("error.addressValidation")}
            closable
            showIcon
            style={{ marginBottom: 20 }}
            type="warning"
          />
        )}
        <Form.Item>
          {getFieldDecorator(`${decoratorPrefix}Country`, {
            initialValue: getCountryInitialValue(),
            rules: isRequired && [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: t("forms.fields.country"),
                }),
                required: true,
              },
            ],
          })(
            <Select
              onSelect={handleCountrySelect}
              placeholder={t("forms.fields.country")}
              showSearch
              optionFilterProp="children"
              filterOption={(input, option) =>
                cleanString(option.props.children).indexOf(
                  cleanString(input),
                ) >= 0
              }
              onClick={isZipPostalRequired}
            >
              {ConfigurationStore?.countryCodes?.map(
                (country: { code: string; name: string }) => (
                  <Option value={country.code} key={country.code}>
                    {country.name}
                  </Option>
                ),
              )}
            </Select>,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator(`${decoratorPrefix}StreetAddress1`, {
            initialValue: getInitialValue(`${decoratorPrefix}StreetAddress1`),
            rules: isRequired && [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: t("forms.fields.address1"),
                }),
                required: true,
              },
              {
                max: 30,
                message: t("forms.validation.max", {
                  number: 30,
                }),
              },
            ],
          })(
            <div className="addressFormSpinner">
              <AutoComplete
                value={getInitialValue(`${decoratorPrefix}StreetAddress1`)}
                placeholder={t("forms.fields.address1")}
                options={renderAutoCompleteData(AutocompleteStore.suggestions)}
                defaultActiveFirstOption={false}
                onSelect={handleAddressInput}
                onSearch={debounce(handleAddressLookup, 200)}
                backfill
                style={{ width: "100%" }}
              />
              <Spin
                className="spinner"
                size="small"
                spinning={AutocompleteStore.getIsLoadingSuggestions()}
              />
            </div>,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator(`${decoratorPrefix}StreetAddress2`, {
            initialValue: getInitialValue(`${decoratorPrefix}StreetAddress2`),
            required: false,
            rules: [
              {
                max: 30,
                message: t("forms.validation.max", {
                  number: 30,
                }),
              },
            ],
          })(
            <Input
              placeholder={addOptionalTag(t, "forms.fields.address2")}
              allowClear
            />,
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator(`${decoratorPrefix}City`, {
            initialValue: getInitialValue(`${decoratorPrefix}City`),
            rules: isRequired && [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: t("forms.fields.city"),
                }),
                required: true,
              },
            ],
          })(<Input placeholder={t("forms.fields.city")} allowClear />)}
        </Form.Item>
        {renderStateOrProvince()}
        <Form.Item>
          {getFieldDecorator(`${decoratorPrefix}ZipPostal`, {
            initialValue: getInitialValue(`${decoratorPrefix}ZipPostal`),
            rules: isRequired && [
              {
                message: t("forms.validation.isRequired", {
                  fieldName: OnboardingStore.isUserUSCitizen()
                    ? t("forms.fields.zipCode")
                    : t("forms.fields.postalCode"),
                }),
                required: isZipPostalRequired(),
              },
              {
                message: t("forms.validation.min", {
                  number: 5,
                }),
                min: 5,
              },
              {
                max: 10,
                message: t("forms.validation.max", {
                  number: 10,
                }),
              },
            ],
            validateTrigger: "onBlur",
          })(
            <Input
              placeholder={`${
                OnboardingStore.isUserUSCitizen()
                  ? t("forms.fields.zipCode")
                  : t("forms.fields.postalCode")
              }${isZipPostalRequired() ? "" : " (optional)"}`}
              allowClear
            />,
          )}
        </Form.Item>
      </React.Fragment>
    );
  },
);

export default AddressForm;
