// ==== Types ====
import {
  EOnboardingModelTypes,
  EOnboardingForms,
} from "views/SignUp/Onboarding/types/onboardingTypes";

// ==== Apis ====
import { getProfileById } from "apiHandlers/ProfileApiHandler";
import DocumentUploadApi from "apis/DocumentUploadApi";

// ==== Stores ====
import FormsStore from "stores/FormsStore";
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import AuthenticationStore from "stores/AuthenticationStore";

// ==== Utilities ====
import { handleErrorTracking } from "utilities/apiUtilities";

export const uploadFile = async (): Promise<void> => {
  AuthenticationStore.checkTokenSource();

  const formData = new FormData();
  formData.append("file", OnboardingStore.uploadedFile);

  try {
    FormsStore.setLoading(true);

    const response = await DocumentUploadApi.postDocument(
      AuthenticationStore._tokenSource.token,
      OnboardingStore.profileId,
      OnboardingStore.getModelId(EOnboardingModelTypes.DOCUMENTS),
      formData,
    );

    if (response) {
      OnboardingStore.setFormItemData(EOnboardingForms.UPLOAD_ID, {
        DocumentId: response.id,
        File: response.file,
        FileName: response.fileName,
        isFileUploaded: true,
      });

      await getProfileById();
    } else {
      OnboardingStore.setFormItemData(EOnboardingForms.UPLOAD_ID, {
        isFileUploaded: false,
      });
      FormsStore.setErrorHandler("uploadFile");
      await getProfileById();
    }
  } catch (e) {
    handleErrorTracking(e, "DocumentApiHandler", "uploadFile");
  } finally {
    FormsStore.setLoading(false);
  }
};
