import { makeAutoObservable, toJS } from "mobx";
import { CancelTokenSource } from "axios";

// ==== Types ====
import {
  IConfig,
  IPrivateLabelSetting,
  FEATURE_FLAGS,
  ERoutePaths,
  ELocalStorageKeys,
} from "types/globalTypes";
import {
  TOKEN_USER_STATES,
  ILoginFormData,
  INewUserCreationFormData,
  IDevToken,
  IVerificationCodeFormData,
  EAuthenticationScreens,
} from "types/authenticationTypes";
import {
  IAccountOpening,
  EOnboardingScreens,
} from "views/SignUp/Onboarding/types/onboardingTypes";
import { ACCOUNT_OPEN_STATUSES } from "views/SignUp/Onboarding/types/onboardingEnums";
import { EMarginScreens } from "views/Margin/types/marginTypes";
import { EOptionScreens } from "views/Options/types/optionsTypes";
import { IUserExistsResponse } from "views/SignUp/SelfEnroll/types/selfEnrollTypes";

// ==== Stores ====
import OnboardingStore from "views/SignUp/Onboarding/store/OnboardingStore";
import FormsStore from "stores/FormsStore";

// ==== APIs ====
import TokenApi from "apis/TokenApi";
import { getAPIToken } from "apiHandlers/TokenApiHandler";
import {
  getHasProfile,
  putProfileSettings,
  generateUserProfile,
  getProfileById,
} from "apiHandlers/ProfileApiHandler";
import { getDevToken } from "apiHandlers/ConfigurationApiHandler";
import { getUserProfile } from "apiHandlers/UserApiHandler";
import {
  postSignUp,
  postExistingUserSignUp,
  postVerificationCode,
  postAuthenticate,
  getUserExists,
  getExistingEmail,
} from "apiHandlers/SelfEnrollApiHandler";

// ==== Utilities ====
import {
  preloadProfileData,
  preloadAccountOpeningData,
} from "views/SignUp/Onboarding/utilities/PreloadOnboardingData";
import { generateUrlWithExistingQueryStrings } from "utilities/apiUtilities";
import { isApplicationRunningLocally } from "utilities/genericUtilities";
import { assignNewUrl } from "utilities/googleTagsUtilities";

const DEFAULT_PRIVATE_LABEL_CODE = "snex1";
export const DEFAULT_COMPANY_NAME = "StoneX";

class AuthenticationStore {
  // ==== Authentication ====
  accessToken: string = undefined;
  devToken: IDevToken = undefined;
  tokenUserStats: string = undefined;
  jwtToken: string = undefined;
  // The screen we need to return back to
  returnScreen: string = undefined;

  // ==== API Response ====
  isUserProfileExist: boolean = undefined;
  userExistsResponse: IUserExistsResponse = undefined;

  // ==== Error Messages ====
  newUserCreationFormError: boolean = undefined;
  genericAuthError: boolean = undefined;
  verificationCodeError: boolean = undefined;
  accountAlreadyExistsError: boolean = undefined;
  isVerifiedError: boolean = undefined;

  // ==== Config ====
  config: IConfig = undefined;

  // ==== Local Storage ====
  authEmail: string = undefined;

  // ==== URL Search Params ====
  urlPrivateLabelCode: string = DEFAULT_PRIVATE_LABEL_CODE;
  urlProfileId: string = undefined;
  urlState: string = undefined;
  urlAccountNumber: string = undefined;
  urlRedirectUrl: string = undefined;
  urlGtmKey: string = undefined;
  // The return object from the privateLabel settings, used for button redirect
  privateLabelSetting: IPrivateLabelSetting = undefined;

  // ==== Form Data ====
  newUserCreationFormData: Partial<INewUserCreationFormData> = {
    ConfirmCountryCode: "1",
    CountryCode: "1",
  };
  verificationCode: string = undefined;
  loginFormData: Partial<ILoginFormData>;

  // ==== User Errors ====
  accountSelectionMessage: string = undefined;

  // API Token Magic
  _tokenSource: CancelTokenSource;

  constructor() {
    makeAutoObservable(this);
  }

  // ==== Getters ====
  getDevToken = (): IDevToken => {
    return toJS(this.devToken);
  };

  // ==== Setters ====
  setConfigData = (config: IConfig) => {
    this.config = config;
  };

  setDevToken = (devToken: IDevToken) => {
    this.devToken = devToken;

    // Handles the edge case where the api returns no country code
    if (!devToken.phoneCountryCode) {
      this.devToken.phoneCountryCode = "1";
    }
  };

  setJWTToken = (jwt: string) => {
    this.jwtToken = jwt;
  };

  setTokenUserStatus = (tokenUserStats: string) => {
    this.tokenUserStats = tokenUserStats;
  };

  setPrivateLabelSetting = (privateLabelSetting: IPrivateLabelSetting) => {
    this.privateLabelSetting = privateLabelSetting;
  };

  setPrivateLabelCode = (_privateLabelCode: string) => {
    this.urlPrivateLabelCode = DEFAULT_PRIVATE_LABEL_CODE;
  };

  setUrlState = (state: string) => {
    this.urlState = state;
  };

  setAuthEmail = (email: string) => {
    this.authEmail = email;
  };

  setUrlProfileId = (profileId: string) => {
    this.urlProfileId = profileId;
  };

  setUrlRedirectUrl = (redirectUrl: string) => {
    this.urlRedirectUrl = redirectUrl;
  };

  setGtmKey = (gtmKey: string) => {
    // Used by marketing/ Forex to track users,storing it locally because there is an auth-refresh
    // that happens after the user gets the key
    localStorage.setItem(ELocalStorageKeys.GTM_KEY, gtmKey);

    this.urlGtmKey = gtmKey;
  };

  setUrlAccountNumber = (urlAccountNumber: string) => {
    this.urlAccountNumber = urlAccountNumber;
  };

  setReturnString = (returnScreen: string) => {
    this.returnScreen = returnScreen;
  };

  setAccountSelectionMessage = (accountSelectionMessage: string) => {
    this.accountSelectionMessage = accountSelectionMessage;
  };

  setVerificationFormData = (
    changedFields: Partial<IVerificationCodeFormData>,
  ) => {
    this.verificationCode = changedFields.verificationCode;

    // If they incorrectly entered a code, clear out the errors
    this.verificationCodeError = false;

    // Edge case: If the user is NOT verified and returns we need to gather their password again
    // we are pushing it into the newUserCreationFormData just to keep it as the single source
    // of truth
    if (changedFields.password) {
      this.updateNewUserCreationFormData({
        ...this.newUserCreationFormData,
        confirmPassword: changedFields.password,
        password: changedFields.password,
      });
    }
  };

  getReturnNavigationScreen = (): string => {
    // If the user has more than one account, we first need to select one
    // before sending them to the feature workflow
    const accountOpenings = toJS(OnboardingStore.profile?.accountOpenings);

    // because REASONS, we need to warp the user to the accountSelection page before warping them to the
    // options/ margin flow. This is to keep the auth redirect code from exploding
    if (FormsStore.initRedirectScreen) {
      return EAuthenticationScreens.ACCOUNT_SELECTION;
    }

    if (!accountOpenings || accountOpenings?.length === 0) {
      return EOnboardingScreens.INDIVIDUAL;
    }

    if (accountOpenings?.length > 0) {
      if (this.urlAccountNumber) {
        const foundAccountNumber = accountOpenings.filter(
          (account: IAccountOpening) =>
            account.accountNumber === this.urlAccountNumber,
        );

        // If we found an account, preload it
        if (foundAccountNumber.length === 1) {
          const foundAccount = foundAccountNumber[0];

          if (
            foundAccount.status === ACCOUNT_OPEN_STATUSES.SUBMITTED.value ||
            foundAccount.status === ACCOUNT_OPEN_STATUSES.OPENED.value
          ) {
            this.handleAccountSelection(foundAccountNumber[0]);

            if (this.returnScreen === EMarginScreens.WELCOME) {
              FormsStore.setCurrentScreen(EOnboardingScreens.WARP_MARGIN);
            } else if (this.returnScreen === EOptionScreens.LANDING) {
              FormsStore.setCurrentScreen(EOnboardingScreens.WARP_OPTIONS);
            }
          } else {
            // If the account selected is NOT submitted
            this.setAccountSelectionMessage(
              "views.signUp.forms.AccountSelection.errorMessages.accountNotSubmitted",
            );

            return EAuthenticationScreens.ACCOUNT_SELECTION;
          }
          return this.returnScreen;
        } else {
          // If there is URL account number BUT it isn't found
          this.setAccountSelectionMessage(
            "views.signUp.forms.AccountSelection.errorMessages.accountNotFound",
          );
          return EAuthenticationScreens.ACCOUNT_SELECTION;
        }
      } else {
        // If the user has created their first account but has not submitted it yet
        // we should redirect them to start of Onboarding
        if (
          accountOpenings.length === 1 &&
          accountOpenings[0].status === ACCOUNT_OPEN_STATUSES.CREATED.value
        ) {
          return EOnboardingScreens.INDIVIDUAL;
        }
        return EAuthenticationScreens.ACCOUNT_SELECTION;
      }
    }

    // Fallback - If we don't have a returnScreen, throw error and guess!
    if (this.returnScreen) {
      return this.returnScreen;
    } else {
      FormsStore.setErrorHandler(
        "getReturnNavigationScreen",
        "Missing returnScreen",
      );
    }

    return undefined;
  };

  getFeatureFlag = (featureFlag: FEATURE_FLAGS): boolean => {
    if (this.config?.featureFlags) {
      return this.config?.featureFlags[featureFlag];
    }

    return false;
  };

  // === Handlers ===
  handleGetToken = async (): Promise<void> => {
    await getDevToken();
  };

  handleEmailValidation = async (): Promise<void> => {
    this.userExistsResponse = await getUserExists();

    // If the API call fails, we need to stop the user from going forward
    if (!this.userExistsResponse) {
      // ==== Invalid API Call ====
      this.genericAuthError = true;
      FormsStore.setErrorHandler(
        "getUserExists",
        "The API call to getUserExists has failed",
      );
    } else {
      // ==== Valid API Call ====
      //
      // If user does not exist --> New User Creation
      // If user does exist, but is not verified --> Verification Screen
      // If user does exist, but verified is undefined --> /Authentication redirect
      // If user does exist, and is verified --> Login

      // User doesn't exist
      if (!this.userExistsResponse?.id) {
        // Take them to create user
        FormsStore.setGuardedCurrentScreen(
          EAuthenticationScreens.NEW_USER_CREATION,
        );
      } else {
        if (this.userExistsResponse.existingUser) {
          this.updateNewUserCreationFormData({
            ConfirmCountryCode: this.userExistsResponse.phoneNumber.countryCode,
            confirmEmail: this.userExistsResponse.email,
            ConfirmPhone: this.userExistsResponse.phoneNumber.phone,
            CountryCode: this.userExistsResponse.phoneNumber.countryCode,
            email: this.userExistsResponse.email,
            firstName: this.userExistsResponse.firstName,
            lastName: this.userExistsResponse.lastName,
            Phone: this.userExistsResponse.phoneNumber.phone,
          });
          FormsStore.setGuardedCurrentScreen(
            EAuthenticationScreens.NEW_USER_CREATION,
          );
        }
        // If the user's isVerified is undefined, that means they were
        // created pre verification and they already have an account
        else if (this.userExistsResponse.isVerified === undefined) {
          this.getTokenWithEmail(this.userExistsResponse.email);
        }
        // User exists, isn't verified
        else if (!this.userExistsResponse.isVerified) {
          // Pre populate the data and let them continue
          this.updateNewUserCreationFormData({
            confirmEmail: this.userExistsResponse.email,
            email: this.userExistsResponse.email,
            firstName: this.userExistsResponse.firstName,
            lastName: this.userExistsResponse.lastName,
          });

          // Show alert to user
          this.isVerifiedError = true;
          FormsStore.setGuardedCurrentScreen(
            EAuthenticationScreens.VERIFICATION_CODE,
          );
        } else {
          // If account does exist and is verified, get token
          this.getTokenWithEmail(this.userExistsResponse.email);
        }
      }
    }
  };

  handleNewUserCreation = async (): Promise<void> => {
    let isUserValid: boolean = undefined;

    if (this.userExistsResponse.existingUser) {
      isUserValid = await postExistingUserSignUp();
    } else {
      isUserValid = await postSignUp();
    }

    if (!isUserValid) {
      // Email not sent, something has gone wrong
      this.newUserCreationFormError = true;
    } else {
      // Email sent to user with code
      FormsStore.setGuardedCurrentScreen(
        EAuthenticationScreens.VERIFICATION_CODE,
      );
    }
  };

  handleVerificationCode = async (): Promise<void> => {
    const isVerificationCodeValid = await postVerificationCode();

    if (!isVerificationCodeValid) {
      // The code the user entered is not correct
      this.verificationCodeError = true;
    } else {
      this.verificationCodeError = false;
      // Verification Code correctly entered
      await postAuthenticate();
    }
  };

  handleUserPreloadWithProfileId = async (): Promise<void> => {
    const response = await getExistingEmail();

    this.updateLoginFormData({
      ConfirmEmail: response,
      Email: response,
    });
    this.updateNewUserCreationFormData({
      confirmEmail: response,
      email: response,
    });
  };

  handleAccountSelection = (account: IAccountOpening): void => {
    OnboardingStore.setAccountOpening(account);

    // need to preload margin/ account data
    preloadAccountOpeningData(account);
  };

  // ==== Login Form ====
  updateLoginFormData = (changedFields: ILoginFormData) => {
    this.loginFormData = {
      ...this.loginFormData,
      ...changedFields,
    };

    FormsStore.resetErrorHandler();
  };

  // ==== Account Creation Form ====
  updateNewUserCreationFormData = (
    changedFields: Partial<INewUserCreationFormData>,
  ) => {
    this.newUserCreationFormData = {
      ...this.newUserCreationFormData,
      ...changedFields,
    };

    // Reset errors
    this.newUserCreationFormError = false;
    FormsStore.resetErrorHandler();
  };

  // ==== Auth Handlers ====
  checkTokenSource = () => {
    if (this._tokenSource) {
      this._tokenSource.cancel();
    }
    this._tokenSource = TokenApi.newTokenSource();
  };

  handleInitialFeatureLoad = async (returnScreen: string): Promise<void> => {
    if (!FormsStore.currentScreen) {
      await FormsStore.setCurrentScreen(returnScreen);
    }

    if (!OnboardingStore.getAccountOpeningAccountNumber()) {
      await this.handleInitialTokenFetch(returnScreen);
    }
  };

  handleInitialTokenFetch = async (returnScreen: string): Promise<void> => {
    FormsStore.setInitAuthLoading(true);

    this.setReturnString(returnScreen);

    let tokenResponse = await getAPIToken();

    if (isApplicationRunningLocally()) {
      // Dev token injection

      const devToken = this.getDevToken();
      if (devToken) {
        this.handleAuthorizedUser(devToken);
        return;
      }
    }

    // Tokens form already existing accounts will not have a userState
    this.setTokenUserStatus(tokenResponse.userState);

    FormsStore.setLoading(true);
    // Stopping the auth spinner just incase the user is NOT authorized
    FormsStore.setInitAuthLoading(false);

    // If there is a profileId, fetch the user's email
    if (this.urlProfileId) {
      await this.handleUserPreloadWithProfileId();
    }

    switch (tokenResponse.userState) {
      case TOKEN_USER_STATES.AUTHORIZED:
        this.handleAuthorizedUser(tokenResponse);
        break;
      case TOKEN_USER_STATES.TOKEN_NOT_FOUND:
        // Token not found =Need to collect Email

        // If the user passed in the user's email via url parameters, send them to login
        if (this.authEmail) {
          FormsStore.setGuardedCurrentScreen(EAuthenticationScreens.LOGIN);
        }
        // If this is a brand new account, they need to sign up first
        else if (this.returnScreen === EOnboardingScreens.INDIVIDUAL) {
          FormsStore.setGuardedCurrentScreen(
            EAuthenticationScreens.EMAIL_VALIDATION,
          );
        } else {
          FormsStore.setGuardedCurrentScreen(EAuthenticationScreens.LOGIN);
        }

        break;
      case TOKEN_USER_STATES.ERROR:
      default:
        break;
    }

    FormsStore.setLoading(false);
  };

  handleAuthorizedUser = async (tokenResponse: IDevToken): Promise<void> => {
    FormsStore.setInitAuthLoading(true);

    this.accessToken = tokenResponse.token;

    // Preload token data into the onboardingStore
    OnboardingStore.setTokenData(tokenResponse);

    await getHasProfile();

    if (this.isUserProfileExist) {
      FormsStore.setLoading(true);

      await getUserProfile();
      await getProfileById();

      // Preload also checks to see if this is a new/ or returning user
      preloadProfileData(toJS(OnboardingStore.profile));
    } else {
      await generateUserProfile();
      await getProfileById();
    }

    // Handling profileSettings fetch
    if (this.urlPrivateLabelCode) {
      await putProfileSettings();
    }

    // You're still here? it's over, go home!
    FormsStore.setCurrentScreen(this.getReturnNavigationScreen());
    FormsStore.setInitAuthLoading(false);
  };

  getTokenWithEmail = async (email: string): Promise<void> => {
    let tokenResponse = await getAPIToken(email);
    if (this.authEmail) {
      FormsStore.setLoading(true);
    }

    this.setTokenUserStatus(tokenResponse?.userState);

    // Need a redirect URL via authRedirect form the /config endpoint
    const redirectBaseUrl = this.config.authRedirect;

    switch (tokenResponse.userState) {
      case TOKEN_USER_STATES.REQUIRES_LOGIN:
        const redirectPath = encodeURIComponent(
          `signup/onboarding?privateLabelCode=${this.urlPrivateLabelCode}`,
        );

        let redirectUrl = `${redirectBaseUrl}l/authenticate?redirectPath=${redirectPath}&privateLabelCode=${this.urlPrivateLabelCode}`;

        // Margin - Note, the initRedirectScreen is used for the case where the user lands on the /margin route non auth'd
        if (
          this.returnScreen === EMarginScreens.WELCOME ||
          FormsStore.initRedirectScreen === ERoutePaths.MARGIN
        ) {
          redirectUrl = `${redirectBaseUrl}l/authenticate?redirectPath=${encodeURIComponent(
            generateUrlWithExistingQueryStrings("margin"),
          )}&privateLabelCode=${this.urlPrivateLabelCode}`;
        }

        // Options
        if (
          this.returnScreen === EOptionScreens.LANDING ||
          FormsStore.initRedirectScreen === ERoutePaths.OPTIONS
        ) {
          redirectUrl = `${redirectBaseUrl}l/authenticate?redirectPath=${encodeURIComponent(
            generateUrlWithExistingQueryStrings("options"),
          )}&privateLabelCode=${this.urlPrivateLabelCode}`;
        }

        // Because we can't store the user's email in the url, save it to local storage
        // for the auth loop-d-loop
        if (this.authEmail) {
          localStorage.setItem(ELocalStorageKeys.USER_EMAIL, this.authEmail);
        }

        assignNewUrl(redirectUrl);
        break;
      case TOKEN_USER_STATES.AUTHORIZED:
        this.handleAuthorizedUser(tokenResponse);
        break;
      case TOKEN_USER_STATES.USER_NOT_FOUND:
        // If the account does not exist, redirect to account creation and add banner
        OnboardingStore.setFormItemData(EOnboardingScreens.INDIVIDUAL, {
          ConfirmEmail: email,
          Email: email,
        });

        FormsStore.setCurrentScreen(EOnboardingScreens.INDIVIDUAL);
        break;
      case TOKEN_USER_STATES.ERROR:
      default:
        FormsStore.setErrorHandler(
          "getTokenWithEmail",
          tokenResponse.userState,
        );
        break;
    }
  };
}

export default new AuthenticationStore();
