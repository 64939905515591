import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { createBrowserHistory, History } from "history";
import { ICustomProperties } from "@microsoft/applicationinsights-core-js";

export const APP_INSIGHTS_KEYS = {
  ERROR_HANDLER: "errorHandler_",
};

class TelemetryService {
  appInsights!: ApplicationInsights;
  history: History;
  reactPlugin: ReactPlugin;

  constructor() {
    this.reactPlugin = new ReactPlugin();
    this.history = createBrowserHistory();
  }

  initialize = (instrumentationKey: string) => {
    this.appInsights = new ApplicationInsights({
      config: {
        disableFetchTracking: false,
        extensionConfig: {
          [this.reactPlugin.identifier]: {
            history: this.history,
          },
        },
        extensions: [this.reactPlugin],
        instrumentationKey,
        maxBatchInterval: 0,
      },
    });
    this.appInsights.loadAppInsights();
  };

  customEvent = (eventName: string, customProperties?: ICustomProperties) => {
    this.appInsights.trackEvent(
      {
        name: eventName,
      },
      customProperties,
    );
  };
}

export let ai = new TelemetryService();
