import * as React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";

// ==== Stores ====
import ConfigurationStore from "stores/ConfigurationStore";

// ==== Types ====
import { EApplicationThemes } from "types/globalTypes";

const stonexLogo = require("../../assets/images/StonexLogo.svg").default;
const stonex100Logo = require("../../assets/images/Stonex100Logo.svg").default;
const stonexLogo_DarkMode =
  require("../../assets/images/StonexLogo_dark.png").default;

// const stonexOneLogo = require("../../assets/images/StonexOneLogo.svg").default;
const stonexOneLogo_DarkMode =
  require("../../assets/images/StonexOneLogo_dark.svg").default;

interface IProps {
  // Used to control what logo is rendered to the user
  isLarge?: boolean;
  isLogoStonexOne?: boolean;
}

const HeaderLogo: React.FunctionComponent<IProps> = observer(
  ({ isLogoStonexOne, isLarge }) => {
    const { t } = useTranslation();

    return (
      <div className="centeredContent">
        {isLogoStonexOne ? (
          <img
            className={`${isLarge ? "largeHeaderLogo" : "headerLogo"}`}
            alt={t("imageAltText.logo")}
            src={
              ConfigurationStore.applicationTheme ===
              EApplicationThemes.DarkMode
                ? stonexOneLogo_DarkMode
                : stonexLogo
            }
          />
        ) : (
          <img
            className={`${isLarge ? "largeHeaderLogo" : "headerLogo"}`}
            alt={t("imageAltText.logo")}
            src={
              ConfigurationStore.applicationTheme ===
              EApplicationThemes.DarkMode
                ? stonexLogo_DarkMode
                : stonex100Logo
            }
          />
        )}
      </div>
    );
  },
);

export default HeaderLogo;
