import * as React from "react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import ReCAPTCHA from "react-google-recaptcha";
import { Form } from "@ant-design/compatible";
import {
  Alert,
  Button,
  Card,
  Col,
  Input,
  Layout,
  Radio,
  Result,
  Row,
  Spin,
} from "antd";
import { FormComponentProps } from "@ant-design/compatible/lib/form";
import { CancelTokenSource } from "axios";
import { Trans, useTranslation } from "react-i18next";

// ==== Components ====
import BrowserWarningFooter from "components/BrowserWarningFooter";
import SiteHeader from "components/SiteHeader";
import SalesForceChat from "components/SalesForceChat";

// ==== Apis ====
import ConfigurationApi from "apis/ConfigurationApi";

// ==== Types ====
import { EPlatforms } from "types/globalTypes";

// ==== Utilities ====
import { ai } from "configuration/appInsights";

interface IContactInfo {
  email: string;
  phone: string;
}

const { Meta } = Card;
const { Content } = Layout;

const Contact: React.FunctionComponent<FormComponentProps> = ({ form }) => {
  const { t } = useTranslation();
  const {
    getFieldDecorator,
    getFieldValue,
    getFieldsValue,
    validateFieldsAndScroll,
  } = form;
  const [cancelToken, setCancelToken] = React.useState<CancelTokenSource>();
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);
  const [platform, setPlatform] = React.useState<EPlatforms>();

  React.useEffect(() => {
    return () => cancelToken?.cancel();
  }, [cancelToken]);

  const [isCaptchaPassed, setIsCaptchaPassed] = React.useState(false);

  const handleSubmit = async () => {
    validateFieldsAndScroll(async (errors) => {
      if (errors === null || (errors && !Object.keys(errors).length)) {
        setIsSubmitting(true);
        setHasError(false);
        setPlatform(getFieldValue("platform"));
        if (cancelToken) {
          cancelToken.cancel();
        }
        const newCancelToken = ConfigurationApi.newTokenSource();
        setCancelToken(newCancelToken);
        const response = await ConfigurationApi.postContact(
          { ...getFieldsValue() },
          newCancelToken.token,
        );
        if (response?.status === 200) {
          setIsSubmitted(true);
        } else {
          setHasError(true);
        }
        setIsSubmitting(false);
      }
    });
  };

  const renderContactInfo = (): IContactInfo => {
    if (platform === EPlatforms.StoneXClient) {
      return {
        email: "stonexclient@stonex.com",
        phone: "+1-800-405-4106",
      };
    }
    return {
      email: "stonexadvisor@stonex.com",
      phone: "+1-407-741-5381",
    };
  };

  return (
    <React.Fragment>
      <SiteHeader />
      <Content>
        <div className="cardWrapper">
          <Card className="home-card">
            <Meta
              title={t("contact.title")}
              description={
                isSubmitted ? (
                  <div className="flexColumn centeredContent">
                    <Result status="success" />
                    <div className="header">{t("forms.result.title")}</div>
                    <div className="body" style={{ paddingTop: 10 }}>
                      {t("contact.success.message1")}
                    </div>
                    <div className="body" style={{ paddingTop: 10 }}>
                      <Trans
                        i18nKey="contact.success.message2"
                        values={{
                          email: renderContactInfo().email,
                          phone: renderContactInfo().phone,
                        }}
                      >
                        {t("contact.furtherAssistance")}
                        <a
                          className="link"
                          href={`mailto:${renderContactInfo().email}`}
                        >
                          {renderContactInfo().email}
                        </a>
                      </Trans>
                    </div>
                  </div>
                ) : (
                  <Form colon={false}>
                    <Spin spinning={isSubmitting}>
                      <div className="body">{t("contact.intro")}</div>
                      {hasError && (
                        <Alert
                          message={t("forms.result.error")}
                          showIcon={true}
                          style={{ marginBottom: 16 }}
                          type="error"
                        />
                      )}
                      <Form.Item label={t("forms.fields.platform")}>
                        {getFieldDecorator("platform", {
                          rules: [
                            {
                              message: t("forms.validation.isRequired", {
                                fieldName: t("forms.fields.platform"),
                              }),
                              required: true,
                            },
                          ],
                        })(
                          <Radio.Group>
                            <Radio value={EPlatforms.StoneXClient}>
                              {t("platforms.client")}
                            </Radio>
                            <Radio value={EPlatforms.StoneXPro}>
                              {t("platforms.pro")}
                            </Radio>
                          </Radio.Group>,
                        )}
                      </Form.Item>
                      {getFieldValue("platform") !== undefined && (
                        <React.Fragment>
                          <Form.Item label={t("forms.fields.email")}>
                            {getFieldDecorator("email", {
                              rules: [
                                {
                                  message: t("forms.validation.isRequired", {
                                    fieldName: t("forms.fields.email"),
                                  }),
                                  required: true,
                                },
                                {
                                  message: t("forms.validation.emailFormat"),
                                  required: true,
                                  type: "email",
                                },
                              ],
                              validateTrigger: ["onBlur"],
                            })(<Input maxLength={100} />)}
                          </Form.Item>
                          <Form.Item label={t("forms.fields.firstName")}>
                            {getFieldDecorator("firstName", {
                              rules: [
                                {
                                  message: t("forms.validation.isRequired", {
                                    fieldName: t("forms.fields.firstName"),
                                  }),
                                  required: true,
                                },
                              ],
                              validateTrigger: ["onBlur"],
                            })(<Input maxLength={30} />)}
                          </Form.Item>
                          <Form.Item label={t("forms.fields.lastName")}>
                            {getFieldDecorator("lastName", {
                              rules: [
                                {
                                  message: t("forms.validation.isRequired", {
                                    fieldName: t("forms.fields.lastName"),
                                  }),
                                  required: true,
                                },
                              ],
                              validateTrigger: ["onBlur"],
                            })(<Input maxLength={30} />)}
                          </Form.Item>
                          {getFieldValue("platform") ===
                            EPlatforms.StoneXClient && (
                            <Form.Item label={t("forms.fields.last4")}>
                              {getFieldDecorator(
                                "last4AcctNo",
                                {},
                              )(<Input maxLength={4} />)}
                            </Form.Item>
                          )}
                          <Form.Item label={t("forms.fields.comments")}>
                            {getFieldDecorator("comments", {
                              rules: [
                                {
                                  message: t("forms.validation.isRequired", {
                                    fieldName: t("forms.fields.comments"),
                                  }),
                                  required: true,
                                },
                              ],
                              validateTrigger: ["onBlur"],
                            })(<Input.TextArea maxLength={500} rows={5} />)}
                          </Form.Item>
                          <div className="centeredContent">
                            <ReCAPTCHA
                              sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                              onChange={() => setIsCaptchaPassed(true)}
                            />
                          </div>
                          <Row
                            justify="space-between"
                            style={{ marginTop: "1em" }}
                          >
                            <Col>
                              <Button
                                onClick={handleSubmit}
                                size="large"
                                type="primary"
                                disabled={!isCaptchaPassed}
                              >
                                {t("forms.buttons.submit")}
                              </Button>
                            </Col>
                            <Col>
                              <SalesForceChat />
                            </Col>
                          </Row>
                        </React.Fragment>
                      )}
                    </Spin>
                  </Form>
                )
              }
            />
          </Card>
        </div>
        <BrowserWarningFooter />
      </Content>
    </React.Fragment>
  );
};

export default withAITracking(ai.reactPlugin, Form.create()(Contact));
