import * as React from "react";

// ==== Components ====
import BrowserWarningFooter from "components/BrowserWarningFooter";
import TopBar from "components/TopBar";
import SalesForceChat from "components/SalesForceChat";

interface IProps {
  children?: React.ReactNode;
}

const CenteredWrapper: React.FunctionComponent<IProps> = ({
  children,
}): JSX.Element => (
  <div className="pageWrapper">
    <TopBar />
    <div style={{ paddingTop: 50 }} className="centeredContent">
      {children}
    </div>
    <SalesForceChat isLink />
    <BrowserWarningFooter />
  </div>
);

export default CenteredWrapper;
