import * as React from "react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { Result } from "antd";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { ai } from "configuration/appInsights";

const NotFound: React.FunctionComponent = () => {
  const location = useLocation();
  const { t } = useTranslation();

  React.useEffect(() => {
    ai.appInsights.trackException({
      error: new Error("404 error"),
      properties: { location },
      severityLevel: SeverityLevel.Error,
    });
  }, [location]);

  return (
    <div className="notFound">
      <Result status="404" subTitle={t("notFound.subTitle")} title="404" />
    </div>
  );
};

export default withAITracking(ai.reactPlugin, NotFound);
