import * as React from "react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { RightOutlined } from "@ant-design/icons";
import { Button, Card, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

// ==== Components ====
import BrowserWarningFooter from "components/BrowserWarningFooter";
import SiteHeader from "components/SiteHeader";

// ==== Config ====
import { ai } from "configuration/appInsights";
import { assignNewUrl } from "utilities/googleTagsUtilities";

// ==== Types ====
import { IConfig, ERoutePaths } from "types/globalTypes";

interface IProps {
  config: IConfig;
}

const Lander: React.FunctionComponent<IProps> = ({ config }) => {
  const { t } = useTranslation();
  const clientsRef = React.useRef<HTMLParagraphElement>(null);
  const professionalsRef = React.useRef<HTMLParagraphElement>(null);

  return (
    <React.Fragment>
      <SiteHeader />
      <div className="cardWrapper">
        <Row align="top" gutter={[15, 15]} justify="space-between">
          <Col xs={24} md={12} xl={11}>
            <Card
              title={
                <span className="largeHeader">{t("lander.clients.title")}</span>
              }
            >
              <p ref={clientsRef}>{t("lander.clients.intro")}</p>
              <Row align="middle" justify="space-between">
                <Button
                  disabled={!config.clientUri}
                  onClick={() => assignNewUrl(config.clientUri)}
                  size="large"
                  type="primary"
                >
                  {t("lander.button", { platform: t("platforms.client") })}{" "}
                  <RightOutlined />
                </Button>
                <Link className="newUserLink" to={ERoutePaths.SIGNUP}>
                  {t("lander.newUser")}
                </Link>
              </Row>
            </Card>
          </Col>
          <Col xs={24} md={12} xl={11}>
            <Card
              title={
                <span className="largeHeader">
                  {t("lander.professionals.title")}
                </span>
              }
            >
              <p ref={professionalsRef}>{t("lander.professionals.intro")}</p>
              <Button
                onClick={() => assignNewUrl(config.proUri)}
                size="large"
                type="primary"
              >
                {t("lander.button", { platform: t("platforms.pro") })}{" "}
                <RightOutlined />
              </Button>
            </Card>
          </Col>
        </Row>
      </div>
      <BrowserWarningFooter />
    </React.Fragment>
  );
};

export default withAITracking(ai.reactPlugin, Lander);
